// load external libs
import React, { useCallback, useEffect, useState } from 'react';
import {
    IonToolbar,
    IonHeader,
    IonButtons,
    IonTitle,
    IonButton,
    IonIcon,
    IonMenuButton,
    IonBadge,
} from '@ionic/react';
import { arrowBack, ellipsisVertical, notificationsOutline } from 'ionicons/icons';

// load internal libs
import { IonicUtils } from '../../utils/ionic-utils';


// import external css files

// import internal css files
import './header.css';
import { useHistory } from 'react-router';
import { useSelector } from 'react-redux';
import { getNewNotificationsCount, getUserState } from '../../store/redux-store';
import { NotificationsService } from '../../services/notifications-service';


type HeaderProps = {
    title: string,
    backUrl: string,
    displayMenuButton: boolean,
    showRightMenuBtn: boolean,
    children?: React.ReactNode
}


export function Header(props: HeaderProps) {

    let [_titleTextSize] = useState<"small" | "large">(IonicUtils.isMobile() ? "small" : "large")
    const _history = useHistory();
    const _userLogedIn = useSelector(getUserState)
    const _newNotificationsCount = useSelector(getNewNotificationsCount)

    let goToNotificationsPage = useCallback(() => {
        _history.push('/notifications');
    }, [_history]);


    // FIXME : this is not the place to do this. But for some reason it causes some bug when done in app component.
    useEffect(() => {
        if (_userLogedIn) {
            NotificationsService.initialize();
        }
    }, [_userLogedIn])

    return (
        <IonHeader>
            <IonToolbar color="primary">
                <IonButtons slot="start">
                    {
                        props.displayMenuButton ?
                            <IonMenuButton autoHide={false} />
                            :
                            <IonButton routerLink={props.backUrl}>
                                <IonIcon slot="icon-only" icon={arrowBack} />
                            </IonButton>
                    }
                </IonButtons>

                <IonTitle size={_titleTextSize} color='light'>{props.title}</IonTitle>

                {
                    props.showRightMenuBtn &&
                    <IonButtons slot="end">
                        {props.children}
                        <IonButton onClick={goToNotificationsPage}>
                            <IonIcon icon={notificationsOutline} />
                            {
                                _newNotificationsCount > 0 &&
                                <IonBadge className="header-badge" color="warning">{_newNotificationsCount < 9 ? _newNotificationsCount : 9}</IonBadge>
                            }
                        </IonButton>
                        <IonMenuButton menu="right" autoHide={false} >
                            <IonIcon icon={ellipsisVertical} />
                        </IonMenuButton>
                    </IonButtons>
                }


            </IonToolbar>
        </IonHeader >
    );
}
