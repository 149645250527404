import { IonCheckbox, IonCol, IonGrid, IonItem, IonLabel, IonRow, IonIcon } from '@ionic/react';
import React, { useState } from 'react';
import { TextInputItem } from '../../../../components/text-input/text-input';
import { CheckboxChangeEventDetail } from '@ionic/core/dist/types/components/checkbox/checkbox-interface'
import { TextareaChangeEventDetail } from '@ionic/core/dist/types/components/textarea/textarea-interface';
import { InputChangeEventDetail } from '@ionic/core/dist/types/components/input/input-interface';
import { Dialog } from '../../../../components/dialog/dialog';
import { help } from 'ionicons/icons';
import { AutocompleteOption } from '../../../../components/autocomplete/autocomplete-option';

type DigitalReportHomeStep2Props = {
    onChangeHarmedPeopleCheckbox: (event: CustomEvent<CheckboxChangeEventDetail>) => void,
    onChangeDamagedPropertyCheckbox: (event: CustomEvent<CheckboxChangeEventDetail>) => void,
    onChangeSpecificDataCheckbox: (event: CustomEvent<CheckboxChangeEventDetail>) => void,
    onChangeExplanations: (event: CustomEvent<TextareaChangeEventDetail | InputChangeEventDetail>) => void,
    onChangeMainFact: (event: React.ChangeEvent<{}>, value: string | string[] | null) => void,
    maintFact: string | null,
    mainFacts: string[],
    harmedPeople: { [x: string]: any },
    selectedHarmedPeople: string[],
    damagedProperty: { [x: string]: any },
    selectedDamagedProperty: string[],
    specificData: { [x: string]: any },
    selectedSpecificData: string[],
    explanations: string
}

const MAX_LENGTH_EXPLAINATION_TEXT_AREA = 820;


export function DigitalReportHomeStep2(props: DigitalReportHomeStep2Props) {

    const [_showHelpDialog, _setShowHelpDialog] = useState<boolean>(false);

    const handleHelpDialogOkButton = () => {
        _setShowHelpDialog(false)
    }

    return (
        <>
            <div className="digital-report-home-title">
                Détails
            </div>
            <IonGrid>
                <IonRow>
                    <IonCol size="6" sizeXl="6" size-lg="6" size-md="6" size-sm="12" size-xs="12" className="digital-report-home-col" >

                        <div className="digital-report-section">
                            <div className="digital-report-home-section-title">
                                Fait principal
                            </div>
                            <AutocompleteOption
                                value={props.maintFact}
                                options={props.mainFacts}
                                placeHolder="Sélectionner le fait principal"
                                onChange={props.onChangeMainFact}
                            />
                        </div>

                        <div className="digital-report-section">
                            <div className="digital-report-home-section-title">
                                Atteintes
                            </div>

                            <IonGrid>
                                <IonRow>
                                    {
                                        Object.keys(props.damagedProperty).map((key) => {
                                            return (
                                                <IonCol key={key} size="6" sizeXl="6" size-lg="6" size-md="6" size-sm="12" size-xs="12" >
                                                    <IonItem className="gdh-top-label-item">
                                                        <IonLabel>{props.damagedProperty[key]}</IonLabel>
                                                        <IonCheckbox value={key} slot="start"
                                                            checked={props.selectedDamagedProperty.includes(key)}
                                                            onIonChange={props.onChangeDamagedPropertyCheckbox}
                                                        />
                                                    </IonItem>
                                                </IonCol>
                                            )
                                        })
                                    }
                                </IonRow>
                            </IonGrid>
                        </div>

                    </IonCol>

                    <IonCol size="6" sizeXl="6" size-lg="6" size-md="6" size-sm="12" size-xs="12" className="digital-report-home-col" >

                        <div className="digital-report-section">
                            <div className="digital-report-home-section-title">
                                Atteintes aux personnes
                            </div>
                            <IonGrid>
                                <IonRow>
                                    {
                                        Object.keys(props.harmedPeople).map((key) => {
                                            return (
                                                <IonCol key={key} size="6" sizeXl="6" size-lg="6" size-md="6" size-sm="12" size-xs="12" >
                                                    <IonItem className="gdh-top-label-item">
                                                        <IonLabel>{props.harmedPeople[key]}</IonLabel>
                                                        <IonCheckbox value={key} slot="start"
                                                            checked={props.selectedHarmedPeople.includes(key)}
                                                            onIonChange={props.onChangeHarmedPeopleCheckbox}
                                                        />
                                                    </IonItem>
                                                </IonCol>
                                            )
                                        })
                                    }
                                </IonRow>
                            </IonGrid>
                        </div>


                        <div className="digital-report-section">
                            <div className="digital-report-home-section-title">
                                Atteintes aux animaux
                            </div>
                            <IonGrid>
                                <IonRow>
                                    {
                                        Object.keys(props.specificData).map((key) => {
                                            return (
                                                <IonCol key={key} size="6" sizeXl="6" size-lg="6" size-md="6" size-sm="12" size-xs="12" >
                                                    <IonItem className="gdh-top-label-item">
                                                        <IonLabel>{props.specificData[key]}</IonLabel>
                                                        <IonCheckbox value={key} slot="start"
                                                            checked={props.selectedSpecificData.includes(key)}
                                                            onIonChange={props.onChangeSpecificDataCheckbox}
                                                        />
                                                    </IonItem>
                                                </IonCol>
                                            )
                                        })
                                    }
                                </IonRow>
                            </IonGrid>
                        </div>

                        <div className="digital-report-section">
                            <div className="digital-report-home-section-title">
                                Explications détaillées des faits
                                <IonIcon slot="icon-only" icon={help} onClick={() => _setShowHelpDialog(true)} className="help-icon"></IonIcon>
                            </div>

                            <TextInputItem
                                className="gdh-top-label-item-input gdh-textarea-explanations"
                                handleChangeArea={props.onChangeExplanations}
                                value={props.explanations}
                                label=""
                                required={false}
                                isArea={true}
                                textLineProps={{ placeholder: "" }}
                                maxLen={MAX_LENGTH_EXPLAINATION_TEXT_AREA}
                            />

                        </div>
                    </IonCol>
                </IonRow>
            </IonGrid>
            <Dialog
                open={_showHelpDialog}
                title="Aide"
                content={
                    <>
                        <span className="digital-report-help-dialog-title">1/ Concernant la date des faits :</span>
                        <span className="digital-report-help-dialog-content">Il est important de bien penser à la préciser et d’envoyer la fiche le plus rapidement possible.</span>
                        <span className="digital-report-help-dialog-title">2/ Concernant le numéro de téléphone </span>
                        <span className="digital-report-help-dialog-content">Bien penser à indiquer son numéro pour que les forces de l’ordre puissent vous joindre s’ils souhaitent obtenir rapidement des précisions.</span>
                        <span className="digital-report-help-dialog-title">3/ Concernant l’adresse des faits :</span>
                        <span className="digital-report-help-dialog-content">Il est important de donner le plus de précisions possibles sur le lieu des faits.Si nécessaire, vous pouvez mettre une croix sur un plan pour bien situer l'endroit visé (Vous pouvez utiliser les plans de masse ou faire des Print écran depuis Google Map).</span>
                        <span className="digital-report-help-dialog-title">5/ Attention au respect des règles CNIL :</span>
                        <span className="digital-report-help-dialog-content">Il est interdit d’inscrire des données à caractère personnel.</span>
                        <span className="digital-report-help-dialog-title">6/ Ne pas affirmer de faits non vérifiés (employer le conditionnel en cas de doute) et rester vigilant sur les termes employés.</span>
                        <span className="digital-report-help-dialog-content">Par exemple, en cas de regroupements de jeunes dans les parties communes, ne pas utiliser le terme « squat ».En effet le squat a une terminologie juridique particulière. Il fait référence à l’occupation d’un logement sans titre et renvoie à une autre procédure particulière.</span>
                    </>
                }
                onConfirm={handleHelpDialogOkButton}
                showCancelButton={false}
            />
        </>
    )


}