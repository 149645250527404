/* eslint-disable no-use-before-define */
import React from 'react';
import Autocomplete from '@mui/lab/Autocomplete';
import TextField from '@mui/material/TextField';
import { useCallback } from 'react';
import "./autocomplete.css"

type AutocompleteInputProps = {
	options: any[],
	placeHolder: string,
	onChange: (event: React.ChangeEvent<{}>, value: string | string[] | null) => void,
	value: any[] | any | null,
	disabled?: boolean,
	multiple?: boolean,
	getOptionLabel?: (option: any) => string,
	onChangeText?: (event: React.ChangeEvent<{}>) => void,
	disableFiltering?: boolean,
	freeSolo?: boolean,
}

export type AutocompleteCustomElement = { [x: string]: string } & { key: any, content: any }

export const useAutocompleteOption = function (
	fetchDataFunction: (searchQuery: string) => Promise<any[]>,
	elementKey: string,
	elementToLabel: (element: { [x: string]: string }) => string,): [
		AutocompleteCustomElement[],
		AutocompleteCustomElement | null,
		(event: React.ChangeEvent<{}>) => void,
		(event: React.ChangeEvent<{}>, value: any) => void,
	] {
	const [_elements, _setElements] = React.useState<AutocompleteCustomElement[]>([]);
	const [_selectedElement, _setSelectedElement] = React.useState<AutocompleteCustomElement | null>(null);

	const onChangeSelectedElement = (event: React.ChangeEvent<{}>, value: any) => {
		if (Array.isArray(value)) {
			console.error("Unable to handle array value", value)
			return;
		}

		_setSelectedElement(value);
	}

	const onChangeText = async (event: React.ChangeEvent<{}>) => {
		const fieldContent = (event.target as HTMLInputElement).value;

		if ((fieldContent === undefined) || !fieldContent || (fieldContent.length === 0)) {
			_setElements([])
			return
		}

		try {
			let elements = await fetchDataFunction(fieldContent);

			elements = elements.map((element: { [x: string]: string }, index: any) => {
				element.content = elementToLabel(element);
				element.key = element[elementKey];
				return element;
			})

			// this is a temporary fix, because the eai has a bug that send duplicate data
			let filteredElements: AutocompleteCustomElement[] = []
			elements.forEach((element: AutocompleteCustomElement) => {
				if (!filteredElements.some(filteredElement => filteredElement.key === element.key)) {
					filteredElements.push(element)
				}
			})

			_setElements(filteredElements);
		} catch (error) {
			console.error(error);
			_setElements([])
		}
	}

	return [
		_elements,
		_selectedElement,
		onChangeText,
		onChangeSelectedElement,
	]
}

export function AutocompleteOption(props: AutocompleteInputProps) {

	const getDefaultOptionLabel = useCallback((option: any) => {
		return option;
	}, [])

	const getOptionLabel = props.getOptionLabel ?? getDefaultOptionLabel;

	const filteringFunction = props.disableFiltering ? (x: any) => x : undefined;

	return (
		<div className="autocomplete-container">
			<Autocomplete
				multiple={props.multiple}
				id="tags-standard"
				options={props.options}
				getOptionLabel={getOptionLabel}
				disabled={props.disabled === true}
				value={props.value}
				onChange={props.onChange}
				filterOptions={filteringFunction}
				freeSolo={props.freeSolo ?? false}
				renderInput={(params) => (
					<TextField
						{...params}
						variant="standard"
						onChange={props.onChangeText}
						label={props.placeHolder}
						onKeyPress={(ev) => {
							if (ev.key === 'Enter') {
								// to prevent default enter event handling
								ev.preventDefault();
							}
						}}
					/>
				)}
			/>
		</div>
	);
}
