import React from 'react';
import { IonCard, IonCardTitle, IonCardSubtitle, IonCardContent } from "@ionic/react";

import './web-query-card.css'


export function WebQueryCard(props: any) {

    const { title, subtitle, description, note, onClick, id } = props;
    const className = props.className ? props.className : "";
    return (
        <IonCard className={"text-center ion-padding web-query-card-container " + className} onClick={() => onClick(id)}>
            <IonCardTitle className="web-query-card-title">
                {title}
            </IonCardTitle>

            <hr className="web-query-separator" />

            <IonCardSubtitle className="web-query-card-subtitle">
                {subtitle}
            </IonCardSubtitle>

            <hr className="web-query-separator" />

            <IonCardContent className="web-query-card-content">
                {description}
            </IonCardContent>

            <div className="web-query-card-note">
                {note}
            </div>
        </IonCard>
    )



}