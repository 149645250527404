import React, { Component, FormEvent, createRef, RefObject } from 'react';
import {
    IonCard,
    IonItem,
    IonLabel,
    IonButton,
    IonText,
    IonSelectOption,
    IonSelect,
    IonCheckbox,
    IonModal,
    IonHeader,
    IonToolbar,
    IonButtons,
    IonTitle,
    IonContent,
} from '@ionic/react';

import './add-flash.css';
import DataService from '../../../../services/data-service';
import GDHToast from '../../../../components/toast/toast';
import { TextInputItem } from '../../../../components/text-input/text-input';
import { SelectionPopover } from '../../../../components/selection-popover/selection-popover';
import { InApp } from '../../../../components/in-app/in-app';
import ConfigUtils from '../../../../utils/config-utils';
import { Store } from '../../../../services/store';
import { SelectChangeEventDetail } from '@ionic/core/dist/types/interface';
import { CheckboxChangeEventDetail } from '@ionic/core/dist/types/components/checkbox/checkbox-interface'
import { AutocompleteContacts } from '../../../../components/autocomplete/autocomplete-contacts';
import { ContactMail } from '../../../../interfaces/contact-mail.interface';
import { StorageUtils } from '../../../../utils/storage-utils';
import { TenantData } from '../../../../interfaces/tenant-data';
import { IonicUtils } from '../../../../utils/ionic-utils';
import { CampaignTypeData } from '../../../../interfaces/campaign-type-data';
import { SmsProposalData } from '../../../../interfaces/sms-proposal-data';
import RightMenu from '../../../../components/menu/right-menu/right-menu';
import ReactGA from 'react-ga4'
import { AccordionData, GdhAccordion } from '../../../../components/accordion/accordion';
import { AutocompleteCustomElement, AutocompleteOption } from '../../../../components/autocomplete/autocomplete-option';

const TOAST_MESSAGE_CREATION_ERROR = 'Erreur de creation';
const TOAST_MESSAGE_NO_TENANT = 'Veuillez ajouter des locataires';
const TOAST_MESSAGE_TEXT_TOO_LONG = 'Certains champs de texte sont trop longs';
const TOAST_MESSAGE_NO_SMS_PROPOSAL = 'Veuillez ajouter une proposition de sms';
const TOAST_MESSAGE_MISSING_PARAM = 'Veuillez remplir tous les paramètres de la proposition SMS'


const INITIAL_STATE = {
    title: '',
    description: '',
    nature: '',
    natures: [],
    selectedHp1Residence: '',
    hp1Residence: '',
    hp1ResidencesList: [],
    showToast: false,
    success: false,
    open: false,
    anchor: null,
    isPrimaryLoading: false,
    isSecondaryLoading: false,
    addedCampaignId: null,

    displayBuildings: [],
    selectedBuildings: [],
    availableBuildings: [],

    displayEntrances: [],
    selectedEntrances: [],
    availableEntrances: [],

    displayContacts: [],
    selectedContacts: [],
    availableContacts: [],

    createForSomeoneElse: false,
    createCapaignFor: [],
    smsProposal: '',
    isCreator: null,
    toastMessage: '',
    selectedSmsTemplate: null,
    smsProposalSelectValue: undefined,
    category: null,
    showLibrairyPopover: false,
    librairyAccordionExpanded: null,
    previousLibrairyAccordionExpanded: null,
    smsTemplate: '',
    smsTemplateCategoryes: [],

    smsProposalParams: {},
    smsProposalBase: '',

    _elements: [],
    _selectedElement: null,
};



type Contact = {
    buildingKey: string,
    entranceKey: string,
    key: string,
    label: string,
    isActive: boolean
}

type Entrance = {
    buildingKey: string,
    key: string,
    label: string
}

type Building = {
    key: string,
    label: string
}

const TITLE_MAX_LEN: number = 75;
const DESCR_MAX_LEN: number = 300;
const SMS_PROPOSAL_MAX_LEN: number = 360;

// TODO : ca devrait plutot se nommer add-campaign


export default class AddFlash extends Component<any, any> {
    private modalTitle: RefObject<HTMLIonModalElement>
    private modalDescription: RefObject<HTMLIonModalElement>
    private modalSmsProposal: RefObject<HTMLIonModalElement>
    private modalSmsTemplate: RefObject<HTMLIonModalElement>
    private inputTitle: RefObject<any>
    private inputDescription: RefObject<any>
    private inputSmsProposal: RefObject<any>
    private inputSmsTemplate: RefObject<any>

    constructor(props: any) {
        super(props);
        this.state = { ...INITIAL_STATE };

        this.modalTitle = createRef<HTMLIonModalElement>()
        this.modalDescription = createRef<HTMLIonModalElement>()
        this.modalSmsProposal = createRef<HTMLIonModalElement>()
        this.modalSmsTemplate = createRef<HTMLIonModalElement>()
        this.inputTitle = createRef<any>()
        this.inputDescription = createRef<any>()
        this.inputSmsProposal = createRef<any>()
        this.inputSmsTemplate = createRef<any>()

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleTitleChange = this.handleTitleChange.bind(this);
        this.handleDescriptionChange = this.handleDescriptionChange.bind(this);
        this.handleNatureChange = this.handleNatureChange.bind(this);
        this.handleCategoryChange = this.handleCategoryChange.bind(this);
        this.onClickLibrairyPopoverCancel = this.onClickLibrairyPopoverCancel.bind(this);
        this.onClickLibrairyPopoverOk = this.onClickLibrairyPopoverOk.bind(this);
        this.handleLibrairyAccordionChange = this.handleLibrairyAccordionChange.bind(this);
        this.onDidDismissLibrairyPopover = this.onDidDismissLibrairyPopover.bind(this);
        this.onClickLibrairyInput = this.onClickLibrairyInput.bind(this);
        this.handleHp1Change = this.handleHp1Change.bind(this);
        this.onClickResidence = this.onClickResidence.bind(this);
        this.initContactsEntrancesBuildings = this.initContactsEntrancesBuildings.bind(this);
        this.handleToastClose = this.handleToastClose.bind(this);
        this.addTenants = this.addTenants.bind(this);
        this.handleEntrancesSelectionChange = this.handleEntrancesSelectionChange.bind(this);
        this.computeDisplayEntrancesFromSelectedBuildings = this.computeDisplayEntrancesFromSelectedBuildings.bind(this);
        this.computeDisplayContactsFromSelectedContacts = this.computeDisplayContactsFromSelectedContacts.bind(this);
        this.handleBuildingsSelectionChange = this.handleBuildingsSelectionChange.bind(this);
        this.handleContactsSelectionChange = this.handleContactsSelectionChange.bind(this);
        this.onClickCreateForSomeoneElseCheckbox = this.onClickCreateForSomeoneElseCheckbox.bind(this);
        this.onSelectSomeoneToCreateCampaignFor = this.onSelectSomeoneToCreateCampaignFor.bind(this);
        this.handleSmsProposalChange = this.handleSmsProposalChange.bind(this);
        this.handleSmsTemplateChange = this.handleSmsTemplateChange.bind(this);
        this.handleSmsProposalParamsChange = this.handleSmsProposalParamsChange.bind(this);
        this.fetchDataFunction = this.fetchDataFunction.bind(this);
        this.elementToLabel = this.elementToLabel.bind(this);
        this._setElements = this._setElements.bind(this);
        this._setSelectedElement = this._setSelectedElement.bind(this);
        this.onChangeSelectedElement = this.onChangeSelectedElement.bind(this);
        this.onChangeText = this.onChangeText.bind(this);
    }

    async componentDidMount() {
        this.setState({ isSecondaryLoading: true });
        try {
            const smsTemplateCategoryes = await DataService.getSmsTemplates();
            this.setState({ smsTemplateCategoryes: smsTemplateCategoryes })
            let natures = await DataService.getCampaignTypes();
            const isCreator = await StorageUtils.getInstance().getIsCreator();
            this.setState({
                natures: natures,
                isCreator: isCreator
            });
        } catch (error) {
            console.error(error);
        }
        this.setState({ isSecondaryLoading: false });
    }

    async handleSubmit(e: FormEvent) {
        e.preventDefault();

        // cannot create a flash if text fields are too long
        if ((this.state.description.length > DESCR_MAX_LEN)
            || (this.state.title.length > TITLE_MAX_LEN)
            || (this.state.smsProposal.length > SMS_PROPOSAL_MAX_LEN)) {
            this.setState({
                showToast: true,
                success: false,
                toastMessage: TOAST_MESSAGE_TEXT_TOO_LONG
            });
            return;
        }

        // cannot create a flash without any tenants
        if (!this.state.selectedContacts || (this.state.selectedContacts.length < 1)) {
            this.setState({
                showToast: true,
                success: false,
                toastMessage: TOAST_MESSAGE_NO_TENANT
            });
            return;
        }

        // cannot create a demand without a sms proposal
        if (!this.state.isCreator && (this.state.smsProposal.length < 1)) {
            this.setState({
                showToast: true,
                success: false,
                toastMessage: TOAST_MESSAGE_NO_SMS_PROPOSAL
            });
            return;
        }

        // We check if there is no missing params
        if (Object.keys(this.state.smsProposalParams).length > 0) {
            let missingParam: boolean = false
            Object.keys(this.state.smsProposalParams).forEach((key: string) => {
                if (!this.state.smsProposalParams[key]) {
                    missingParam = true;
                }
            })
            if (missingParam) {
                this.setState({
                    showToast: true,
                    success: false,
                    toastMessage: TOAST_MESSAGE_MISSING_PARAM
                });
                return;
            }
        }

        this.setState({ isSecondaryLoading: true });

        let demandFor;
        if (this.state.createForSomeoneElse && this.state.createCapaignFor[0]) {
            demandFor = this.state.createCapaignFor[0].id;
        }

        let smsProposal = (this.state.smsProposal.length === 0) ? undefined : this.state.smsProposal;

        try {
            let newCampaign = await DataService.addFlash(
                this.state.title,
                this.state.description,
                this.state.nature.id,
                this.state.selectedHp1Residence,
                demandFor,
                smsProposal);

            this.setState({ addedCampaignId: newCampaign.id })
            await this.addTenants(newCampaign.id);
        } catch (error) {
            console.error(error);
            this.setState({
                showToast: true,
                success: false,
                toastMessage: TOAST_MESSAGE_CREATION_ERROR
            });
        }
        this.setState({ isSecondaryLoading: false });
    }

    async addTenants(flashId: number) {
        let tenantsToAddQueries: any[] = [];
        let tenantsToDisableQueries: any[] = [];

        // add all existing tenants to the campaign
        for (let selectedContact of this.state.availableContacts) {
            tenantsToAddQueries.push(
                DataService.addTarget(
                    selectedContact.hp1,
                    selectedContact.hp2,
                    selectedContact.hp3,
                    selectedContact.noug,
                    selectedContact.lblContrat,
                    selectedContact.lblContrat,
                    selectedContact.noca,
                    selectedContact.telPor,
                    flashId)
            );
        }

        // disable not selected tenants
        let success;
        try {
            let addedTenants = await Promise.all(tenantsToAddQueries);

            for (let tenant of addedTenants) {
                // if the added contact is not part of the list of selected ones, disable it
                if (this.state.selectedContacts.some((selectedTenant: TenantData) => {
                    return (selectedTenant.hp3 === tenant.hp3) && (selectedTenant.telPor === tenant.telPort);
                }) === false) {
                    tenantsToDisableQueries.push(
                        DataService.disableCampaignTarget(flashId, tenant.id)
                    );
                }
            }
            await Promise.all(tenantsToDisableQueries);

            // rise a flag so next time campaign are displayed, they are fetched from the server and not from cache
            Store.setNeedToReloadCampaigns(true);
            success = true;
        } catch (error) {
            console.error(error);
            success = false;
        }

        this.setState({
            success: success,
            showToast: true,
            toastMessage: success ? ("Flash n°" + this.state.addedCampaignId + " créé") : TOAST_MESSAGE_CREATION_ERROR
        });

        if (success) {
            ReactGA.event({
                category: 'FLASH',
                action: 'FLASH_CREATION_SUCCESS'
            });
        }
    }

    handleTitleChange(e: any) {
        this.setState({ title: e?.detail?.value });
    }

    handleSmsProposalChange(e: any) {
        this.setState({ smsProposal: e?.detail?.value });
    }

    handleDescriptionChange(e: any) {
        this.setState({ description: e?.detail?.value });
    }

    handleNatureChange(e: CustomEvent<SelectChangeEventDetail<CampaignTypeData>>) {
        const nature: CampaignTypeData | undefined = e.detail.value;

        if (!this.state.isCreator) {
            this.setState({
                librairyAccordionExpanded: null,
                previousLibrairyAccordionExpanded: null,
                category: null,
                smsProposal: "",
                smsProposalParams: {},
            });
        }

        this.setState({
            nature: nature
        });
    }

    handleCategoryChange(e: CustomEvent<SelectChangeEventDetail<any>>) {
        this.setState({
            librairyAccordionExpanded: null,
            previousLibrairyAccordionExpanded: null,
            category: e.detail.value,
            smsProposal: "",
            smsProposalParams: {},
        });
    }

    handleSmsTemplateChange(e: CustomEvent<SelectChangeEventDetail<SmsProposalData>>) {
        if (!e?.detail?.value?.content) {
            return;
        }

        this.setState({
            smsProposal: e.detail.value.content,
            selectedSmsTemplate: e.detail.value
        })
    }

    async handleHp1Change(e: any) {
        const fieldContent = e.target.value;

        if (fieldContent === this.state.hp1Residence) {
            // if the value has not changed, return
            return;
        }

        this.setState({
            hp1Residence: fieldContent,
            anchor: e.currentTarget
        });

        if ((fieldContent === undefined) || (fieldContent.length === 0)) {
            this.setState({ open: false });
            return;
        }

        try {
            let residenceList = await DataService.searchResidence(fieldContent);
            if (fieldContent !== this.state.hp1Residence) {
                // the response does not match the current serached text, return
                return;
            }
            let open = residenceList.length !== 0;
            this.setState({
                hp1ResidencesList: residenceList,
                open: open
            })
        } catch (error) {
            console.error(error);
            if (fieldContent !== this.state.hp1Residence) {
                // the response does not match the current serached text, return
                return;
            }
            this.setState({
                hp1ResidencesList: [],
                open: false
            })
        }
    }

    onClickResidence(selectedResidence: any) {
        let hp1ToDisplay = (selectedResidence && selectedResidence.HPLBLPAT) ?
            (selectedResidence.HPCDPAT1 + " - " + selectedResidence.HPLBLPAT) : (selectedResidence.HPCDPAT1)

        this.setState({
            selectedHp1Residence: selectedResidence.HPCDPAT1,
            hp1Residence: hp1ToDisplay,
            open: false
        });

        DataService.getResidenceActiveContacts(selectedResidence.HPCDPAT1).then((response: any) => {
            let availableBuildingIds: string[] = [];
            for (const buildingId in response.BATIMENTS) {
                availableBuildingIds.push(buildingId)
            }
            this.initContactsEntrancesBuildings(response);
        }).catch((error) => {
            console.error(error);
        });
    }

    initContactsEntrancesBuildings(activeContacts: any) {

        // activeContacts is structured like this:
        //
        //  BATIMENTS
        //      BUILDING_INDEX
        //          ENTREES
        //              ENTRANCE_INDEX
        //                  CONTACTS
        //                      CONTACT_INDEX
        //                          contact data

        let contacts: Contact[] = [];
        let entrances: Entrance[] = [];
        let buildings: Building[] = [];

        // iterate over buildings
        let buildingsData = activeContacts.BATIMENTS;
        for (let buildingIndex in buildingsData) {
            let buildingData = buildingsData[buildingIndex];
            buildings.push({
                key: buildingIndex,
                label: buildingIndex + ", " + buildingData.INFOS.ADR
            });

            // for each building, iterate over entrances
            for (let entranceIndex in buildingData.ENTREES) {
                let entranceData = buildingData.ENTREES[entranceIndex];
                let entranceKey = buildingIndex + "-" + entranceIndex;
                entrances.push({
                    buildingKey: buildingIndex,
                    key: entranceKey,
                    label: buildingIndex + "-" + entranceIndex + ", " + entranceData.INFOS.ADR
                });

                // for each entrance, iterate over contacts
                for (let contactIndex in entranceData.CONTACTS) {
                    if (!entranceData.CONTACTS.hasOwnProperty(contactIndex)) {
                        // check that the itareted property is a not a prototype property
                        // this bug appears to be linked with orgChart lib which apparently add a "has" property...
                        continue;
                    }

                    const contactData: TenantData = entranceData.CONTACTS[contactIndex];
                    const isActive: boolean = (contactData.telPor !== undefined) && (contactData.telPor.length > 1);

                    contacts.push({
                        ...contactData,
                        buildingKey: buildingIndex,
                        entranceKey: entranceKey,
                        key: buildingIndex + "-" + entranceIndex + "-" + contactIndex,
                        label: buildingIndex + "-" + entranceIndex + "-" + contactData.noug + "-" + contactData.noca + ", " + contactData.lblContrat,
                        isActive: isActive
                    });
                }
            }
        }

        this.setState({
            displayBuildings: [...buildings],
            selectedBuildings: [...buildings],
            availableBuildings: [...buildings],

            displayEntrances: [...entrances],
            selectedEntrances: [...entrances],
            availableEntrances: [...entrances],

            displayContacts: [...contacts],
            selectedContacts: [...contacts.filter((contact: Contact) => { return contact.isActive })],
            availableContacts: [...contacts],
        })
    }

    computeDisplayEntrancesFromSelectedBuildings(selectedBuildings: Building[]): Entrance[] {
        let displayEntrances: Entrance[] = [];
        for (let entrance of this.state.availableEntrances) {
            // check if a building with the correct building key is in the selected building list
            if (selectedBuildings.find((building: Building) => building.key === entrance.buildingKey) !== undefined) {
                displayEntrances.push(entrance);
            }
        }
        return displayEntrances;
    }

    computeDisplayContactsFromSelectedContacts(selectedEntrances: Entrance[]): Contact[] {
        let displayContacts: Contact[] = [];
        for (let contact of this.state.availableContacts) {
            // check if a building with the correct building key is in the selected building list
            if (selectedEntrances.find((entrance: Entrance) => entrance.key === contact.entranceKey) !== undefined) {
                displayContacts.push(contact);
            }
        }
        return displayContacts;
    }


    handleEntrancesSelectionChange(event: CustomEvent<SelectChangeEventDetail>): void {
        let selectedEntrances = event.detail.value;

        // FIXME: for some reason, since the migration to ionic 6, this event is sometimes triggered with invalid string data
        if (!Array.isArray(selectedEntrances)) {
            return
        }

        let displayContacts: Contact[] = this.computeDisplayContactsFromSelectedContacts(selectedEntrances);

        this.setState({
            selectedEntrances: selectedEntrances,
            displayContacts: [...displayContacts],
            selectedContacts: [...displayContacts.filter((contact: Contact) => { return contact.isActive })],
        })
    }


    handleContactsSelectionChange(event: CustomEvent<SelectChangeEventDetail>): void {
        let selectedContacts = event.detail.value;

        // FIXME: for some reason, since the migration to ionic 6, this event is sometimes triggered with invalid string data
        if (!Array.isArray(selectedContacts)) {
            return
        }

        this.setState({
            selectedContacts: selectedContacts
        })
    }


    handleToastClose() {
        if (this.state.success && this.state.addedCampaignId) {
            this.props.history.push(ConfigUtils.getFlashBaseURL() + "/" + this.state.addedCampaignId + "/sms");
        } else {
            this.setState({
                showToast: false
            });
        }
    }

    handleBuildingsSelectionChange(event: CustomEvent<SelectChangeEventDetail>): void {
        let selectedBuildings = event.detail.value

        // FIXME: for some reason, since the migration to ionic 6, this event is sometimes triggered with invalid string data
        if (!Array.isArray(selectedBuildings)) {
            return
        }


        let displayEntrances: Entrance[] = this.computeDisplayEntrancesFromSelectedBuildings(selectedBuildings);

        // assuming all display entrances are selected
        let displayContacts: Contact[] = this.computeDisplayContactsFromSelectedContacts(displayEntrances);

        this.setState({
            selectedBuildings: selectedBuildings,
            displayEntrances: [...displayEntrances],
            selectedEntrances: [...displayEntrances],
            displayContacts: [...displayContacts],
            selectedContacts: [...displayContacts.filter((contact: Contact) => { return contact.isActive })],
        })
    }

    onClickCreateForSomeoneElseCheckbox(event: CustomEvent<CheckboxChangeEventDetail>) {
        this.setState({ createForSomeoneElse: event.detail.checked });
    }

    onSelectSomeoneToCreateCampaignFor(event: React.ChangeEvent<{}>, value: ContactMail | ContactMail[] | null) {
        // TODO : do not use multiple for AutocompleteContacts, and store createCapaignFor not as an array of 1

        // this shall not happen as AutocompleteContacts is multiple
        if (!value || !Array.isArray(value)) {
            console.error("Unable to handle null or not array value", value);
            return;
        }


        // this is a workaround to have a single element that ca be selected
        if (value.length > 1) {
            this.setState({ createCapaignFor: [value[1]] });
        } else {
            this.setState({ createCapaignFor: value });
        }
    }

    onClickLibrairyPopoverOk(event: React.MouseEvent<HTMLIonButtonElement, MouseEvent>) {
        this.setState({
            showLibrairyPopover: false,
            smsProposal: this.state.librairyAccordionExpanded?.content ?? ""
        })
    }

    onClickLibrairyPopoverCancel(event: React.MouseEvent<HTMLIonButtonElement, MouseEvent>) {
        this.setState({
            librairyAccordionExpanded: this.state.previousLibrairyAccordionExpanded,
            showLibrairyPopover: false,
            smsProposal: this.state.previousLibrairyAccordionExpanded?.content ?? ""
        })
    }

    onDidDismissLibrairyPopover(event: any) {
        this.setState({
            // previousLibrairyAccordionExpanded: this.state.librairyAccordionExpanded,
            showLibrairyPopover: false,
            smsProposal: this.state.librairyAccordionExpanded?.content ?? ""
        })
    }

    handleLibrairyAccordionChange(panel: AccordionData, event: React.ChangeEvent<{}>, isExpanded: boolean) {
        if (isExpanded) {
            this.setState({
                librairyAccordionExpanded: panel
            });
        } else {
            this.setState({ librairyAccordionExpanded: null });
        }
    }

    onClickLibrairyInput(event: any) {
        this.setState({
            showLibrairyPopover: true,
            previousLibrairyAccordionExpanded: this.state.librairyAccordionExpanded
        })
    }

    confirmModalTitle() {
        this.setState({ title: this.inputTitle.current?.value })
        this.modalTitle.current?.dismiss(this.inputTitle.current?.value, 'confirm');
    }

    confirmModalDescription() {
        this.setState({ description: this.inputDescription.current?.value })
        this.modalDescription.current?.dismiss(this.inputDescription.current?.value, 'confirm');
    }

    confirmModalSmsProposal() {
        this.setState({ smsProposal: this.inputSmsProposal.current?.value })
        this.modalSmsProposal.current?.dismiss(this.inputSmsProposal.current?.value, 'confirm');
    }

    confirmModalSmsTemplate() {
        // we initialise our object with all keys
        let proposalParams: any = {}
        this.state.librairyAccordionExpanded?.parameters?.forEach((parameter: any) => {
            proposalParams[parameter.code] = ""
        })
        this.setState({
            selectedSmsTemplate: this.inputSmsTemplate.current?.value,
            smsProposal: this.state.librairyAccordionExpanded?.content ?? '',
            smsProposalBase: this.state.librairyAccordionExpanded?.content ?? '',
            smsProposalParams: proposalParams,
        })
        this.modalSmsTemplate.current?.dismiss(this.inputSmsTemplate.current?.value, 'confirm');
    }

    dismissModalSmsTemplate() {
        this.modalSmsTemplate.current?.dismiss()
        this.setState({
            librairyAccordionExpanded: this.state.previousLibrairyAccordionExpanded,
            showLibrairyPopover: false,
            smsProposal: this.state.previousLibrairyAccordionExpanded?.content ?? ""
        })
    }

    handleSmsProposalParamsChange(event: any, codeParam: string) {
        let proposalWithParams = this.state.smsProposalBase
        let proposalParams = this.state.smsProposalParams
        proposalParams[codeParam] = event?.detail?.value

        Object.keys(proposalParams).forEach((key: string) => {
            if (proposalParams[key]) {
                proposalWithParams = proposalWithParams.replace(key, proposalParams[key])
            }
        })

        this.setState({
            smsProposal: proposalWithParams,
        })
    }

    /*
     *
     * /!\ FIXME This function is duplicated in AutocompleteOption hooks
     * transform this class in functionnal component and use the hook
     *  vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv
     */
    fetchDataFunction = DataService.searchResidence
    elementKey = 'HPCDPAT1'
    elementToLabel = (element: any) => element.HPLBLPAT + " " + element.HPCDPAT1

    _setElements(elements: AutocompleteCustomElement[]) {
        this.setState({ _elements: elements })
    }

    _setSelectedElement(element: AutocompleteCustomElement | null) {
        this.setState({ _selectedElement: element })

        if (!element) {
            return
        }

        this.onClickResidence(element)
    }

    onChangeSelectedElement(event: React.ChangeEvent<{}>, value: any) {
        if (Array.isArray(value)) {
            console.error("Unable to handle array value", value)
            return;
        }

        this._setSelectedElement(value);
    }

    async onChangeText(event: React.ChangeEvent<{}>) {
        const fieldContent = (event.target as HTMLInputElement).value;

        if ((fieldContent === undefined) || !fieldContent || (fieldContent.length === 0)) {
            this._setElements([])
            return
        }

        try {
            let elements = await this.fetchDataFunction(fieldContent);

            elements = elements.map((element: { [x: string]: string }, index: any) => {
                element.content = this.elementToLabel(element);
                element.key = element[this.elementKey];
                return element;
            })

            // this is a temporary fix, because the eai has a bug that send duplicate data
            let filteredElements: AutocompleteCustomElement[] = []
            elements.forEach((element: AutocompleteCustomElement) => {
                if (!filteredElements.some(filteredElement => filteredElement.key === element.key)) {
                    filteredElements.push(element)
                }
            })

            this._setElements(filteredElements);
        } catch (error) {
            console.error(error);
            this._setElements([])
        }
    }
    /*
     * ^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^
     * /!\ FIXME This function is duplicated in AutocompleteOption hooks
     * transform this class in functionnal component and use the hook
     *
     */

    render() {
        let title = ConfigUtils.getFlashApplicationName();
        if (this.state.isCreator !== null) {
            title += " - ";
            title += this.state.isCreator ? "Créer un nouveau flash" : "Créer une nouvelle demande";
        }

        return (
            <InApp
                isPrimaryLoading={this.state.isPrimaryLoading}
                isSecondaryLoading={this.state.isSecondaryLoading}
                backUrl={ConfigUtils.getFlashBaseURL()}
                title={title}
                displayMenuButton={false}
                showRightMenuBtn={true}
                rightMenu={<RightMenu />}
                refresh={null}
            >
                <IonCard>
                    <form onSubmit={this.handleSubmit}>

                        <TextInputItem
                            className="item-input ion-margin"
                            handleChangeArea={this.handleTitleChange}
                            value={this.state.title}
                            label="Titre de la campagne"
                            maxLen={TITLE_MAX_LEN}
                            blockAtMaxLen={!IonicUtils.isMobile()}
                            required={true}
                            isArea={false}
                            id="open-modal-title"
                        />

                        <IonModal ref={this.modalTitle} trigger="open-modal-title">
                            <IonHeader>
                                <IonToolbar>
                                    <IonButtons slot="start">
                                        <IonButton onClick={() => this.modalTitle.current?.dismiss()}>Annuler</IonButton>
                                    </IonButtons>
                                    <IonTitle className='add-flash-modal-title'>Titre</IonTitle>
                                    <IonButtons slot="end">
                                        <IonButton strong={true} onClick={() => this.confirmModalTitle()}>
                                            Confirmer
                                        </IonButton>
                                    </IonButtons>
                                </IonToolbar>
                            </IonHeader>
                            <IonContent className="ion-padding">
                                <TextInputItem isArea={true} innerRef={this.inputTitle} value={this.state.title ?? ""} className="add-flash-modal-text-area" />
                            </IonContent>
                        </IonModal>

                        <TextInputItem
                            className='item-input ion-margin'
                            value={this.state.description}
                            handleChangeArea={this.handleDescriptionChange}
                            maxLen={DESCR_MAX_LEN}
                            blockAtMaxLen={!IonicUtils.isMobile()}
                            label='Description de la campagne'
                            required={true}
                            isArea={false}
                            id="open-modal-description"
                        />

                        <IonModal ref={this.modalDescription} trigger="open-modal-description">
                            <IonHeader>
                                <IonToolbar>
                                    <IonButtons slot="start">
                                        <IonButton onClick={() => this.modalDescription.current?.dismiss()}>Annuler</IonButton>
                                    </IonButtons>
                                    <IonTitle className='add-flash-modal-title'>Description</IonTitle>
                                    <IonButtons slot="end">
                                        <IonButton strong={true} onClick={() => this.confirmModalDescription()}>
                                            Confirmer
                                        </IonButton>
                                    </IonButtons>
                                </IonToolbar>
                            </IonHeader>
                            <IonContent className="ion-padding">
                                <TextInputItem isArea={true} innerRef={this.inputDescription} value={this.state.description ?? ""} className="add-flash-modal-text-area" />
                            </IonContent>
                        </IonModal>

                        <IonItem className="item-input ion-margin">
                            <IonLabel position="floating">
                                Nature
                                <IonText color="danger"> *</IonText>
                            </IonLabel>
                            <IonSelect interface="popover" value={this.state.nature} onIonChange={this.handleNatureChange} >
                                {
                                    this.state.natures.map((element: any, index: any) => {
                                        return (
                                            <IonSelectOption value={element} key={index}>
                                                {element.name}
                                            </IonSelectOption>
                                        )
                                    })
                                }
                            </IonSelect>
                        </IonItem>

                        {
                            this.state.isCreator === false &&
                            <>
                                <IonItem className="item-input ion-margin">
                                    <IonLabel position="floating">
                                        Categorie
                                    </IonLabel>
                                    <IonSelect disabled={!this.state.nature} interface="popover" value={this.state.category} onIonChange={this.handleCategoryChange} >
                                        {
                                            this.state.smsTemplateCategoryes &&
                                            this.state.smsTemplateCategoryes.filter((smsTemplateCategory: any) => {
                                                return (this.state.nature.id === smsTemplateCategory.campagneType.id)
                                            }).map((element: any) => {
                                                return (
                                                    <IonSelectOption value={element} key={element.id}>
                                                        {element.name}
                                                    </IonSelectOption>
                                                )
                                            })
                                        }
                                    </IonSelect>
                                </IonItem>

                                <TextInputItem
                                    className="item-input ion-margin"
                                    disabled={!this.state.category}
                                    handleChangeInput={() => null}
                                    value={this.state.librairyAccordionExpanded?.name}
                                    isArea={false}
                                    label="Modèle de SMS"
                                    onClick={this.onClickLibrairyInput}
                                    id="open-modal-sms-template"
                                />

                                <IonModal ref={this.modalSmsTemplate} trigger="open-modal-sms-template">
                                    <IonHeader>
                                        <IonToolbar>
                                            <IonButtons slot="start">
                                                <IonButton onClick={() => this.dismissModalSmsTemplate()}>Annuler</IonButton>
                                            </IonButtons>
                                            <IonTitle className='add-flash-modal-title'>Proposition SMS</IonTitle>
                                            <IonButtons slot="end">
                                                <IonButton strong={true} onClick={() => this.confirmModalSmsTemplate()}>
                                                    Confirmer
                                                </IonButton>
                                            </IonButtons>
                                        </IonToolbar>
                                    </IonHeader>
                                    <IonContent className="ion-padding">
                                        <GdhAccordion
                                            data={this.state.category?.propositions.map((proposition: any) => proposition.propositionSms)}
                                            handleChange={this.handleLibrairyAccordionChange}
                                            expanded={this.state.librairyAccordionExpanded}
                                        />
                                    </IonContent>
                                </IonModal>

                                <TextInputItem
                                    className="item-input ion-margin"
                                    handleChangeArea={this.handleSmsProposalChange}
                                    value={this.state.smsProposal}
                                    label="Proposition texte sms"
                                    maxLen={SMS_PROPOSAL_MAX_LEN}
                                    blockAtMaxLen={!IonicUtils.isMobile()}
                                    required={true}
                                    isArea={false}
                                    id="open-modal-sms-proposal"
                                />

                                <IonModal ref={this.modalSmsProposal} trigger="open-modal-sms-proposal">
                                    <IonHeader>
                                        <IonToolbar>
                                            <IonButtons slot="start">
                                                <IonButton onClick={() => this.modalSmsProposal.current?.dismiss()}>Annuler</IonButton>
                                            </IonButtons>
                                            <IonTitle className='add-flash-modal-title'>Proposition SMS</IonTitle>
                                            <IonButtons slot="end">
                                                <IonButton strong={true} onClick={() => this.confirmModalSmsProposal()}>
                                                    Confirmer
                                                </IonButton>
                                            </IonButtons>
                                        </IonToolbar>
                                    </IonHeader>
                                    <IonContent className="ion-padding">
                                        <TextInputItem readonly={this.state.librairyAccordionExpanded?.parameters?.length > 0} isArea={true} innerRef={this.inputSmsProposal} value={this.state.smsProposal ?? ""} className={this.state.librairyAccordionExpanded?.parameters?.length > 0 ? "add-flash-modal-text-area add-flash-modal-text-area-readonly" : "add-flash-modal-text-area"} />
                                        {
                                            this.state.librairyAccordionExpanded?.parameters.map((parameter: any) => {
                                                return <TextInputItem
                                                    className="item-input ion-margin"
                                                    handleChangeInput={(event) => this.handleSmsProposalParamsChange(event, parameter.code)}
                                                    value={this.state.smsProposalParams[parameter.code]}
                                                    label={"Paramètre : " + parameter.code}
                                                    blockAtMaxLen={!IonicUtils.isMobile()}
                                                    required={true}
                                                    isArea={false}
                                                    id={parameter.code}
                                                />
                                            })
                                        }
                                    </IonContent>
                                </IonModal>

                            </>
                        }

                        <IonItem className="item-input ion-margin">
                            <AutocompleteOption
                                value={this.state._selectedElement}
                                options={this.state._elements}
                                getOptionLabel={(option: any) => option.content}
                                placeHolder="Sélection de la résidence"
                                onChange={this.onChangeSelectedElement}
                                onChangeText={this.onChangeText}
                                disableFiltering={true}
                            />
                        </IonItem>

                        <MultipleSelectionPopup
                            title={"Choix des bâtiments " + this.state.selectedHp1Residence}
                            availableOptions={this.state.displayBuildings}
                            selectedOptions={this.state.selectedBuildings}
                            onChange={this.handleBuildingsSelectionChange}
                        />

                        <MultipleSelectionPopup
                            title={"Choix des entrées " + this.state.selectedHp1Residence}
                            availableOptions={this.state.displayEntrances}
                            selectedOptions={this.state.selectedEntrances}
                            onChange={this.handleEntrancesSelectionChange}
                        />

                        <MultipleSelectionPopup
                            title={"Choix des contacts " + this.state.selectedHp1Residence}
                            availableOptions={this.state.displayContacts}
                            selectedOptions={this.state.selectedContacts}
                            onChange={this.handleContactsSelectionChange}
                        />


                        <IonItem className="ion-margin">
                            <IonLabel>Créer ce flash pour un collaborateur</IonLabel>
                            <IonCheckbox slot="start" checked={this.state.createForSomeoneElse} onIonChange={this.onClickCreateForSomeoneElseCheckbox} />
                        </IonItem>
                        {
                            this.state.createForSomeoneElse &&
                            <IonItem className="ion-margin">
                                <AutocompleteContacts
                                    onChange={this.onSelectSomeoneToCreateCampaignFor}
                                    value={this.state.createCapaignFor}
                                    multiple
                                />
                            </IonItem>
                        }

                        <IonItem className="ion-margin">
                            <IonLabel>Contacts sélectionnés : {this.state.selectedContacts.length}</IonLabel>
                        </IonItem>

                        <div className="ion-padding">
                            <IonButton expand="block" type="submit">Créer</IonButton>
                        </div>
                    </form>
                </IonCard>

                <GDHToast
                    show={this.state.showToast}
                    handleClose={this.handleToastClose}
                    success={this.state.success}
                    content={this.state.toastMessage}
                />
            </InApp>
        );
    }

}


type MultipleSelectionOption = {
    key: string,
    label: string,
    isActive?: boolean
}

type MultipleSelectionPopupProps = {
    title: string,
    availableOptions: MultipleSelectionOption[],
    selectedOptions: MultipleSelectionOption[],
    onChange: (event: CustomEvent<SelectChangeEventDetail>) => void
}

function MultipleSelectionPopup(props: MultipleSelectionPopupProps) {
    return (
        <IonItem className="item-input ion-margin">
            <IonLabel position="floating">{props.title}</IonLabel>
            <IonSelect okText="Ok" multiple={true} cancelText="Annuler" value={props.selectedOptions} onIonChange={props.onChange} >
                {
                    props.availableOptions.map((availableOption: MultipleSelectionOption) => {
                        return (
                            <IonSelectOption disabled={availableOption.isActive === false} value={availableOption} key={availableOption.key}>{availableOption.label}</IonSelectOption>
                        )
                    })
                }
            </IonSelect>
        </IonItem>
    )
}
