import React from 'react';
import * as serviceWorker from './serviceWorker';
import { Provider } from 'react-redux';
import { ReduxStore } from './store/redux-store'
import { createRoot } from 'react-dom/client'

import App from './App';

const container = document.getElementById('root')
const root = createRoot(container!)
root.render(
    <Provider store={ReduxStore}>
        <App />
    </ Provider>
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
