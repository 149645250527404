import { NotificationData } from "../interfaces/notification-data";
import { ACTION_SET_NEW_NOTIFICATIONS_COUNT, ACTION_SET_NOTIFICATIONS, ReduxStore } from "../store/redux-store";
import DataService from "./data-service";

export class NotificationsService {

    private constructor() { }

    public static s_initialized = false;

    public static async initialize() {
        if (this.s_initialized) {
            return;
        }
        try {
            const notifications = await DataService.getNotificationsHistory(false);
            ReduxStore.dispatch({ type: ACTION_SET_NOTIFICATIONS, payload: { notifications: notifications } });
            const count = notifications.filter((value: NotificationData) => (value.read === false)).length;
            ReduxStore.dispatch({ type: ACTION_SET_NEW_NOTIFICATIONS_COUNT, payload: { newNotificationsCount: count } });
            this.s_initialized = true;
        } catch (error) {
            console.error("Unable to fetch notifications, error: ", error);
        }
    }
}