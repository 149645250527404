import { IonButton } from '@ionic/react';
import React, { useRef } from 'react';
import { IonicUtils } from '../../utils/ionic-utils';


type FileInputButtonProps = {
    onInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void,
    acceptedExtentions: string,
    disabled?: boolean
}


export function FileInputButton(props: FileInputButtonProps) {

    const _inputRef = useRef<HTMLInputElement>(null);
    const disabled = props.disabled ?? false;
    /*
     * The html input field is hidden for design reasons. So a click on the add buton emulates a click on the html input button.
     */
    const onClickAddFileButton = () => {
        if (!_inputRef?.current) {
            return;
        }
        _inputRef.current.click();
    }

    return (
        <>
            <IonButton disabled={disabled} expand="block" onClick={onClickAddFileButton}>
                Ajouter un document
            </IonButton>
            {
                IonicUtils.isBrowser() ?
                    <input ref={_inputRef} multiple onChange={props.onInputChange} type="file" accept={props.acceptedExtentions} hidden />
                    :
                    <input ref={_inputRef} multiple onChange={props.onInputChange} type="file" hidden />
            }
        </>
    )
}