/* eslint-disable no-use-before-define */
import React from 'react';
import Autocomplete from '@mui/lab/Autocomplete';
import TextField from '@mui/material/TextField';
import { ContactMail } from '../../interfaces/contact-mail.interface';
import { useCallback } from 'react';


type AutocompleteInputProps = {
	availablePersons: ContactMail[],
	placeHolder: string,
	onChange: (event: React.ChangeEvent<{}>, value: ContactMail | ContactMail[] | null) => void,
	value: ContactMail[],
	disabled?: boolean,
	multiple?: boolean
}

export function AutocompleteInput(props: AutocompleteInputProps) {

	const getOptionLabel = useCallback((option: any) => {
		const firstname = option.firstname ?? "";
		const lastname = option.lastname ?? "";
		let label = firstname + " " + lastname;
		if (option.title) {
			label += " - " + option.title;
		}
		return label;
	}, []);


	return (
		<div className="autocomplete-container">
			<Autocomplete
				multiple={props.multiple}
				id="tags-standard"
				options={props.availablePersons}
				getOptionLabel={getOptionLabel}
				disabled={props.disabled === true}
				value={props.value}
				onChange={props.onChange}
				renderInput={(params) => (
					<TextField
						{...params}
						variant="standard"
						label={props.placeHolder}
						onKeyPress={(ev) => {
							if (ev.key === 'Enter') {
								// to prevent default enter event handling
								ev.preventDefault();
							}
						}}
					/>
				)}
			/>
		</div>
	);
}
