import React, { useEffect, useState } from 'react';
import { InApp } from '../../../../components/in-app/in-app';
import RightMenu from '../../../../components/menu/right-menu/right-menu';
import { useTextWithPopover } from '../../../../hooks/useTextWithPopover';
import DataService from '../../../../services/data-service';
import { SelectionPopover } from '../../../../components/selection-popover/selection-popover';
import { TextInputItem } from '../../../../components/text-input/text-input';
import { useParams, useHistory } from "react-router-dom";
import GDHToast from '../../../../components/toast/toast';

export function CTISearch(props: any) {

    const { id } = useParams<{ id: string }>()
    const { msg } = useParams<{ msg: string }>()

    const _history = useHistory();

    const [_showToast, _setShowToast] = useState<boolean>(false);
    const [_toastSuccess, _setToastSuccess] = useState<boolean>(false);
    const [_toastContent, _setToastContent] = useState<string>("");

    const [
        _applicants, ,
        _selectedApplicant, ,
        _applicantText, _setApplicantText,
        _applicantOpen, ,
        _applicantAnchor, ,
        _onClickApplicant,
        _onChangeApplicantText] = useTextWithPopover(
            DataService.searchTenant,
            'NOCA',
            (element: any) => element.NOCA + " - " + element.LBL_CONTRAT);


    useEffect(() => {
        if (!id) {
            return
        }
        setTimeout(() => {
            _setApplicantText(id)

        }, 200)

    }, [id])


    useEffect(() => {
        if (!_selectedApplicant) {
            return
        }
        console.log(_selectedApplicant)
        _history.push("/CTI/home/" + _selectedApplicant.NOCA)
    }, [_selectedApplicant])


    useEffect(() => {
        if (!msg) {
            return
        }
        setTimeout(() => {
            _setToastSuccess(false)
            let toastMsg = msg
            // TODO : use consts instead of hard coded strings
            if (msg === "no-tenant") {
                toastMsg = "Aucun locataire trouvé"
            } else if (msg === "multiple-tenant") {
                toastMsg = "Plusieurs locataires trouvés"
            }
            _setToastContent(toastMsg)
            _setShowToast(true)
        }, 200)

    }, [msg])

    const handleToastClose = () => {
        // do nothing.
    }

    return (
        <InApp
            isPrimaryLoading={false}
            isSecondaryLoading={false}
            backUrl=""
            title="CTI - Recherche"
            displayMenuButton={true}
            showRightMenuBtn={true}
            rightMenu={<RightMenu />}
            refresh={null}
        >
            <GDHToast
                content={_toastContent}
                handleClose={handleToastClose}
                show={_showToast}
                success={_toastSuccess}
            />

            <TextInputItem
                className="gdh-top-label-item-input gdh-top-label-item"
                handleChangeInput={_onChangeApplicantText}
                value={_applicantText}
                label="Locataire"
                required={true}
                isArea={false}
                textLineProps={{ placeholder: "" }}
            />
            <SelectionPopover
                open={_applicantOpen}
                anchor={_applicantAnchor}
                elements={_applicants}
                onClickElement={_onClickApplicant}
            />

        </InApp>
    )
}