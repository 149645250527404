import { Applications } from "../buisness/applications";
import { AuthResponseData } from "../interfaces/auth-response-data";
import { ACTION_CLEAR_ENABLED_APPS, ACTION_CONNECT, ACTION_DISCONNECT, ACTION_SET_ENABLED_APPS, ReduxStore } from "../store/redux-store";
import { StorageUtils } from "../utils/storage-utils";
import { Utils } from "../utils/utils";
import DataService from "./data-service";
import FetchGdhApiService from "./fetch-gdh-api-service";
import ReactGA from 'react-ga4'

export class AuthentificationService {

    private constructor() { }

    public static async initialize() {
        try {
            let tokenValid: boolean = false;
            const hasToken: boolean = await StorageUtils.getInstance().isAuth();
            console.log("AUTH hasToken ", hasToken);
            if (!hasToken) {
                ReactGA.event({
                    category: 'AUTH',
                    action: 'NO_TOKEN_STORED'
                });
            }
            if (hasToken) {
                tokenValid = await Applications.getInstance().initialize();
                await AuthentificationService.initializeEnabledApps();
            }
            if (hasToken && !tokenValid) {
                ReactGA.event({
                    category: 'AUTH',
                    action: 'INVALID_TOKEN'
                });
            }
            console.log("AUTH tokenValid ", tokenValid);
            if (hasToken && tokenValid) {
                ReduxStore.dispatch({ type: ACTION_CONNECT })
                return;
            }
        } catch (error) {
            console.error(error)
        }

        // if something did not worked. logout
        AuthentificationService.logout();
    }

    private static async initializeEnabledApps(): Promise<void> {
        await Applications.getInstance().initialize();
        const enabledApps = Applications.getInstance().getEnabledApps();
        if (enabledApps === undefined) {
            console.error("Unable to initialize applications");
        }
        ReduxStore.dispatch({ type: ACTION_SET_ENABLED_APPS, payload: enabledApps });
    }

    public static async login(login: string, password: string): Promise<boolean> {
        try {
            const response: AuthResponseData = await DataService.signin(login, password);
            if (response.value) {
                StorageUtils.getInstance().setIsCreator(Utils.isCreatorFromRoles(response.user.roles));
                StorageUtils.getInstance().setAuthToken(response.value);
                StorageUtils.getInstance().setUserId(response.user.id.toString());
                ReduxStore.dispatch({ type: ACTION_CONNECT });
                await AuthentificationService.initializeEnabledApps();
                return true;
            }
        } catch (error) {
            console.error(error);
        }
        return false;
    }


    public static async logout(): Promise<void> {
        const fcmToken = await StorageUtils.getInstance().getFcmAuthToken();
        if (fcmToken) {
            // this will be executed only when a token is stored, ie: only on mobile app
            try {
                await DataService.deleteFcmToken(fcmToken);
            } catch (error) {
                console.error("Unable to delete FCM token from EAI ", error);
            }
        }
        Applications.getInstance().clear();
        await StorageUtils.getInstance().clearAll();
        FetchGdhApiService.clearCache();
        ReduxStore.dispatch({ type: ACTION_DISCONNECT });
        ReduxStore.dispatch({ type: ACTION_CLEAR_ENABLED_APPS });
    }



}