import { IonicUtils } from './ionic-utils';
import { Preferences } from '@capacitor/preferences';

const AUTH_TOKEN_KEY = "token";
const FCM_AUTH_TOKEN_KEY = "fcmToken";
const USER_ID_KEY = "userId";
const IS_CREATOR_KEY = "isCreator";
const DOCUSIGN_FORM = 'docusignForm'



export class StorageUtils {


    static instance: StorageUtils | null = null;

    _isBrowser: boolean = false;

    // make the constructor private so the class cannot be instantiated
    private constructor() {
        StorageUtils.instance = this;
        this._isBrowser = IonicUtils.isBrowser();
    }

    public static getInstance(): StorageUtils {
        if (!StorageUtils.instance) {
            new StorageUtils();
        }

        // the instance can never be null here
        return StorageUtils.instance as StorageUtils;
    }


    public async isAuth(): Promise<boolean> {
        return ((await this.getData(AUTH_TOKEN_KEY)) !== null);
    }

    public async clearAll() {
        await this.clearAuthToken();
        await this.clearFcmAuthToken();
        await this.clearUserId();
        await this.clearIsCreator();
        await this.clearDocusignForm();
    }

    public async clearUserId() {
        await this.removeData(USER_ID_KEY);
    }

    public async clearAuthToken() {
        await this.removeData(AUTH_TOKEN_KEY);
    }

    public async clearFcmAuthToken() {
        await this.removeData(FCM_AUTH_TOKEN_KEY);
    }

    public async clearIsCreator() {
        await this.removeData(IS_CREATOR_KEY);
    }

    public async clearDocusignForm() {
        await this.removeData(DOCUSIGN_FORM);
    }

    public async setAuthToken(token: string) {
        await this.setData(AUTH_TOKEN_KEY, token);
    }

    public async setFcmAuthToken(token: string) {
        await this.setData(FCM_AUTH_TOKEN_KEY, token);
    }

    public async setIsCreator(isCreator: boolean) {
        await this.setData(IS_CREATOR_KEY, isCreator.toString());
    }

    public async getAuthToken(): Promise<string | null> {
        return await this.getData(AUTH_TOKEN_KEY);
    }

    public async getFcmAuthToken(): Promise<string | null> {
        return await this.getData(FCM_AUTH_TOKEN_KEY);
    }

    public async setUserId(userId: string) {
        await this.setData(USER_ID_KEY, userId);
    }

    public async getUserId(): Promise<string | null> {
        return await this.getData(USER_ID_KEY);
    }

    public async getIsCreator(): Promise<boolean | null> {
        return ((await this.getData(IS_CREATOR_KEY)) === 'true');
    }

    public async getDocusignForm(): Promise<string | null> {
        return await this.getData(DOCUSIGN_FORM)
    }

    public async setDocusignForm(form: string): Promise<void> {
        await this.setData(DOCUSIGN_FORM, form);
    }


    public async setData(key: string, value: string) {
        if (this._isBrowser) {
            localStorage.setItem(key, value);
        } else {
            await Preferences.set({
                key: key,
                value: value
            });
        }
    }

    public async getData(key: string): Promise<string | null> {
        if (this._isBrowser) {
            return localStorage.getItem(key);
        }
        const ret = await Preferences.get({ key: key });
        return ret.value;
    }

    public async removeData(key: string) {
        if (this._isBrowser) {
            localStorage.removeItem(key);
        } else {
            await Preferences.remove({ key: key });
        }
    }

}
