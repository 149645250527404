import React from "react";
import { IonToast } from "@ionic/react";
import { closeOutline, alertCircleOutline } from 'ionicons/icons';

import './toast.css';

type GDHToastProps = {
    show: boolean,
    handleClose: () => void,
    success: boolean,
    content: string
}


export default function GDHToast(props: GDHToastProps) {

    return (
        <IonToast
            isOpen={props.show}
            onDidDismiss={props.handleClose}
            message={props.content}
            duration={3000}
            color={props.success ? "success" : "danger"}
            position='top'
            buttons={[
                {
                    side: 'end',
                    icon: closeOutline as any
                },
                {
                    side: 'start',
                    icon: alertCircleOutline as any
                }
            ]}
        />
    )
}


