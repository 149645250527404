import React from 'react';
import {
	IonToolbar,
	IonButton,
	IonGrid,
	IonRow,
	IonCol,
	IonIcon,
	IonList,
	IonFooter,
	IonTextarea,
} from '@ionic/react';

import Comment from '../../../../components/comment/comment'
import { send } from 'ionicons/icons';
import { RefresherEventDetail } from '@ionic/core';

import './campaign-comments.css';
import DataService from '../../../../services/data-service';
import GDHToast from '../../../../components/toast/toast';
import ConfigUtils from '../../../../utils/config-utils';
import { InApp } from '../../../../components/in-app/in-app';
import { TabBar, TabName } from '../../../../components/flash/tab-bar/tab-bar';
import { CommentData } from '../../../../interfaces/comment-data';
import RightMenu from '../../../../components/menu/right-menu/right-menu';
import ReactGA from 'react-ga4'

const PAGE_TITLE_PREFIX = ConfigUtils.getFlashApplicationName() + " - Commentaires";

const INITIAL_STATE = {
	comments: '',
	message: '',
	showToast: false,
	campaignId: null,
	isPrimaryLoading: false,
	isSecondaryLoading: false,
	pageTitle: PAGE_TITLE_PREFIX
}


export class CampaignComments extends React.Component<any, any> {

	constructor(props: any) {
		super(props);
		this.state = { ...INITIAL_STATE };
		this.handleChange = this.handleChange.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.handleClose = this.handleClose.bind(this);
		this.onClickTab = this.onClickTab.bind(this);
		this.fetchCampaignInfo = this.fetchCampaignInfo.bind(this);
		this.refresh = this.refresh.bind(this);
		this.fetch = this.fetch.bind(this);
		this.fetchComments = this.fetchComments.bind(this);
	}


	async componentDidMount() {
		const campaignId = this.props.match.params.id;

		this.setState({ campaignId });

		this.fetch(campaignId);
	}

	async fetch(campaignId: number, forceDownload: boolean = false): Promise<void> {
		this.setState({ isPrimaryLoading: true });

		await Promise.all([
			this.fetchCampaignInfo(campaignId, forceDownload),
			this.fetchComments(campaignId, forceDownload),
		]);

		this.setState({ isPrimaryLoading: false });
	}

	async fetchComments(campaignId: number, forceDownload: boolean = false): Promise<void> {
		try {
			let comments: CommentData[] = await DataService.getCampaignComments(campaignId, forceDownload);
			this.setState({ comments: comments });
		} catch (error) {
			console.error(error);
		}
	}

	async fetchResidenceName(pat1: string, forceDownload: boolean = false) {
		try {
			let residences = await DataService.searchResidence(pat1, forceDownload);
			if (residences && residences[0] && residences[0].HPLBLPAT) {
				this.setState({ pageTitle: PAGE_TITLE_PREFIX + " - " + residences[0].HPLBLPAT + " - " + pat1 })
			} else {
				console.error("Unable to get residence name");
			}
		} catch (error) {
			console.error(error)
		}
	}

	async fetchCampaignInfo(campaignId: number, forceDownload: boolean = false) {
		try {
			let campaignDetails = await DataService.getCampaignDetails(campaignId, forceDownload);
			this.fetchResidenceName(campaignDetails.pat1, forceDownload);
		} catch (e) {
			console.error(e);
		}
	}


	handleClose() {
		this.setState({ showToast: false });
	}

	handleChange(e: any) {
		this.setState({ message: e.target.value });
	}

	handleSubmit(event: any) {
		event.preventDefault();

		const { params } = this.props.match;
		const contenu = this.state.message;

		// enpty the text field and set loading animation
		this.setState({
			message: "",
			isSecondaryLoading: true
		});

		let success;
		DataService.addCampaignComment(params.id, contenu).then((addedComment) => {
			/*	FIXME : HERE I AM MUTATING THE OBJECT CACHED BY THE DATA SERVICE. THIS IS NOT A GOOD PRACTISE
			 *  AN INETERMEDIARY CLASS HANDLING THIS SHALL BE ADDED BETWEEN HERE AND THE DATA SERVICE.
			 *
			 * This is so the next time this page is loaded, and the comments are requested to the dataService,
			 * the whole data are delivered without force refresh
			 */
			let commentsWithNewOne = this.state.comments;
			commentsWithNewOne.unshift(addedComment);
			success = true;
			this.setState({
				comments: commentsWithNewOne,
				showToast: true,
				isSecondaryLoading: false
			});

		}).catch((error) => {
			success = false;
			console.error(error);
			this.setState({
				isSecondaryLoading: false,
			});
		});

		ReactGA.event({
			category: 'FLASH',
			action: success ? 'SEND_COMMENT_SUCCESS' : 'SEND_COMMENT_ERROR'
		});
	}


	onClickTab(tabName: TabName): void {
		switch (tabName) {
			case TabName.DETAILS:
				this.props.history.push(ConfigUtils.getFlashBaseURL() + "/" + this.state.campaignId + "/edit");
				return;
			case TabName.TARGETS:
				this.props.history.push(ConfigUtils.getFlashBaseURL() + "/" + this.state.campaignId + "/cibleLoc");
				return;
			case TabName.COMMENTS:
				// already on this tab, do nothing
				// this.props.history.push(ConfigService.getFlashBaseURL() + "/" + this.state.campaignId + "/commentaires");
				return;
			case TabName.SMS:
				this.props.history.push(ConfigUtils.getFlashBaseURL() + "/" + this.state.campaignId + "/sms");
				return;
			case TabName.IN_MAIL:
				this.props.history.push(ConfigUtils.getFlashBaseURL() + "/" + this.state.campaignId + "/inMail");
				return;
		}
	}

	async refresh(event: CustomEvent<RefresherEventDetail>) {
		this.setState({ ...INITIAL_STATE, campaignId: this.state.campaignId });
		await this.fetch(this.state.campaignId, true);
		event.detail.complete();
	}

	render() {
		const { comments, showToast } = this.state;

		return (

			<InApp
				isPrimaryLoading={this.state.isPrimaryLoading}
				isSecondaryLoading={this.state.isSecondaryLoading}
				backUrl={ConfigUtils.getFlashBaseURL()}
				title={this.state.pageTitle}
				displayMenuButton={false}
				showRightMenuBtn={true}
				rightMenu={<RightMenu />}
				refresh={this.refresh}
				outOfIonContentElement={
					<IonFooter>
						<IonToolbar>
							<form onSubmit={this.handleSubmit}>
								<IonGrid>
									<IonRow>
										<IonCol size="10" >
											<IonTextarea class="comment-input" value={this.state.message}
												placeholder="Nouveau commentaire" onIonChange={this.handleChange}>
											</IonTextarea>
										</IonCol>

										<IonCol size="2" >
											<IonButton expand="block" type="submit">
												<IonIcon icon={send} />
											</IonButton>
										</IonCol>
									</IonRow>
								</IonGrid>
							</form>
						</IonToolbar>

						<TabBar
							selected={TabName.COMMENTS}
							onClickTab={this.onClickTab}
						/>
					</IonFooter>
				}
			>
				<GDHToast
					show={showToast}
					success={true}
					handleClose={this.handleClose}
					content='Commentaire ajouté'
				/>
				<IonList>
					{
						comments &&
						comments.map((comment: CommentData) => (<Comment key={comment.id} comment={comment} />))
					}
				</IonList>
			</InApp>
		);
	}
}
