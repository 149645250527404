import { IonButton, IonItem, IonLabel, IonList } from '@ionic/react';
import React, { useState } from 'react';
import { DragAndDrop } from '../../../../components/drag-and-drop/drag-and-drop';
import { FileInputButton } from '../../../../components/file-input-button/file-input-button';
import { InApp } from '../../../../components/in-app/in-app';
import RightMenu from '../../../../components/menu/right-menu/right-menu';
import GDHToast from '../../../../components/toast/toast';
import { ERROR_MIN_ONE_FILE, SEVERAL_FILES_WITH_SAME_NAME, UNKNOWN_ERROR_MSG } from '../../../../utils/constants';

import { TextInputItem } from '../../../../components/text-input/text-input';
import { useEffect } from 'react';
import { PdfService } from '../../../../services/pdf-service';
import { Utils } from '../../../../utils/utils';

import './digital-attribution-home.css';
import { SmbGatewayApiService } from '../../../../services/smb-gateway-api-service';
import DataService from '../../../../services/data-service';
import { StorageUtils } from '../../../../utils/storage-utils';
import { AutocompleteOption } from '../../../../components/autocomplete/autocomplete-option';
import { useTextWithPopover } from '../../../../hooks/useTextWithPopover';
import { SelectionPopover } from '../../../../components/selection-popover/selection-popover';
import { useHistory } from 'react-router';
import { PdfUtils } from '../../../../utils/pdf-utils';

const MERGED_FILE_DEFAULT_NAME = "Fichier converti par pdf utils";

// FIXME : this should not be hard coded but asked to the smb-gateway
const DEFAULT_DIRECTORIES = [
    "AUTRES DOCUMENTS",
    "DOCUMENTS JURIDIQUES",
    "MANDAT SEPA",
    "PIECES COMPLEMENTAIRES",
    "PIECES OBLIGATOIRES"
]

export function DigitalAttributionHome(props: any) {

    const [_files, _setFiles] = useState<File[]>([]);
    const [_showToast, _setShowToast] = useState<boolean>(false);
    const [_success, _setSuccess] = useState<boolean>(false);
    const [_toastMessage, _setToastMessage] = useState<string>("");

    const [_isPrimaryLoading, _setIsPrimaryLoading] = useState<boolean>(false);

    const [_username, _setUsername] = useState<string>("");
    const [_destinationDirectory, _setDestinationDirectory] = useState<string | null>(null);

    const _history = useHistory();

    const [
        _applicants, ,
        _selectedApplicant, _setSelectedApplicant,
        _applicantText, ,
        _applicantOpen, ,
        _applicantAnchor, ,
        _onClickApplicant, _onChangeApplicantText] = useTextWithPopover(
            DataService.searchAccommodationApplicants,
            'DDECOD',
            (element: any) => element.PRENOMS + " " + element.NOMNAISS + " - " + element.DDECOD);

    useEffect(() => {
        const blob = PdfService.getBlob();
        if (!blob) {
            return;
        }
        _setFiles([Utils.convertBlobToFile(blob, MERGED_FILE_DEFAULT_NAME)]);
    }, [])

    useEffect(() => {
        getUsername();
    }, []);

    const getUsername = async () => {
        const userId: string | null = await StorageUtils.getInstance().getUserId();
        if (userId) {
            try {
                const users = await DataService.getEmployeeInfo(userId);
                const username = users[0].firstname + ' ' + users[0].lastname?.toUpperCase();
                _setUsername(username);
            } catch (error) {
                console.error(error);
            }
        }
    }

    const onDestinationDirectoryChanged = (event: React.ChangeEvent<{}>, value: string | string[] | null) => {
        if (Array.isArray(value)) {
            console.error("Unable to handle array value", value)
            return;
        }

        _setDestinationDirectory(value);
    }

    /*
     * This function is the handler for click event on store button
     */
    const onClickStoreButton = async () => {
        if (_files.length < 1) {
            _setSuccess(false);
            _setToastMessage(ERROR_MIN_ONE_FILE);
            _setShowToast(true);
            return;
        }

        if (!_selectedApplicant) {
            _setSuccess(false);
            _setToastMessage("Veuillez sélectionner un demandeur");
            _setShowToast(true);
            return;
        }

        if (!_destinationDirectory) {
            _setSuccess(false);
            _setToastMessage("Veuillez sélectionner le type de fichier");
            _setShowToast(true);
            return;
        }

        // name shall have the format John DOE
        const applicantFirstname = Utils.capitalizeFirstLetter(_selectedApplicant.PRENOMS ?? "")
        const applicantLastname = _selectedApplicant.NOMNAISS?.toUpperCase()
        const applicantFullname = applicantFirstname + ' ' + applicantLastname;
        const applicationId = _selectedApplicant.NTIERS ?? ""


        _setIsPrimaryLoading(true);
        try {
            for (const file of _files) {
                // if the file comes from pdf utils, name it "<NUM_DEMANDEUR>-<TYPE_DE_FICHIER>.pdf". Because all merged file are PDF
                const filename = (file.name === MERGED_FILE_DEFAULT_NAME) ?
                    (applicationId + '-' + _destinationDirectory + '.pdf') : file.name;
                let strB64 = await Utils.convertFileToBase64(file);
                // use a regex to remove data url part
                strB64 = strB64
                    .replace("data:", "")
                    .replace(/^.+,/, "");

                await SmbGatewayApiService.writeFile(filename, strB64, _username, applicationId, applicantFullname, _destinationDirectory);
            }

            _setSuccess(true);
            _setToastMessage("Fichier stocké avec succès");
            _setShowToast(true);

            // reset all fields
            _setDestinationDirectory(null);
            _setSelectedApplicant(undefined);
            _setFiles([]);
        } catch (error) {
            console.error(error);
            _setSuccess(false);
            _setToastMessage(UNKNOWN_ERROR_MSG);
            _setShowToast(true);
        }
        _setIsPrimaryLoading(false);
    }

    /*
     * This function is called when one or several new files are imported
     */
    const onInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (!event?.target?.files || !event.target.files[0]) {
            return;
        }

        const filesArray: File[] = Object.values(event.target.files);

        addFilesToList(filesArray);
    }

    /*
     * Check if there is no several occurence of the same file and add files to the selected files
     */
    const addFilesToList = (filesToAdd: File[]) => {
        // check that the selected files do not contain two files with the same name
        let nbOccurences = PdfUtils.countMaxOccurenceOfFileName(filesToAdd, filesToAdd);
        // check for length superior to 1 because it will at least match with itself
        if (nbOccurences > 1) {
            // show a fail toast and return
            showSeveralFilesWithSameNamesToast();
            return;
        }

        // check that the selected files, and the previously selected files do not contain two files with the same name
        nbOccurences = PdfUtils.countMaxOccurenceOfFileName(filesToAdd, _files);
        // check for length superior to one because it shall not match once
        if (nbOccurences > 0) {
            // show a fail toast and return
            showSeveralFilesWithSameNamesToast();
            return;
        }

        _setFiles([..._files, ...filesToAdd]);
    }

    /*
     * Show a several files with same name error toast
     */
    const showSeveralFilesWithSameNamesToast = () => {
        _setToastMessage(SEVERAL_FILES_WITH_SAME_NAME);
        _setSuccess(false);
        _setShowToast(true);
    }

    /*
     * The handler for click on the delete buton of each file
     */
    const onClickDeleteFile = (filename: string) => {
        const remainingFiles = _files.filter((file: any) => {
            return file.name !== filename;

        })
        _setFiles(remainingFiles);
    }

    /*
     * Handler for toas close event
     */
    const handleToastClose = () => {
        if (_success) {
            _history.push('/home');
        }
        _setShowToast(false);
    }

    /*
     * Handler for droped file error
     */
    const onDropedFileError = (errorMsg: string) => {
        _setSuccess(false);
        _setToastMessage(errorMsg);
        _setShowToast(true);
    }

    /*
     * Handler for droped file success
     */
    const onDropedFiles = (files: File[]) => {
        addFilesToList(files);
    }

    return (
        <InApp
            isPrimaryLoading={_isPrimaryLoading}
            isSecondaryLoading={false}
            backUrl=""
            title="Attributions digitalisées"
            displayMenuButton={true}
            showRightMenuBtn={true}
            rightMenu={<RightMenu />}
            refresh={() => null}
        >
            <div className="digital-attribution-home-container">
                <div className="digital-attribution-home-button-container">
                    <FileInputButton
                        onInputChange={onInputChange}
                        acceptedExtentions=".pdf,.png,.jpg,.jpeg,.doc,.docx"
                    />
                </div>

                <DragAndDrop
                    acceptedExtention={["pdf", "png", "jpg", "jpeg", "doc", "docx"]}
                    errorCallback={onDropedFileError}
                    isEmpty={_files.length === 0}
                    dropedFilesCallback={onDropedFiles}
                >
                    <IonList>
                        {
                            _files.map((file: File) => {
                                return (
                                    <IonItem key={file.name}>
                                        <IonLabel>{file.name}</IonLabel>
                                        <div slot="end" onClick={() => onClickDeleteFile(file.name)} className="digital-attribution-home-delete-button-container">
                                            <i className="far fa-trash-alt"></i>
                                        </div>
                                    </IonItem>
                                )
                            })
                        }
                    </IonList>
                </DragAndDrop>

                <div className="digital-attribution-home-applicant-info-container">
                    <TextInputItem
                        className="gdh-top-label-item-input gdh-top-label-item gdh-item-input digital-attribution-home-item-input"
                        handleChangeInput={_onChangeApplicantText}
                        value={_applicantText}
                        label="Demandeur"
                        required={true}
                        isArea={false}
                        textLineProps={{ placeholder: "" }}
                    />
                    <SelectionPopover
                        open={_applicantOpen}
                        anchor={_applicantAnchor}
                        elements={_applicants}
                        onClickElement={_onClickApplicant}
                    />

                </div>

                <div className="digital-attribution-home-applicant-info-container">
                    <AutocompleteOption
                        value={_destinationDirectory}
                        options={DEFAULT_DIRECTORIES}
                        placeHolder="Sélectionner le type de fichier"
                        onChange={onDestinationDirectoryChanged}
                    />
                </div>


                <div className="digital-attribution-home-button-container">
                    <IonButton disabled={_files.length < 1} expand="block" onClick={onClickStoreButton}>
                        Stocker sur le réseau
                    </IonButton>
                </div>
            </div>

            <GDHToast
                show={_showToast}
                handleClose={handleToastClose}
                success={_success}
                content={_toastMessage}
            />
        </InApp>
    )
}