import React, { ReactNode } from 'react';
import { IonCard, IonCardHeader, IonCardSubtitle, IonCardContent } from '@ionic/react';
import "./kpi-card.css";

type KpiCardProps = {
    name: ReactNode,
    value: number,
    annotation?: string,
    onClick?: () => void,
    className?: string
}


export function KpiCard(props: KpiCardProps) {


    const annotation = props.annotation ?
        <div className="ion-text-right kpi-card-annotation">
            {props.annotation}
        </div>
        : null;

    // if onCLick is not defined, the function does nothing
    const onClickCallback = props.onClick ? props.onClick : (() => null);

    const className = props.className ? props.className : "";

    return (
        <IonCard className={"kpi-card-card " + (props.onClick ? "kpi-card-card-clickable" : "") + " " + className} onClick={onClickCallback} >
            <IonCardHeader>
                <IonCardSubtitle className="kpi-card-title">{props.name}</IonCardSubtitle>
            </IonCardHeader>
            <IonCardContent className="kpi-card-content" >
                <div className="kpi-card-value">
                    {props.value}
                </div>
                {annotation}
            </IonCardContent>
        </IonCard>
    )

}