import React from 'react';
import {
	IonButton,
	IonItem,
	IonLabel,
	IonIcon,
	IonFooter,
	IonGrid,
	IonRow,
	IonCol,
	IonToolbar,
	IonCheckbox,
} from '@ionic/react';
import { RefresherEventDetail } from '@ionic/core';

import DataService from '../../../../services/data-service';
import { chevronDown, chevronUp, eye, send } from 'ionicons/icons';
import { TextInputItem } from '../../../../components/text-input/text-input';
import { AutocompleteContacts } from '../../../../components/autocomplete/autocomplete-contacts';
import { ContactMail } from '../../../../interfaces/contact-mail.interface';
import GDHToast from '../../../../components/toast/toast';
import ConfigUtils from '../../../../utils/config-utils';
import { TabBar, TabName } from '../../../../components/flash/tab-bar/tab-bar';
import { InApp } from '../../../../components/in-app/in-app';


import "./sms.css";
import { DataTable } from '../../../../components/data-table/data-table';
import { MUIDataTableMeta } from 'mui-datatables';
import { CampaignData } from '../../../../interfaces/campaign-data';
import { StorageUtils } from '../../../../utils/storage-utils';
import { IonicUtils } from '../../../../utils/ionic-utils';
import RightMenu from '../../../../components/menu/right-menu/right-menu';
import ReactGA from 'react-ga4'

const PAGE_TITLE_PREFIX = ConfigUtils.getFlashApplicationName() + " - Rédaction de SMS";

const VISIBLE_COLUMNS_STORAGE_KEY = "flashSmsVisibleColumns";

const SMS_MAX_LEN = 360;

const TOAST_MESSAGE_SEND_SMS_ERROR = 'Erreur d\'envoi';
const TOAST_MESSAGE_SEND_SMS_SUCCESS = 'SMS envoyé';
const TOAST_MESSAGE_NO_CONTACT = 'Veuillez ajouter des destinataires';
const TOAST_MESSAGE_NO_CONTENT = 'Veuillez écrire un message';
const TOAST_MESSAGE_SMS_TOO_LONG = 'Le SMS est trop long';

const INITIAL_STATE = {
	campagneDetails: '',
	createdBy: null,
	internalContacts: null,
	accountsManagers: null,
	siteManagers: null,
	campaignId: null,
	text: '',
	setText: '',
	message: '',
	contacts: [],
	sendToTargets: false,
	sendToInternalContacts: false,
	sendToCreator: true,
	sendToSiteManagers: true,
	sendToAccountsManagers: true,
	showToast: false,
	isPrimaryLoading: false,
	isSecondaryLoading: false,
	pageTitle: PAGE_TITLE_PREFIX,
	showTypes: false,
	envoiesSms: [],
	isCreator: null,
	toastMessage: ''
}


export class Sms extends React.Component<any, any> {

	private resetState = false;

	private columns = [
		{ name: "id", label: "ID", options: { display: false } },
		{ name: "numberOfSmsSent", label: "SMS envoyés", options: { display: true } },
		{
			name: "dateUpd", label: "Date", options: {
				display: true,
				customBodyRender: (value: any) => {
					return new Date(value).toLocaleString('fr-FR');
				},
			}
		},
		{ name: "text", label: "Message", options: { display: true } },
		// detail data does not exist in the fetched data. The only thing we need to display this column is the id,
		// which is found in tableMeta rowData. We cannot use id for the columns name, ia ti would conflict with the first column name
		// and then, the user custom displayed column would not work
		{
			name: "detail", label: "Détail", options: {
				display: true,
				filter: false,
				customBodyRender: (id: any, tableMeta: MUIDataTableMeta, updateValue: (value: string) => void) => {
					return (
						<IonButton color="light" onClick={() => this.onClickSMSDetails(tableMeta.rowData[0])} >
							<IonIcon icon={eye} />
						</IonButton>
					)
				}
			}
		}
	];

	constructor(props: any) {
		super(props);

		this.state = { ...INITIAL_STATE };

		this.handleChange = this.handleChange.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.onClickSMSDetails = this.onClickSMSDetails.bind(this);
		this.onCheckedInternalContacts = this.onCheckedInternalContacts.bind(this);
		this.onCheckedCreator = this.onCheckedCreator.bind(this);
		this.onCheckedTargets = this.onCheckedTargets.bind(this);
		this.onCheckedAccountsManagers = this.onCheckedAccountsManagers.bind(this);
		this.onCheckedSiteManagers = this.onCheckedSiteManagers.bind(this);
		this.handleToastClose = this.handleToastClose.bind(this);
		this.onClickTab = this.onClickTab.bind(this);
		this.fetchResidenceName = this.fetchResidenceName.bind(this);
		this.onClickTypesButton = this.onClickTypesButton.bind(this);
		this.fetch = this.fetch.bind(this);
		this.refresh = this.refresh.bind(this);
		this.reloadData = this.reloadData.bind(this);
	}



	async componentDidMount() {
		const campaignId = this.props.match.params.id;

		const isCreator = await StorageUtils.getInstance().getIsCreator();

		this.setState({
			campaignId: campaignId,
			isCreator: isCreator
		});

		this.fetch(campaignId);
	}

	async fetch(campaignId: number, forceDownload: boolean = false): Promise<void> {
		this.setState({ isPrimaryLoading: true });

		try {
			const campaignDetails: CampaignData = await DataService.getCampaignDetails(campaignId, forceDownload);

			let createdBy = null;
			if (campaignDetails.createdBy &&
				(campaignDetails.createdBy.lastname || campaignDetails.createdBy.firstname)) {
				createdBy = campaignDetails.createdBy.firstname ? campaignDetails.createdBy.firstname : "";
				createdBy += campaignDetails.createdBy.lastname ? (" " + campaignDetails.createdBy.lastname) : "";
			}

			let internalContacts: string | null = null;
			if (campaignDetails.internes) {
				campaignDetails.internes.forEach((contact: any) => {
					if (contact && contact.eai &&
						(contact.eai.lastname || contact.eai.firstname)) {
						let contactName = contact.eai.firstname ? contact.eai.firstname : "";
						contactName += contact.eai.lastname ? (" " + contact.eai.lastname) : "";
						if (internalContacts === null) {
							internalContacts = contactName;
						} else {
							internalContacts += ", " + contactName;
						}
					}
				})
			}

			let accountsManagers: string | null = null;
			if (campaignDetails.chargesCompteClient) {
				campaignDetails.chargesCompteClient.forEach((accountManager: any) => {
					if (accountManager && accountManager.eai && accountManager.eai.lastname && accountManager.eai.firstname) {
						let fullName = accountManager.eai.firstname + " " + accountManager.eai.lastname;
						if (accountsManagers === null) {
							accountsManagers = fullName;
						} else {
							accountsManagers += ", " + fullName;
						}
					}
				})
			}

			let siteManagers: string | null = null;
			if (campaignDetails.responsablesDeSite) {
				campaignDetails.responsablesDeSite.forEach((siteManager: any) => {
					if (siteManager && siteManager.eai && siteManager.eai.lastname && siteManager.eai.firstname) {
						let fullName = siteManager.eai.firstname + " " + siteManager.eai.lastname;
						if (siteManagers === null) {
							siteManagers = fullName;
						} else {
							siteManagers += ", " + fullName;
						}
					}
				})
			}
			/*	FIXME : HERE I AM MUTATING THE OBJECT CACHED BY THE DATA SERVICE. THIS IS NOT A GOOD PRACTISE
			 *  AN INETERMEDIARY CLASS HANDLING THIS SHALL BE ADDED BETWEEN HERE AND THE DATA SERVICE.
			 *
			 */
			// add the number of sent messages for each sms to be displayed in the data table
			if (campaignDetails.envoiesSms) {
				for (const smsId in campaignDetails.envoiesSms) {
					campaignDetails.envoiesSms[smsId].numberOfSmsSent = campaignDetails.envoiesSms[smsId].recipients ?
						campaignDetails.envoiesSms[smsId].recipients.length : 0;
				}
			}

			this.setState({
				campagneDetails: campaignDetails,
				envoiesSms: campaignDetails.envoiesSms,
				createdBy: createdBy,
				internalContacts,
				accountsManagers,
				siteManagers,
			});

			await this.fetchResidenceName(campaignDetails.pat1, forceDownload);

		} catch (error) {
			console.error(error);
		}

		this.setState({ isPrimaryLoading: false });
	}


	async fetchResidenceName(pat1: string, forceDownload: boolean = false): Promise<void> {
		try {
			let residences = await DataService.searchResidence(pat1, forceDownload);
			if (residences && residences[0] && residences[0].HPLBLPAT) {
				this.setState({ pageTitle: PAGE_TITLE_PREFIX + " - " + residences[0].HPLBLPAT + " - " + pat1 })
			} else {
				console.error("Unable to get residence name");
			}
		} catch (error) {
			console.error(error)
		}
	}

	handleChange(event: any) {
		this.setState({
			message: event.detail.value
		});
	}


	async handleSubmit(event: any) {
		event.preventDefault();

		// cannot create a flash if text fields are too long
		if (this.state.message.length > SMS_MAX_LEN) {
			this.setState({
				showToast: true,
				success: false,
				toastMessage: TOAST_MESSAGE_SMS_TOO_LONG
			});
			return;
		}

		if (!this.state.sendToCreator
			&& !this.state.sendToTargets
			&& !this.state.sendToAccountsManagers
			&& !this.state.sendToSiteManagers
			&& (!this.state.sendToInternalContacts || this.state.contacts.length === 0)
		) {
			this.resetState = false;
			this.setState({
				success: false,
				showToast: true,
				toastMessage: TOAST_MESSAGE_NO_CONTACT
			});
			return;
		}

		if (!this.state.message || (this.state.message.length < 1)) {
			this.resetState = false;
			this.setState({
				success: false,
				showToast: true,
				toastMessage: TOAST_MESSAGE_NO_CONTENT
			});
			return;
		}

		this.setState({ isSecondaryLoading: true });

		const { params } = this.props.match;

		let types: Array<"R" | "C" | "T" | "L" | "I"> = [];
		if (this.state.sendToCreator) {
			types.push("T");
		}
		if (this.state.sendToInternalContacts) {
			types.push("I");
		}
		if (this.state.sendToTargets) {
			types.push("L");
		}
		if (this.state.sendToAccountsManagers) {
			types.push("C");
		}
		if (this.state.sendToSiteManagers) {
			types.push("R");
		}

		let emails: string[] = [];
		this.state.contacts.forEach((contact: any) => {
			emails.push(contact.email);
		});

		let success;
		let toastMessage;
		try {
			let lastSentSms = await DataService.postSms(params.id, this.state.message, types, emails);
			/*	FIXME : HERE I AM MUTATING THE OBJECT CACHED BY THE DATA SERVICE. THIS IS NOT A GOOD PRACTISE
			 *  AN INETERMEDIARY CLASS HANDLING THIS SHALL BE ADDED BETWEEN HERE AND THE DATA SERVICE.
			 *
			 * This is so the next time this page is loaded, and the comments are requested to the dataService,
			 * the whole data are delivered without force refresh
			 */
			let envoiesSms = this.state.envoiesSms;
			// set the number of sms recipients
			lastSentSms.numberOfSmsSent = lastSentSms.recipients ? lastSentSms.recipients.length : 0;
			envoiesSms.unshift(lastSentSms);

			success = true;
			toastMessage = TOAST_MESSAGE_SEND_SMS_SUCCESS;
		} catch (e) {
			success = false;
			toastMessage = TOAST_MESSAGE_SEND_SMS_ERROR;
		}

		this.resetState = success;
		this.setState({
			success: success,
			showToast: true,
			isSecondaryLoading: false,
			toastMessage: toastMessage
		});

		ReactGA.event({
			category: 'FLASH',
			action: success ? 'SEND_SMS_SUCCESS' : 'SEND_SMS_ERROR'
		});
	}

	onCheckedInternalContacts(e: any) {
		this.setState({ sendToInternalContacts: e.detail.checked });
	}

	onCheckedCreator(e: any) {
		this.setState({ sendToCreator: e.detail.checked });
	}

	onCheckedTargets(e: any) {
		this.setState({ sendToTargets: e.detail.checked });
	}

	onCheckedAccountsManagers(e: any) {
		this.setState({ sendToAccountsManagers: e.detail.checked });
	}

	onCheckedSiteManagers(e: any) {
		this.setState({ sendToSiteManagers: e.detail.checked });
	}

	onClickSMSDetails(smsId: number) {
		this.props.history.push(this.props.location.pathname + '/' + smsId)
	}

	handleToastClose() {
		if (this.resetState) {
			this.resetState = false;
			this.setState({
				contacts: [],
				message: '',
				sendToTargets: false,
				sendToInternalContacts: false,
				sendToCreator: true,
			})
		}
		this.setState({
			showToast: false
		});
	}

	onClickTab(tabName: TabName): void {
		switch (tabName) {
			case TabName.DETAILS:
				this.props.history.push(ConfigUtils.getFlashBaseURL() + "/" + this.state.campaignId + "/edit");
				return;
			case TabName.TARGETS:
				this.props.history.push(ConfigUtils.getFlashBaseURL() + "/" + this.state.campaignId + "/cibleLoc");
				return;
			case TabName.COMMENTS:
				this.props.history.push(ConfigUtils.getFlashBaseURL() + "/" + this.state.campaignId + "/commentaires");
				return;
			case TabName.SMS:
				// already on this tab, do nothing
				// this.props.history.push(ConfigService.getFlashBaseURL() + "/" + this.state.campaignId + "/sms");
				return;
			case TabName.IN_MAIL:
				this.props.history.push(ConfigUtils.getFlashBaseURL() + "/" + this.state.campaignId + "/inMail");
				return;
		}
	}

	onClickTypesButton() {
		this.setState((prevState: any, props: any) => {
			return { showTypes: !prevState.showTypes }
		})
	}

	async refresh(event: CustomEvent<RefresherEventDetail>) {
		await this.reloadData();
		event.detail.complete();
	}

	async reloadData() {
		this.setState({ ...INITIAL_STATE, campaignId: this.state.campaignId, isCreator: this.state.isCreator });
		await this.fetch(this.state.campaignId, true);
	}

	render() {
		let { envoiesSms } = this.state;

		return (
			<InApp
				isPrimaryLoading={this.state.isPrimaryLoading}
				isSecondaryLoading={this.state.isSecondaryLoading}
				backUrl={ConfigUtils.getFlashBaseURL()}
				title={this.state.pageTitle}
				displayMenuButton={false}
				showRightMenuBtn={true}
				rightMenu={<RightMenu />}
				refresh={this.refresh}
				outOfIonContentElement={
					<IonFooter className="flash-sms-footer" >
						<IonToolbar>
							<form onSubmit={this.handleSubmit}>
								<IonGrid>
									<IonRow>
										<IonCol size="12">
											<IonItem onClick={this.onClickTypesButton} className="flash-sms-types-button">
												<IonLabel>Cibles</IonLabel>
												{
													this.state.showTypes ?
														<IonIcon icon={chevronDown} size="large"></IonIcon>
														:
														<IonIcon icon={chevronUp} size="large"></IonIcon>
												}
											</IonItem>
										</IonCol>
										{
											this.state.showTypes &&
											<>
												<IonCol size="12" size-lg="4"  >
													<IonItem>
														<IonLabel>Cibles locataires</IonLabel>
														<IonCheckbox disabled={!this.state.isCreator} slot="start" onIonChange={this.onCheckedTargets} checked={this.state.sendToTargets} />
													</IonItem>
												</IonCol>

												<IonCol size="12" size-lg="4"  >
													<IonItem>
														<IonLabel>Créateur : {this.state.createdBy}</IonLabel>
														<IonCheckbox disabled={!this.state.isCreator} slot="start" onIonChange={this.onCheckedCreator} checked={this.state.sendToCreator} />
													</IonItem>
												</IonCol>

												<IonCol size="12" size-lg="4"  >
													<IonItem>
														<IonLabel>CdC : {this.state.accountsManagers}</IonLabel>
														<IonCheckbox disabled={!this.state.isCreator} slot="start" onIonChange={this.onCheckedAccountsManagers} checked={this.state.sendToAccountsManagers} />
													</IonItem>
												</IonCol>

												<IonCol size="12" size-lg="4"  >
													<IonItem>
														<IonLabel>Resp. site : {this.state.siteManagers}</IonLabel>
														<IonCheckbox disabled={!this.state.isCreator} slot="start" onIonChange={this.onCheckedSiteManagers} checked={this.state.sendToSiteManagers} />
													</IonItem>
												</IonCol>

												<IonCol size="12" size-lg="4"  >
													<IonItem>
														<IonLabel>Contacts internes : {this.state.internalContacts}</IonLabel>
														<IonCheckbox disabled={!this.state.isCreator} slot="start" onIonChange={this.onCheckedInternalContacts} checked={this.state.sendToInternalContacts} />
													</IonItem>
												</IonCol>
											</>
										}
									</IonRow>
									<IonRow>
										<AutocompleteContacts
											contactMustHavePhoneNumber={true}
											disabled={!this.state.isCreator}
											value={this.state.contacts}
											onChange={(event: React.ChangeEvent<{}>, value: ContactMail | ContactMail[] | null) => this.setState({ contacts: value })}
										/>
									</IonRow>
									<IonRow>
										<IonCol size="10" >
											<TextInputItem
												disabled={!this.state.isCreator}
												value={this.state.message}
												handleChangeArea={this.handleChange}
												maxLen={SMS_MAX_LEN}
												textAreaProps={{ rows: 2 }}
												isArea={true}
												blockAtMaxLen={!IonicUtils.isMobile()}
											/>
										</IonCol>

										<IonCol size="2" >
											<IonButton disabled={!this.state.isCreator} expand="block" type="submit">
												<IonIcon icon={send} />
											</IonButton>
										</IonCol>
									</IonRow>
								</IonGrid>
							</form>
						</IonToolbar>

						<TabBar
							selected={TabName.SMS}
							onClickTab={this.onClickTab}
						/>
					</IonFooter>
				}
			>
				<DataTable
					title={"SMS Envoyés"}
					data={envoiesSms}
					columns={this.columns}
					storageKey={VISIBLE_COLUMNS_STORAGE_KEY}
					storeVisibleColumns={true}
				/>

				<GDHToast
					show={this.state.showToast}
					handleClose={this.handleToastClose}
					success={this.state.success}
					content={this.state.toastMessage}
				/>
			</InApp>
		);
	}
}
