import React from 'react';
import { Popper } from '@mui/material';
export type PopoverElement = { key: any, content: any }

export type SelectionPopoverProps = {
    open: boolean,
    anchor: any,
    elements: PopoverElement[],
    onClickElement: (key: any) => void
}


export class SelectionPopover extends React.Component<SelectionPopoverProps, any> {
    render() {
        const { open, anchor, elements, onClickElement } = this.props;

        if (elements.length === 0) {
            return null;
        }

        return (
            <Popper id="simple-popper" open={open} anchorEl={anchor}>
                {
                    elements.map((element: any, index: any) => {
                        let className = "paper "
                        className += (element.key === elements[elements.length - 1]?.key) ? "paper-last" : "";
                        return (
                            <div onClick={() => onClickElement(element.key)} key={element.key} data-key={element.key} className={className}>{element.content}</div>
                        )
                    })
                }
            </Popper>
        )
    }
}