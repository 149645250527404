import { IonFooter, IonIcon, IonLabel, IonSegment, IonSegmentButton, IonToolbar } from '@ionic/react';
import { informationCircle, mail, paperPlane, person, chatbubbles } from 'ionicons/icons';
import React from 'react';

import './tab-bar.css';

export enum TabName {
    DETAILS = "details",
    TARGETS = "targets",
    COMMENTS = "comments",
    SMS = "SMS",
    IN_MAIL = "inMail"
}


type TabBarProps = {
    selected: TabName,
    onClickTab: (tabName: TabName) => void
}

export function TabBar(props: TabBarProps) {
    return (
        <IonFooter>
            <IonToolbar>
                <IonSegment onIonChange={e => console.log('Segment selected', e.detail.value)} value={props.selected}>
                    <IonSegmentButton className='tab-bar-button' value={TabName.DETAILS}
                        onClick={(event: React.MouseEvent<HTMLIonSegmentButtonElement, MouseEvent>) => props.onClickTab(TabName.DETAILS)}>
                        <IonIcon icon={informationCircle} />
                        <IonLabel className='tab-bar-button-label'>Détails</IonLabel>
                    </IonSegmentButton>

                    <IonSegmentButton className='tab-bar-button' value={TabName.TARGETS}
                        onClick={(event: React.MouseEvent<HTMLIonSegmentButtonElement, MouseEvent>) => props.onClickTab(TabName.TARGETS)}>
                        <IonIcon icon={person} />
                        <IonLabel className='tab-bar-button-label'>Cibles</IonLabel>
                    </IonSegmentButton>

                    <IonSegmentButton className='tab-bar-button' value={TabName.COMMENTS}
                        onClick={(event: React.MouseEvent<HTMLIonSegmentButtonElement, MouseEvent>) => props.onClickTab(TabName.COMMENTS)}>
                        <IonIcon icon={chatbubbles} />
                        <IonLabel className='tab-bar-button-label'>Commentaires</IonLabel>
                    </IonSegmentButton>

                    <IonSegmentButton className='tab-bar-button' value={TabName.SMS}
                        onClick={(event: React.MouseEvent<HTMLIonSegmentButtonElement, MouseEvent>) => props.onClickTab(TabName.SMS)}>
                        <IonIcon icon={paperPlane} />
                        <IonLabel className='tab-bar-button-label'>SMS</IonLabel>
                    </IonSegmentButton>

                    <IonSegmentButton className='tab-bar-button' value={TabName.IN_MAIL}
                        onClick={(event: React.MouseEvent<HTMLIonSegmentButtonElement, MouseEvent>) => props.onClickTab(TabName.IN_MAIL)}>
                        <IonIcon icon={mail} />
                        <IonLabel className='tab-bar-button-label'>inMail</IonLabel>
                    </IonSegmentButton>

                </IonSegment>
            </IonToolbar>
        </IonFooter>
    )
}