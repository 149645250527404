import { IonButton, IonItem, IonLabel } from "@ionic/react";
import { InApp } from "../../../../components/in-app/in-app";
import RightMenu from "../../../../components/menu/right-menu/right-menu";
import { StorageUtils } from "../../../../utils/storage-utils";
import { useEffect } from "react";

export function PaymentRedirect() {

    let _token: any

    const redirectToPaymentGDH = async () => {
        if (typeof _token !== "string") {
            setTokenCo()
            return
        }
        let url = "http://paiement.granddelta.fr/?token=" + encodeURIComponent(_token)
        window.location.replace(url)
    }

    const setTokenCo = async () => {
        _token = await StorageUtils.getInstance().getAuthToken()
    }

    useEffect(() => {
        redirectToPaymentGDH()
    })

    return (
        <InApp
            isPrimaryLoading={false}
            isSecondaryLoading={false}
            backUrl=""
            title="Paiement Redirection"
            displayMenuButton={true}
            showRightMenuBtn={true}
            rightMenu={<RightMenu />}
            refresh={null}
        >
            <IonItem>
                <IonLabel>Si vous n'êtes pas redirigé, veuillez cliquer sur le bouton en bas de la page</IonLabel>
            </IonItem>
            <IonButton className="payment-home-next-button" disabled={false} expand="block" onClick={redirectToPaymentGDH} type="submit">Être redirigé</IonButton>
        </InApp>
    )
}