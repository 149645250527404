import { Dispatch, SetStateAction, useCallback, useState } from "react";

export const useMultipleCheckboxes = (): [{ [x: string]: any },
    Dispatch<SetStateAction<{ [x: string]: any }>>,
    string[],
    Dispatch<SetStateAction<string[]>>,
    (value: string, checked: boolean) => void
] => {

    const [_checkboxes, _setCheckboxes] = useState<{ [x: string]: any }>({});
    const [_selectedCheckboxes, _setSelectedCheckboxes] = useState<string[]>([]);

    const onChecked = useCallback((value?: string, checked?: boolean) => {
        if (!value) {
            console.error('Unable to handle null value', value);
            return;
        }

        if (checked) {
            const checkboxes = [..._selectedCheckboxes, value];
            _setSelectedCheckboxes(checkboxes);
        } else {
            const checkboxes = _selectedCheckboxes.filter((selectedCheckbox: string) => {
                return selectedCheckbox !== value;
            })
            _setSelectedCheckboxes(checkboxes);
        }
    }, [_selectedCheckboxes]);

    return [
        _checkboxes,
        _setCheckboxes,
        _selectedCheckboxes,
        _setSelectedCheckboxes,
        onChecked
    ]
};
