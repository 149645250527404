import { IonButton, IonCheckbox, IonItem, IonLabel } from '@ionic/react';
import React, { useState, useEffect, useRef } from 'react';
import { GDHDatePicker } from '../../../../components/date-picker/date-picker';
import { InApp } from '../../../../components/in-app/in-app';
import RightMenu from '../../../../components/menu/right-menu/right-menu';
import { CheckboxChangeEventDetail } from '@ionic/core/dist/types/components/checkbox/checkbox-interface'
import { format, isValid, compareAsc, addDays } from 'date-fns'
import { TextInputItem } from '../../../../components/text-input/text-input';
import ConfigUtils from '../../../../utils/config-utils';
import { Utils } from '../../../../utils/utils';
import GDHToast from '../../../../components/toast/toast';
import { Dialog } from '../../../../components/dialog/dialog';
import { ConstantPayboxData, PayboxData } from '../../../../interfaces/paybox-data';
import DataService from '../../../../services/data-service';
import { InputChangeEventDetail } from '@ionic/core/dist/types/components/input/input-interface';
import { TextareaChangeEventDetail } from '@ionic/core/dist/types/components/textarea/textarea-interface';
import { SelectionPopover } from '../../../../components/selection-popover/selection-popover';
import { TenantSearchData } from '../../../../interfaces/tenant-search-data';
import { useParams } from "react-router-dom";

import './payment-home.css';

const DIRECT_PAYMENT = 'DIRECT_PAYMENT';
const DELAYED_PAYMENT = 'DELAYED_PAYMENT';

const PAYBOX_GATEWAY_URL = ConfigUtils.getPayboxUrl();
const RETURN_URL = ConfigUtils.getFrontendUrl() + "payment/home";
const MIN_AMOUNT = 100;
const EURO_TO_CENTS = 100;
const PBX_CMD_PREFIX = "grand_delta_";

const data: ConstantPayboxData = {
    PBX_SITE: "1558001",
    PBX_RANG: "001",
    PBX_IDENTIFIANT: "658077748",
    PBX_DEVISE: "978",
    // PBX_RETOUR allows to retrieve the paid value, the ref, and the error code
    PBX_RETOUR: "Mt:M;Ref:R;Auto:A;Erreur:E",
    PBX_EFFECTUE: RETURN_URL,
    PBX_ANNULE: RETURN_URL,
    PBX_REFUSE: RETURN_URL,
    PBX_HASH: "SHA512",
}

export function PaymentHome() {

    const [_showToast, _setShowToast] = useState<boolean>(false);
    const [_toastSuccess, _setToastSuccess] = useState<boolean>(false);
    const [_toastContent, _setToastContent] = useState<string>("");

    const [_showDialog, _setShowDialog] = useState<boolean>(false);
    const [_dialogContent, _setDialogContent] = useState<React.ReactNode>(<></>);

    const [_delayedAmounts, _setDelayedAmounts] = useState<string[]>(new Array(3).fill(""));
    const [_selectedDates, _setSelectedDates] = useState<(Date | null)[]>(new Array(3).fill(null));
    const [_delayedPayment, _setDelayedPayment] = useState<boolean>(false);
    const [_directPayment, _setDirectPayment] = useState<boolean>(true);
    const [_directAmount, _setDirectAmount] = useState<string>("");
    const [_searchField, _setSearchField] = useState<string>("");
    const [_tenants, _setTenants] = useState<any[]>([]);
    const [_selectedTenant, _setSelectedTenant] = useState<TenantSearchData>();
    const [_openSearchPopup, _setOpenSearchPopup] = useState<boolean>(false);
    const [_searchPopupTarget, _setSearchPopupTarget] = useState<EventTarget | null>(null);
    const [_showEmailField, _setShowEmailField] = useState<boolean>(false);
    const [_email, _setEmail] = useState<string>("");
    const [_minDates, _setMinDates] = useState<Date[]>([]);
    const [_maxDates, _setMaxDates] = useState<Date[]>([]);
    const _numberOfValidDelayedPayments = useRef<number>(0);

    const _refHmac = useRef<any>(null);
    const _refSubmit = useRef<any>(null);
    const _refTime = useRef<any>(null);
    const _refTotal = useRef<any>(null);
    const _refCmd = useRef<any>(null);
    const _refRepTo = useRef<any>(null);
    const _refMail = useRef<any>(null);
    const _refAmounts = [useRef<any>(null), useRef<any>(null), useRef<any>(null)];
    const _refDates = [useRef<any>(null), useRef<any>(null), useRef<any>(null)];
    const { delayed, noca } = useParams<{ delayed: string, noca: string }>()
    const _refNoca = useRef<HTMLIonInputElement>(null)


    // update min and max dates whenever the selected dates change
    useEffect(() => {
        updateMinMaxDates();
        // FIXME : this is not the best way to do this
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [_selectedDates])


    // On mount of component
    useEffect(() => {
        // the delayed value is set to '1' by the calling url to enbale the delayed payment
        if (delayed === '1') {
            _setDelayedPayment(true)
            _setDirectPayment(false)
        }

        // if NOCA exist we set it in inputField and we set tenants with corresponding NOCA
        if (noca) {
            _setSearchField(noca)
            updateTenantsList(noca)
            setTenantMatchingNoca(noca)
        }

    }, [])

    useEffect(() => {
        // we set popup anchor with input NOCA
        _setSearchPopupTarget(_refNoca?.current)
    }, [_refNoca])

    const setTenantMatchingNoca = async (inputText: string) => {
        // searchTenant function has max defaut value at 5, to optimize we retreive only 2 values
        const tenants = await DataService.searchTenant(inputText, 2);

        // if there is only one tenant matching with noca we set this tenant as final tenant
        if (tenants.length === 1) {
            _setSearchField(tenants[0].LBL_CONTRAT + " - " + noca);
            _setSelectedTenant(tenants[0]);
            // show the email input field if the tenant email is not valid
            _setShowEmailField(!tenants[0].EMAIL);
            _setOpenSearchPopup(false)
        }
    }

    /*
     * This is to avoid creating a component "DatePickerWithIndex", and not have lousy render perfs
     */
    const handleDateChange0 = (date: Date | null) => {
        handleDateChange(date, 0);
    }

    const handleDateChange1 = (date: Date | null) => {
        handleDateChange(date, 1);
    }

    const handleDateChange2 = (date: Date | null) => {
        handleDateChange(date, 2);
    }

    const handleDateChange = (date: Date | null, index: number) => {
        _setSelectedDates(prevDates => [
            ...prevDates.slice(0, index),
            date,
            ...prevDates.slice(index + 1)
        ]);
    };

    const updateMinMaxDates = () => {
        const { minDates, maxDates } = getMinMaxDates();
        _setMinDates(minDates);
        _setMaxDates(maxDates);
    }

    const handleToastClose = () => {
        _setShowToast(false);
    }

    const handleSubmit = () => {
        // check input fields
        if (!Utils.isNumeric(_directAmount) ||
            _delayedAmounts.some((amount) => {
                return amount && (!Utils.isNumeric(amount))
            })) {
            _setToastContent("Les valeurs des montants doivent être des nombres")
            _setToastSuccess(false);
            _setShowToast(true);
            return;
        }

        const pbxTotal = Math.trunc((+ _directAmount) * EURO_TO_CENTS);
        if ((pbxTotal < MIN_AMOUNT) ||
            _delayedAmounts.some((amount) => {
                return amount && ((Math.trunc((+ amount) * EURO_TO_CENTS) < MIN_AMOUNT))
            })) {
            _setToastContent("La valeur minimale des montants est de 1€")
            _setToastSuccess(false);
            _setShowToast(true);
            return;
        }

        if (!_selectedTenant) {
            _setToastContent("Veuiller sélectionner un locataire")
            _setToastSuccess(false);
            _setShowToast(true);
            return;
        }

        if ((!_selectedTenant.EMAIL) && (!Utils.checkEmail(_email))) {
            _setToastContent("Veuillez entrer une adresse email valide")
            _setToastSuccess(false);
            _setShowToast(true);
            return;
        }

        const datesBounds = getMinMaxDates();
        _numberOfValidDelayedPayments.current = 0;
        for (let i = 0; i < _delayedAmounts.length; i++) {
            if (_delayedAmounts[i] && _selectedDates[i] && isValid(_selectedDates[i])
                && isDateBetweenBounds(_selectedDates[i], datesBounds.minDates[i], datesBounds.maxDates[i])
            ) {
                _numberOfValidDelayedPayments.current++;
            } else {
                break;
            }
        }

        let dialogBody: React.ReactNode[] = [];
        dialogBody.push(<React.Fragment key={-1}>La somme de {_directAmount}€  va être débitée immédiatement<br /></React.Fragment>);
        for (let i = 0; i < _numberOfValidDelayedPayments.current; i++) {
            dialogBody.push(<React.Fragment key={i}>
                La somme de {_delayedAmounts[i]}€ sera débitée le {Utils.formatDateIfValid(_selectedDates[i], "dd/MM/yyyy")}<br />
            </React.Fragment>)
        }
        _setDialogContent(
            <>
                {
                    dialogBody.map(element => element)
                }
            </>
        );
        _setShowDialog(true);
        return;
    }

    const handleDelayedAmountChanged0 = (event: any) => {
        handleDelayedAmountChanged(event, 0);
    }

    const handleDelayedAmountChanged1 = (event: any) => {
        handleDelayedAmountChanged(event, 1);
    }

    const handleDelayedAmountChanged2 = (event: any) => {
        handleDelayedAmountChanged(event, 2);
    }

    const handleDelayedAmountChanged = (event: any, index: number) => {
        if (event?.target) {
            _setDelayedAmounts(prevAmounts => [
                ...prevAmounts.slice(0, index),
                event.target.value,
                ...prevAmounts.slice(index + 1)
            ]);
            return;
        }
        console.error("Undefined event", event)
    }
    const handleAmountChanged = (event: any) => {
        if (event?.target) {
            _setDirectAmount(event.target.value);
            return;
        }
        console.error("Undefined event", event)
    }

    const handlePaymentChanged = (event: CustomEvent<CheckboxChangeEventDetail>) => {
        if (event.detail.checked) {
            if (event.detail.value === DIRECT_PAYMENT) {
                _setDelayedPayment(false);
                _setDirectPayment(true);
            } else if (event.detail.value === DELAYED_PAYMENT) {
                _setDelayedPayment(true);
                _setDirectPayment(false);
            } else {
                console.error("Error unknown event value : ", event.detail.value)
                return;
            }
        } else {
            if (event.detail.value === DIRECT_PAYMENT) {
                _setDirectPayment(false);
            } else if (event.detail.value === DELAYED_PAYMENT) {
                _setDelayedPayment(false);
            } else {
                console.error("Error unknown event value : ", event.detail.value)
                return;
            }
        }
    }

    const handleDialogCancel = () => {
        _setShowDialog(false)
    }

    const handleDialogOk = async () => {
        const pbxTotal: string = Math.trunc((+ _directAmount) * EURO_TO_CENTS).toString();

        // /!\ here we use new Date () and not getDate(), because we need the current timestamp
        const pbxTime = format(new Date(), "yyyy-MM-dd'T'HH:mm:ssxxx")

        let email;
        if (_selectedTenant?.EMAIL) {
            email = _selectedTenant?.EMAIL;
        } else {
            email = _email;
        }

        const unixTimeStamp = Math.floor(Date.now() / 1000)
        const pbxCmd = PBX_CMD_PREFIX + _selectedTenant?.NOCA + "_" + unixTimeStamp;

        // fill the value of PBX_2MONT1,..., only if they are sent
        for (let i = 0; i < _delayedAmounts.length; i++) {
            let date = "";
            let amount = "";

            if (i < _numberOfValidDelayedPayments.current) {
                date = Utils.formatDateIfValid(_selectedDates[i], "dd/MM/yyyy");
                amount = Math.trunc((+ _delayedAmounts[i]) * EURO_TO_CENTS).toString();
            }

            if (_refAmounts[i].current) {
                _refAmounts[i].current.value = amount;
            }
            if (_refDates[i].current) {
                _refDates[i].current.value = date;
            }
        }

        const payboxDataRequest: PayboxData = {
            ...data,
            PBX_CMD: pbxCmd,
            PBX_PORTEUR: email,
            PBX_REPONDRE_A: "https://webhook.granddelta.fr/ca/cb/index.php", // this will be set by the back end
            PBX_TIME: pbxTime,
            PBX_TOTAL: pbxTotal,
            PBX_2MONT1: _refAmounts[0].current.value,
            PBX_DATE1: _refDates[0].current.value,
            PBX_2MONT2: _refAmounts[1].current.value,
            PBX_DATE2: _refDates[1].current.value,
            PBX_2MONT3: _refAmounts[2].current.value,
            PBX_DATE3: _refDates[2].current.value,
        }

        try {
            const payboxDataReceived = await DataService.hashPayboxData(payboxDataRequest);

            if (_refRepTo.current) {
                _refRepTo.current.value = payboxDataReceived.PBX_REPONDRE_A;
            }

            if (_refMail.current) {
                _refMail.current.value = email;
            }

            if (_refCmd.current) {
                _refCmd.current.value = pbxCmd;
            }

            if (_refHmac.current) {
                _refHmac.current.value = payboxDataReceived.PBX_HMAC;
            }

            if (_refTime.current) {
                _refTime.current.value = pbxTime;
            }

            if (_refTotal.current) {
                _refTotal.current.value = pbxTotal;
            }

            if (_refSubmit.current) {
                _refSubmit.current.click();
            }
        } catch (error) {
            console.error(error);
            return;
        }
    }

    const getMinMaxDates = () => {
        let minDates = [getDate(), _selectedDates[0] ?? getDate(), _selectedDates[1] ?? getDate()]
        minDates = minDates.map((date) => {
            return (addDays(date, 1))
        })
        let maxDate1 = getDate();
        maxDate1.setMonth((getDate()).getMonth() + 6);
        // the max date is either the previous date + 6 months, ot if the previous date is not defined, the current date + 6 months
        let maxDate2 = _selectedDates[0] ? new Date(_selectedDates[0]) : getDate();
        maxDate2.setMonth((_selectedDates[0]?.getMonth() ?? (getDate()).getMonth()) + 6);
        // the max date is either the previous date + 6 months, ot if the previous date is not defined, the current date + 6 months
        let maxDate3 = _selectedDates[1] ? new Date(_selectedDates[1]) : getDate();
        maxDate3.setMonth((_selectedDates[1]?.getMonth() ?? (getDate()).getMonth()) + 6);
        const maxDates = [maxDate1, maxDate2, maxDate3]
        return {
            minDates,
            maxDates
        }
    }

    const isDateBetweenBounds = (date: Date | null, minDate: Date | null, maxDate: Date | null): boolean => {
        if (!date || !minDate || !maxDate) {
            return false
        }

        return (
            (
                (compareAsc(date, minDate) === 1)
                || (compareAsc(date, minDate) === 0)
            ) &&
            (
                (compareAsc(date, maxDate) === -1)
                || (compareAsc(date, maxDate) === 0)
            )
        )
    }

    const handleCAChanged = async (event: CustomEvent<TextareaChangeEventDetail | InputChangeEventDetail>) => {
        if (!event?.detail ||
            // FIXME : this is probably not the best way to do this
            // eslint-disable-next-line eqeqeq
            (event.detail.value == undefined) ||
            !event?.currentTarget) {
            return;
        }

        const inputText = event.detail.value;
        if (inputText === _searchField) {
            // no need to process twice the same data
            // it could also mean, that the search fields has been filled by clicking on a dropdown element
            return;
        }

        if (_selectedTenant) {
            // if the search field content has changed, while a tenant was selected, there is no selected tenant anymore.
            _setSelectedTenant(undefined);
        }

        _setSearchField(inputText);

        // if the input text is empty, close the popup
        if (inputText === "") {
            _setOpenSearchPopup(false)
            return;
        }

        _setSearchPopupTarget(event.currentTarget);
        updateTenantsList(inputText)

    }

    const updateTenantsList = async (inputText: string) => {
        try {
            const tenants = await DataService.searchTenant(inputText);
            _setTenants(tenants);
            _setOpenSearchPopup(true);
        } catch (error) {
            console.error(error);
        }
    }

    const handleSearchPopupElementClick = (key?: string) => {
        if (!key) {
            // if the key is undefined, return
            return;
        }

        const clickedTenant: TenantSearchData = _tenants.find((tenant: TenantSearchData) => (tenant.NOCA === key));
        if (!clickedTenant) {
            console.error("Unable to find the clicked tenant for the key ", key);
            return;
        }
        _setSearchField(clickedTenant.LBL_CONTRAT + " - " + key);
        _setSelectedTenant(clickedTenant);
        // show the email input field if the tenant email is not valid
        _setShowEmailField(!clickedTenant.EMAIL);
        _setOpenSearchPopup(false)
    }

    const handleEmailChanged = (event: CustomEvent<TextareaChangeEventDetail | InputChangeEventDetail>) => {
        if (!event?.detail ||
            // FIXME : this is probably not the best way to do this
            // eslint-disable-next-line eqeqeq
            (event.detail.value == undefined)) {
            console.error("Undefined event", event)
            return;
        }
        _setEmail(event.detail.value);
    }

    const handleSearchFieldBlur = () => {
        // the timeout allows that if a click occured on the popup itself,
        // the popup handles the click event before it is closed
        setTimeout(() => {
            _setOpenSearchPopup(false);
        }, 200);
    }

    // we use getDate to avoid troubles when comparing dates with boundaries
    const getDate = (): Date => {
        let date = new Date();
        date.setHours(0);
        date.setMinutes(0);
        date.setSeconds(0);
        date.setMilliseconds(0);
        return date;
    }


    return (
        <InApp
            isPrimaryLoading={false}
            isSecondaryLoading={false}
            backUrl=""
            title="Paiement"
            displayMenuButton={true}
            showRightMenuBtn={true}
            rightMenu={<RightMenu />}
            refresh={null}
        >
            <GDHToast
                content={_toastContent}
                handleClose={handleToastClose}
                show={_showToast}
                success={_toastSuccess}
            />

            <div className="payment-home-container">

                <div className="payment-home-section">
                    <IonItem>
                        <IonLabel>Paiement direct</IonLabel>
                        <IonCheckbox slot="start" value={DIRECT_PAYMENT} onIonChange={handlePaymentChanged} checked={_directPayment} />
                    </IonItem>
                    <IonItem>
                        <IonLabel>Paiement différé</IonLabel>
                        <IonCheckbox slot="start" value={DELAYED_PAYMENT} onIonChange={handlePaymentChanged} checked={_delayedPayment} />
                    </IonItem>
                </div>

                <div className="payment-home-section">
                    <TextInputItem
                        handleChangeInput={handleCAChanged}
                        value={_searchField}
                        isArea={false}
                        label="Compte affaire"
                        onBlur={handleSearchFieldBlur}
                        highlight={_selectedTenant !== undefined}
                        innerRef={_refNoca}
                    />

                    <SelectionPopover
                        open={_openSearchPopup}
                        anchor={_searchPopupTarget}
                        elements={_tenants.map((tenant: TenantSearchData) => {
                            return {
                                key: tenant.NOCA,
                                content: ((tenant.LBL_CONTRAT ?? "") + " " + (tenant.NOCA ?? ""))
                            };
                        })}
                        onClickElement={handleSearchPopupElementClick}
                    />

                    {
                        _showEmailField && _selectedTenant &&
                        <div className="payment-home-email-container">
                            <TextInputItem
                                handleChangeInput={handleEmailChanged}
                                value={_email}
                                isArea={false}
                                label="Email de contact"
                            />
                        </div>
                    }
                </div>

                <div className="payment-home-section">
                    <TextInputItem
                        handleChangeInput={handleAmountChanged}
                        value={_directAmount}
                        isArea={false}
                        label="Montant du paiement immédiat ( € )"
                    />
                </div>

                {
                    _delayedPayment &&
                    <div className="payment-home-section">
                        <div className="payment-home-sub-section">
                            <div className="payment-home-text-container">
                                <TextInputItem
                                    handleChangeInput={handleDelayedAmountChanged0}
                                    value={_delayedAmounts[0]}
                                    isArea={false}
                                    label="Montant du second paiement ( € )"
                                />
                            </div>

                            <GDHDatePicker
                                minDate={_minDates[0]}
                                maxDate={_maxDates[0]}
                                selectedDate={_selectedDates[0]}
                                handleDateChange={handleDateChange0}
                                title="Date du second paiement"
                            />
                        </div>

                        <div className="payment-home-sub-section">
                            <div className="payment-home-text-container">
                                <TextInputItem
                                    handleChangeInput={handleDelayedAmountChanged1}
                                    value={_delayedAmounts[1]}
                                    isArea={false}
                                    label="Montant du troisième paiement ( € )"
                                />
                            </div>

                            <GDHDatePicker
                                minDate={_minDates[1]}
                                maxDate={_maxDates[1]}
                                selectedDate={_selectedDates[1]}
                                handleDateChange={handleDateChange1}
                                title="Date du troisième paiement"
                            />
                        </div>

                        <div className="payment-home-sub-section">
                            <div className="payment-home-text-container">
                                <TextInputItem
                                    handleChangeInput={handleDelayedAmountChanged2}
                                    value={_delayedAmounts[2]}
                                    isArea={false}
                                    label="Montant du quatrième paiement ( € )"
                                />
                            </div>

                            <GDHDatePicker
                                minDate={_minDates[2]}
                                maxDate={_maxDates[2]}
                                selectedDate={_selectedDates[2]}
                                handleDateChange={handleDateChange2}
                                title="Date du quatrième paiement"
                            />
                        </div>
                    </div>
                }

            </div>

            <form method="post" action={PAYBOX_GATEWAY_URL}>
                <input type="hidden" name="PBX_SITE" value={data.PBX_SITE} />
                <input type="hidden" name="PBX_RANG" value={data.PBX_RANG} />
                <input type="hidden" name="PBX_IDENTIFIANT" value={data.PBX_IDENTIFIANT} />
                <input type="hidden" ref={_refTotal} name="PBX_TOTAL" value="" />
                <input type="hidden" name="PBX_DEVISE" value={data.PBX_DEVISE} />
                <input type="hidden" ref={_refCmd} name="PBX_CMD" value="" />
                <input type="hidden" ref={_refMail} name="PBX_PORTEUR" value="" />
                <input type="hidden" ref={_refRepTo} name="PBX_REPONDRE_A" value="" />
                <input type="hidden" name="PBX_RETOUR" value={data.PBX_RETOUR} />
                <input type="hidden" name="PBX_EFFECTUE" value={data.PBX_EFFECTUE} />
                <input type="hidden" name="PBX_ANNULE" value={data.PBX_ANNULE} />
                <input type="hidden" name="PBX_REFUSE" value={data.PBX_REFUSE} />
                <input type="hidden" name="PBX_HASH" value={data.PBX_HASH} />
                <input type="hidden" ref={_refTime} name="PBX_TIME" value="" />
                <input type="hidden" ref={_refHmac} name="PBX_HMAC" value="" />
                <input type="hidden" ref={_refAmounts[0]} name="PBX_2MONT1" value="" />
                <input type="hidden" ref={_refDates[0]} name="PBX_DATE1" value="" />
                <input type="hidden" ref={_refAmounts[1]} name="PBX_2MONT2" value="" />
                <input type="hidden" ref={_refDates[1]} name="PBX_DATE2" value="" />
                <input type="hidden" ref={_refAmounts[2]} name="PBX_2MONT3" value="" />
                <input type="hidden" ref={_refDates[2]} name="PBX_DATE3" value="" />

                <input hidden type="submit" ref={_refSubmit} value="" />
            </form>

            <Dialog
                open={_showDialog}
                title="Résumé du paiement"
                content={_dialogContent}
                onCancel={handleDialogCancel}
                onConfirm={handleDialogOk}
            />

            <div className="payment-home-button-container">
                <IonButton className="payment-home-next-button" disabled={false} expand="block" onClick={handleSubmit} type="submit">Payer</IonButton>
            </div>
        </InApp>
    )

}
