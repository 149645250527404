import React from "react";
import { IonItem } from "@ionic/react";
import { Utils } from "../../../utils/utils";

import './menu-item.css';

export default function MenuItem(props: any) {

    let faIcon = Utils.checkFaIconIsValid(props.faIcon) ? props.faIcon : "far fa-circle";

    if (props.depth === 0) {
        faIcon += " menu-item-faicon__purple";
    }

    return (
        <IonItem button disabled={props.disabled} onClick={() => props.onClick(props.code)} className={props.itemClassName}>
            <i className={faIcon + " ion-margin-end"} />
            {props.name}
        </IonItem>
    )
}
