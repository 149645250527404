import React, { Component, FormEvent } from 'react';
import {
    IonCard,
    IonItemDivider,
    IonItem,
    IonInput,
    IonLabel,
    IonButton,
    IonText,
    IonAlert
} from '@ionic/react';

import './add-target.css';
import DataService from '../../../../services/data-service';
import { InApp } from '../../../../components/in-app/in-app';
import ConfigUtils from '../../../../utils/config-utils';
import RightMenu from '../../../../components/menu/right-menu/right-menu';

const PAGE_TITLE_PREFIX = ConfigUtils.getFlashApplicationName() + " - Ajouter une cible";

const INITIAL_STATE = {
    hp1: '',
    hp2: '',
    hp3: '',
    codeUniteGestion: '',
    lastName: '',
    firstName: '',
    numCA: '',
    phone: '',
    isPrimaryLoading: false,
    isSecondaryLoading: false,
    pageTitle: PAGE_TITLE_PREFIX
};


class AddTarget extends Component<any, any> {

    private id: any;

    constructor(props: any) {
        super(props);
        this.state = { ...INITIAL_STATE };

        this.id = this.props.match.params.id;


        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleHp1Change = this.handleHp1Change.bind(this);
        this.handleHp2Change = this.handleHp2Change.bind(this);
        this.handleHp3Change = this.handleHp3Change.bind(this);
        this.handleLastNameChange = this.handleLastNameChange.bind(this);
        this.handleFirstNameChange = this.handleFirstNameChange.bind(this);
        this.handleCUGChange = this.handleCUGChange.bind(this);
        this.handleNumCAChange = this.handleNumCAChange.bind(this);
        this.handlePhoneChange = this.handlePhoneChange.bind(this);
        this.fetchCampaignDetails = this.fetchCampaignDetails.bind(this);
    }

    componentDidMount() {
        this.fetchCampaignDetails();
    }

    async fetchCampaignDetails() {
        this.setState({ isPrimaryLoading: true });
        try {
            let campaignDetails = await DataService.getCampaignDetails(this.id);
            this.setState({
                targets: campaignDetails.locataires,
            });
            await this.fetchResidenceName(campaignDetails.pat1)
        } catch (e) {
            console.error(e);
        }
        this.setState({ isPrimaryLoading: false });
    }

    private async fetchResidenceName(pat1: string) {
        try {
            let residences = await DataService.searchResidence(pat1);
            if (residences && residences[0] && residences[0].HPLBLPAT) {
                this.setState({ pageTitle: PAGE_TITLE_PREFIX + " - " + residences[0].HPLBLPAT + " - " + pat1 })
            } else {
                console.error("Unable to get residence name");
            }
        } catch (error) {
            console.error(error)
        }
    }

    handleSubmit(e: FormEvent) {
        e.preventDefault();
        let { hp1, hp2, hp3, codeUniteGestion, lastName, firstName, numCA, phone } = this.state;

        DataService.addTarget(hp1, hp2, hp3, codeUniteGestion, lastName, firstName, numCA, phone, this.id).then(() => {
            this.setState({
                showPopup: true,
                success: true
            });
        }).catch((errorMsg) => {
            this.setState({
                showPopup: true,
                success: false,
                creationStatus: errorMsg
            });
        })
    }

    handleHp1Change(e: any) {
        this.setState({
            hp1: e.target.value
        });
    }

    handleHp2Change(e: any) {
        this.setState({
            hp2: e.target.value
        });
    }

    handleHp3Change(e: any) {
        this.setState({
            hp3: e.target.value
        });
    }

    handleLastNameChange(e: any) {
        this.setState({
            lastName: e.target.value
        });
    }

    handleFirstNameChange(e: any) {
        this.setState({
            firstName: e.target.value
        });
    }

    handleCUGChange(e: any) {
        this.setState({
            codeUniteGestion: e.target.value
        });
    }

    handleNumCAChange(e: any) {
        this.setState({
            numCA: e.target.value
        });
    }

    handlePhoneChange(e: any) {
        this.setState({
            phone: e.target.value
        });
    }


    render() {
        return (
            <InApp
                isPrimaryLoading={this.state.isPrimaryLoading}
                isSecondaryLoading={this.state.isSecondaryLoading}
                backUrl={ConfigUtils.getFlashBaseURL() + "/" + this.props.match.params.id + "/cibleLoc"}
                title={this.state.pageTitle}
                displayMenuButton={false}
                showRightMenuBtn={false}
                rightMenu={<RightMenu />}
                refresh={null}
            >
                <IonCard>
                    <form onSubmit={(e) => { this.handleSubmit(e); }}>
                        <IonItemDivider>
                            informations
                        </IonItemDivider>


                        <IonItem className="item-input ion-margin">
                            <IonLabel position="stacked">
                                Code patrimoine 1
                                <IonText color="danger"> *</IonText>
                            </IonLabel>
                            <IonInput required value={this.state.hp1} onIonChange={this.handleHp1Change} ></IonInput>
                        </IonItem>


                        <IonItem className="item-input ion-margin">
                            <IonLabel position="stacked">
                                Code patrimoine 2
                                <IonText color="danger"> *</IonText>
                            </IonLabel>
                            <IonInput required value={this.state.hp2} onIonChange={this.handleHp2Change}  ></IonInput>
                        </IonItem>


                        <IonItem className="item-input ion-margin">
                            <IonLabel position="stacked">
                                Code patrimoine 3
                                <IonText color="danger"> *</IonText>
                            </IonLabel>
                            <IonInput required value={this.state.hp3} onIonChange={this.handleHp3Change}  ></IonInput>
                        </IonItem>

                        <IonItem className="item-input ion-margin">
                            <IonLabel position="stacked">
                                Code unité de gestion
                                <IonText color="danger"> *</IonText>
                            </IonLabel>
                            <IonInput required value={this.state.codeUniteGestion} onIonChange={this.handleCUGChange}  ></IonInput>
                        </IonItem>

                        <IonItem className="item-input ion-margin">
                            <IonLabel position="stacked">
                                Nom
                                <IonText color="danger"> *</IonText>
                            </IonLabel>
                            <IonInput required value={this.state.lastName} onIonChange={this.handleLastNameChange}  ></IonInput>
                        </IonItem>

                        <IonItem className="item-input ion-margin">
                            <IonLabel position="stacked">
                                Prenom
                                <IonText color="danger"> *</IonText>
                            </IonLabel>
                            <IonInput required value={this.state.firstName} onIonChange={this.handleFirstNameChange}  ></IonInput>
                        </IonItem>

                        <IonItem className="item-input ion-margin">
                            <IonLabel position="stacked">
                                Numero compte affaire
                                <IonText color="danger"> *</IonText>
                            </IonLabel>
                            <IonInput required value={this.state.numCA} onIonChange={this.handleNumCAChange}  ></IonInput>
                        </IonItem>


                        <IonItem className="item-input ion-margin">
                            <IonLabel position="stacked">
                                Numero de portable
                                <IonText color="danger"> *</IonText>
                            </IonLabel>
                            <IonInput required value={this.state.phone} onIonChange={this.handlePhoneChange}  ></IonInput>
                        </IonItem>

                        <div className="ion-padding">
                            <IonButton expand="block" type="submit">Soumettre</IonButton>
                        </div>
                    </form>
                </IonCard>


                <IonAlert
                    isOpen={this.state.showPopup && this.state.success}
                    header={'Cible ajoutée'}
                    message={'Que voulez vous faire ?'}
                    buttons={[
                        {
                            text: 'Ajouter une autre cible',
                            handler: () => {
                                this.setState({ ...INITIAL_STATE });
                            }
                        },
                        {
                            text: 'Retourner aux cibles locataires',
                            handler: () => {
                                this.props.history.push(ConfigUtils.getFlashBaseURL())
                            }
                        }
                    ]}
                />


                <IonAlert
                    isOpen={this.state.showPopup && !this.state.success}
                    header={'Echec de la création'}
                    message={this.state.creationStatus}
                    buttons={[
                        {
                            text: 'Réessayer',
                            handler: () => {
                                this.setState({
                                    showPopup: false
                                });
                            }
                        }
                    ]}
                />

            </InApp>
        );
    }
}


export default AddTarget;