import React, { useState } from 'react';
import { Utils } from '../../utils/utils';


import './drag-and-drop.css';

const INVALID_EXTENTION = "Ce type de fichier n'est pas accepté";

type DragAndDropProps = {
    children: React.ReactNode,
    acceptedExtention: string[],
    errorCallback: (errorMsg: string) => void,
    dropedFilesCallback: (files: File[]) => void,
    isEmpty: boolean
}

export function DragAndDrop(props: DragAndDropProps) {

    const [_dropZoneDragedOver, _setDropZoneDragedOver] = useState<boolean>(false);

    /*
     * The dragged item is dragged over dropArea, making it the target for the drop event if the user drops it there.
     */
    const onDragEnter = (e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        e.stopPropagation();
        _setDropZoneDragedOver(true);
    }

    /*
     * Every few hundred milliseconds, while the dragged item is over dropArea and is moving.
     */
    const onDragOver = (e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        e.stopPropagation();
        _setDropZoneDragedOver(true);
    }

    /*
     * The dragged item is dragged off of dropArea and onto another element, making it the target for the drop event instead.
     */
    const onDragLeave = (e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        e.stopPropagation();
        _setDropZoneDragedOver(false);
    }

    /*
     * The user releases their mouse button, dropping the dragged item onto dropArea.
     */
    const onDrop = (e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        e.stopPropagation();
        _setDropZoneDragedOver(false);

        if (!e?.dataTransfer?.files || !e.dataTransfer.files[0]) {
            return;
        }

        const filesArray: File[] = Object.values(e.dataTransfer.files);
        let invalidExtention = false;
        for (let file of filesArray) {
            if (!Utils.matchFileExtentions(file.name, props.acceptedExtention)) {
                invalidExtention = true;
                break;
            }
        }

        if (invalidExtention) {
            props.errorCallback(INVALID_EXTENTION);
            return;
        }

        props.dropedFilesCallback(filesArray);

    }


    return (
        <div className={
            "drag-and-drop-container"
            + (_dropZoneDragedOver ? " drag-and-drop-container__draged-over" : "")
            + ((props.isEmpty) ? " drag-and-drop-container__no-file" : "")
        }
            onDragEnter={onDragEnter} onDragOver={onDragOver} onDragLeave={onDragLeave} onDrop={onDrop}
        >
            <div className="drag-and-drop-container-inner-no-file-message">
                Déposer vos fichiers ici
            </div>
            <div className="drag-and-drop-container-inner">
                {props.children}
            </div>
        </div>
    )
}
