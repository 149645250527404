import { IonCol, IonGrid, IonImg, IonRow, IonButton, IonActionSheet } from '@ionic/react';
import React, { useState } from 'react';
import { Camera, CameraResultType, CameraSource } from '@capacitor/camera';
import GDHToast from '../../../../components/toast/toast';
import { useToast } from '../../../../hooks/useToast';

type DigitalReportHomeStep4Props = {
    imgs: (string | null)[]
    setImgs: (imgs: (string | null)[]) => void
}

// nginx max request size is 20Mo
// we take 1Mo for the rest of the request ( which is huge )
const IMG_MAX_LENGTH = 19000000

export function DigitalReportHomeStep4(props: DigitalReportHomeStep4Props) {

    const [
        _showToast, ,
        _successToast, ,
        _contentToast, ,
        _handleCloseToast, _activateToast] = useToast();

    const [showActionSheet, setShowActionSheet] = useState(false);
    const [currentPhotoIndex, setCurrentPhotoIndex] = useState<number | null>(null);

    const takePicture = async (photoIndex: number, source: CameraSource) => {
        const image = await Camera.getPhoto({
            quality: 90,
            allowEditing: false,
            resultType: CameraResultType.Base64,
            source: source,
        });

        let imageUrl = `data:image/${image.format};base64,` + image.base64String

        // check img size
        if (imageUrl.length > IMG_MAX_LENGTH) {
            _activateToast(false, 'Image trop volumineuse. Veuillez choisir une image de 19Mo max.');
            return;
        }

        if (imageUrl === undefined) {
            return
        }

        let imgs = [...props.imgs];

        imgs[photoIndex] = imageUrl;

        props.setImgs(imgs)
    };

    const onClickDeleteBtn = (photoIndex: number) => {
        let imgs = [...props.imgs];
        imgs[photoIndex] = null;
        props.setImgs(imgs)
    }

    const showOptions = (photoIndex: number) => {
        setCurrentPhotoIndex(photoIndex);
        setShowActionSheet(true);
    };

    return (
        <>
            <div className="digital-report-home-title">
                Photos
            </div>
            <div className="digital-report-section">
                <IonGrid>
                    <IonRow>
                        {
                            props.imgs.map((img: (string | null), index: number) => {
                                if (img !== null) {
                                    return (
                                        <IonCol key={index}>
                                            <IonImg onClick={() => showOptions(index)} className="digital-report-photo" src={img} />
                                            <div className="digital-report-del-btn-container">
                                                <IonButton onClick={() => onClickDeleteBtn(index)} color="danger">Supprimer</IonButton>
                                            </div>
                                        </IonCol>
                                    )
                                } else {
                                    return (
                                        <IonCol key={index}>
                                            <div onClick={() => showOptions(index)} className="digital-report-photo" >
                                                <span>Ajouter</span>
                                            </div>
                                        </IonCol>
                                    )
                                }
                            })
                        }
                    </IonRow>
                </IonGrid>
            </div>
            <IonActionSheet
                isOpen={showActionSheet}
                onDidDismiss={() => setShowActionSheet(false)}
                buttons={[
                    {
                        text: 'Prendre une photo',
                        // icon: 'camera',
                        handler: () => {
                            if (currentPhotoIndex !== null) {
                                takePicture(currentPhotoIndex, CameraSource.Camera);
                            }
                        }
                    },
                    {
                        text: 'Choisir dans la galerie',
                        // icon: 'images',
                        handler: () => {
                            if (currentPhotoIndex !== null) {
                                takePicture(currentPhotoIndex, CameraSource.Photos);
                            }
                        }
                    },
                    {
                        text: 'Annuler',
                        // icon: 'close',
                        role: 'cancel'
                    }
                ]}
            />
            <GDHToast
                show={_showToast}
                handleClose={_handleCloseToast}
                success={_successToast}
                content={_contentToast}
            />
        </>
    )
}
