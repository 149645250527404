import React, { Dispatch, SetStateAction, useState } from "react";
import { IonNote, IonItem, IonTextarea, IonLabel, IonText, IonInput } from "@ionic/react";
import { InputChangeEventDetail } from '@ionic/core/dist/types/components/input/input-interface';
import { TextareaChangeEventDetail } from '@ionic/core/dist/types/components/textarea/textarea-interface';
import './text-input.css';

export type TextInputProps = {
    handleChangeArea?: (event: CustomEvent<TextareaChangeEventDetail>) => void,
    handleChangeInput?: (event: CustomEvent<InputChangeEventDetail>) => void,
    value: string,
    maxLen?: number,
    blockAtMaxLen?: boolean,
    label?: string, // TODO : name ???
    className?: string,
    disabled?: boolean,
    required?: boolean,
    isArea: boolean,
    textLineProps?: TextLineProps,
    textAreaProps?: TextAreaProps,
    onClick?: React.MouseEventHandler<HTMLIonItemElement>,
    readonly?: boolean,
    onBlur?: () => void,
    highlight?: boolean,
    innerRef?: any,
    id?: string,
}

type TextLineProps = {
    placeholder?: string
}

type TextAreaProps = {
    rows?: number,
}

// TODO : check if it is good to use Either keyword from https://gcanti.github.io/fp-ts/modules/Either.ts.html
// because the compoenent is EITHER a TextArea OR a TextLine

const DEFAULT_ROWS = 5;


export function TextInputItem(props: TextInputProps) {

    const outOfBound = props.maxLen && (props.value.length > props.maxLen);
    const className = props.className ? props.className : '';
    const required = props.required ?? false;
    const rows = props.textAreaProps?.rows ?? DEFAULT_ROWS;
    const readonly = (props.readonly === true);
    const inputClassName = (outOfBound ? "out-of-bound " : "") + (props.highlight ? "text-input-highlight" : "")

    return (
        <IonItem id={props.id ?? ""} className={className} onClick={props.onClick} >
            {
                props.label &&
                <IonLabel position="floating">
                    {props.label}
                    {
                        required &&
                        <IonText color="danger"> *</IonText>
                    }
                </IonLabel>
            }
            {
                props.maxLen &&
                <IonNote slot="end">{props.value.length + " / " + props.maxLen.toString()}</IonNote>
            }
            {
                props.isArea ?
                    <IonTextarea onBlur={props.onBlur} readonly={readonly} required={required} rows={rows} disabled={(props.disabled === true)} value={props.value} onIonChange={props.handleChangeArea}
                        className={inputClassName} maxlength={props.blockAtMaxLen ? props.maxLen : undefined} ref={props.innerRef}
                    />
                    :
                    <IonInput readonly={readonly} name={props.label} required={required} placeholder={props.textLineProps?.placeholder} disabled={(props.disabled === true)}
                        value={props.value} onIonChange={props.handleChangeInput} className={inputClassName} maxlength={props.blockAtMaxLen ? props.maxLen : undefined}
                        onBlur={props.onBlur} ref={props.innerRef}
                    />
            }
        </IonItem>
    )

}


export function useTextInputItem(): [
    string,
    Dispatch<SetStateAction<string>>,
    (event: CustomEvent<TextareaChangeEventDetail | InputChangeEventDetail>) => void
] {

    const [_text, _setText] = useState<string>('');

    const onChange = (event: CustomEvent<TextareaChangeEventDetail | InputChangeEventDetail>) => {
        _setText(event?.detail?.value ?? '');
    }

    return [
        _text,
        _setText,
        onChange
    ]
}