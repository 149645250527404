import { format, isValid } from 'date-fns'

export class Utils {

    // make the constructor private so the class cannot be instantiated
    private constructor() { }

    public static checkFaIconIsValid(faiconStr: string): boolean {

        if (!faiconStr) {
            return false;
        }

        const regex = /^[a-z0-9- ]*$/i;

        return !!faiconStr.match(regex);


    }

    public static isCreatorFromRoles(roles?: string[]): boolean {

        if (!roles) {
            return false;
        }

        return roles.some((role: string) => {
            return (
                (role === 'ROLE_GDH_APPS_ADMIN_CAMPAGNE_EDITOR')
                || (role === 'ROLE_GDH_APPS_ADMIN_CAMPAGNE_ADMIN')
                || (role === 'ROLE_SUPER_ADMIN')
            );
        });
    }

    /**
     * Get the only static instance of this class
     * @param textToSplit {string} the text to be splited in lines
     * @param lineMaxChars {number} the number of characters max in a line. default value is 12
     *
     * @return {string[]} an array of lines
     */
    public static splitTextIntoLines(textToSplit: string, lineMaxChars: number = 12): string[] {
        if (textToSplit.length <= lineMaxChars) {
            // the whole text fits in a single line
            return [textToSplit];
        }

        const lines = [];
        let line = "";
        const words = textToSplit.split(" ");
        for (let word of words) {
            line += (word + " ");
            if (line.length > lineMaxChars) {
                lines.push(line);
                line = "";
            }
        }

        lines.push(line);

        return lines;
    }

    /**
     * Check if a fileName has the correct extention
     * @param fileName {string} the name of the file to check
     * @param requestedExtention {string} the extention to search
     *
     * @return {boolean} true if the filename matches the requested extention
     */
    public static matchFileExtention(fileName: string, requestedExtention: string): boolean {
        return requestedExtention === fileName.split('.').pop();
    }

    /**
     * Check if a fileName has the correct extention
     * @param fileName {string} the name of the file to check
     * @param requestedExtentions {string[]} the extentions to search
     *
     * @return {boolean} true if the filename matches at least one of the requested extentions
     */
    public static matchFileExtentions(fileName: string, requestedExtentions: string[]): boolean {
        return requestedExtentions.some((requestedExtention: string) => {
            return this.matchFileExtention(fileName, requestedExtention);
        })
    }


    /**
     * Convert a blob to base64 string
     * @param blob {Blob} The blob to convert
     *
     * @return {string} The base64 string
     */
    public static convertBlobToBase64(blob: Blob): Promise<string | ArrayBuffer | null> {

        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onerror = reject;
            reader.onload = () => {
                resolve(reader.result);
            };
            reader.readAsDataURL(blob);
        })
    }

    /**
     * Convert a blob to File
     * @param blob {Blob} The blob to convert
     * @param filename {string} the name of the file
     *
     * @return {File} The converted file
     */
    public static convertBlobToFile(blob: Blob, filename: string): File {
        return new File([blob], filename);
    }

    /**
     * convert a file to base64 string
     * @param file {File} the file to convert
     * @returns the base64 string
     */
    public static async convertFileToBase64(file: File): Promise<string> {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result as string);
            reader.onerror = error => reject(error);
        });
    }

    /**
     * Check if a string can be converted to a number
     * @param str {string} the string to check
     *
     * @return {boolean} true if the string can be converted to number
     */
    public static isNumeric(str: string): boolean {
        return !isNaN(+ str)
    }

    /**
     * Check if a string is a valid email. It must be trimmed first
     *
     * @return {boolean} true if it is a valid email, false otherwise
     * @param email
     */
    public static checkEmail(email: string): boolean {
        const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return regex.test(String(email).toLowerCase());
    }

    /**
     * Set a string with a capital as first letter and the rest with lower case. Typically for first name
     * @param str {string} the str to proceed
     *
     * @return {string} the string with the correct case
     */
    public static capitalizeFirstLetter(str: string): string {
        return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
    }


    public static capitalizeUsername(firstname: string, lastname: string) {
        const firstnameCapitalized = Utils.capitalizeFirstLetter(firstname)
        const lastnameCapitalized = lastname?.toUpperCase()
        return firstnameCapitalized + ' ' + lastnameCapitalized;
    }

    // 20210125 to 25/01/2021
    public static rawDateToHumanReadable(rawDate?: string) {
        if (!rawDate || rawDate.length !== 8) {
            console.log("Inavlid date : ", rawDate)
            return rawDate;
        }

        let date = rawDate.substring(6)
        date += "/"
        date += rawDate.substring(4, 6)
        date += "/"
        date += rawDate.substring(0, 4)

        return date
    }

    public static formatDateIfValid(date: Date | null, formatStr: string) {
        return (date && isValid(date)) ? format(date, formatStr) : ""
    }
}
