export class Store {

    // TODO : merge this class with the redux store !

    private static sNeedToReloadCampaigns = false;

    private constructor() { };

    public static setNeedToReloadCampaigns(needToReload: boolean) {
        this.sNeedToReloadCampaigns = needToReload;
    }

    // if campaign reload is needed, returns true and set the token to false so it is reloaded only once
    public static needToReloadCampaigns(): boolean {
        let needToReload = this.sNeedToReloadCampaigns;
        if (this.sNeedToReloadCampaigns) {
            this.sNeedToReloadCampaigns = false;
        }
        return needToReload;
    }
}