import React from 'react';
import { AutocompleteInput } from "./autocomplete-input";
import { ContactMail } from "../../interfaces/contact-mail.interface";
import { useState } from "react";
import DataService from "../../services/data-service";
import { useMount } from '../../hooks/useMount';
import { UserData } from '../../interfaces/user-data';


type AutocompleteContactsProps = {
    onChange: (event: React.ChangeEvent<{}>, value: ContactMail | ContactMail[] | null) => void,
    value: ContactMail[],
    disabled?: boolean,
    contactMustHaveEmail?: boolean,
    contactMustHavePhoneNumber?: boolean,
    multiple?: boolean
}


export function AutocompleteContacts(props: AutocompleteContactsProps) {

    useMount(() => {
        fetchEmployees();
    })

    let [employees, setEmployees] = useState<UserData[]>([]);

    const fetchEmployees = async (forceDownload: boolean = false) => {
        try {
            let employees: UserData[] = await DataService.getAllEmployees();
            employees = employeesSanityUpdate(employees);
            setEmployees(employees);
        } catch (e) {
            console.error(e);
        }
    }

    const employeesSanityUpdate = (employees: UserData[]): any[] => {
        employees = employees.filter(employee => {
            return !(!employee
                || (!employee.firstname && !employee.lastname)
                || (!employee.email && props.contactMustHaveEmail)
                || (!employee.mobilePhone && props.contactMustHavePhoneNumber));


        });

        for (let employee of employees) {
            employee.firstname = employee.firstname ? employee.firstname : "";
            employee.lastname = employee.lastname ? employee.lastname : "";
            employee.title = employee.title ? employee.title : "";
        }
        return employees;
    }

    return (
        <AutocompleteInput
            disabled={props.disabled}
            value={props.value}
            availablePersons={employees}
            placeHolder="Sélectionnner dans l'annuaire interne"
            onChange={props.onChange}
            multiple={props.multiple}
        />
    )

}