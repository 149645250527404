import React from 'react';
import {
	IonIcon,
	IonFab,
	IonFabButton,
	IonToggle,
	IonGrid,
	IonRow,
	IonCol
} from '@ionic/react';
import { add } from 'ionicons/icons';
import DataService from '../../../../services/data-service';
import { MUIDataTableColumnDef, MUIDataTableMeta } from "mui-datatables";
import { RefresherEventDetail } from '@ionic/core';

import "./campaign-targets.css";
import GDHToast from '../../../../components/toast/toast';
import ConfigUtils from '../../../../utils/config-utils';
import { InApp } from '../../../../components/in-app/in-app';
import { TabBar, TabName } from '../../../../components/flash/tab-bar/tab-bar';
import { DataTable } from '../../../../components/data-table/data-table';
import { KpiCard } from '../../../../components/kpi-card/kpi-card';
import { StorageUtils } from '../../../../utils/storage-utils';
import RightMenu from '../../../../components/menu/right-menu/right-menu';


type Tenant = {
	id: number,
	active: boolean
}

const PAGE_TITLE_PREFIX = ConfigUtils.getFlashApplicationName() + " - Cibles";

const VISIBLE_COLUMNS_STORAGE_KEY = "flashCampaignTargetsVisibleColumns";


const sortOrder: { name: string, direction: "desc" | "asc" } = {
	name: "initiallyActive",
	direction: "desc"
}

const dataTableOptions = {
	sortOrder: sortOrder
}

const INITIAL_STATE = {
	campaignId: null,
	showToast: false,
	success: false,
	toastMessage: "",
	isPrimaryLoading: false,
	isSecondaryLoading: false,
	pageTitle: PAGE_TITLE_PREFIX,
	targetsCount: null,
	activeTargetsCount: null,
	targets: [],
	isCreator: null
}

class CampaignTargets extends React.Component<any, any> {


	changingTargetState = false;


	constructor(props: any) {
		super(props);

		this.state = { ...INITIAL_STATE };

		this.handleClose = this.handleClose.bind(this);
		this.onClickTab = this.onClickTab.bind(this);
		this.fetchResidenceName = this.fetchResidenceName.bind(this);
		this.refresh = this.refresh.bind(this);
	}

	async componentDidMount() {
		let campaignId = this.props.match.params.id;

		const isCreator = await StorageUtils.getInstance().getIsCreator();
		this.setState({
			campaignId: campaignId,
			isCreator: isCreator
		});

		this.fetch(campaignId);
	}

	private async fetch(campaignId: number, forceDownload: boolean = false) {
		this.setState({ isPrimaryLoading: true });

		try {
			let campaignDetails = await DataService.getCampaignDetails(campaignId, forceDownload);
			if (campaignDetails) {
				// add initiallyActive property in tenants. So when active property changes, the table display order does not
				if (campaignDetails.locataires) {
					for (const tenantId in campaignDetails.locataires) {
						if (campaignDetails.locataires[tenantId]) {
							campaignDetails.locataires[tenantId].initiallyActive = campaignDetails.locataires[tenantId].active;
						}
					}

					// count targets
					let count = this.countNumberOfTargets(campaignDetails.locataires);
					this.setState({
						activeTargetsCount: count.numberOfActiveTargets,
						targetsCount: count.numberOfTargets
					});
				}

				this.setState({
					targets: campaignDetails.locataires
				});
				await this.fetchResidenceName(campaignDetails.pat1, forceDownload);
			} else {
				console.error("Unable to get targets from null campaign details");
			}
		} catch (e) {
			console.error(e);
		}

		this.setState({ isPrimaryLoading: false });
	}

	private async fetchResidenceName(pat1: string, forceDownload: boolean = false) {
		try {
			let residences = await DataService.searchResidence(pat1, forceDownload);
			if (residences && residences[0] && residences[0].HPLBLPAT) {
				this.setState({ pageTitle: PAGE_TITLE_PREFIX + " - " + residences[0].HPLBLPAT + " - " + pat1 })
			} else {
				console.error("Unable to get residence name");
			}
		} catch (error) {
			console.error(error)
		}
	}

	// returns -1 if id is not found
	private getTargetIndexFromId(targetId: number, targets: Tenant[]) {
		for (let i = 0; i < targets.length; i++) {
			if (targets[i].id === targetId) {
				return i;
			}
		}
		return -1;
	}

	private countNumberOfTargets(targets: Tenant[]): { numberOfTargets: number, numberOfActiveTargets: number } {
		let count = {
			numberOfTargets: targets.length,
			numberOfActiveTargets: 0
		};
		for (const target of targets) {
			if (target.active) {
				count.numberOfActiveTargets++;
			}
		}
		return count;
	}


	async onClickToggleTargetState(id: number, e: any) {
		if (this.changingTargetState) {
			return;
		}

		this.changingTargetState = true;

		this.setState({ isSecondaryLoading: true });


		let targets = this.state.targets;
		let index = this.getTargetIndexFromId(id, targets);

		// if the target is not found, do not edit the state.
		if (index !== -1) {

			let targetCurrentlyEnabled = targets[index].active;
			targets[index].active = !targetCurrentlyEnabled;

			if (targetCurrentlyEnabled) {
				try {
					await DataService.disableCampaignTarget(this.state.campaignId, id);
					this.setState({
						success: true,
						toastMessage: "Cible désactivée"
					});
				} catch (e) {
					this.setState({
						success: false,
						toastMessage: "Erreur"
					});
					console.error(e);
				}
			} else {
				try {
					await DataService.enableCampaignTarget(this.state.campaignId, id);
					this.setState({
						success: true,
						toastMessage: "Cible activée"
					});
				} catch (e) {
					this.setState({
						success: false,
						toastMessage: "Erreur"
					});
					console.error(e);
				}
			}

			this.setState({
				showToast: true,
				targets: targets
			});


		} else {
			console.error("Unable to find target with id : " + id);
		}

		// update number of targets
		let count = this.countNumberOfTargets(this.state.targets);
		this.setState({
			activeTargetsCount: count.numberOfActiveTargets
		});

		this.changingTargetState = false;
		this.setState({ isSecondaryLoading: false });
	}

	isTargetActive(id: number): boolean {
		if (this.state.targets) {
			for (let target of this.state.targets) {
				if (target.id === id) {
					// use this so undefined or null also retun false
					return (target.active === true);
				}
			}
		}
		return false;
	}

	handleClose() {
		this.setState({ showToast: false });
	}

	// TODO : in functional component, there is a way to grab history object to navigate
	onClickTab(tabName: TabName): void {
		switch (tabName) {
			case TabName.DETAILS:
				this.props.history.push(ConfigUtils.getFlashBaseURL() + "/" + this.state.campaignId + "/edit");
				return;
			case TabName.TARGETS:
				// already on this tab, do nothing
				// this.props.history.push(ConfigService.getFlashBaseURL() + "/" + this.state.campaignId + "/cibleLoc");
				return;
			case TabName.COMMENTS:
				this.props.history.push(ConfigUtils.getFlashBaseURL() + "/" + this.state.campaignId + "/commentaires");
				return;
			case TabName.SMS:
				this.props.history.push(ConfigUtils.getFlashBaseURL() + "/" + this.state.campaignId + "/sms");
				return;
			case TabName.IN_MAIL:
				this.props.history.push(ConfigUtils.getFlashBaseURL() + "/" + this.state.campaignId + "/inMail");
				return;
		}
	}

	async refresh(event: CustomEvent<RefresherEventDetail>) {
		this.setState({ ...INITIAL_STATE, campaignId: this.state.campaignId, isCreator: this.state.isCreator });
		await this.fetch(this.state.campaignId, true);
		event.detail.complete();
	}

	render() {

		const { targets } = this.state;

		const columns: MUIDataTableColumnDef[] = [
			// this property is only used to sort the list. We do not use active property, so when active property changes, the table display order does not
			{ name: "initiallyActive", options: { display: 'excluded' } },
			{ name: "nom", label: "Nom", options: { display: true } },
			{ name: "numca", label: "Num CA", options: { display: true } },
			{ name: "telPort", label: "Tel", options: { display: true } },
			{ name: "hp2", label: "Bat", options: { display: true } },
			{ name: "hp3", label: "Entrée", options: { display: true } },
			{ name: "ug", label: "logement", options: { display: true } },
			{
				name: "id", label: "Etat", options: {
					display: true,
					filter: false,
					customBodyRender: (id: any, tableMeta: MUIDataTableMeta, updateValue: (value: string) => void) => {
						// the key is composed of the num CA and the phone number to ensure it is unique
						// the toogle button is disabled if the user is not a creator, or if the contact has no telephone number
						return (
							<IonToggle key={tableMeta.rowData[1] + "-" + tableMeta.rowData[2]}
								disabled={(!this.state.isCreator) || ((tableMeta.rowData[3] === undefined) || (tableMeta.rowData[3].length === 0))}
								checked={this.isTargetActive(id)} onIonChange={(e) => this.onClickToggleTargetState(id, e.detail.checked)} />
						)
					}
				}
			}
		];

		return (
			<InApp
				isPrimaryLoading={this.state.isPrimaryLoading}
				isSecondaryLoading={this.state.isSecondaryLoading}
				backUrl={ConfigUtils.getFlashBaseURL()}
				title={this.state.pageTitle}
				displayMenuButton={false}
				showRightMenuBtn={true}
				rightMenu={<RightMenu />}
				refresh={this.refresh}
				outOfIonContentElement={
					<TabBar
						selected={TabName.TARGETS}
						onClickTab={this.onClickTab}
					/>
				}
			>
				<GDHToast
					show={this.state.showToast}
					success={this.state.success}
					handleClose={this.handleClose}
					content={this.state.toastMessage}
				/>
				<IonFab vertical="bottom" horizontal="end" slot="fixed">
					<IonFabButton disabled={!this.state.isCreator} routerLink={ConfigUtils.getFlashBaseURL() + "/addTarget/" + this.state.campaignId}>
						<IonIcon icon={add} />
					</IonFabButton>
				</IonFab>

				<IonGrid>

					<IonRow>
						<IonCol size="6">
							<KpiCard
								className="no-margin"
								name={"Total cibles"}
								value={this.state.targetsCount}
							/>
						</IonCol>

						<IonCol size="6">
							<KpiCard
								className="no-margin"
								name={"Cibles actives"}
								value={this.state.activeTargetsCount}
							/>
						</IonCol>
					</IonRow>

					<IonRow>
						<IonCol size="12">
							<DataTable
								title={"Cible locataires"}
								data={targets}
								options={dataTableOptions}
								columns={columns}
								storageKey={VISIBLE_COLUMNS_STORAGE_KEY}
								storeVisibleColumns={true}
							/>
						</IonCol>
					</IonRow>
				</IonGrid>
			</InApp>

		);
	}
}

export default CampaignTargets;
