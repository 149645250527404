import config from '../config.json'

type Mode = "DEV" | "REC" | "PROD" | "DEBUG" | "LOCAL";
export default class ConfigUtils {

    // make the constructor private so the class cannot be instantiated
    private constructor() { }

    public static getServerUrl(): string {

        if (config.mode === "DEV") {
            return config.DEV.backendUrl;
        } else if (config.mode === "REC") {
            return config.REC.backendUrl;
        } else if (config.mode === "PROD") {
            return config.PROD.backendUrl;
        } else if (config.mode === "DEBUG") {
            return config.DEBUG.backendUrl;
        } else if (config.mode === "LOCAL") {
            return config.LOCAL.backendUrl;
        }

        console.error("Unable to handle mode: " + config.mode);
        return config.DEV.backendUrl;
    }

    public static getFrontendUrl(): string {

        if (config.mode === "DEV") {
            return config.DEV.frontendUrl;
        } else if (config.mode === "REC") {
            return config.REC.frontendUrl;
        } else if (config.mode === "PROD") {
            return config.PROD.frontendUrl;
        } else if (config.mode === "DEBUG") {
            return config.DEBUG.frontendUrl;
        } else if (config.mode === "LOCAL") {
            return config.LOCAL.frontendUrl;
        }

        console.error("Unable to handle mode: " + config.mode);
        return config.DEV.frontendUrl;
    }

    public static getAnalyticsKey(): string {

        if (config.mode === "DEV") {
            return config.DEV.analyticsKey;
        } else if (config.mode === "REC") {
            return config.REC.analyticsKey;
        } else if (config.mode === "PROD") {
            return config.PROD.analyticsKey;
        } else if (config.mode === "DEBUG") {
            return config.DEBUG.analyticsKey;
        } else if (config.mode === "LOCAL") {
            return config.DEBUG.analyticsKey;
        }

        console.error("Unable to handle mode: " + config.mode);
        return config.DEV.analyticsKey;
    }

    public static getPayboxUrl(): string {

        if (config.mode === "DEV") {
            return config.DEV.payboxUrl;
        } else if (config.mode === "REC") {
            return config.REC.payboxUrl;
        } else if (config.mode === "PROD") {
            return config.PROD.payboxUrl;
        } else if (config.mode === "DEBUG") {
            return config.DEBUG.payboxUrl;
        } else if (config.mode === "LOCAL") {
            return config.DEBUG.payboxUrl;
        }

        console.error("Unable to handle mode: " + config.mode);
        return config.DEV.payboxUrl;
    }

    public static getDocusignUrl(): string {

        if (config.mode === "DEV") {
            return config.DEV.docusignUrl;
        } else if (config.mode === "REC") {
            return config.REC.docusignUrl;
        } else if (config.mode === "PROD") {
            return config.PROD.docusignUrl;
        } else if (config.mode === "DEBUG") {
            return config.DEBUG.docusignUrl;
        } else if (config.mode === "LOCAL") {
            return config.DEBUG.docusignUrl;
        }

        console.error("Unable to handle mode: " + config.mode);
        return config.DEV.docusignUrl;
    }

    public static getSmbGatewayUrl(): string {

        if (config.mode === "DEV") {
            return config.DEV.smbGatewayUrl;
        } else if (config.mode === "REC") {
            return config.REC.smbGatewayUrl;
        } else if (config.mode === "PROD") {
            return config.PROD.smbGatewayUrl;
        } else if (config.mode === "DEBUG") {
            return config.DEBUG.smbGatewayUrl;
        } else if (config.mode === "LOCAL") {
            return config.DEBUG.smbGatewayUrl;
        }

        console.error("Unable to handle mode: " + config.mode);
        return config.DEV.smbGatewayUrl;
    }

    public static getDocusignAuthBaseUrl(): string {

        if (config.mode === "DEV") {
            return config.DEV.docusignAuthBaseUrl;
        } else if (config.mode === "REC") {
            return config.REC.docusignAuthBaseUrl;
        } else if (config.mode === "PROD") {
            return config.PROD.docusignAuthBaseUrl;
        } else if (config.mode === "DEBUG") {
            return config.DEBUG.docusignAuthBaseUrl;
        } else if (config.mode === "LOCAL") {
            return config.LOCAL.docusignAuthBaseUrl;
        }

        console.error("Unable to handle mode: " + config.mode);
        return config.DEV.docusignAuthBaseUrl;
    }

    public static getMode(): Mode {
        return config.mode as Mode;
    }

    public static getHandsontableLicenceKey(): string {
        return config.handsontableLicenceKey;
    }

    public static getApplicationVersion(): string {
        return config.applicationVersion;
    }

    public static getFlashApplicationName(): string {
        return config.flash.APPLICATION_NAME;
    }

    public static getFlashBaseURL(): string {
        return config.flash.BASE_URL;
    }

    public static getWebqueryBaseURL(): string {
        return config.webquery.BASE_URL;
    }
}
