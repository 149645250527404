// load external libs
import React, { useState, useEffect, useRef } from "react";
import { IonInput } from "@ionic/react";

// load internal libs
import DataService from "../../../../services/data-service";
import { SelectionPopover, SelectionPopoverProps } from "../../../selection-popover/selection-popover";
import { WebqueryData } from "../../../../interfaces/webquery-data";

// import external css files

// import internal css files
import './webquery-search-bar.css'


type SearchComponentProps = {
    onClickElement: (elementId: number) => void
}




export function WebquerySearchBar(props: SearchComponentProps) {

    const [_text, setText] = useState("");
    const [_open, setOpen] = useState(false);
    const [_anchor, setAnchor] = useState(null);
    const [_webqueries, setWebqueries] = useState<WebqueryData[] | null>(null);
    const [_elementsToDisplay, setElementsToDisplay] = useState<SelectionPopoverProps["elements"]>([]);

    const _blurTimer = useRef<NodeJS.Timeout | null>(null);

    useEffect(() => {
        DataService.getWebQueries().then((data) => {
            setWebqueries(data);
        }).catch((error) => {
            console.error(error);
        })

        return () => {
            if (_blurTimer.current) {
                clearTimeout(_blurTimer.current);
            }
        }
    }, [])

    const handleChange = (event: any) => {
        const searchedText: string = event.target.value;

        updateDropdown(event, searchedText);
    }

    function updateDropdown(event: any, searchedText: string) {
        if (!_webqueries) {
            return;
        }

        setText(searchedText);


        if (!searchedText || (searchedText.length === 0)) {
            setOpen(false);
            return;
        }

        // first add the webqueries that matches the code
        // then the name
        // then the description
        const searchedTextLowerCase: string = searchedText.toLocaleLowerCase();

        let matching: SelectionPopoverProps["elements"] = [];

        for (let webquery of _webqueries) {
            let code: string = webquery.code;
            if (code && code.toLocaleLowerCase().includes(searchedTextLowerCase)) {
                let displayableWebquery = {
                    content: webquery.code + " - " + webquery.name,
                    key: webquery.id
                }
                matching.push(displayableWebquery)
            }
        }

        for (let webquery of _webqueries) {
            let name: string = webquery.name;
            // check if the webquery is not already contained in the list
            if (name &&
                name.toLocaleLowerCase().includes(searchedTextLowerCase) &&
                (matching.map((e) => { return e.key; }).indexOf(webquery.id) === -1)
            ) {
                let displayableWebquery = {
                    content: webquery.code + " - " + webquery.name,
                    key: webquery.id
                }
                matching.push(displayableWebquery)
            }
        }

        for (let webquery of _webqueries) {
            let descr: string = webquery.userDescription;
            // check if the webquery is not already contained in the list
            if (descr &&
                descr.toLocaleLowerCase().includes(searchedTextLowerCase) &&
                (matching.map((e) => { return e.key; }).indexOf(webquery.id) === -1)
            ) {
                let displayableWebquery = {
                    content: webquery.code + " - " + webquery.name,
                    key: webquery.id
                }
                matching.push(displayableWebquery)
            }
        }

        // if no element is matching the input text, hide the dropdown
        if (matching.length === 0) {
            setOpen(false);
            return;
        }

        setElementsToDisplay(matching);
        setAnchor(event.currentTarget)
        setOpen(true);
    }

    // when the search field lose the focus, we shall hide the dropdown
    // but when an element in the dropdown is clicked, the text field loses the
    // focus before the onClick event is triggered. Thats why there is a timeout
    function handleBlur(e: any) {
        _blurTimer.current = setTimeout(() => {
            setOpen(false);
        }, 300)
    }

    function handleFocus(event: any) {
        updateDropdown(event, _text);
    }


    return (
        <span>
            <IonInput className="webquery-search-bar" placeholder="Rechercher" required value={_text}
                onIonChange={(e) => handleChange(e)} onIonBlur={(e) => handleBlur(e)} onIonFocus={(e) => handleFocus(e)}
            />
            <SelectionPopover
                open={_open}
                anchor={_anchor}
                elements={_elementsToDisplay}
                onClickElement={props.onClickElement}
            />
        </span>
    )
}