import { IonItem, IonLabel, IonList } from '@ionic/react';
import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { notificationMessageToUrl } from '../../buisness/notifications';
import { InApp } from '../../components/in-app/in-app';
import RightMenu from '../../components/menu/right-menu/right-menu';
import { NotificationData, NotificationMessageBodyData } from '../../interfaces/notification-data';
import DataService from '../../services/data-service';
import { RefresherEventDetail } from '@ionic/core';


import "./notifications.css";
import { Avatar } from '../../components/avatar/avatar';
import { useSelector } from 'react-redux';
import { ACTION_SET_NEW_NOTIFICATIONS_COUNT, ACTION_SET_NOTIFICATIONS, getNotifications, ReduxStore } from '../../store/redux-store';

export function Notifications(props: any) {

    let [_isLoading, _setIsLoading] = useState<boolean>(false);
    const history = useHistory();
    const _notifications = useSelector(getNotifications)

    const fetch = useCallback(async (forceDownload: boolean = false) => {
        _setIsLoading(true);
        try {
            let notifications = await DataService.getNotificationsHistory(forceDownload);
            ReduxStore.dispatch({ type: ACTION_SET_NOTIFICATIONS, payload: { notifications: notifications } });
        } catch (error) {
            console.error("Unable to fetch notifications, error: ", error);
        }
        _setIsLoading(false);
    }, []);

    const onClick = useCallback((event: React.MouseEvent<HTMLIonItemElement, MouseEvent>, notificationMessageBody?: NotificationMessageBodyData) => {
        const url = notificationMessageToUrl(notificationMessageBody);
        history.push(url);
    }, [history]);

    // whenever this page is shown, set the new notification counter to 0.
    useEffect(() => {
        ReduxStore.dispatch({ type: ACTION_SET_NEW_NOTIFICATIONS_COUNT, payload: { newNotificationsCount: 0 } });
    }, []);

    const refresh = useCallback(async (event: CustomEvent<RefresherEventDetail>) => {
        await fetch(true);
        event.detail.complete();
    }, [fetch]);

    return (
        <InApp
            isPrimaryLoading={_isLoading}
            isSecondaryLoading={false}
            backUrl=""
            title="Notifications"
            displayMenuButton={true}
            showRightMenuBtn={true}
            rightMenu={<RightMenu />}
            refresh={refresh}
            contentClassName="notifications-ion-content"
        >

            <IonList className="notifications-ion-list">
                {
                    _notifications.map((notification: NotificationData) => {
                        return (
                            <IonItem lines="full" className={notification.read ? "" : "notification-item__not-read"} button key={notification.id}
                                onClick={(event: React.MouseEvent<HTMLIonItemElement, MouseEvent>) => onClick(event, notification.message?.body)}
                            >
                                <Avatar
                                    avatarUrl={notification.message?.body?.metadata?.usercreator?.avatar}
                                    firstname={notification.message?.body?.metadata?.usercreator?.prenom}
                                    lastname={notification.message?.body?.metadata?.usercreator?.nom}
                                />
                                <IonLabel className={notification.read ? "" : "notification-label__not-read"}>
                                    <h2 className="notification-title">{notification.message?.body?.title ?? "Notification sans titre"}</h2>
                                    <p className="notification-content">{notification.message?.body?.content ?? ""}</p>
                                </IonLabel>
                            </IonItem>
                        )
                    })
                }

            </IonList>
        </InApp>
    )
}