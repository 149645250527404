import { IonButton, IonImg } from '@ionic/react';
import React, { useCallback, useEffect } from 'react';
import { useState } from 'react';
import { DotsStepper } from '../../../../components/dots-stepper/dots-stepper';
import { InApp } from '../../../../components/in-app/in-app';
import RightMenu from '../../../../components/menu/right-menu/right-menu';
import DataService from '../../../../services/data-service';
import { DigitalReportHomeStep0 } from './digital-report-home-step-0';
import { DigitalReportHomeStep1 } from './digital-report-home-step-1';
import { DigitalReportHomeStep2 } from './digital-report-home-step-2';
import { DigitalReportHomeStep3, RADIO_BUTTON_YES_RESPONSE } from './digital-report-home-step-3';
import { DigitalReportHomeStep4 } from './digital-report-home-step-4';
import { Dialog } from '../../../../components/dialog/dialog';
import { RadioGroupChangeEventDetail } from '@ionic/core/dist/types/components/radio-group/radio-group-interface'
import { InputChangeEventDetail } from '@ionic/core/dist/types/components/input/input-interface';
import { TextareaChangeEventDetail } from '@ionic/core/dist/types/components/textarea/textarea-interface';
import { CheckboxChangeEventDetail } from '@ionic/core/dist/types/components/checkbox/checkbox-interface'

import './digital-report-home.css';
import { useMultipleCheckboxes } from '../../../../hooks/useMultipleCheckboxes';
import GDHToast from '../../../../components/toast/toast';
import { useToast } from '../../../../hooks/useToast';
import { useHistory } from 'react-router';
import { Utils } from '../../../../utils/utils';
import { useAutocompleteOption } from '../../../../components/autocomplete/autocomplete-option';

const RECIPIENTS = {
    "POLICE_NATIONALE": "Police nationale ou gendarmerie",
    "POLICE_MUNICIPALE": "Police municipale",
    "MAIRIE": "Mairie",
    "AUTRE": "Autre"
}

const NUMBER_OF_STEPS = 5;

// step 0
const API_SURVEY_QUESTION_KEY_RECIPIENTS = 'DESTINATAIRES';
const API_SURVEY_QUESTION_KEY_FREQUENCIES = 'FREQUENCE';

// step 1
const API_SURVEY_QUESTION_KEY_SHARED_ROOMS_INTER = 'PARTIES_COMMUNE_INTER';
const API_SURVEY_QUESTION_KEY_SHARED_ROOMS_EXTER = 'PARTIES_COMMUNE_EXTER';
const API_SURVEY_QUESTION_KEY_PRIVATE_ROOMS = 'PARTIES_PRIVATIVES';
const API_SURVEY_QUESTION_KEY_LESSOR_LOCALS = 'LOCAUX_BAILLEURS';

// step 2
const API_SURVEY_QUESTION_KEY_HARMED_PEOPLE = 'ATTEINTES_PERSONNES';
const API_SURVEY_QUESTION_KEY_DAMAGED_PROPERTY = 'ATTEINTES_BIENS';
const API_SURVEY_QUESTION_KEY_SPECIFIC_DATA = 'DONNEES_SPECIFIQUES';

// step 3
const API_SURVEY_QUESTION_KEY_VICTIM_INFO = 'VICTIMES_FAITS';
const API_SURVEY_QUESTION_KEY_AUTHOR_INFO = 'AUTEURS_FAITS';
const API_SURVEY_QUESTION_KEY_AUTHOR_IDENTIFIED = 'AUTEURS_IDENTIFIE';
const API_SURVEY_QUESTION_KEY_AUTHOR_SEX = 'AUTEURS_SEXE';
const API_SURVEY_QUESTION_KEY_AUTHOR_AGE = 'AUTEURS_AGE';
const API_SURVEY_QUESTION_KEY_AUTHOR_RESIDENT = 'RESIDENT_QUARTIER';
const API_SURVEY_QUESTION_KEY_COMPLAINT = 'DEPOT_PLAINTE';

export function DigitalReportHome(props: any) {

    const _history = useHistory();
    const [_isPrimaryLoading, _setIsPrimaryLoading] = useState<boolean>(false);
    const [_step, _setStep] = useState<number>(0);
    const [_pageValid, _setPageValid] = useState<boolean>(false);

    const [_showConfirmationDialog, _setShowConfirmationDialog] = useState<boolean>(false);

    // step 0
    const [_recipients, _setRecipients, _selectedRecipients, _setSelectedRecipients,] = useMultipleCheckboxes();

    const [_recipientsInternal, _setRecipientsInternal] = useState<boolean>(false);
    const [_videoSurveille, _setVideoSurveille] = useState<boolean>(false);

    const [_addressText, _setAddressText] = useState<string>('');

    const [_communeText, _setCommuneText] = useState<string>('');

    const [_residences,
        _selectedResidence,
        _onChangeResidenceText,
        _onChangeResidenceSelectedElement] = useAutocompleteOption(DataService.searchResidence,
            'HPCDPAT1',
            (element: any) => element.HPLBLPAT + " " + element.HPCDPAT1,
        );


    // step 1
    const [_hourFrom, _setHourFrom] = useState<string>("12:00");
    const [_hourFromObservation, _setHourFromObservation] = useState<string>("12:00");
    const [_hourTo, _setHourTo] = useState<string>("12:00");
    const [_hourToObservation, _setHourToObservation] = useState<string>("12:00");
    const [_frequencies, _setFrequencies] = useState<{ [x: string]: any }>({});
    const [_selectedFrequency, _setSelectedFrequency] = useState<string>('');
    const [_selectedDate, _setSelectedDate] = useState<Date | null>(new Date());
    const [_selectedDateObservation, _setSelectedDateObservation] = useState<Date | null>(new Date());

    const [_lessorLocals, _setLessorLocals, _selectedLessorLocals,
        _setSelectedLessorLocals, _onCheckedLessorLocals] = useMultipleCheckboxes();

    const [_privateRooms, _setPrivateRooms, _selectedPrivateRooms,
        _setSelectedPrivateRooms, _onCheckedPrivateRooms] = useMultipleCheckboxes();

    const [_sharedRoomsInterior, _setSharedRoomsInterior, _selectedSharedRoomsInterior,
        _setSelectedSharedRoomsInterior, _onCheckedSharedRoomsInterior] = useMultipleCheckboxes();

    const [_sharedRoomsExterior, _setSharedRoomsExterior, _selectedSharedRoomsExterior,
        _setSelectedSharedRoomsExterior, _onCheckedSharedRoomsExterior] = useMultipleCheckboxes();

    // step 2
    const [_harmedPeople, _setHarmedPeople, _selectedHarmedPeople, , _onCheckedHarmedPeople] = useMultipleCheckboxes();

    const [_damagedProperty, _setDamagedProperty, _selectedDamagedProperty, , _onCheckedDamagedProperty] = useMultipleCheckboxes();

    const [_specificData, _setSpecificData, _selectedSpecificData, , _onCheckedSpecificData] = useMultipleCheckboxes();

    const [_explanations, _setExplanations] = useState<string>('');

    // step 3
    const [_mainFacts, _setMainFacts] = useState<string[]>([]);
    const [_mainFact, _setMainFact] = useState<string | null>(null);

    const [_victimInfo, _setVictimInfo, _selectedVictimInfo, , _onCheckedVictimInfo] = useMultipleCheckboxes();

    const [_authorInfo, _setAuthorInfo] = useState<{ [x: string]: any }>({});
    const [_selectedAuthorInfo, _setSelectedAuthorInfo] = useState<string>('');

    const [_authorIdentified, _setAuthorIdentified] = useState<{ [x: string]: any }>({});
    const [_selectedAuthorIdentified, _setSelectedAuthorIdentified] = useState<string>('');

    const [_authorSex, _setAuthorSex, _selectedAuthorSex, , _setSelectedAuthorSex] = useMultipleCheckboxes();

    const [_authorAge, _setAuthorAge, _selectedAuthorAge, , _setSelectedAuthorAge] = useMultipleCheckboxes();

    const [_authorResident, _setAuthorResident, _selectedAuthorResident, , _setSelectedAuthorResident] = useMultipleCheckboxes();

    const [_claim, _setClaim] = useState<{ [x: string]: any }>({});
    const [_selectedClaim, _setSelectedClaim] = useState<string>('');

    const [_claimDate, _setClaimDate] = useState<Date | null>(new Date());

    const [_claimId, _setClaimId] = useState<string>('');

    const [_complaint, _setComplaint] = useState<{ [x: string]: any }>({});
    const [_selectedComplaint, _setSelectedComplaint] = useState<string>('');

    const [_complaintDate, _setComplaintDate] = useState<Date | null>(new Date());

    const [_complaintId, _setComplaintId] = useState<string>('');

    // step 4
    const [_imgs, _setImgs] = useState<(string | null)[]>([null, null, null]);

    const onCloseToast = (success: boolean) => {
        if (success) {
            _history.push('/home');
        }
    }

    const [
        _showToast, ,
        _successToast, ,
        _contentToast, ,
        _handleCloseToast, _activateToast] = useToast(onCloseToast);

    const [_dateDisabled, _setDateDisabled] = useState<boolean>(false);
    const [_hourDisabled, _setHourDisabled] = useState<boolean>(false);

    // check step 0
    useEffect(() => {
        if (_step !== 0) {
            return;
        }

        if (!_selectedResidence) {
            _setPageValid(false);
            return;
        }

        if (_addressText.length === 0) {
            _setPageValid(false);
            return;
        }

        _setPageValid(true);
    }, [_step,
        _selectedRecipients,
        _addressText,
        _selectedResidence]);

    // whenever the user changes step, scroll to top
    // it is not the "react" way to do it but it works
    useEffect(() => {
        let content = document.querySelector(".main-content") as any
        if (!content) {
            console.error("Unable to find main content")
            return
        }
        content.scrollToTop()
    }, [_step])

    useEffect(() => {

        _setSelectedRecipients([])

        if (!_selectedResidence) {
            return
        }

        updateSelectedRecipients()
    }, [_selectedResidence])

    const onChangeAdress = (address: string | null) => {
        _setAddressText(address ?? "")
    }

    const onChangeCommune = (commune: string | null) => {
        _setCommuneText(commune ?? "")
    }

    // check step 1
    useEffect(() => {
        if (_step !== 1) {
            return;
        }

        if (_selectedFrequency.length === 0) {
            _setPageValid(false);
            return;
        }

        _setPageValid(true);
    }, [_step,
        _selectedFrequency]);

    // check step 2
    useEffect(() => {
        if (_step !== 2) {
            return;
        }

        if (_explanations.length === 0
            || _mainFact === null) {
            _setPageValid(false);
            return;
        }

        _setPageValid(true);
    }, [_step,
        _mainFact,
        _explanations]);

    // check step 3
    useEffect(() => {
        if (_step !== 3) {
            return;
        }

        if (_selectedAuthorInfo.length === 0
            || _selectedAuthorIdentified.length === 0
            || _selectedAuthorSex.length === 0
            || _selectedAuthorAge.length === 0
            || _selectedAuthorResident.length === 0
            || _selectedClaim.length === 0
            || _selectedComplaint.length === 0
        ) {
            _setPageValid(false);
            return;
        }

        _setPageValid(true);
    }, [_step,
        _selectedAuthorInfo,
        _selectedAuthorIdentified,
        _selectedAuthorSex,
        _selectedAuthorAge,
        _selectedAuthorResident,
        _selectedClaim,
        _selectedComplaint,
    ]);

    // check step 4, photos are optional so it is always valid
    useEffect(() => {
        if (_step !== 4) {
            return;
        }

        _setPageValid(true);
    }, [_step
    ]);


    const handleStepperNext = useCallback(() => {
        if (_step >= NUMBER_OF_STEPS) {
            return;
        }
        _setStep(_step + 1);
    }, [_step]);

    const handleStepperPrev = useCallback(() => {
        if (_step <= 0) {
            return;
        }
        _setStep(_step - 1);
    }, [_step]);

    const onSubmit = async () => {
        if (_step !== 4) {
            handleStepperNext();
            return;
        }

        _setShowConfirmationDialog(true)
        return
    }

    useEffect(() => {
        init();
        // FIXME : this is probably not the best way to do this
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const init = async () => {
        _setIsPrimaryLoading(true);
        await Promise.all([initStep0(), initStep2(), initStep3(), initStep4()]);
        _setIsPrimaryLoading(false);
    }

    const initStep4 = async () => {
        try {
            const victim = await DataService.getSurveyQuestionAvailableAnswers(API_SURVEY_QUESTION_KEY_VICTIM_INFO);
            const author = await DataService.getSurveyQuestionAvailableAnswers(API_SURVEY_QUESTION_KEY_AUTHOR_INFO);
            const authorId = await DataService.getSurveyQuestionAvailableAnswers(API_SURVEY_QUESTION_KEY_AUTHOR_IDENTIFIED);
            const authorSex = await DataService.getSurveyQuestionAvailableAnswers(API_SURVEY_QUESTION_KEY_AUTHOR_SEX);
            const authorAge = await DataService.getSurveyQuestionAvailableAnswers(API_SURVEY_QUESTION_KEY_AUTHOR_AGE);
            const authorResident = await DataService.getSurveyQuestionAvailableAnswers(API_SURVEY_QUESTION_KEY_AUTHOR_RESIDENT);
            const complaint = await DataService.getSurveyQuestionAvailableAnswers(API_SURVEY_QUESTION_KEY_COMPLAINT);

            _setVictimInfo(victim);
            _setAuthorInfo(author);
            _setAuthorIdentified(authorId);
            _setAuthorSex(authorSex);
            _setAuthorAge(authorAge);
            _setAuthorResident(authorResident);
            _setClaim(complaint);
            _setComplaint(complaint);
        } catch (error) {
            console.error(error);
        }
    }

    const initStep3 = async () => {
        try {
            const harmedPeople = await DataService.getSurveyQuestionAvailableAnswers(API_SURVEY_QUESTION_KEY_HARMED_PEOPLE);
            const damagedProperty = await DataService.getSurveyQuestionAvailableAnswers(API_SURVEY_QUESTION_KEY_DAMAGED_PROPERTY);
            const specificData = await DataService.getSurveyQuestionAvailableAnswers(API_SURVEY_QUESTION_KEY_SPECIFIC_DATA);

            _setMainFacts([...Object.values(harmedPeople), ...Object.values(damagedProperty), ...Object.values(specificData)])

            _setHarmedPeople(harmedPeople);
            _setDamagedProperty(damagedProperty);
            _setSpecificData(specificData);

        } catch (error) {
            console.error(error);
        }
    }

    const initStep2 = async () => {
        try {
            const frequencies = await DataService.getSurveyQuestionAvailableAnswers(API_SURVEY_QUESTION_KEY_FREQUENCIES);
            const sharedRoomsInter = await DataService.getSurveyQuestionAvailableAnswers(API_SURVEY_QUESTION_KEY_SHARED_ROOMS_INTER);
            const sharedRoomsExter = await DataService.getSurveyQuestionAvailableAnswers(API_SURVEY_QUESTION_KEY_SHARED_ROOMS_EXTER);
            const privateRooms = await DataService.getSurveyQuestionAvailableAnswers(API_SURVEY_QUESTION_KEY_PRIVATE_ROOMS);
            const lessorLocals = await DataService.getSurveyQuestionAvailableAnswers(API_SURVEY_QUESTION_KEY_LESSOR_LOCALS);

            const frequenciesKeys = Object.keys(frequencies);
            if (frequenciesKeys.length === 0) {
                console.error("Cannot handle empty survey frequencies", frequencies);
                return;
            }

            _setFrequencies(frequencies);

            _setPrivateRooms(privateRooms);
            _setSelectedPrivateRooms([]);

            _setSharedRoomsExterior(sharedRoomsExter);
            _setSelectedSharedRoomsExterior([]);

            _setSharedRoomsInterior(sharedRoomsInter);
            _setSelectedSharedRoomsInterior([]);

            _setLessorLocals(lessorLocals);
            _setSelectedLessorLocals([])
        } catch (error) {
            console.error(error);
        }
    }

    const updateSelectedRecipients = async () => {
        if (!_selectedResidence) {
            return
        }

        try {
            const selectedRecipients = await DataService.getSignalementContacts(_selectedResidence.HPCDPAT1)
            _setSelectedRecipients(selectedRecipients)
        } catch (error) {
            console.error(error);
        }
    }

    const onChangeRecipientsCheckbox = (event: CustomEvent<CheckboxChangeEventDetail>) => {
        // do nothing. Recipients checkboxes are programmatically handled
    }

    const onChangePrivateRoomCheckbox = (event: CustomEvent<CheckboxChangeEventDetail>) => {
        _onCheckedPrivateRooms(event.detail.value, event.detail.checked);
    }

    const onChangeLessorLocalsCheckbox = (event: CustomEvent<CheckboxChangeEventDetail>) => {
        _onCheckedLessorLocals(event.detail.value, event.detail.checked);
    }

    const onChangeSharedRoomInterCheckbox = (event: CustomEvent<CheckboxChangeEventDetail>) => {
        _onCheckedSharedRoomsInterior(event.detail.value, event.detail.checked);
    }

    const onChangeVictimInfoCheckbox = (event: CustomEvent<CheckboxChangeEventDetail>) => {
        _onCheckedVictimInfo(event.detail.value, event.detail.checked);
    }

    const onChangeAuthorInfoRadio = (event: CustomEvent<RadioGroupChangeEventDetail>) => {
        _setSelectedAuthorInfo(event.detail.value);
    }

    const onChangeAuthorIdentifiedRadio = (event: CustomEvent<RadioGroupChangeEventDetail>) => {
        _setSelectedAuthorIdentified(event.detail.value);
    }

    const onChangeAuthorSexCheckbox = (event: CustomEvent<CheckboxChangeEventDetail>) => {
        _setSelectedAuthorSex(event.detail.value, event.detail.checked);
    }

    const onChangeAuthorResidentCheckbox = (event: CustomEvent<CheckboxChangeEventDetail>) => {
        _setSelectedAuthorResident(event.detail.value, event.detail.checked);
    }

    const onChangeAuthorAgeCheckbox = (event: CustomEvent<CheckboxChangeEventDetail>) => {
        _setSelectedAuthorAge(event.detail.value, event.detail.checked);
    }

    const onChangeClaimRadio = (event: CustomEvent<RadioGroupChangeEventDetail>) => {
        _setSelectedClaim(event.detail.value);
    }

    const onChangeMainFact = (event: React.ChangeEvent<{}>, value: string | string[] | null) => {
        if (Array.isArray(value)) {
            console.error("Unable to handle array value", value)
            return;
        }

        // When user changes main fact, it will empty all selected checkboxes
        _selectedDamagedProperty.splice(0, _selectedDamagedProperty.length)
        _selectedHarmedPeople.splice(0, _selectedHarmedPeople.length)
        _selectedSpecificData.splice(0, _selectedSpecificData.length)

        // we check the associated checkbox to the main fact
        Object.keys(_damagedProperty).find((key) => {
            if (_damagedProperty[key] === value) {
                _selectedDamagedProperty.push(key)
            }
        })
        Object.keys(_harmedPeople).find((key) => {
            if (_harmedPeople[key] === value) {
                _selectedHarmedPeople.push(key)
            }
        })
        Object.keys(_specificData).find((key) => {
            if (_specificData[key] === value) {
                _selectedSpecificData.push(key)
            }
        })

        _setMainFact(value);
    }

    const onChangeClaimDate = (date: Date | null) => {
        _setClaimDate(date);
    }

    const onChangeClaimIdText = (event: CustomEvent<TextareaChangeEventDetail | InputChangeEventDetail>) => {
        if (event?.detail) {
            _setClaimId(event.detail.value ?? '');
            return;
        }
        console.error("Undefined event or its detail", event);
    }

    const onChangeComplaintRadio = (event: CustomEvent<RadioGroupChangeEventDetail>) => {
        _setSelectedComplaint(event.detail.value);
    }

    const onChangeComplaintDate = (date: Date | null) => {
        _setComplaintDate(date);
    }

    const onChangeComplaintIdText = (event: CustomEvent<TextareaChangeEventDetail | InputChangeEventDetail>) => {
        if (event?.detail) {
            _setComplaintId(event.detail.value ?? '');
            return;
        }
        console.error("Undefined event or its detail", event);
    }

    const onChangeExplanations = (event: CustomEvent<TextareaChangeEventDetail | InputChangeEventDetail>) => {
        if (event?.detail) {
            _setExplanations(event.detail.value ?? '');
            return;
        }
        console.error("Undefined event or its detail", event);
    }

    const onChangeHarmedPeopleCheckbox = (event: CustomEvent<CheckboxChangeEventDetail>) => {
        _onCheckedHarmedPeople(event.detail.value, event.detail.checked);
    }

    const onCheckedDamagedPropertyCheckbox = (event: CustomEvent<CheckboxChangeEventDetail>) => {
        _onCheckedDamagedProperty(event.detail.value, event.detail.checked);
    }

    const onCheckedSpecificDataCheckbox = (event: CustomEvent<CheckboxChangeEventDetail>) => {
        _onCheckedSpecificData(event.detail.value, event.detail.checked);
    }

    const onChangeSharedRoomExterCheckbox = (event: CustomEvent<CheckboxChangeEventDetail>) => {
        _onCheckedSharedRoomsExterior(event.detail.value, event.detail.checked);
    }

    const onChangeDate = (date: Date | null) => {
        _setSelectedDate(date);
    }

    const onChangeDateObservation = (date: Date | null) => {
        _setSelectedDateObservation(date);
    }

    const onChangeFrequencyRadio = (event: CustomEvent<RadioGroupChangeEventDetail>) => {
        _setSelectedFrequency(event.detail.value);
    }

    const onChangeRecipientInternal = (event: CustomEvent<CheckboxChangeEventDetail>) => {
        _setRecipientsInternal(event.detail.checked);
    }

    const onChangeVideoSurveille = (event: CustomEvent<CheckboxChangeEventDetail>) => {
        _setVideoSurveille(event.detail.checked);
    }

    const onChangeHourFrom = (e: any) => {
        _setHourFrom(e.target.value);
    }

    const onChangeHourFromObservation = (e: any) => {
        _setHourFromObservation(e.target.value);
    }

    const onChangeHourTo = (e: any) => {
        _setHourTo(e.target.value);
    }

    const onChangeHourToObservation = (e: any) => {
        _setHourToObservation(e.target.value);
    }

    const onChangeUnknownDate = (e: any) => {
        if (e?.detail?.checked) {
            _setDateDisabled(true)
            _setSelectedDate(null)
        } else {
            _setDateDisabled(false)
            _setSelectedDate(new Date())
        }
    }

    const onChangeUnknownHour = (e: any) => {
        if (e?.detail?.checked) {
            _setHourDisabled(true)
            _setHourFrom("")
            _setHourTo("")
        } else {
            _setHourDisabled(false)
            _setHourFrom("12:00")
            _setHourTo("12:00")
        }
    }

    const initStep0 = async () => {
        try {
            const recipients = await DataService.getSurveyQuestionAvailableAnswers(API_SURVEY_QUESTION_KEY_RECIPIENTS);
            const recipientsKeys = Object.keys(recipients);
            if (recipientsKeys.length === 0) {
                console.error("Cannot handle empty survey recipients", recipients);
                return;
            }
            // FIXME : do not use hard coded recipients list
            // _setRecipients(recipients);
            _setRecipients(RECIPIENTS);
        } catch (error) {
            console.error(error);
        }
    }

    const handleConfiramtionDialogOk = async () => {
        if (!_selectedResidence) {
            return
        }

        _setShowConfirmationDialog(false)
        _setIsPrimaryLoading(true)

        let error = ""

        // do not check data validity because it has been checked to click on the button
        try {
            const apiResponse = await DataService.postDigitalReport(
                {
                    hp1: _selectedResidence.HPCDPAT1,
                    adresse: _addressText,
                    commune: _communeText,
                    destinataires: _selectedRecipients,
                    isSent: _recipientsInternal ? false : true,
                    faitLe: Utils.formatDateIfValid(_selectedDate, "yyyy-MM-dd"),
                    heureFaitDebut: _hourFrom,
                    heureFaitFin: _hourTo,
                    dateConstatDebut: Utils.formatDateIfValid(_selectedDateObservation, "yyyy-MM-dd") + ' ' + _hourFromObservation,
                    dateConstatFin: Utils.formatDateIfValid(_selectedDateObservation, "yyyy-MM-dd") + ' ' + _hourToObservation,
                    faitPrincipal: _mainFact as string, // cannot arrive to this step if null
                    explicationDetaillee: _explanations,
                    frequences: [_selectedFrequency],
                    partiesCommunesInterieurs: _selectedSharedRoomsInterior,
                    partiesPrivatives: _selectedPrivateRooms,
                    partiesCommunesExterieurs: _selectedSharedRoomsExterior,
                    locauxBailleurs: _selectedLessorLocals,
                    atteintesPersonnes: _selectedHarmedPeople,
                    atteintesBiens: _selectedDamagedProperty,
                    donneesSpecifiques: _selectedSpecificData,
                    victimesDesFaits: _selectedVictimInfo,
                    auteurPresumeDesFaits: [_selectedAuthorInfo],
                    auteurIdentifies: [_selectedAuthorIdentified],
                    auteurIdentificationSexe: _selectedAuthorSex,
                    auteurIdentificationAge: _selectedAuthorAge,
                    residentQuartier: _selectedAuthorResident,
                    mainCourante: _selectedClaim,
                    mainCouranteDate: _selectedClaim?.toLowerCase() === RADIO_BUTTON_YES_RESPONSE ? Utils.formatDateIfValid(_claimDate, "yyyy-MM-dd") : '',
                    mainCouranteIdentifiant: _selectedClaim?.toLowerCase() === RADIO_BUTTON_YES_RESPONSE ? _claimId : '',
                    depotPlainte: _selectedComplaint,
                    depotPlainteDate: _selectedComplaint?.toLowerCase() === RADIO_BUTTON_YES_RESPONSE ? Utils.formatDateIfValid(_complaintDate, "yyyy-MM-dd") : '',
                    depotPlainteIdentifiant: _selectedComplaint?.toLowerCase() === RADIO_BUTTON_YES_RESPONSE ? _complaintId : '',
                    videoSurveillee: _videoSurveille,
                }
            );

            if (apiResponse?.id) {
                await sendPhotos(apiResponse.id)
                await changeState(apiResponse.id)
                _activateToast(true, 'Rapport envoyé');
            } else {
                error = "Erreur id de signalement"
            }

        } catch (err: any) {
            console.error(err);
            error = err.toString()
        }

        if (error) {
            _activateToast(false, error);
        }

        _setIsPrimaryLoading(false)
    }

    const sendPhotos = async (reportId: string) => {
        if (!_imgs.some((img: string | null) => img !== null)) {
            // no photo
            return
        }

        const requests = []
        for (let img of _imgs) {
            if (img === null) {
                continue
            }
            const request = DataService.sendReportPhoto(reportId, img);
            requests.push(request)
        }

        await Promise.all(requests);
    }

    const changeState = async (reportId: string) => {
        const request = await DataService.changeReportState(reportId);
    }
    const handleConfiramtionDialogCancel = () => {
        _setShowConfirmationDialog(false)
    }

    const confirmationDialogContent = (<>
        {!_recipientsInternal ?
            <ConfirmationDialogMultipleChoices
                title="Destinataires"
                list={_recipients}
                keys={_selectedRecipients}
                displayInRed={true}
            /> : ""}


        <span className="block"><span className="digital-report-confirmation-label">Envoi interne :</span>{_recipientsInternal ? " Oui" : " RA, RS et Pôle Sureté"}</span>
        <span className="block digital-report-confirmation-dialog-section-title">Lieux des faits</span>
        <span className="block"><span className="digital-report-confirmation-label">Résidence : </span>{_selectedResidence?.HPLBLPAT + " " + _selectedResidence?.HPCDPAT1}</span>
        <span className="block"><span className="digital-report-confirmation-label">Adresse : </span>{_addressText}</span>
        <span className="block"><span className="digital-report-confirmation-label">Commune : </span>{_communeText}</span>
        <span className="block"><span className="digital-report-confirmation-label">Vidéo surveillé :</span>{_videoSurveille ? " Oui" : " Non"}</span>

        <span className="block digital-report-confirmation-dialog-section-title">Date et heure des faits</span>
        <span className="block"><span className="digital-report-confirmation-label">Date : </span>{_selectedDate ? Utils.formatDateIfValid(_selectedDate, "dd/MM/yyyy") : "Inconnu"}</span>
        <span className="block"><span className="digital-report-confirmation-label">Heure : </span>{_hourFrom && _hourTo ? "De " + _hourFrom + " à " + _hourTo : "Inconnu"}</span>
        <span hidden className="block digital-report-confirmation-dialog-section-title">Date et heure du constat</span>
        <span hidden className="block"><span className="digital-report-confirmation-label">Date début : </span>{Utils.formatDateIfValid(_selectedDateObservation, "dd/MM/yyyy") + ' à ' + _hourFromObservation}</span>
        <span hidden className="block"><span className="digital-report-confirmation-label">Date fin : </span>{Utils.formatDateIfValid(_selectedDateObservation, "dd/MM/yyyy") + ' à ' + _hourToObservation}</span>
        <br />
        <ConfirmationDialogMultipleChoices
            title="Locaux bailleurs"
            list={_lessorLocals}
            keys={_selectedLessorLocals}
        />
        <ConfirmationDialogMultipleChoices
            title="Parties privatives"
            list={_privateRooms}
            keys={_selectedPrivateRooms}
        />
        <ConfirmationDialogMultipleChoices
            title="Parties communes intérieures"
            list={_sharedRoomsInterior}
            keys={_selectedSharedRoomsInterior}
        />
        <ConfirmationDialogMultipleChoices
            title="Parties communes extérieures"
            list={_sharedRoomsExterior}
            keys={_selectedSharedRoomsExterior}
        />

        <span className="block digital-report-confirmation-dialog-section-title">Détails</span>
        <span className="block"><span className="digital-report-confirmation-label">Fait principal :</span> {_mainFact}</span>
        <ConfirmationDialogMultipleChoices
            title="Atteintes aux personnes"
            list={_harmedPeople}
            keys={_selectedHarmedPeople}
        />
        <ConfirmationDialogMultipleChoices
            title="Atteintes aux annimaux"
            list={_specificData}
            keys={_selectedSpecificData}
        />
        <ConfirmationDialogMultipleChoices
            title="Atteintes aux biens"
            list={_damagedProperty}
            keys={_selectedDamagedProperty}
        />
        <span className="block"><span className="digital-report-confirmation-label">Explications détaillées des faits :</span> {_explanations}</span>

        <span className="block digital-report-confirmation-dialog-section-title">Auteur(s) et victime(s)</span>
        <ConfirmationDialogMultipleChoices
            title="Victime(s) des faits"
            list={_victimInfo}
            keys={_selectedVictimInfo}
        />
        <span className="block"><span className="digital-report-confirmation-label">Auteur :</span> {_authorInfo[_selectedAuthorInfo]}</span>
        <ConfirmationDialogMultipleChoices
            title="Age"
            list={_authorAge}
            keys={_selectedAuthorAge}
        />
        <span className="block"><span className="digital-report-confirmation-label">Identifié :</span> {_authorIdentified[_selectedAuthorIdentified]}</span>
        <ConfirmationDialogMultipleChoices
            title="Sexe"
            list={_authorSex}
            keys={_selectedAuthorSex}
        />
        <ConfirmationDialogMultipleChoices
            title="Résident du quartier"
            list={_authorResident}
            keys={_selectedAuthorResident}
        />
        <span className="block"><span className="digital-report-confirmation-label">Dépôt de main courante :</span> {_claim[_selectedClaim]}</span>
        {_claim[_selectedClaim]?.toLowerCase() === RADIO_BUTTON_YES_RESPONSE && <span className="block"><span className="digital-report-confirmation-label">Date de la main courante :</span> {Utils.formatDateIfValid(_claimDate, "dd/MM/yyyy")}</span>}
        {_claim[_selectedClaim]?.toLowerCase() === RADIO_BUTTON_YES_RESPONSE && <span className="block"><span className="digital-report-confirmation-label">Numéro de la main courante :</span> {_claimId}</span>}
        <span className="block"><span className="digital-report-confirmation-label">Dépôt de plainte :</span> {_complaint[_selectedComplaint]}</span>
        {_complaint[_selectedComplaint]?.toLowerCase() === RADIO_BUTTON_YES_RESPONSE && <span className="block"><span className="digital-report-confirmation-label">Date de la plainte :</span> {Utils.formatDateIfValid(_complaintDate, "dd/MM/yyyy")}</span>}
        {_complaint[_selectedComplaint]?.toLowerCase() === RADIO_BUTTON_YES_RESPONSE && <span className="block"><span className="digital-report-confirmation-label">Numéro de la plainte :</span> {_complaintId}</span>}


        <span className="block digital-report-confirmation-dialog-section-title">Photos</span>
        {
            _imgs.map((img, index: number) => {
                if (img) {
                    return (
                        <IonImg key={index} className="digital-report-photo" src={img} />
                    )
                } else {
                    return (<></>)
                }
            })
        }
    </>)

    return (
        <InApp
            isPrimaryLoading={_isPrimaryLoading}
            isSecondaryLoading={false}
            backUrl=""
            title="Signalement"
            displayMenuButton={true}
            showRightMenuBtn={true}
            rightMenu={<RightMenu />}
            refresh={null}
        >
            <GDHToast
                show={_showToast}
                handleClose={_handleCloseToast}
                success={_successToast}
                content={_contentToast}
            />
            <div className="digital-report-home-container">

                <div className="digital-report-stepper-container">
                    <DotsStepper
                        activeStep={_step}
                        handleNext={handleStepperNext}
                        handlePrev={handleStepperPrev}
                        steps={NUMBER_OF_STEPS}
                        forceDisableNextButton={!_pageValid}
                    />
                </div>

                {
                    _step === 0 &&
                    <DigitalReportHomeStep0
                        onChangeRecipientCheckbox={onChangeRecipientsCheckbox}
                        onChangeRecipientInternal={onChangeRecipientInternal}
                        onChangeVideoSurveille={onChangeVideoSurveille}
                        recipients={_recipients}
                        selectedRecipient={_selectedRecipients}
                        internal={_recipientsInternal}
                        videoSurveille={_videoSurveille}
                        onChangeAdress={onChangeAdress}
                        residences={_residences}
                        selectedResidence={_selectedResidence}
                        onChangeResidenceText={_onChangeResidenceText}
                        onChangeResidenceSelectedElement={_onChangeResidenceSelectedElement}
                        onChangeCommune={onChangeCommune}
                    />
                }

                {
                    _step === 1 &&
                    <DigitalReportHomeStep1
                        onChangeHourFrom={onChangeHourFrom}
                        onChangeHourFromObservation={onChangeHourFromObservation}
                        onChangeHourTo={onChangeHourTo}
                        onChangeHourToObservation={onChangeHourToObservation}
                        onChangeFrequencyRadio={onChangeFrequencyRadio}
                        onChangeLessorLocalsCheckbox={onChangeLessorLocalsCheckbox}
                        onChangePrivateRoomCheckbox={onChangePrivateRoomCheckbox}
                        onChangeSharedRoomInterCheckbox={onChangeSharedRoomInterCheckbox}
                        onChangeSharedRoomExterCheckbox={onChangeSharedRoomExterCheckbox}
                        onChangeDate={onChangeDate}
                        onChangeDateObservation={onChangeDateObservation}
                        onChangeUnknownDate={onChangeUnknownDate}
                        onChangeUnknownHour={onChangeUnknownHour}
                        hourFrom={_hourFrom}
                        hourFromObservation={_hourFromObservation}
                        hourTo={_hourTo}
                        hourToObservation={_hourToObservation}
                        frequencies={_frequencies}
                        lessorLocals={_lessorLocals}
                        selectedFrequency={_selectedFrequency}
                        selectedLessorLocals={_selectedLessorLocals}
                        selectedDate={_selectedDate}
                        selectedDateObservation={_selectedDateObservation}
                        privateRooms={_privateRooms}
                        selectedPrivateRooms={_selectedPrivateRooms}
                        sharedRoomsInterior={_sharedRoomsInterior}
                        selectedSharedRoomsInterior={_selectedSharedRoomsInterior}
                        sharedRoomsExterior={_sharedRoomsExterior}
                        selectedSharedRoomsExterior={_selectedSharedRoomsExterior}
                        dateDisabled={_dateDisabled}
                        hourDisabled={_hourDisabled}
                    />
                }

                {
                    _step === 2 &&
                    <DigitalReportHomeStep2
                        onChangeHarmedPeopleCheckbox={onChangeHarmedPeopleCheckbox}
                        onChangeDamagedPropertyCheckbox={onCheckedDamagedPropertyCheckbox}
                        onChangeSpecificDataCheckbox={onCheckedSpecificDataCheckbox}
                        onChangeExplanations={onChangeExplanations}
                        onChangeMainFact={onChangeMainFact}
                        maintFact={_mainFact}
                        mainFacts={_mainFacts}
                        harmedPeople={_harmedPeople}
                        selectedHarmedPeople={_selectedHarmedPeople}
                        damagedProperty={_damagedProperty}
                        selectedDamagedProperty={_selectedDamagedProperty}
                        specificData={_specificData}
                        selectedSpecificData={_selectedSpecificData}
                        explanations={_explanations}
                    />
                }

                {
                    _step === 3 &&
                    <DigitalReportHomeStep3
                        onChangeVictimInfoCheckbox={onChangeVictimInfoCheckbox}
                        onChangeAuthorInfoRadio={onChangeAuthorInfoRadio}
                        onChangeAuthorIdentifiedRadio={onChangeAuthorIdentifiedRadio}
                        onChangeAuthorSexCheckbox={onChangeAuthorSexCheckbox}
                        onChangeAuthorResidentCheckbox={onChangeAuthorResidentCheckbox}
                        onChangeAuthorAgeCheckbox={onChangeAuthorAgeCheckbox}
                        onChangeClaimRadio={onChangeClaimRadio}
                        onChangeClaimIdText={onChangeClaimIdText}
                        onChangeComplaintRadio={onChangeComplaintRadio}
                        onChangeComplaintIdText={onChangeComplaintIdText}
                        onChangeDate={onChangeClaimDate}
                        selectedDate={_claimDate}
                        onChangeComplaintDate={onChangeComplaintDate}
                        selectedComplaintDate={_complaintDate}
                        victimInfo={_victimInfo}
                        selectedVictimInfo={_selectedVictimInfo}
                        authorInfo={_authorInfo}
                        selectedAuthorInfo={_selectedAuthorInfo}
                        authorIdentified={_authorIdentified}
                        selectedAuthorIdentified={_selectedAuthorIdentified}
                        authorSex={_authorSex}
                        selectedAuthorSex={_selectedAuthorSex}
                        authorAge={_authorAge}
                        selectedAuthorAge={_selectedAuthorAge}
                        authorResident={_authorResident}
                        selectedAuthorResident={_selectedAuthorResident}
                        claim={_claim}
                        selectedClaim={_selectedClaim}
                        claimId={_claimId}
                        complaint={_complaint}
                        selectedComplaint={_selectedComplaint}
                        complaintId={_complaintId}
                    />
                }
                {
                    _step === 4 &&
                    <DigitalReportHomeStep4
                        imgs={_imgs}
                        setImgs={_setImgs}
                    />
                }
            </div>
            <div className="digital-report-button-container">
                {
                    _step === 4 ?
                        <IonButton expand="block" onClick={onSubmit} disabled={!_pageValid}>
                            Envoyer
                        </IonButton>
                        :
                        <IonButton expand="block" onClick={onSubmit} disabled={!_pageValid}>
                            Suivant
                        </IonButton>
                }
            </div>
            <Dialog
                open={_showConfirmationDialog}
                onCancel={handleConfiramtionDialogCancel}
                onConfirm={handleConfiramtionDialogOk}
                title="Veuillez relire attentivement votre déclaration avant transmission aux destinataires"
                content={confirmationDialogContent}
                cancelButtonText="Corriger"
                showTitleInRed={true}
            />
        </InApp>
    )
}

type ConfirmationDialogMultipleChoicesProps = {
    list: { [x: string]: any },
    keys: string[],
    title: string,
    displayInRed?: boolean,
}

function ConfirmationDialogMultipleChoices(props: ConfirmationDialogMultipleChoicesProps) {

    return (
        <span className={"block"}><span className="digital-report-confirmation-label">{props.title}</span> : {
            (props.keys.length !== 0) &&
            props.keys.map((key: string, index: number) => {
                const prefixWithComa: boolean = index > 0
                return (<span className={props.displayInRed ? "digital-report-color-red" : ""} key={key}>{prefixWithComa ? ", " : ""}{props.list[key]}</span>)
            })
        }</span>
    )
}
