export const traductionDataTable = {
    body: {
      noMatch: "Aucun résultat",
      toolTip: "Trier",
      columnHeaderTooltip: (column: any) => `Sort for ${column.label}`
    },
    pagination: {
      next: "Page suivante",
      previous: "Page précédente",
      rowsPerPage: "Lignes par page :",
      displayRows: "de",
    },
    toolbar: {
      search: "Rechercher",
      downloadCsv: "Télécharger CSV",
      print: "Imprimer",
      viewColumns: "Cacher des colonnes",
      filterTable: "Filtre",
    },
    filter: {
      all: "Tous",
      title: "FILTRES",
      reset: "RESET",
    },
    viewColumns: {
      title: "Afficher colonne",
      titleAria: "Afficher/Cacher colonne",
    },
    selectedRows: {
      text: "ligne(s) selectionnées",
      delete: "Supprimer",
      deleteAria: "Supprimer les lignes sélectionnées",
    },
  }