import ConfigUtils from "../utils/config-utils";
import { SESSION_TOKEN_NOT_DEFINED } from "../utils/constants";
import { StorageUtils } from "../utils/storage-utils";

const DOCUSIGN_URL = ConfigUtils.getDocusignUrl()

export class DocusignService {

    public static async createEnvelope(applicantFullname: string,
        applicantNumber: string,
        username: string,
        urlCode: string
    ): Promise<any> {

        const tokenSession = await StorageUtils.getInstance().getAuthToken();
        if (!tokenSession) {
            throw new Error(SESSION_TOKEN_NOT_DEFINED);
        }

        const headers = new Headers();
        headers.append("Content-Type", "application/json");

        const raw = JSON.stringify({
            "authToken": tokenSession,
            applicantFullname,
            applicantNumber,
            username,
            urlCode
        });

        const requestOptions = {
            method: 'POST',
            headers: headers,
            body: raw,
        };

        try {
            const response = await fetch(DOCUSIGN_URL + "create-envelope", requestOptions)
            console.log(response.ok)
            if (!response.ok) {
                throw new Error(response.statusText);
            }
            return await response.text()
        } catch (error: any) {
            console.error(error)
            throw new Error(error)
        }
    }
}
