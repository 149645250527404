import { NotificationMessageBodyData } from "../interfaces/notification-data";
import ConfigUtils from "../utils/config-utils";

export function notificationMessageToUrl(notificationMessageBody?: NotificationMessageBodyData): string {

    if (notificationMessageBody?.metadata?.flash_id) {
        let page = '/edit';

        // FIXME : this ain't pretty, and should be improved when more notification types are created
        if (notificationMessageBody.metadata.routing_key) {
            if (notificationMessageBody.metadata.routing_key.includes("comment")) {
                page = '/commentaires';
            } else if (notificationMessageBody.metadata.routing_key.includes("sms")) {
                page = '/sms';
            }
        }

        // go to flash id page
        return ConfigUtils.getFlashBaseURL() + '/' + notificationMessageBody.metadata.flash_id + page;
    }

    console.error("unable to get flash_id");
    return ConfigUtils.getFlashBaseURL();
}
