import { AuthResponseData } from "../interfaces/auth-response-data";
import { CampaignData } from "../interfaces/campaign-data";
import { CampaignTypeData } from "../interfaces/campaign-type-data";
import { CommentData } from "../interfaces/comment-data";
import { HostData } from "../interfaces/host-data";
import { NotificationData } from "../interfaces/notification-data";
import { PayboxData } from "../interfaces/paybox-data";
import { SmsProposalData } from "../interfaces/sms-proposal-data";
import { TenantSearchData } from "../interfaces/tenant-search-data";
import { QpatData } from "../interfaces/qpat-data";
import { UserData } from "../interfaces/user-data";
import { WebqueryData } from "../interfaces/webquery-data";
import { IonicUtils } from "../utils/ionic-utils";
import { StorageUtils } from "../utils/storage-utils";
import FetchGdhApiService from "./fetch-gdh-api-service";

import { SESSION_TOKEN_NOT_DEFINED } from "../utils/constants"
import { ApplicantData } from "../interfaces/applicant-data";

const PARAMETER_NOT_DEFINED = "some parameters are not defined";

export type YesNoAll = "O" | "N" | "T";

export default class DataService {

    // make the constructor private so the class cannot be instantiated
    private constructor() { }

    // returns a promess which either resolves with the token value
    // or rejects with the error text
    public static async signin(login: string, password: string): Promise<AuthResponseData> {

        if ((!login) ||
            (!password)) {
            throw new Error(PARAMETER_NOT_DEFINED);
        }

        let headers = {};
        let userData = { login: login, password: password };

        try {
            const data = await FetchGdhApiService.post('auth-tokens?_format=json', userData, headers);
            if (data && data.value) {
                return data;
            }
            throw new Error('unable to signin')
        } catch (error: any) {
            throw new Error(error)
        }
    }

    // returns a promess which either resolves with no data
    // or rejects with the error text
    public static async addFlash(title: string,
        description: string,
        nature: string,
        hp1: string,
        demandForId?: number,
        smsProposal?: string): Promise<CampaignData> {

        if ((!title) ||
            (!description) ||
            (!nature) ||
            (!hp1)) {
            throw new Error(PARAMETER_NOT_DEFINED);
        }

        let tokenSession = await StorageUtils.getInstance().getAuthToken();
        if (!tokenSession) {
            throw new Error(SESSION_TOKEN_NOT_DEFINED);
        }

        let headers = {
            'X-Auth-Token': tokenSession,
        }


        let userData = {
            titre: title,
            description: description,
            type: nature,
            pat1: hp1,
            demandeur: demandForId,
            propositionSms: smsProposal
        };

        try {
            return await FetchGdhApiService.post('campagne?_format=json', userData, headers);
        } catch (error: any) {
            throw new Error(error)
        }

    }



    // returns a promess which either resolves with no data
    // or rejects with the error text
    public static async addTarget(hp1: string,
        hp2: string,
        hp3: string,
        cug: string,
        lastName: string,
        firstName: string,
        numCA: string,
        phone: string,
        flashID: number): Promise<any> {

        if ((hp1 == null) ||
            (hp2 == null) ||
            (hp3 == null) ||
            (cug == null) ||
            (lastName == null) ||
            (firstName == null) ||
            (numCA == null) ||
            (phone == null) ||
            (flashID == null)) {
            throw new Error(PARAMETER_NOT_DEFINED);
        }

        let tokenSession = await StorageUtils.getInstance().getAuthToken();
        if (!tokenSession) {
            throw new Error(SESSION_TOKEN_NOT_DEFINED);
        }

        let headers = {
            'X-Auth-Token': tokenSession,
        }

        let userData = {
            hp1: hp1,
            hp2: hp2,
            hp3: hp3,
            ug: cug,
            nom: lastName,
            prenom: firstName,
            numca: numCA,
            tel: phone
        };

        const url = 'campagne/' + flashID + '/locataire?_format=json'

        try {
            return await FetchGdhApiService.post(url, userData, headers);
        } catch (error: any) {
            throw new Error(error)
        }
    }


    public static async getApps(forceDownload: boolean = false): Promise<HostData[]> {

        let tokenSession = await StorageUtils.getInstance().getAuthToken();
        if (!tokenSession) {
            throw new Error(SESSION_TOKEN_NOT_DEFINED);
        }

        let headers = {
            'X-Auth-Token': tokenSession,
        }

        const url = 'myApps?_format=json'

        try {
            return await FetchGdhApiService.get(url, {}, headers, forceDownload);
        } catch (error: any) {
            throw new Error(error)
        }
    }


    public static async getAppsV2(forceDownload: boolean = false): Promise<HostData[]> {

        let tokenSession = await StorageUtils.getInstance().getAuthToken();
        if (!tokenSession) {
            throw new Error(SESSION_TOKEN_NOT_DEFINED);
        }

        let headers = {
            'X-Auth-Token': tokenSession,
        }

        const url = 'myApps/v2?_format=json'

        try {
            return await FetchGdhApiService.get(url, {}, headers, forceDownload);
        } catch (error: any) {
            throw new Error(error)
        }
    }


    // status : 0-en attente:1-validé;2-close;3-encours
    // if status is null, get all campaigns
    // http://eai-dev.granddelta.fr/api/doc#get--api-campagnes
    public static async getCampaigns(status: number | null = null, forceDownload: boolean = false): Promise<any> {

        let tokenSession = await StorageUtils.getInstance().getAuthToken();
        if (!tokenSession) {
            throw new Error(SESSION_TOKEN_NOT_DEFINED);
        }

        let headers = {
            'X-Auth-Token': tokenSession,
        }

        const userData = (status != null) ? { status: status } : {};

        const url = 'campagnes?_format=json'

        try {
            return await FetchGdhApiService.get(url, userData, headers, forceDownload);
        } catch (error: any) {
            throw new Error(error)
        }
    }


    public static async getmyCampaigns(forceDownload: boolean = false): Promise<any> {

        let tokenSession = await StorageUtils.getInstance().getAuthToken();
        if (!tokenSession) {
            throw new Error(SESSION_TOKEN_NOT_DEFINED);
        }

        let headers = {
            'X-Auth-Token': tokenSession,
        }

        const userData = {};

        const url = 'myCampagnes?_format=json'

        try {
            return await FetchGdhApiService.get(url, userData, headers, forceDownload);
        } catch (error: any) {
            throw new Error(error)
        }
    }



    public static async searchResidence(pattern: string, forceDownload: boolean = false): Promise<any[]> {

        let tokenSession = await StorageUtils.getInstance().getAuthToken();
        if (!tokenSession) {
            throw new Error(SESSION_TOKEN_NOT_DEFINED);
        }

        let headers = {
            'X-Auth-Token': tokenSession,
        }

        const url = 'ikos/patps?_format=json'


        const userData = { query: pattern };



        // TODO : gérer de facon plus generique les error codes, et la reponse qu'on renvoi dans ce cas la.

        try {
            const data = await FetchGdhApiService.get(url, userData, headers, forceDownload);
            if (data.code) {
                throw new Error(data.code);
            }
            return data;
        } catch (error: any) {
            throw new Error(error)
        }
    }

    public static async searchBuildings(residenceCode: string, forceDownload: boolean = false): Promise<any> {

        let tokenSession = await StorageUtils.getInstance().getAuthToken();
        if (!tokenSession) {
            throw new Error(SESSION_TOKEN_NOT_DEFINED);
        }

        let headers = {
            'X-Auth-Token': tokenSession,
        }

        const url = 'ikos/' + residenceCode + '/pat2?_format=json'


        const userData = {};

        // TODO : gérer de facon plus generique les error codes, et la reponse qu'on renvoi dans ce cas la.

        try {
            const data = await FetchGdhApiService.get(url, userData, headers, forceDownload);
            if (data.code) {
                throw new Error(data.code);
            }
            return data;
        } catch (error: any) {
            throw new Error(error)
        }
    }


    public static async getWebQueries(forceDownload: boolean = false): Promise<WebqueryData[]> {

        let tokenSession = await StorageUtils.getInstance().getAuthToken();
        if (!tokenSession) {
            throw new Error(SESSION_TOKEN_NOT_DEFINED);
        }

        let headers = {
            'X-Auth-Token': tokenSession,
        }

        const url = 'myWebQueries?_format=json'

        const userData = {};

        try {
            return await FetchGdhApiService.get(url, userData, headers, forceDownload);
        } catch (error: any) {
            throw new Error(error)
        }
    }

    public static async postSms(campaignId: number, message: string, types: Array<"R" | "C" | "T" | "L" | "I">, internalContacts: string[]): Promise<any> {

        if ((!campaignId) ||
            (!message) ||
            (!types) ||
            (!internalContacts)) {
            throw new Error(PARAMETER_NOT_DEFINED);
        }


        const tokenSession = await StorageUtils.getInstance().getAuthToken();
        if (!tokenSession) {
            throw new Error(SESSION_TOKEN_NOT_DEFINED);
        }

        let headers = {
            'X-Auth-Token': tokenSession,
        }

        const url = 'campagne/' + campaignId + '/sms?_format=json'

        const userData: { [k: string]: any } = {
            message: message,
        };

        for (let i = 0; i < types.length; i++) {
            let objKey: any = `type_envoie[${i}]`;
            userData[objKey] = types[i];
        }

        for (let i = 0; i < internalContacts.length; i++) {
            let objKey: any = `destinataires[${i}][email]`;
            userData[objKey] = internalContacts[i];
        }

        try {
            return await FetchGdhApiService.post(url, userData, headers);
        } catch (error: any) {
            throw new Error(error)
        }
    }


    public static async getCampaignDetails(campaignId: number, forceDownload: boolean = false): Promise<CampaignData> {

        if (!campaignId) {
            throw new Error(PARAMETER_NOT_DEFINED);
        }

        let tokenSession = await StorageUtils.getInstance().getAuthToken();
        if (!tokenSession) {
            throw new Error(SESSION_TOKEN_NOT_DEFINED);
        }

        let headers = {
            'X-Auth-Token': tokenSession,
        }

        const url = 'campagne/' + campaignId + '?_format=json'

        const userData = {};

        try {
            return await FetchGdhApiService.get(url, userData, headers, forceDownload);
        } catch (error: any) {
            throw new Error(error);
        }
    }

    public static async getCampaignComments(campaignId: number, forceDownload: boolean = false): Promise<CommentData[]> {

        if (!campaignId) {
            throw new Error(PARAMETER_NOT_DEFINED);
        }

        let tokenSession = await StorageUtils.getInstance().getAuthToken();
        if (!tokenSession) {
            throw new Error(SESSION_TOKEN_NOT_DEFINED);
        }

        let headers = {
            'X-Auth-Token': tokenSession,
        }

        const url = 'campagne/' + campaignId + '/commentaires?_format=json'

        const userData = {};

        try {
            return await FetchGdhApiService.get(url, userData, headers, forceDownload);
        } catch (error: any) {
            throw new Error(error);
        }
    }

    // this function add a comment to a campaign and returns the add comments data
    public static async addCampaignComment(campaignId: number, comment: string, internal: 0 | 1 = 1): Promise<CommentData> {

        if ((!campaignId) ||
            (!comment)) {
            throw new Error(PARAMETER_NOT_DEFINED);
        }

        let tokenSession = await StorageUtils.getInstance().getAuthToken();
        if (!tokenSession) {
            throw new Error(SESSION_TOKEN_NOT_DEFINED);
        }

        let headers = {
            'X-Auth-Token': tokenSession,
        }

        const url = 'campagne/' + campaignId + '/commentaire?_format=json'

        const userData = {
            contenu: comment,
            internal: internal
        };

        try {
            return await FetchGdhApiService.post(url, userData, headers);
        } catch (error: any) {
            throw new Error(error);
        }
    }

    public static async getCampaignTenants(campaignId: number, forceDownload: boolean = false): Promise<any> {

        if (!campaignId) {
            throw new Error(PARAMETER_NOT_DEFINED);
        }

        let tokenSession = await StorageUtils.getInstance().getAuthToken();
        if (!tokenSession) {
            throw new Error(SESSION_TOKEN_NOT_DEFINED);
        }

        let headers = {
            'X-Auth-Token': tokenSession,
        }

        const url = 'campagne/' + campaignId + '/locataires?_format=json'

        const userData = {};

        try {
            return await FetchGdhApiService.get(url, userData, headers, forceDownload);
        } catch (error: any) {
            throw new Error(error);
        }
    }

    // residenceId is equivalent to pat1
    public static async getResidenceActiveContacts(residenceId: number, forceDownload: boolean = false): Promise<any> {

        if (!residenceId) {
            throw new Error(PARAMETER_NOT_DEFINED);
        }

        let tokenSession = await StorageUtils.getInstance().getAuthToken();
        if (!tokenSession) {
            throw new Error(SESSION_TOKEN_NOT_DEFINED);
        }

        let headers = {
            'X-Auth-Token': tokenSession,
        }

        const url = 'ikos/' + residenceId + '/contacts/actifs?_format=json'

        const userData = {};

        try {
            return await FetchGdhApiService.get(url, userData, headers, forceDownload);
        } catch (error: any) {
            throw new Error(error);
        }
    }


    public static async getCampaignTypes(forceDownload: boolean = false): Promise<CampaignTypeData[]> {

        let tokenSession = await StorageUtils.getInstance().getAuthToken();
        if (!tokenSession) {
            throw new Error(SESSION_TOKEN_NOT_DEFINED);
        }

        let headers = {
            'X-Auth-Token': tokenSession,
        }

        const url = 'ikos/typecampagne?_format=json'

        const userData = {};

        try {
            return await FetchGdhApiService.get(url, userData, headers, forceDownload);
        } catch (error: any) {
            throw new Error(error);
        }
    }


    public static async getSmsTemplates(forceDownload: boolean = false): Promise<SmsProposalData[]> {

        let tokenSession = await StorageUtils.getInstance().getAuthToken();
        if (!tokenSession) {
            throw new Error(SESSION_TOKEN_NOT_DEFINED);
        }

        let headers = {
            'X-Auth-Token': tokenSession,
        }

        const url = 'categories/proposition?_format=json'

        const userData = {};

        try {
            return await FetchGdhApiService.get(url, userData, headers, forceDownload);
        } catch (error: any) {
            throw new Error(error);
        }
    }


    public static async getEmployeeInfo(query: string, forceDownload: boolean = false): Promise<UserData[]> {

        let tokenSession = await StorageUtils.getInstance().getAuthToken();
        if (!tokenSession) {
            throw new Error(SESSION_TOKEN_NOT_DEFINED);
        }

        let headers = {
            'X-Auth-Token': tokenSession,
        }

        const url = 'ikos/employee/info?_format=json'

        const userData = {
            query: query
        };


        try {
            return await FetchGdhApiService.get(url, userData, headers, forceDownload);
        } catch (error: any) {
            throw new Error(error);
        }
    }


    public static async getEmployeeAvatarUrl(userId: number, forceDownload: boolean = false): Promise<any> {

        let tokenSession = await StorageUtils.getInstance().getAuthToken();
        if (!tokenSession) {
            throw new Error(SESSION_TOKEN_NOT_DEFINED);
        }

        let headers = {
            'X-Auth-Token': tokenSession,
        }

        const url = 'ikos/' + userId + '/trombino/small/avatar?_format=json'

        const userData = {};

        try {
            let data = await FetchGdhApiService.get(url, userData, headers, forceDownload);
            if (data.code) {
                throw new Error(data.code);
            }
            return data;
        } catch (error: any) {
            throw new Error(error);
        }
    }


    public static async getWebQuery(id: number, simu: boolean, payload: any, forceDownload: boolean = false): Promise<any> {

        let tokenSession = await StorageUtils.getInstance().getAuthToken();
        if (!tokenSession) {
            throw new Error(SESSION_TOKEN_NOT_DEFINED);
        }

        let headers = {
            'X-Auth-Token': tokenSession,
        }

        const url = 'v1/webquery/' + id + '?_format=json'

        const mode = simu ? "simulation" : "download";


        const userData: { [key: string]: string } = {
            modeLancement: mode,
        };

        // check if payload is empty
        if (Object.keys(payload).length !== 0) {
            userData.payload = JSON.stringify(payload);
        }

        try {
            return await FetchGdhApiService.get(url, userData, headers, forceDownload);
        } catch (error: any) {
            throw new Error(error)
        }
    }

    public static async getMediaBase64(id: number, forceDownload: boolean = false): Promise<any> {

        const tokenSession = await StorageUtils.getInstance().getAuthToken();
        if (!tokenSession) {
            throw new Error(SESSION_TOKEN_NOT_DEFINED);
        }

        let headers = {
            'X-Auth-Token': tokenSession,
        }

        const url = 'tools/' + id + '/media/base64?_format=json'

        const userData = {};

        try {
            return await FetchGdhApiService.get(url, userData, headers, forceDownload);
        } catch (error: any) {
            throw new Error(error)
        }
    }

    public static async getAllEmployees(forceDownload: boolean = false): Promise<UserData[]> {

        const tokenSession = await StorageUtils.getInstance().getAuthToken();
        if (!tokenSession) {
            throw new Error(SESSION_TOKEN_NOT_DEFINED);
        }

        let headers = {
            'X-Auth-Token': tokenSession,
        }

        const url = 'ikos/employees?_format=json'

        const userData = {};

        try {
            return await FetchGdhApiService.get(url, userData, headers, forceDownload);
        } catch (error: any) {
            throw new Error(error)
        }
    }


    public static async sendInMail(campaignId: any, senderName: string, senderMail: string, recipients: string[], title: string, content: string): Promise<any> {

        const tokenSession = await StorageUtils.getInstance().getAuthToken();
        if (!tokenSession) {
            throw new Error(SESSION_TOKEN_NOT_DEFINED);
        }

        let headers = {
            'X-Auth-Token': tokenSession,
        }

        let recipientsStr = "";
        for (let i = 0; i < recipients.length; i++) {
            recipientsStr += recipients[i];
            if (i !== (recipients.length - 1)) {
                // if it not the last recipient, add ;
                recipientsStr += ";";
            }
        }

        const url = 'campagne/' + campaignId + '/mail?_format=json';

        const userData = {
            sender_name: senderName,
            sender_email: senderMail,
            recipients: recipientsStr,
            title: title,
            text: content
        };

        try {
            return await FetchGdhApiService.post(url, userData, headers);
        } catch (error: any) {
            throw new Error(error)
        }
    }

    public static async getSMSCredits(forceDownload: boolean = false): Promise<any> {

        const tokenSession = await StorageUtils.getInstance().getAuthToken();
        if (!tokenSession) {
            throw new Error(SESSION_TOKEN_NOT_DEFINED);
        }

        let headers = {
            'X-Auth-Token': tokenSession,
        }

        const url = 'sms/credits?_format=json';

        const userData = {};

        try {
            return await FetchGdhApiService.get(url, userData, headers, forceDownload);
        } catch (error: any) {
            throw new Error(error)
        }
    }


    public static async disableCampaignTarget(campaignId: number, targetId: number): Promise<any> {

        const tokenSession = await StorageUtils.getInstance().getAuthToken();
        if (!tokenSession) {
            throw new Error(SESSION_TOKEN_NOT_DEFINED);
        }

        let headers = {
            'X-Auth-Token': tokenSession,
        }

        const url = 'campagne/' + campaignId + '/locataire/' + targetId + '?_format=json';

        try {
            return await FetchGdhApiService.delete(url, {}, headers);
        } catch (error: any) {
            throw new Error(error)
        }
    }


    public static async enableCampaignTarget(campaignId: number, targetId: number): Promise<any> {

        const tokenSession = await StorageUtils.getInstance().getAuthToken();
        if (!tokenSession) {
            throw new Error(SESSION_TOKEN_NOT_DEFINED);
        }

        let headers = {
            'X-Auth-Token': tokenSession,
        }

        const url = 'campagne/' + campaignId + '/locataire/' + targetId + '?_format=json';

        try {
            return await FetchGdhApiService.put(url, headers);
        } catch (error: any) {
            throw new Error(error)
        }
    }



    public static async launchCampaign(campaignId: number): Promise<any> {

        const tokenSession = await StorageUtils.getInstance().getAuthToken();
        if (!tokenSession) {
            throw new Error(SESSION_TOKEN_NOT_DEFINED);
        }

        let headers = {
            'X-Auth-Token': tokenSession,
        }

        const url = 'campagne/' + campaignId + '/lancer?_format=json';

        try {
            return await FetchGdhApiService.put(url, headers);
        } catch (error: any) {
            throw new Error(error)
        }
    }


    public static async closeCampaign(campaignId: number): Promise<any> {

        const tokenSession = await StorageUtils.getInstance().getAuthToken();
        if (!tokenSession) {
            throw new Error(SESSION_TOKEN_NOT_DEFINED);
        }

        let headers = {
            'X-Auth-Token': tokenSession,
        }

        const url = 'campagne/' + campaignId + '/cloturer?_format=json';

        try {
            return await FetchGdhApiService.put(url, headers);
        } catch (error: any) {
            throw new Error(error)
        }
    }


    public static async registerFcmToken(fcmToken: string): Promise<any> {

        const tokenSession = await StorageUtils.getInstance().getAuthToken();
        if (!tokenSession) {
            throw new Error(SESSION_TOKEN_NOT_DEFINED);
        }

        let headers = {
            'X-Auth-Token': tokenSession,
        }

        const url = 'fcmToken?_format=json';

        const userData = {
            fcmToken: fcmToken
        };

        try {
            return await FetchGdhApiService.post(url, userData, headers);
        } catch (error: any) {
            throw new Error(error)
        }
    }

    public static async deleteFcmToken(fcmToken: string): Promise<any> {

        const tokenSession = await StorageUtils.getInstance().getAuthToken();
        if (!tokenSession) {
            throw new Error(SESSION_TOKEN_NOT_DEFINED);
        }

        let headers = {
            'X-Auth-Token': tokenSession,
        }

        const url = 'fcmToken?_format=json';

        const userData = {
            fcmToken: fcmToken
        };

        try {
            return await FetchGdhApiService.delete(url, userData, headers);
        } catch (error: any) {
            throw new Error(error)
        }
    }

    public static async getNotificationsHistory(forceDownload: boolean = false): Promise<NotificationData[]> {

        const tokenSession = await StorageUtils.getInstance().getAuthToken();
        if (!tokenSession) {
            throw new Error(SESSION_TOKEN_NOT_DEFINED);
        }

        let headers = {
            'X-Auth-Token': tokenSession,
        }

        const url = 'myMessages?_format=json';

        const userData = {
            device: IonicUtils.isBrowser() ? "web" : "mobile"
        };

        try {
            return await FetchGdhApiService.get(url, userData, headers, forceDownload);
        } catch (error: any) {
            throw new Error(error)
        }
    }

    // send PayboxData to Back end, it may change the PBX_REPONDRE_A, then generates the hash, and return the updated PayboxData
    public static async hashPayboxData(data: PayboxData): Promise<PayboxData> {

        const tokenSession = await StorageUtils.getInstance().getAuthToken();
        if (!tokenSession) {
            throw new Error(SESSION_TOKEN_NOT_DEFINED);
        }

        let headers = {
            'X-Auth-Token': tokenSession,
            'Content-Type': 'application/json'
        }

        const url = 'v1/etransaction.json';

        const userData: PayboxData = data;

        try {
            return await FetchGdhApiService.postRawData(url, userData, headers);
        } catch (error: any) {
            throw new Error(error)
        }
    }

    // the query can either be firstname, lastname, or CA ( tenant buiness id )
    public static async searchTenant(query: string, numRows: number = 5, activeOnly: YesNoAll = "T",
        getConection: boolean = false, getEvents: boolean = false, getInsurance: boolean = false,
        forceDownload: boolean = false): Promise<TenantSearchData[]> {

        const tokenSession = await StorageUtils.getInstance().getAuthToken();
        if (!tokenSession) {
            throw new Error(SESSION_TOKEN_NOT_DEFINED);
        }

        let headers = {
            'X-Auth-Token': tokenSession,
            'Content-Type': 'application/json'
        }

        // FIXME : the "?" at the end is a workaround because there is a bug in the FetchGdhApiService.get function
        const url = 'v1/ikos/compteaffaireclient.json?';

        const userData = {
            query: query,
            actif: activeOnly,
            num_rows: numRows,
            indic_connexion: getConection ? "O" : "N",
            indic_evenement: getEvents ? "O" : "N",
            indic_assurance: getInsurance ? "O" : "N",
        };

        try {
            return await FetchGdhApiService.get(url, userData, headers, forceDownload);
        } catch (error: any) {
            throw new Error(error)
        }
    }

    /**
     * Get all possible responses for a survey question
     * @param question the question string code
     * @param template the survey template name
     * @param forceDownload set to true to not use cache
     * @returns the available response
     */
    public static async getSurveyQuestionAvailableAnswers(question: string,
        template: string = 'SIGNALEMENT', forceDownload: boolean = false): Promise<{ [x: string]: string }> {

        const tokenSession = await StorageUtils.getInstance().getAuthToken();
        if (!tokenSession) {
            throw new Error(SESSION_TOKEN_NOT_DEFINED);
        }

        const headers = {
            'X-Auth-Token': tokenSession,
            'Content-Type': 'application/json'
        }
        const version = 1;

        const url = `${version}/question/${template}/reponses/${question}.json`;

        const userData = {};

        try {
            return await FetchGdhApiService.get(url, userData, headers, forceDownload);
        } catch (error: any) {
            throw new Error(error)
        }
    }


    // voir wiki eai : https://gitlab.granddelta.fr/gdh/eai-core/-/wikis/Specs/Signalement-digitalis%C3%A9#creation-dun-signalement-via-api
    public static async postDigitalReport(report:
        {
            hp1: string,
            adresse: string,
            commune: string,
            destinataires: string[],
            isSent: boolean,
            faitLe: string,
            heureFaitDebut: string,
            heureFaitFin: string,
            dateConstatDebut: string,
            dateConstatFin: string,
            faitPrincipal: string,
            explicationDetaillee: string,
            frequences: string[],
            partiesCommunesInterieurs: string[]
            partiesPrivatives: string[],
            partiesCommunesExterieurs: string[],
            locauxBailleurs: string[],
            atteintesPersonnes: string[],
            atteintesBiens: string[],
            donneesSpecifiques: string[],
            victimesDesFaits: string[],
            auteurPresumeDesFaits: string[],
            auteurIdentifies: string[],
            auteurIdentificationSexe: string[],
            auteurIdentificationAge: string[],
            residentQuartier: string[],
            mainCourante: string,
            mainCouranteDate: string,
            mainCouranteIdentifiant: string,
            depotPlainte: string,
            depotPlainteDate: string,
            depotPlainteIdentifiant: string,
            videoSurveillee: boolean,
        }): Promise<{ [x: string]: string }> {

        const tokenSession = await StorageUtils.getInstance().getAuthToken();
        if (!tokenSession) {
            throw new Error(SESSION_TOKEN_NOT_DEFINED);
        }

        const headers = {
            'X-Auth-Token': tokenSession,
            'Content-Type': 'application/json'
        }
        const version = 1;

        const url = `${version}/signalement.json`;

        const userData: any = {
            hp1: report.hp1,
            adresse: report.adresse,
            commune: report.commune,
            destinataires: report.destinataires,
            isSent: report.isSent,
            dateConstatDebut: report.dateConstatDebut,
            dateConstatFin: report.dateConstatFin,
            faitPrincipal: report.faitPrincipal,
            explicationDetaillee: report.explicationDetaillee,
            frequences: report.frequences,
            partiesCommunesInterieurs: report.partiesCommunesInterieurs,
            partiesPrivatives: report.partiesPrivatives,
            partiesCommunesExterieurs: report.partiesCommunesExterieurs,
            locauxBailleurs: report.locauxBailleurs,
            atteintesPersonnes: report.atteintesPersonnes,
            atteintesBiens: report.atteintesBiens,
            donneesSpecifiques: report.donneesSpecifiques,
            victimesDesFaits: report.victimesDesFaits,
            auteurPresumeDesFaits: report.auteurPresumeDesFaits,
            auteurIdentifies: report.auteurIdentifies,
            auteurIdentificationSexe: report.auteurIdentificationSexe,
            auteurIdentificationAge: report.auteurIdentificationAge,
            residentQuartier: report.residentQuartier,
            mainCourante: report.mainCourante,
            depotPlainte: report.depotPlainte,
            videoSurveillee: report.videoSurveillee,
        };

        if (report.faitLe) {
            userData.faitLe = report.faitLe
        }

        if (report.heureFaitDebut) {
            userData.heureFaitDebut = report.heureFaitDebut
        }

        if (report.heureFaitFin) {
            userData.heureFaitFin = report.heureFaitFin
        }

        if (report.depotPlainteDate.length > 0) {
            userData.depotPlainteDate = report.depotPlainteDate
        }

        if (report.mainCouranteDate.length > 0) {
            userData.mainCouranteDate = report.mainCouranteDate
        }

        if (report.depotPlainteIdentifiant.length > 0) {
            userData.depotPlainteIdentifiant = report.depotPlainteIdentifiant
        }

        if (report.mainCouranteIdentifiant.length > 0) {
            userData.mainCouranteIdentifiant = report.mainCouranteIdentifiant
        }

        try {
            return await FetchGdhApiService.postRawData(url, userData, headers);
        } catch (error: any) {
            throw new Error(error)
        }
    }

    public static async searchAccommodationApplicants(query: string, limit: number = 5, forceDownload: boolean = false): Promise<ApplicantData[]> {

        const tokenSession = await StorageUtils.getInstance().getAuthToken();
        if (!tokenSession) {
            throw new Error(SESSION_TOKEN_NOT_DEFINED);
        }

        let headers = {
            'X-Auth-Token': tokenSession,
            'Content-Type': 'application/json'
        }

        // FIXME : the "?" at the end is a workaround because there is a bug in the FetchGdhApiService.get function
        const url = 'v1/tools/demandelogement.json?';

        const userData = {
            query,
            limit
        }

        try {
            return await FetchGdhApiService.get(url, userData, headers, forceDownload);
        } catch (error: any) {
            throw new Error(error)
        }
    }


    public static async getQpat(ug: string, forceDownload: boolean = false): Promise<QpatData[]> {

        const tokenSession = await StorageUtils.getInstance().getAuthToken();
        if (!tokenSession) {
            throw new Error(SESSION_TOKEN_NOT_DEFINED);
        }

        let headers = {
            'X-Auth-Token': tokenSession,
            'Content-Type': 'application/json'
        }

        const url = 'v1/ikos/qpat001.json?';

        const userData = {
            noug: ug,
        }

        try {
            return await FetchGdhApiService.get(url, userData, headers, forceDownload);
        } catch (error: any) {
            throw new Error(error)
        }
    }

    public static async getFlashsForTenant(noca: string, forceDownload: boolean = false): Promise<CampaignData[]> {

        const tokenSession = await StorageUtils.getInstance().getAuthToken();
        if (!tokenSession) {
            throw new Error(SESSION_TOKEN_NOT_DEFINED);
        }

        let headers = {
            'X-Auth-Token': tokenSession,
            'Content-Type': 'application/json'
        }

        const url = 'v1/ikos/cap/flash/info.json?';

        const userData = {
            noca,
        }

        try {
            return await FetchGdhApiService.get(url, userData, headers, forceDownload);
        } catch (error: any) {
            throw new Error(error)
        }
    }

    public static async sendSMS(telNumber: string, sender: string, msg: string, simulate: boolean, transactional: boolean = true): Promise<any> {

        const tokenSession = await StorageUtils.getInstance().getAuthToken();
        if (!tokenSession) {
            throw new Error(SESSION_TOKEN_NOT_DEFINED);
        }

        let headers = {
            'X-Auth-Token': tokenSession,
        }

        const url = 'v1/send/sms.json?';

        const userData = {
            numero: telNumber,
            expediteur: sender,
            message: msg,
            simulation: simulate ? "OUI" : "NON",
            transactional: transactional ? "OUI" : "NON",
        };

        try {
            return await FetchGdhApiService.post(url, userData, headers);
        } catch (error: any) {
            throw new Error(error)
        }
    }

    public static async sendMail(senderName: string, senderEmail: string, recipients: string,
        title: string, msg: string, cc: string): Promise<any> {

        const tokenSession = await StorageUtils.getInstance().getAuthToken();
        if (!tokenSession) {
            throw new Error(SESSION_TOKEN_NOT_DEFINED);
        }

        let headers = {
            'X-Auth-Token': tokenSession,
        }

        const url = 'v1/send/mail.json?';

        const userData = {
            sender_name: senderName,
            sender_email: senderEmail,
            recipients: recipients,
            title: title,
            text: msg,
            cc: cc,
        };

        try {
            return await FetchGdhApiService.post(url, userData, headers);
        } catch (error: any) {
            throw new Error(error)
        }
    }

    public static async sendReportPhoto(reportId: string, photoB64: string): Promise<any> {

        const tokenSession = await StorageUtils.getInstance().getAuthToken();
        if (!tokenSession) {
            throw new Error(SESSION_TOKEN_NOT_DEFINED);
        }

        let headers = {
            'X-Auth-Token': tokenSession,
        }

        const url = `v1/enquete/${reportId}/photo.json?`;

        const userData = {
            photoBase64: photoB64
        };

        try {
            return await FetchGdhApiService.post(url, userData, headers);
        } catch (error: any) {
            throw new Error(error)
        }
    }

    public static async getSignalementContacts(hp1: string, forceDownload: boolean = false): Promise<string[]> {

        const tokenSession = await StorageUtils.getInstance().getAuthToken();
        if (!tokenSession) {
            throw new Error(SESSION_TOKEN_NOT_DEFINED);
        }

        let headers = {
            'X-Auth-Token': tokenSession,
            'Content-Type': 'application/json'
        }

        const url = 'v1/typecontacts.json?';

        const userData = {
            hp1
        }

        try {
            return await FetchGdhApiService.get(url, userData, headers, forceDownload);
        } catch (error: any) {
            throw new Error(error)
        }
    }

    public static async changeReportState(reportId: string): Promise<any> {

        const tokenSession = await StorageUtils.getInstance().getAuthToken();
        if (!tokenSession) {
            throw new Error(SESSION_TOKEN_NOT_DEFINED);
        }

        let headers = {
            'X-Auth-Token': tokenSession,
        }

        const url = `signalement/${reportId}/created`;

        try {
            return await FetchGdhApiService.put(url, headers);
        } catch (error: any) {
            throw new Error(error)
        }
    }
}
