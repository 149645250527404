import { IonCheckbox, IonCol, IonGrid, IonItem, IonLabel, IonRow } from '@ionic/react';
import React, { useEffect, useState } from 'react';
import { CheckboxChangeEventDetail } from '@ionic/core/dist/types/components/checkbox/checkbox-interface'
import { AutocompleteCustomElement, AutocompleteOption } from '../../../../components/autocomplete/autocomplete-option';
import DataService from '../../../../services/data-service';
import { TextInputItem } from '../../../../components/text-input/text-input';


type DigitalReportHomeStep0Props = {
    onChangeRecipientCheckbox: (event: CustomEvent<CheckboxChangeEventDetail>) => void,
    onChangeRecipientInternal: (event: CustomEvent<CheckboxChangeEventDetail>) => void,
    onChangeVideoSurveille: (event: CustomEvent<CheckboxChangeEventDetail>) => void,
    recipients: { [x: string]: any },
    selectedRecipient: string[],
    internal: boolean,
    videoSurveille: boolean,
    onChangeAdress: (address: string | null) => void,
    residences: AutocompleteCustomElement[],
    selectedResidence: AutocompleteCustomElement | null,
    onChangeResidenceText: (event: React.ChangeEvent<{}>) => void,
    onChangeResidenceSelectedElement: (event: React.ChangeEvent<{}>, value: any) => void,
    onChangeCommune: (commune: string | null) => void,
}


export function DigitalReportHomeStep0(props: DigitalReportHomeStep0Props) {

    const [_adresses, _setAddresses] = useState<AutocompleteCustomElement[]>([]);
    const [_adress, _setAddress] = useState<{ key: any, content: any } | null>(null);
    const [_commune, _setCommune] = useState<{ key: any, content: any } | null>(null)

    useEffect(() => {
        props.onChangeAdress(_adress?.content)
    }, [_adress]);

    useEffect(() => {
        props.onChangeCommune(_commune?.content)
    }, [_commune])

    const onAdressSelected = (event: React.ChangeEvent<{}>, value: any) => {
        if (Array.isArray(value)) {
            console.error("Unable to handle array value", value)
            return
        }

        _setAddress(value)
    }

    const onAdressTextChange = (event: any) => {
        _setAddress({key: 0, content: event.target.value})
    }

    useEffect(() => {
        _setAddress(null)

        if (!props.selectedResidence) {
            return
        }

        let textAddress = '';
        if (props.selectedResidence.HPADRRUE) {
            textAddress = props.selectedResidence.HPADRRUE
        }
        if (props.selectedResidence.HPLOCALITE) {
            _setCommune({ key: 0, content: props.selectedResidence.HPLOCALITE })
        }
        populateHps2(props.selectedResidence.HPCDPAT1)

    }, [props.selectedResidence]);

    let populateHps2 = async (hp1: string) => {
        let hps2Infos: []
        let hps2Addresses: string[] = []
        let hps2AddressElements: AutocompleteCustomElement[] = []
        try {
            hps2Infos = await DataService.searchBuildings(hp1)
            // Filter duplicate addresses
            hps2Infos.forEach((value) => {
                if (!hps2Addresses.includes(value['HPADRRUE'])) {
                    hps2Addresses.push(value['HPADRRUE'])
                }
            })
            hps2AddressElements = hps2Addresses.map((addressStr: string, index: number) => {
                return { key: index.toString(), content: addressStr }
            })

            _setAddresses(hps2AddressElements)

        } catch (error) {
            console.error(error)
        }
    }

    return (
        <>
            <div className="digital-report-home-title">
                Saisie d'un signalement
            </div>
            <div className="digital-report-section">
                <div className="digital-report-home-section-title">
                    Lieux des faits
                </div>
                <IonGrid>
                    <IonRow>
                        <IonCol size="6" sizeXl="6" size-lg="6" size-md="6" size-sm="12" size-xs="12" className="digital-report-home-col">
                            <AutocompleteOption
                                value={props.selectedResidence}
                                options={props.residences}
                                getOptionLabel={(option: any) => option.content}
                                placeHolder="Résidence"
                                onChange={props.onChangeResidenceSelectedElement}
                                onChangeText={props.onChangeResidenceText}
                                disableFiltering={true}
                            />
                        </IonCol>

                        <IonCol size="6" sizeXl="6" size-lg="6" size-md="6" size-sm="12" size-xs="12" className="digital-report-home-col">
                            <AutocompleteOption
                                value={_adress}
                                options={_adresses}
                                placeHolder="Adresse"
                                onChange={onAdressSelected}
                                getOptionLabel={(option: any) => option.content}
                                onChangeText={onAdressTextChange}
                                freeSolo={true}
                            />
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol size="6" sizeXl="6" size-lg="6" size-md="6" size-sm="12" size-xs="12" className="digital-report-home-col">
                            <IonItem className="gdh-top-label-item">
                                <IonLabel>Vidéo surveillé</IonLabel>
                                <IonCheckbox slot="start" checked={props.videoSurveille} onIonChange={props.onChangeVideoSurveille} />
                            </IonItem>
                        </IonCol>
                        <IonCol size="6" sizeXl="6" size-lg="6" size-md="6" size-sm="12" size-xs="12" className="digital-report-home-col">
                            <TextInputItem
                                className="gdh-top-label-item-input gdh-input-readonly"
                                value={_commune?.content}
                                label="Commune"
                                readonly={true}
                                required={false}
                                isArea={false}
                                textLineProps={{ placeholder: "" }}
                            />
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </div>
            <div className="digital-report-section">
                <div className="digital-report-home-section-title">
                    Destinataires
                </div>
                <IonGrid>
                    <IonRow>
                        <IonCol size="6" sizeXl="6" size-lg="6" size-md="6" size-sm="12" size-xs="12" className="digital-report-home-col">
                            {
                                Object.keys(props.recipients).map((key) => {
                                    return (
                                        <IonCol key={key} size="6" sizeXl="6" size-lg="6" size-md="6" size-sm="12" size-xs="12" >
                                            <IonItem disabled className="gdh-top-label-item">
                                                <IonLabel>{props.recipients[key]}</IonLabel>
                                                <IonCheckbox value={key} slot="start"
                                                    checked={props.selectedRecipient.includes(key) && !props.internal}
                                                    onIonChange={props.onChangeRecipientCheckbox}
                                                />
                                            </IonItem>
                                        </IonCol>
                                    )
                                })
                            }
                        </IonCol>

                        <IonCol size="6" sizeXl="6" size-lg="6" size-md="6" size-sm="12" size-xs="12" className="digital-report-home-col">
                            <IonItem className="gdh-top-label-item">
                                <IonLabel>Je ne souhaite pas que le signalement soit, à cet instant, directement adressé aux partenaires</IonLabel>
                                <IonCheckbox slot="start" checked={props.internal} onIonChange={props.onChangeRecipientInternal} />
                            </IonItem>
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </div>
        </>
    )


}