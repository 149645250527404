// load external libs
import React from 'react';
import {
	IonFab,
	IonFabButton,
	IonIcon,
	IonCard,
	IonGrid,
	IonCol,
	IonRow,
	IonFabList
} from '@ionic/react';
import { RefresherEventDetail } from '@ionic/core';
import { add, create } from 'ionicons/icons';
import { LatLngExpression } from 'leaflet';
import { isPlatform } from '@ionic/react';

// load internal libs
import DataService from '../../../../services/data-service';
import { InApp } from '../../../../components/in-app/in-app';
import { KpiCard } from '../../../../components/kpi-card/kpi-card';
import { MapboxMap } from '../../../../components/map/mapbox-map/mapbox-map';

// import external css files

// import internal css files
import './FlashHome.css';
import ConfigUtils from '../../../../utils/config-utils';
import { CampaignStatus, countCampaignsInEachState, getCampaignStatusLabel } from '../../../../buisness/campaign'
import { Store } from '../../../../services/store';
import { DataTable } from '../../../../components/data-table/data-table';
import { StorageUtils } from '../../../../utils/storage-utils';
import RightMenu from '../../../../components/menu/right-menu/right-menu';


const INITIAL_STATE = {
	residence: null,
	campaigns: [],
	isPrimaryLoading: false,
	isSecondaryLoading: false,
	filterBy: [getCampaignStatusLabel(CampaignStatus.OPEN), getCampaignStatusLabel(CampaignStatus.STAND_BY), getCampaignStatusLabel(CampaignStatus.DEMAND)],
	allCampaigns: null,
	openCampaigns: null,
	closeCampaigns: null,
	isCreator: null
}

const VISIBLE_COLUMNS_STORAGE_KEY = "flashHomeVisibleColumns";

const sortOrder: { name: string, direction: "desc" | "asc" } = {
	name: "dateAdd",
	direction: "desc"
}


class FlashHome extends React.Component<any, any> {

	private _isMounted: boolean = false;

	private _columns: any[] = [];

	constructor(props: any) {
		super(props);
		this.state = { ...INITIAL_STATE };

		this.doRefresh = this.doRefresh.bind(this);
		this.goToCampaignDetails = this.goToCampaignDetails.bind(this);
		this.fetchData = this.fetchData.bind(this);
		this.initColumns = this.initColumns.bind(this);

		this.initColumns();
	}

	async componentDidMount() {
		this._isMounted = true;
		this.setState({ isPrimaryLoading: true });
		let forceDownload = Store.needToReloadCampaigns();
		await this.fetchData(forceDownload);
		if (!this._isMounted) {
			console.debug("not mounted, return");
			return;
		}
		const isCreator = await StorageUtils.getInstance().getIsCreator()

		this.setState({
			isPrimaryLoading: false,
			isCreator: isCreator
		});
	}

	initColumns() {
		let display: 'true' | 'false' = isPlatform('mobile') ? 'false' : 'true';

		this._columns = [
			{ name: "id", label: "ID", options: { display: true } },
			{ name: "titre", label: "Titre", options: { display: true } },
			{ name: "libelleHp1", label: "Résidence", options: { display: true } },
			{ name: "site", label: "Site", options: { display: display } },
			{ name: "address", label: "Adresse", options: { display: display } },
			{ name: "cp", label: "Code Postal", options: { display: display } },
			{ name: "city", label: "Ville", options: { display: display } },
			{ name: "description", label: "Description", options: { display: display } },
			{
				name: "statusLabel", label: "Etat", options: {
					display: true,
					filterList: this.state.filterBy,
				}
			},
			{ name: "createdBy.fullName", label: "Créateur", options: { display: display } },
			{
				name: "dateAdd", label: "Ajouté le", options: {
					display: display,
					customBodyRender: (value: any) => {
						return new Date(value).toLocaleString('fr-FR');
					}
				}
			}
		];
	}

	componentWillUnmount() {
		this._isMounted = false;
	}


	private async fillCampaings(campaigns: any[], forceDownload: boolean = false) {

		let queries: any[] = [];

		for (const campaign of campaigns) {
			queries.push(DataService.searchResidence(campaign.pat1, forceDownload))
		}

		let residencesInfo: any[] = [];
		try {
			residencesInfo = await Promise.all(queries);
		} catch (error) {
			console.error(error)
		}
		if (!this._isMounted) {
			console.debug("not mounted, return");
			return;
		}

		for (const campaignIndex in campaigns) {
			let error = false;
			if (campaigns[campaignIndex]
				&& campaigns[campaignIndex].pat1
				&& residencesInfo[campaignIndex]
				&& residencesInfo[campaignIndex][0]
				&& residencesInfo[campaignIndex][0].HPCDPAT1
				&& (campaigns[campaignIndex].pat1 === residencesInfo[campaignIndex][0].HPCDPAT1)) {
				if (residencesInfo[campaignIndex][0].HPLBLPAT) {
					campaigns[campaignIndex].libelleHp1 = residencesInfo[campaignIndex][0].HPLBLPAT;
				} else {
					error = true;
				}

				if (residencesInfo[campaignIndex][0].HPADRRUE) {
					campaigns[campaignIndex].address = residencesInfo[campaignIndex][0].HPADRRUE;
				} else {
					error = true;
				}

				if (residencesInfo[campaignIndex][0].HPCDPOSTAL) {
					campaigns[campaignIndex].cp = residencesInfo[campaignIndex][0].HPCDPOSTAL;
				} else {
					error = true;
				}

				if (residencesInfo[campaignIndex][0].HPLOCALITE) {
					campaigns[campaignIndex].city = residencesInfo[campaignIndex][0].HPLOCALITE;
				} else {
					error = true;
				}

				if (residencesInfo[campaignIndex][0].ORLBLORG) {
					campaigns[campaignIndex].site = residencesInfo[campaignIndex][0].ORLBLORG;
				} else {
					error = true;
				}
			}
			else {
				error = true;
			}

			if (error) {
				console.error("invalid data for campaign at index : " + campaignIndex);
			}
		}

		for (let campaign of campaigns) {
			// edit the created by object so it contains the fullName property which is displayed in the data table
			if (campaign
				&& campaign.createdBy.firstname
				&& campaign.createdBy.lastname) {

				campaign.createdBy.fullName = campaign.createdBy.firstname + " " + campaign.createdBy.lastname;

			} else {
				campaign.createdBy.fullName = "";
			}
		}

		this.setState({
			campaigns: campaigns,
			isSecondaryLoading: false
		});
	}

	private async fetchData(forceDownload: boolean = false) {
		try {
			let campaigns = await DataService.getmyCampaigns(forceDownload);
			if (!this._isMounted) {
				console.debug("not mounted, return");
				return;
			}
			let counts = countCampaignsInEachState(campaigns);
			this.setState({
				closeCampaigns: counts.closeCampaigns,
				allCampaigns: campaigns.length,
				openCampaigns: counts.openCampaigns,
				isSecondaryLoading: true,
				campaigns: campaigns
			});
			this.fillCampaings(campaigns, forceDownload);

		} catch (error) {
			console.error(error);
		}
	}

	goToCampaignDetails(campaignId: number) {
		this.props.history.push(ConfigUtils.getFlashBaseURL() + '/' + campaignId + '/edit/');
	}


	async doRefresh(event: CustomEvent<RefresherEventDetail>) {
		await this.fetchData(true);
		event.detail.complete();
	}

	render() {
		// update datatable filter
		// index 8 is statusLabel
		if (this._columns[8]) {
			this._columns[8].options.filterList = this.state.filterBy;
		}

		const options = {
			onRowClick: (rowData: any) => this.goToCampaignDetails(rowData[0]),
			sortOrder: sortOrder
		};

		let markers: any[] | null = null;
		if (this.state.campaigns) {
			markers = this.state.campaigns.filter((campagne: any) => {
				return (campagne.longitude && campagne.latitude);
			}).map((element: any, index: any) => {
				let coords: LatLngExpression = [element.latitude, element.longitude];
				return {
					id: element.id,
					coords: coords,
					popupContent: element.libelleHp1,
					onPopupClick: this.goToCampaignDetails,
					onPopupClickParam: element.id,
				}
			})
		}

		const createFlashButtonLabel = this.state.isCreator ? 'Créer une nouvelle campagne' : 'Effectuer une demande de campagne';

		return (
			<InApp
				isPrimaryLoading={this.state.isPrimaryLoading}
				isSecondaryLoading={this.state.isSecondaryLoading}
				backUrl=""
				title={ConfigUtils.getFlashApplicationName()}
				displayMenuButton={true}
				showRightMenuBtn={true}
				rightMenu={<RightMenu />}
				refresh={this.doRefresh}
			>

				<IonFab vertical="bottom" horizontal="end" slot="fixed">
					<IonFabButton>
						<IonIcon icon={create} />
					</IonFabButton>
					<IonFabList side="top">
						<IonFabButton routerLink={ConfigUtils.getFlashBaseURL() + "/addFlash"} data-desc={createFlashButtonLabel} >
							<IonIcon icon={add} />
						</IonFabButton>
					</IonFabList>
				</IonFab>

				<IonGrid>
					<IonRow>
						<IonCol size-xs="6" size-md="4" size="4">
							<KpiCard
								className="no-margin"
								name={<> Campagnes créées <i onClick={() => this.setState({ filterBy: [] })} className="fa fa-sort fa-lg" aria-hidden="true"></i></>}
								value={this.state.allCampaigns}
							/>
						</IonCol>

						<IonCol size-xs="6" size-md="4" size="4">
							<KpiCard
								className="no-margin"
								name={<> Campagnes en cours <i onClick={() => this.setState({ filterBy: [getCampaignStatusLabel(CampaignStatus.OPEN)] })} className="fa fa-sort fa-lg" aria-hidden="true"></i></>}
								value={this.state.openCampaigns}
							/>
						</IonCol>

						<IonCol size-xs="6" size-md="4" size="4" className="hidden-sm" >
							<KpiCard
								className="no-margin"
								name={<> Campagnes en clôturées <i onClick={() => this.setState({ filterBy: [getCampaignStatusLabel(CampaignStatus.CLOSE)] })} className="fa fa-sort fa-lg" aria-hidden="true"></i></>}
								value={this.state.closeCampaigns}
							/>
						</IonCol>
					</IonRow>

					<IonRow>
						<IonCol size="12">
							<IonCard className="no-margin">
								<DataTable
									title={"Liste des campagnes"}
									data={this.state.campaigns}
									columns={this._columns}
									options={options}
									storageKey={VISIBLE_COLUMNS_STORAGE_KEY}
									storeVisibleColumns={true}
								/>
							</ IonCard>
						</IonCol>
					</IonRow>

					<IonRow>
						<IonCol size="12">
							<IonCard className="map-card">
								<MapboxMap markers={markers} zoom={8} />
							</IonCard>
						</IonCol>
					</IonRow>

				</IonGrid>
			</InApp>
		);
	}
}

export default FlashHome;
