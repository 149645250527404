import {useEffect} from 'react';
import { useHistory } from 'react-router-dom';
import { App } from '@capacitor/app';
import ConfigUtils from '../utils/config-utils';


/*
 * This file is used to make Android App deep link work
 * see: https://capacitorjs.com/docs/guides/deep-links
 */

export function AppUrlListener (props: any) {
    let history = useHistory();

    useEffect( () => {
        App.addListener('appUrlOpen', (data: any) => {
            // if the url is http://app-dev.granddelta.fr/flash/223/edit/, the slug is /flash/223/edit/
            const [baseurl, slug]: string[] = data.url.split('.granddelta.fr');
            if (
                slug
                && (
                    ( baseurl.endsWith('app') && (ConfigUtils.getMode() === 'PROD') )
                    || ( baseurl.endsWith('app-dev') && ((ConfigUtils.getMode() === 'DEV') || (ConfigUtils.getMode() === 'DEBUG')) )
                   )
                ) {
                history.push(slug);
            }
            // if no match, or if it is a DEV url and the application is in PROD, or vice versa
            // do nothing. The router will handle the routing
        })
    }, [history])

    return null;
}