import { ILovePdfApiService, ServerFile } from './i-love-pdf-api-service';
import { IonicUtils } from '../utils/ionic-utils';
import { Utils } from '../utils/utils';
import { Browser } from '@capacitor/browser';

export class PdfService {

    private constructor() { }

    private static blob: Blob;




    /**
     * Getter for the blob
     *
     * @return {Blob} the blob
     */
    static getBlob(): Blob {
        return this.blob;
    }


    /**
     * Open the last processed file
     *
     * @return {Promise<void>} resolves the opening is completed
     */
    static async open() {
        if (!this.blob) {
            console.error("Unable to open a blob without blob");
            return;
        }

        if (!IonicUtils.isBrowser()) {

        } else {
            Browser.open({ url: URL.createObjectURL(this.blob), windowName: '_blank' });
        }
    }

    /**
     * Open the last processed file
     *
     * @return {Promise<void>} resolves the opening is completed
     */
    static async download() {
        if (!this.blob) {
            console.error("Unable to download a blob without blob");
            return;
        }

        if (!IonicUtils.isBrowser()) {

        } else {
            const a = document.createElement("a");
            document.body.appendChild(a);
            a.style.display = "none";

            // for some reason the downloaded zip has a pdf blob type instead of zip.
            this.blob = this.blob.slice(0, this.blob.size, "application/zip")

            const url = window.URL.createObjectURL(this.blob);
            a.href = url;
            a.download = "GDApps-pdfs.zip";
            a.click();
            window.URL.revokeObjectURL(url);
        }
    }

    /**
     * Merge two pdf files
     * @param files {File[]} the files to merge
     *
     * @return {Promise<void>} resolves when merge is complete
     */
    static async merge(files: File[]): Promise<void> {
        try {
            await ILovePdfApiService.auth();

            const startRes = await ILovePdfApiService.start('merge')

            const filesData: ServerFile[] = [];
            for (const file of files) {
                const uploadRes = await ILovePdfApiService.upload(startRes.server, startRes.task, file);
                filesData.push({
                    server_filename: uploadRes.server_filename,
                    filename: file.name
                });
            }

            await ILovePdfApiService.process(startRes.server, startRes.task, filesData, 'merge');

            this.blob = await ILovePdfApiService.download(startRes.server, startRes.task);
            return;
        } catch (error: any) {
            throw new Error(error);
        }
    }

    /**
     * Convert an office file to pdf
     * @param file {File} the file to convert
     *
     * @param operation
     * @return {Promise<void>} resolves when convertion is complete
     */
    static async convert(file: File, operation: 'officepdf' | 'imagepdf'): Promise<void> {
        try {
            await ILovePdfApiService.auth();

            const startRes = await ILovePdfApiService.start(operation)

            const filesData: ServerFile[] = [];
            const uploadRes = await ILovePdfApiService.upload(startRes.server, startRes.task, file);
            filesData.push({
                server_filename: uploadRes.server_filename,
                filename: file.name
            });

            await ILovePdfApiService.process(startRes.server, startRes.task, filesData, operation);

            this.blob = await ILovePdfApiService.download(startRes.server, startRes.task);
            return;
        } catch (error: any) {
            throw new Error(error);
        }
    }

    /**
     * Split a pdf into several ones
     * @param file {File} the file to convert
     *
     * @param ranges
     * @return {Promise<void>} resolves when the split is complete
     */
    static async split(file: File, ranges: string): Promise<void> {
        try {
            await ILovePdfApiService.auth();

            const startRes = await ILovePdfApiService.start('split')

            const filesData: ServerFile[] = [];
            const uploadRes = await ILovePdfApiService.upload(startRes.server, startRes.task, file);
            filesData.push({
                server_filename: uploadRes.server_filename,
                filename: file.name
            });

            await ILovePdfApiService.process(startRes.server, startRes.task, filesData, 'split', { ranges: ranges });

            this.blob = await ILovePdfApiService.download(startRes.server, startRes.task);
            return;
        } catch (error: any) {
            throw new Error(error);
        }
    }


    /**
     * Convert files into pdf and then merge them
     * @param files {File[]} the images, pdf or doc files
     *
     * @return {Promise<void>} resolves when the split is complete
     */
    static async convertAndMerge(files: File[]): Promise<void> {
        try {
            const pdfFiles: File[] = [];
            for (const file of files) {
                if (Utils.matchFileExtentions(file.name, ["jpg", "jpeg", "png"])) {
                    await this.convert(file, 'imagepdf');
                    pdfFiles.push(new File([this.blob], file.name));
                } else if (Utils.matchFileExtentions(file.name, ["doc", "docx"])) {
                    await this.convert(file, 'officepdf');
                    pdfFiles.push(new File([this.blob], file.name));
                } else if (Utils.matchFileExtention(file.name, "pdf")) {
                    pdfFiles.push(file)
                } else {
                    throw new Error("Unable to convert and merge file : " + file.name);
                }
            }

            await this.merge(pdfFiles);
            return;
        } catch (error: any) {
            throw new Error(error);
        }
    }
}
