import React from 'react';
import { InApp } from '../../components/in-app/in-app';
import RightMenu from '../../components/menu/right-menu/right-menu';


export function NoApplication(props: any) {
    return (
        <InApp
            isPrimaryLoading={false}
            isSecondaryLoading={false}
            backUrl=""
            title="Accueil"
            displayMenuButton={true}
            showRightMenuBtn={true}
            rightMenu={<RightMenu />}
            refresh={() => null}
        >
            <div>Aucune application n'est configurée</div>
        </InApp>
    )
}