import { isPlatform } from '@ionic/react';

export class IonicUtils {

    // make the constructor private so the class cannot be instantiated
    private constructor() { }

    public static isMobile(): boolean {
        return (
            isPlatform("android")
            || isPlatform("capacitor")
            || isPlatform("cordova")
            || isPlatform("hybrid")
            || isPlatform("ios")
            || isPlatform("ipad")
            || isPlatform("iphone")
            || isPlatform("mobile")
            || isPlatform("mobileweb")
            || isPlatform("phablet")
            || isPlatform("tablet")
        );
    }

    public static isBrowser(): boolean {
        return !(
            isPlatform("capacitor")
            || isPlatform("cordova")
        );
    }



}
