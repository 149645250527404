import React from 'react';
import MobileStepper from '@mui/material/MobileStepper';
import Button from '@mui/material/Button';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';

import './dots-stepper.css';

type DotsStepperProps = {
	activeStep: number,
	handleNext: () => void,
	handlePrev: () => void,
	steps: number,
	forceDisableNextButton?: boolean
}


export function DotsStepper(props: DotsStepperProps) {

	return (
		<MobileStepper
			variant="dots"
			steps={props.steps}
			position="static"
			activeStep={props.activeStep}
			className="dots-stepper-root"
			nextButton={
				<Button size="small" onClick={props.handleNext} disabled={props.forceDisableNextButton || (props.activeStep === (props.steps - 1))}>
					Suivant
					<KeyboardArrowRight />
				</Button>
			}
			backButton={
				<Button size="small" onClick={props.handlePrev} disabled={props.activeStep === 0}>
					<KeyboardArrowLeft />
					Précédent
				</Button>
			}
		/>
	);
}