import React from "react";
import { Avatar as MUIAvatar } from "@mui/material";
import ConfigUtils from "../../utils/config-utils";


type AvatarProps = {
    avatarBase64?: string,
    firstname?: string,
    lastname?: string,
    avatarUrl?: string,
    className?: string
}

export function Avatar(props: AvatarProps) {
    if (props.avatarBase64) {
        return <MUIAvatar className={props.className} src={"data:image/png;base64," + props.avatarBase64} />
    } else if (props.avatarUrl && !props.avatarUrl.includes("default_avatar")) {
        return <MUIAvatar className={props.className} src={ConfigUtils.getServerUrl() + "/" + props.avatarUrl} />
    }
    else if (props.firstname && props.lastname) {
        return <MUIAvatar className={props.className} >
            {props.firstname.substr(0, 1).toUpperCase() + props.lastname.substr(0, 1).toUpperCase()}
        </MUIAvatar>
    }
    return null;
}