import React from "react";
import {
    IonList, IonFooter, IonToolbar, IonGrid, IonRow, IonCol, IonTextarea, IonButton, IonIcon
} from "@ionic/react";
import DataService from "../../../../services/data-service";
import MessageCard from "../../../../components/message-card/message-card";
import { RefresherEventDetail } from '@ionic/core';
import { send } from "ionicons/icons";
import { ContactMail } from "../../../../interfaces/contact-mail.interface";
import GDHToast from "../../../../components/toast/toast";
import { AutocompleteContacts } from "../../../../components/autocomplete/autocomplete-contacts";
import { StorageUtils } from "../../../../utils/storage-utils";
import ConfigUtils from "../../../../utils/config-utils";
import { TabBar, TabName } from "../../../../components/flash/tab-bar/tab-bar";
import { InApp } from "../../../../components/in-app/in-app";
import { UserData } from "../../../../interfaces/user-data";
import RightMenu from "../../../../components/menu/right-menu/right-menu";
import ReactGA from 'react-ga4'

const PAGE_TITLE_PREFIX = ConfigUtils.getFlashApplicationName() + " - Rédaction de inMail";

const INITIAL_STATE = {
    campaignDetails: null,
    contacts: [],
    message: "",
    user: null,
    showPopup: false,
    success: true,
    campaignId: null,
    isPrimaryLoading: false,
    isSecondaryLoading: false,
    pageTitle: PAGE_TITLE_PREFIX
}


export class InMail extends React.Component<any, any> {

    constructor(props: any) {
        super(props);

        this.state = { ...INITIAL_STATE }

        this.fetch = this.fetch.bind(this);
        this.refresh = this.refresh.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);

        this.onContactChange = this.onContactChange.bind(this);
        this.fetchUser = this.fetchUser.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.onClickTab = this.onClickTab.bind(this);
        this.fetchResidenceName = this.fetchResidenceName.bind(this);
        this.fetchCampaignDetails = this.fetchCampaignDetails.bind(this);
    }

    componentDidMount() {
        const campaignId = this.props.match.params.id;

        this.setState({ campaignId: campaignId });

        this.fetch(campaignId);
    }

    async fetchUser(forceDownload: boolean = false) {
        const userId: string | null = await StorageUtils.getInstance().getUserId();
        if (userId) {
            try {
                let users: UserData[] = await DataService.getEmployeeInfo(userId, forceDownload);
                this.setState({ user: users[0] });
            } catch (error) {
                console.error(error)
            }
        }
    }

    async fetch(campaignId: number, forceDownload: boolean = false) {
        this.setState({ isPrimaryLoading: true });

        await Promise.all([
            this.fetchUser(forceDownload),
            this.fetchCampaignDetails(campaignId, forceDownload)
        ]);

        this.setState({ isPrimaryLoading: false });
    }

    private async fetchCampaignDetails(campaignId: number, forceDownload: boolean = false) {
        try {
            let campaignDetails = await DataService.getCampaignDetails(campaignId, forceDownload);
            this.setState({ campaignDetails: campaignDetails });
            await this.fetchResidenceName(campaignDetails.pat1);
        } catch (error) {
            console.error(error);
        }
    }

    private async fetchResidenceName(pat1: string) {
        try {
            let residences = await DataService.searchResidence(pat1);
            if (residences && residences[0] && residences[0].HPLBLPAT) {
                this.setState({ pageTitle: PAGE_TITLE_PREFIX + " - " + residences[0].HPLBLPAT + " - " + pat1 })
            } else {
                console.error("Unable to get residence name");
            }
        } catch (error) {
            console.error(error)
        }
    }

    handleClose() {
        this.setState({ showPopup: false });
    }


    onContactChange(event: React.ChangeEvent<{}>, value: ContactMail | ContactMail[] | null) {
        // this shall not happen as AutocompleteContacts is multiple
        if (!value || !Array.isArray(value)) {
            console.error("Unable to handle null or not array value", value);
            return;
        }
        this.setState({ contacts: value });
    }

    async handleSubmit(event: any) {
        event.preventDefault();

        if (!this.state.user || !this.state.user.firstname || !this.state.user.lastname
            || !this.state.user.email || !this.state.message || !this.state.contacts
            || !this.props.match.params.id) {
            return;
        }

        this.setState({ isSecondaryLoading: true });
        let recipients: string[] = [];
        this.state.contacts.forEach((contact: any) => {
            recipients.push(contact.email);
        });

        let residenceName;
        try {
            let response = await DataService.searchResidence(this.state.campaignDetails.pat1);
            residenceName = (response && response[0] && response[0].HPLBLPAT) ? response[0].HPLBLPAT : "";
        } catch (e) {
            console.error(e);
            this.setState({
                showPopup: true,
                success: false,
                isSecondaryLoading: false
            })
            return;
        }


        let nature = (this.state.campaignDetails && this.state.campaignDetails.type && this.state.campaignDetails.type.name) ? this.state.campaignDetails.type.name : null;
        // [EAI][CAMPAGNE:50] [Résidence:0347-NEMAUSUS 1][Titre:Incident - Portail ouvert - pièce en commande][Nature:Ascenseur et Portail]
        let title = `[Résidence:${this.state.campaignDetails.pat1}-${residenceName}]` +
            `[Titre:${this.state.campaignDetails.titre}][Nature:${nature}]`;

        let success;
        try {
            let sentInMail = await DataService.sendInMail(this.props.match.params.id,
                this.state.user.firstname + this.state.user.lastname,
                this.state.user.email, recipients, title, this.state.message);
            /*	FIXME : HERE I AM MUTATING THE OBJECT CACHED BY THE DATA SERVICE. THIS IS NOT A GOOD PRACTISE
             *  AN INETERMEDIARY CLASS HANDLING THIS SHALL BE ADDED BETWEEN HERE AND THE DATA SERVICE.
             *
             * This is so the next time this page is loaded, and the comments are requested to the dataService,
             * the whole data are delivered without force refresh
             */
            let campaignDetails = this.state.campaignDetails;
            campaignDetails.envoiesMails.unshift(sentInMail);
            success = true;
            this.setState({
                campaignDetails: campaignDetails,
            });

        } catch (error) {
            console.error(error);
            success = false;
        }

        this.setState({
            success: success,
            contacts: [],
            message: "",
            showPopup: true,
            isSecondaryLoading: false
        });

        ReactGA.event({
            category: 'FLASH',
            action: success ? 'SEND_INMAIL_SUCCESS' : 'SEND_INMAIL_ERROR'
        });
    }

    handleChange(e: any) {
        this.setState({ message: e.target.value });
    }

    async refresh(event: CustomEvent<RefresherEventDetail>) {
        this.setState({ ...INITIAL_STATE, campaignId: this.state.campaignId });
        await this.fetch(this.state.campaignId, true);
        event.detail.complete();
    }

    onClickTab(tabName: TabName): void {
        switch (tabName) {
            case TabName.DETAILS:
                this.props.history.push(ConfigUtils.getFlashBaseURL() + "/" + this.state.campaignId + "/edit");
                return;
            case TabName.TARGETS:
                this.props.history.push(ConfigUtils.getFlashBaseURL() + "/" + this.state.campaignId + "/cibleLoc");
                return;
            case TabName.COMMENTS:
                this.props.history.push(ConfigUtils.getFlashBaseURL() + "/" + this.state.campaignId + "/commentaires");
                return;
            case TabName.SMS:
                this.props.history.push(ConfigUtils.getFlashBaseURL() + "/" + this.state.campaignId + "/sms");
                return;
            case TabName.IN_MAIL:
                // already on this tab, do nothing
                // this.props.history.push(ConfigService.getFlashBaseURL() + "/" + this.state.campaignId + "/inMail");
                return;
        }
    }

    render() {
        return (
            <InApp
                isPrimaryLoading={this.state.isPrimaryLoading}
                isSecondaryLoading={this.state.isSecondaryLoading}
                backUrl={ConfigUtils.getFlashBaseURL()}
                title={this.state.pageTitle}
                displayMenuButton={false}
                showRightMenuBtn={true}
                rightMenu={<RightMenu />}
                refresh={this.refresh}
                outOfIonContentElement={
                    <IonFooter>
                        <IonToolbar>
                            <form onSubmit={this.handleSubmit}>
                                <IonGrid>
                                    <IonRow>
                                        <AutocompleteContacts
                                            value={this.state.contacts}
                                            onChange={this.onContactChange}
                                            contactMustHaveEmail={true}
                                            multiple
                                        />
                                    </IonRow>
                                    <IonRow>
                                        <IonCol size="10" >
                                            <IonTextarea class="comment-input" value={this.state.message}
                                                placeholder="Message InMail" onIonChange={this.handleChange}>
                                            </IonTextarea>
                                        </IonCol>

                                        <IonCol size="2" >
                                            <IonButton expand="block" type="submit">
                                                <IonIcon icon={send} />
                                            </IonButton>
                                        </IonCol>
                                    </IonRow>
                                </IonGrid>
                            </form>
                        </IonToolbar>

                        <TabBar
                            selected={TabName.IN_MAIL}
                            onClickTab={this.onClickTab}
                        />

                    </IonFooter>
                }
            >

                <IonList>
                    {
                        this.state.campaignDetails &&
                        this.state.campaignDetails.envoiesMails &&
                        this.state.campaignDetails.envoiesMails.map((element: any, index: any) => {
                            const sender = element.senderName + " - " + element.senderEmail;
                            const dateTime = new Date(element.dateUpd).toLocaleString('fr-FR');
                            return (
                                <MessageCard
                                    key={index}
                                    dateTime={dateTime}
                                    title={sender}
                                    subTitle={element.title}
                                    content={element.textBrut}
                                />
                            )
                        })
                    }
                </IonList>
                <GDHToast
                    show={this.state.showPopup}
                    handleClose={this.handleClose}
                    success={this.state.success}
                    content={this.state.success ? "InMail envoyé" : "Erreur d'envoi"}
                />
            </InApp>
        )
    }


}
