// load external libs
import React, { useState, useEffect, useRef } from "react";
import { IonInput } from "@ionic/react";

// load internal libs
import DataService from "../../../../services/data-service";
import { SelectionPopover, SelectionPopoverProps } from "../../../selection-popover/selection-popover";
import { HomeData } from "../../../../interfaces/home-data";

// import external css files

// import internal css files
import './home-search-bar.css'
import { useHistory } from "react-router";

export function HomeSearchBar() {

    const [_text, setText] = useState("");
    const [_open, setOpen] = useState(false);
    const [_anchor, setAnchor] = useState(null);
    const [_dataApp, _setDataApp] = useState<HomeData[] | null>(null);
    const [_elementsToDisplay, setElementsToDisplay] = useState<SelectionPopoverProps["elements"]>([]);

    const _blurTimer = useRef<NodeJS.Timeout | null>(null);
    const _history = useHistory();

    const getSearchBar = async () => {
        try {
            const appCategories = await DataService.getAppsV2();
            let urlApp: any[] = []
            appCategories.forEach((category) => {
                const appNamesForCategory = category.urls.map((app) => {
                    let localAppNamesForCategory = Object.assign(app)
                    localAppNamesForCategory.label = category.name + " - " + app.name
                    return localAppNamesForCategory
                })
                urlApp = urlApp.concat(appNamesForCategory)
            })
            _setDataApp(urlApp);
        } catch (error) {
            console.error(error);
        }
    }

    useEffect(() => {
        getSearchBar()
        return () => {
            if (_blurTimer.current) {
                clearTimeout(_blurTimer.current);
            }
        }
    }, [])

    const handleChange = (event: any) => {
        const searchedText: string = event.target.value;

        updateDropdown(event, searchedText);
    }

    function updateDropdown(event: any, searchedText: string) {
        if (!_dataApp) {
            return;
        }

        setText(searchedText);

        if (!searchedText || (searchedText.length === 0)) {
            setOpen(false);
            return;
        }

        // first add the webqueries that matches the code
        // then the name
        // then the description
        const searchedTextLowerCase: string = searchedText.toLocaleLowerCase();

        let matching: SelectionPopoverProps["elements"] = [];

        for (let dataApp of _dataApp) {
            let name: string | undefined = dataApp.label;
            // check if the home is not already contained in the list
            if (name &&
                name.toLocaleLowerCase().includes(searchedTextLowerCase) &&
                (matching.map((e) => { return e.key; }).indexOf(dataApp.code) === -1)
            ) {
                let displayableHome = {
                    content: dataApp.label,
                    key: dataApp.code
                }
                matching.push(displayableHome)
            }
        }


        // if no element is matching the input text, hide the dropdown
        if (matching.length === 0) {
            setOpen(false);
            return;
        }

        setElementsToDisplay(matching);
        setAnchor(event.currentTarget)
        setOpen(true);
    }

    // when the search field lose the focus, we shall hide the dropdown
    // but when an element in the dropdown is clicked, the text field loses the
    // focus before the onClick event is triggered. Thats why there is a timeout
    function handleBlur(event: any) {
        _blurTimer.current = setTimeout(() => {
            setOpen(false);
        }, 300)
    }

    function handleFocus(event: any) {
        updateDropdown(event, _text);
    }

    const onClickElement = (key: any) => {
        const application = _dataApp?.find((app) => {
            return app.code === key
        })
        if (application?.url === undefined) {
            console.error("Url not found", key)
            return
        }
        _history.push(application.url)
    }

    return (
        <span>
            <IonInput className="home-search-bar" placeholder="Rechercher" required value={_text}
                onIonChange={(e) => handleChange(e)} onIonBlur={(e) => handleBlur(e)} onIonFocus={(e) => handleFocus(e)}
            />
            <SelectionPopover
                open={_open}
                anchor={_anchor}
                elements={_elementsToDisplay}
                onClickElement={onClickElement}
            />
        </span>
    )
}
