import ConfigUtils from "../utils/config-utils";
import { SESSION_TOKEN_NOT_DEFINED } from "../utils/constants"
import { StorageUtils } from "../utils/storage-utils";


export class SmbGatewayApiService {

    /**
     * Private constructor. Shall never be instantiated
     */
    private constructor() { }

    /**
     * Retrieve the ILovePdfToken
     *
     * @return {void}
     * @throws Error SESSION_TOKEN_NOT_DEFINED
     */
    public static async writeFile(filename: string, fileB64: string, username: string,
        applicantNumber: string, applicantFullname: string, destinationFolder: string): Promise<void> {

        let tokenSession = await StorageUtils.getInstance().getAuthToken();
        if (!tokenSession) {
            throw new Error(SESSION_TOKEN_NOT_DEFINED);
        }

        const url = ConfigUtils.getSmbGatewayUrl() + '/write-file';

        let myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        let raw = JSON.stringify({
            "authToken": tokenSession,
            "username": username,
            "applicantNumber": applicantNumber,
            "applicantFullname": applicantFullname,
            "filename": filename,
            "folder": destinationFolder,
            "fileB64": fileB64
        });

        let requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
        };

        try {
            const response = await fetch(url, requestOptions);
            if (response.ok) {
                const text = await response.text();
                console.log(text)
            } else {
                throw new Error("Fetch error. " + response.status + " " + response.statusText + " " + response.url);
            }
        } catch (error: any) {
            throw new Error(error);
        }
    }

    public static async listDirectoryContent(): Promise<void> {

        let tokenSession = await StorageUtils.getInstance().getAuthToken();
        if (!tokenSession) {
            throw new Error(SESSION_TOKEN_NOT_DEFINED);
        }

        const url = ConfigUtils.getSmbGatewayUrl() + '/list-dir-content';

        let myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        let raw = JSON.stringify({
            "authToken": tokenSession
        });

        let requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
        };

        try {
            const response = await fetch(url, requestOptions);
            if (response.ok) {
                return await response.json()
            } else {
                throw new Error("Fetch error. " + response.status + " " + response.statusText + " " + response.url);
            }
        } catch (error: any) {
            throw new Error(error);
        }
    }

    public static async listUserDirectoryContent(username: string): Promise<string[]> {

        let tokenSession = await StorageUtils.getInstance().getAuthToken();
        if (!tokenSession) {
            throw new Error(SESSION_TOKEN_NOT_DEFINED);
        }

        const url = ConfigUtils.getSmbGatewayUrl() + '/list-user-dir-content';

        let myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        let raw = JSON.stringify({
            "authToken": tokenSession,
            "username": username
        });

        let requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
        };

        try {
            const response = await fetch(url, requestOptions);
            if (response.ok) {
                return await response.json()
            } else {
                throw new Error("Fetch error. " + response.status + " " + response.statusText + " " + response.url);
            }
        } catch (error: any) {
            throw new Error(error);
        }
    }
}
