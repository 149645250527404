import React, { Component } from 'react';
import {
    IonPage,
    IonContent,
    IonButton,
    IonInput,
    IonProgressBar
} from '@ionic/react';
import GdhLogo from '../../assets/logo-gd-apps.svg';
import './Signin.css';
import { AuthentificationService } from '../../services/authentification-service';
import ConfigUtils from '../../utils/config-utils';

const INITIAL_STATE = {
    login: '',
    password: '',
    error: false,
    isLoading: false
};


const VERSION = ConfigUtils.getApplicationVersion();

const CONNECTION_ERROR_MESSAGE = 'Échec de la connexion.';
const CONNECTION_ERROR_MESSAGE_SECONDARY = 'Nous n\'avons pas réussi à vous connecter. Veuillez réessayer.';
class Signin extends Component<any, any> {

    constructor(props: any) {
        super(props);
        this.state = { ...INITIAL_STATE };

    }

    handleKeyPress = (event: any) => {
        if (event.key === 'Enter') {
            this.login(event);
        }
    }


    login = async (event: any) => {
        event.preventDefault();
        this.setState({ isLoading: true });

        let success: boolean = await AuthentificationService.login(this.state.login, this.state.password);
        if (!success) {
            // only change the state if the login failed, because login success will triger changing page
            this.setState({
                error: true,
                isLoading: false
            })
        }
    };

    onChange = (event: any) => {
        this.setState({
            [event.target.name]: event.target.value,
            error: false
        });
    };

    render() {
        const { login, password, error } = this.state;

        const isInvalid = password === '' || login === '';
        return (
            <IonPage>
                <IonContent>
                    <div className="home-bg">
                        {
                            this.state.isLoading &&
                            <IonProgressBar class="signin-progress-bar" type="indeterminate"></IonProgressBar>
                        }
                        <div className="signin-container">
                            <div className="singin-logo-container">
                                <img src={GdhLogo} className="signin-logo" alt="Logo GDH" />
                            </div>

                            <div className="signin-input-container" >
                                <IonInput
                                    autofocus={true}
                                    name="login"
                                    value={login}
                                    onIonChange={this.onChange}
                                    type="text"
                                    placeholder="Login"
                                    class="signin-input"
                                    padding-horizontal
                                />
                            </div>

                            <div className="signin-input-container" >
                                <IonInput
                                    name="password"
                                    value={password}
                                    onIonChange={this.onChange}
                                    type="password"
                                    placeholder="Mot de passe"
                                    class="signin-input"
                                    onKeyPress={this.handleKeyPress}
                                    padding-horizontal
                                />
                            </div>

                            <div className="signin-input-container signin-input-container__button" >
                                <IonButton disabled={isInvalid} onClick={this.login} type="submit" color="undefined" className="signin-connection-btn">
                                    <strong>Connexion</strong>
                                </IonButton>
                            </div>

                            <div className="signin-error-message-container">
                                {
                                    error &&
                                    <div className="signin-error-message-inner">
                                        <div className="signin-error-message">
                                            <i className="fas fa-exclamation-triangle signin-warning-icon"></i>
                                            {CONNECTION_ERROR_MESSAGE}
                                        </div>
                                        <div className="signin-error-message signin-error-message__secondary">{CONNECTION_ERROR_MESSAGE_SECONDARY}</div>

                                    </div>
                                }

                            </div>

                        </div>

                        <div className="signin-app-version">
                            version : {VERSION}
                        </div>
                    </div>
                </IonContent >
            </IonPage >
        );
    }
}





export default Signin;