import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import ReactGA from 'react-ga4'
import ConfigUtils from '../utils/config-utils';


ReactGA.initialize(ConfigUtils.getAnalyticsKey())

/*
 * This file is used to make Android App deep link work
 * see: https://capacitorjs.com/docs/guides/deep-links
 */

export function GAUrlListener(props: any) {
    let history = useHistory();


    useEffect(() => {
        history.listen((location) => {
            // some urls can be like :
            // - /flash/367/edit/
            // - /webquery/extraction/17
            // for the analytics page view, we are not interested in which campaing is openend but rather which page is viewed
            // so we remove all parts like /367 or /17 so the two above urls become :
            // - /flash/edit/
            // - /webquery/extraction

            let currentPath = location.pathname;
            currentPath = currentPath.replace(/\/[0-9]+/g, '');

            ReactGA.send({ hitType: 'pageview', page: currentPath })
        })
    }, [history])

    return null;
}