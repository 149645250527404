// load external libs
import React, { useEffect, useState } from "react";
import {
    IonGrid,
    IonRow,
    IonCol
} from "@ionic/react";

// load internal libs
import { WebQueryCard } from "../../../../components/web-query-card/web-query-card";
import DataService from "../../../../services/data-service";
import { WebquerySearchBar } from "../../../../components/applications/webquery/search-bar/webquery-search-bar";
import { WebqueryRightMenu } from "../../../../components/applications/webquery/right-menu/webquery-right-menu";
import { InApp } from "../../../../components/in-app/in-app";
import { WebqueryData } from "../../../../interfaces/webquery-data";
import { useParams, useHistory } from "react-router";

export function WebqueryCategory(props: any) {

    const [_isLoading, _setIsLoading] = useState<boolean>(false);
    const [_extractions, _setExtractions] = useState<any[]>([]);
    const [_categoryName, _setCategoryName] = useState<String>("");

    const _history = useHistory();
    const { id } = useParams<{ id: string }>()

    useEffect(() => {
        fetchData(+id);
    }, [id]);

    const fetchData = async (categoryId: number) => {
        _setIsLoading(true)

        try {
            let webqueries: WebqueryData[] = await DataService.getWebQueries();
            const extractions: any[] = [];
            let categoryName;
            for (const extraction of webqueries) {
                for (let categoryData of extraction.categories) {
                    if (categoryData.id === categoryId) {
                        categoryName = categoryData.name;
                        extractions.push(extraction)
                        break;
                    }
                }
            }

            _setExtractions(extractions)
            _setCategoryName(categoryName)

        } catch (error) {
            console.error(error);
        }

        _setIsLoading(false)
    }

    // when a card is clicked, go to the corresponding extraction page
    const handleClick = (id: number) => {
        _history.push("/webquery/extraction/" + id)
    }

    const onClickSearchBarElement = (webqueryId: number) => {
        _history.push("/webquery/extraction/" + webqueryId);
    }

    return (
        <InApp
            isPrimaryLoading={false}
            isSecondaryLoading={_isLoading}
            backUrl="/webquery/home"
            title={"Webquery - Categorie " + _categoryName}
            displayMenuButton={false}
            showRightMenuBtn={true}
            rightMenu={<WebqueryRightMenu />}
            refresh={null}
            headerChildren={<WebquerySearchBar onClickElement={onClickSearchBarElement} />}
        >
            <IonGrid>
                <IonRow>

                    {
                        _extractions.map((extraction: WebqueryData) => {
                            return (
                                <IonCol size="12" size-md="4" size-xl="4" key={extraction.id}>
                                    <WebQueryCard
                                        className="no-margin"
                                        onClick={handleClick}
                                        id={extraction.id}
                                        title={extraction.categories[0].name}
                                        subtitle={extraction.code + " - " + extraction.name}
                                        description={extraction.userDescription}
                                        note={"temps moyen de generation : " + (extraction.averageExecTime ? extraction.averageExecTime : "inconnu")}
                                    />
                                </IonCol>
                            )
                        })
                    }

                </IonRow>
            </IonGrid>
        </InApp>
    )
}
