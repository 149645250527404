import { Dispatch, SetStateAction, useState } from "react";
// import { ReferenceObject } from 'popper.js/index'
import { InputChangeEventDetail } from '@ionic/core/dist/types/components/input/input-interface';
import { TextareaChangeEventDetail } from '@ionic/core/dist/types/components/textarea/textarea-interface';
import { PopoverElement } from "../components/selection-popover/selection-popover";

export type CustomElement = { [x: string]: string } & PopoverElement

export const useTextWithPopover = (
    fetchDataFunction: (searchQuery: string) => Promise<any[]>,
    elementKey: string,
    elementToLabel: (element: { [x: string]: string }) => string,): [
        CustomElement[],
        Dispatch<SetStateAction<CustomElement[]>>,
        CustomElement | undefined,
        Dispatch<SetStateAction<CustomElement | undefined>>,
        string,
        Dispatch<SetStateAction<string>>,
        boolean,
        Dispatch<SetStateAction<boolean>>,
        any,
        Dispatch<SetStateAction<any>>,
        (elementId: string) => void,
        (event: CustomEvent<TextareaChangeEventDetail | InputChangeEventDetail>) => Promise<void>,
    ] => {

    const [_elements, _setElements] = useState<CustomElement[]>([]);
    const [_selectedElement, _setSelectedElement] = useState<CustomElement>();
    const [_text, _setText] = useState<string>('');
    const [_open, _setOpen] = useState<boolean>(false);
    // const [_anchor, _setAnchor] = useState<ReferenceObject>();
    const [_anchor, _setAnchor] = useState<any>();

    const onChangeText = async (event: CustomEvent<TextareaChangeEventDetail | InputChangeEventDetail>) => {
        const fieldContent = event?.detail?.value;

        if (_selectedElement && fieldContent === _selectedElement[elementKey]) {
            // if the value has not changed, return
            return;
        }

        _setText(fieldContent ?? '');
        _setAnchor(event.currentTarget);

        if ((fieldContent === undefined) || !fieldContent || (fieldContent.length === 0)) {
            _setOpen(false);
            return;
        }

        try {
            let elements = await fetchDataFunction(fieldContent);

            elements = elements.map((element: { [x: string]: string }, index: any) => {
                element.content = elementToLabel(element);
                element.key = element[elementKey];
                return element;
            })

            // this is a temporary fix, because the eai has a bug that send duplicate data
            let filteredElements: CustomElement[] = []
            elements.forEach((element: CustomElement) => {
                if (!filteredElements.some(filteredElement => filteredElement.key === element.key)) {
                    filteredElements.push(element)
                }
            })


            let open = filteredElements.length !== 0;
            _setElements(filteredElements);
            _setOpen(open);
        } catch (error) {
            console.error(error);
            if (fieldContent !== _text) {
                // the response does not match the current searched text, return
                return;
            }
            _setElements([]);
            _setOpen(false);
        }
    }

    const onClickElement = (elementId: string) => {

        const clickedElement = _elements.find((element: { [x: string]: string }) => { return (elementId === element.key) })

        if (!clickedElement) {
            console.error('clicked element is undefined');
            return;
        }

        const labelToFillInTextField = elementToLabel(clickedElement);

        _setSelectedElement(clickedElement);
        _setText(labelToFillInTextField);
        _setOpen(false);
    }

    return [
        _elements,
        _setElements,
        _selectedElement,
        _setSelectedElement,
        _text,
        _setText,
        _open,
        _setOpen,
        _anchor,
        _setAnchor,
        onClickElement,
        onChangeText
    ]
};

