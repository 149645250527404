import React from "react";
import DataService from "../../../../services/data-service";
import { InApp } from "../../../../components/in-app/in-app";
import { IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonCol, IonGrid, IonRow } from "@ionic/react";
import ConfigUtils from "../../../../utils/config-utils";
import { DataTable } from "../../../../components/data-table/data-table";
import { RefresherEventDetail } from '@ionic/core';
import { CampaignData } from "../../../../interfaces/campaign-data";
import RightMenu from "../../../../components/menu/right-menu/right-menu";

const columns = [
    { name: "nom_prenom", label: "Nom", options: { display: true } },
    { name: "tel", label: "N° de téléphone", options: { display: true } },
    { name: "ca", label: "N° de CA", options: { display: true } }
];

const VISIBLE_COLUMNS_STORAGE_KEY = "flashSmsDetailsVisibleColumns";


const PAGE_TITLE_PREFIX = ConfigUtils.getFlashApplicationName() + " - Détails SMS";

const INITIAL_STATE = {
    isLoading: false,
    SMSDetails: [],
    pageTitle: PAGE_TITLE_PREFIX,
    campaignId: null,
    smsId: null
}


export class SMSDetails extends React.Component<any, any> {

    constructor(props: any) {
        super(props);

        this.state = { ...INITIAL_STATE }

        this.fetchResidenceName = this.fetchResidenceName.bind(this);
        this.fetch = this.fetch.bind(this);
        this.refresh = this.refresh.bind(this);
    }

    async componentDidMount() {
        const campaignId = this.props.match.params.campaignid;

        // convert to number
        const smsId = + this.props.match.params.smsid;

        this.setState({
            campaignId: campaignId,
            smsId: smsId
        });

        this.fetch(campaignId, smsId);
    }

    private async fetch(campaignId: number, smsId: number, forceDownload: boolean = false) {
        this.setState({ isLoading: true });

        try {
            const campaign: CampaignData = await DataService.getCampaignDetails(campaignId, forceDownload);

            let SMSDetails;
            // convert to number
            if (campaign && campaign.envoiesSms) {
                for (let sms of campaign.envoiesSms) {
                    if (sms.id === smsId) {
                        SMSDetails = sms;
                        break;
                    }
                }
            }
            this.setState({ SMSDetails: SMSDetails });
            await this.fetchResidenceName(campaign.pat1, forceDownload);
        } catch (error) {
            console.error(error);
        }

        this.setState({ isLoading: false });
    }


    // TODO : cette fonction est dupliquee. Elle pourrait etre factorisee dans un hook
    private async fetchResidenceName(pat1: string, forceDownload: boolean = false) {
        try {
            let residences = await DataService.searchResidence(pat1, forceDownload);
            if (residences && residences[0] && residences[0].HPLBLPAT) {
                this.setState({ pageTitle: PAGE_TITLE_PREFIX + " - " + residences[0].HPLBLPAT + " - " + pat1 })
            } else {
                console.error("Unable to get residence name");
            }
        } catch (error) {
            console.error(error)
        }
    }

    async refresh(event: CustomEvent<RefresherEventDetail>) {
        this.setState({ ...INITIAL_STATE, campaignId: this.state.campaignId, smsId: this.state.smsId });
        await this.fetch(this.state.campaignId, this.state.smsId, true);
        event.detail.complete();
    }


    render() {
        const campaingId = this.props.match.params.campaignid;
        const tableData = this.state.SMSDetails ? this.state.SMSDetails.recipients : [];
        const message = this.state.SMSDetails ? this.state.SMSDetails.text : "";

        return (
            <InApp
                isPrimaryLoading={this.state.isLoading}
                isSecondaryLoading={false}
                backUrl={ConfigUtils.getFlashBaseURL() + "/" + campaingId + "/sms"}
                title={this.state.pageTitle}
                displayMenuButton={false}
                showRightMenuBtn={true}
                rightMenu={<RightMenu />}
                refresh={this.refresh}
            >
                <IonGrid>
                    <IonRow>
                        <IonCol size="12">
                            <IonCard className="no-margin">
                                <IonCardHeader class="text-center">
                                    <IonCardTitle>Contenu du SMS</IonCardTitle>
                                </IonCardHeader>
                                <IonCardContent>
                                    {message}
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol size="12">
                            <IonCard className="no-margin">
                                <DataTable
                                    title={"Détails de l'envoi"}
                                    data={tableData}
                                    columns={columns}
                                    storageKey={VISIBLE_COLUMNS_STORAGE_KEY}
                                    storeVisibleColumns={true}
                                />
                            </IonCard>
                        </IonCol>
                    </IonRow>
                </IonGrid>

            </InApp>
        )
    }


}
