import React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import { ExpandMore } from '@mui/icons-material';

import "./accordion.css";

export type AccordionData = {
  id: string,
  name: string,
  secondaryName?: string,
  content: string
}

type GdhAccordionProps = {
  data: AccordionData[],
  handleChange: (panel: AccordionData, event: React.ChangeEvent<{}>, isExpanded: boolean) => void,
  expanded: AccordionData
}

export function GdhAccordion(props: GdhAccordionProps) {

  const handleChange = (panel: AccordionData) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
    props.handleChange(panel, event, isExpanded);
  };

  if (!props.data || (props.data.length === 0)) {
    return (
      <div className="accordion-no-data" >Aucune donnée</div>
    )
  }

  return (
    <div className="accordion-container">
      {
        props.data.map((element) => {
          return (
            <Accordion key={element.id} expanded={props.expanded?.id === element.id} onChange={handleChange(element)} >
              <AccordionSummary
                expandIcon={<ExpandMore />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
                className={(props.expanded?.id === element.id) ? "accordion-expanded-summary" : ""}
              >
                <Typography className="accordion-heading">{element.name}</Typography>
                <Typography >{element.secondaryName}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography> {element.content} </Typography>
              </AccordionDetails>
            </Accordion>
          )
        })
      }
    </div>
  );
}
