import React, { useRef, useState } from 'react';

import MUIDataTable, { MUIDataTableColumnDef, MUIDataTableOptions } from 'mui-datatables';
import { traductionDataTable } from '../../assets/strings/mui-data-table-traductions';
import { StorageUtils } from '../../utils/storage-utils';
import { useMount } from '../../hooks/useMount';

type DataTableProps = {
    title: string,
    data: Array<object | number[] | string[]>,
    columns: MUIDataTableColumnDef[],
    options?: MUIDataTableOptions,
    storeVisibleColumns?: boolean,
    storageKey?: string,
}

const DEFAULT_OPTIONS = {
    filterType: "dropdown" as any,
    responsive: "standard" as "standard" | "vertical" | "simple" | undefined,
    selectableRows: "none" as any,
    textLabels: traductionDataTable,
    enableNestedDataAccess: '.', // allows nested data separated by "." (see column names and the data structure above)
}

export function DataTable(props: DataTableProps) {

    const _visibleColumns = useRef<string[]>([]);
    const [_columns, _setColumns] = useState<MUIDataTableColumnDef[]>(props.columns);


    // changedColumn takes the id of the toggled columns
    // changedColumn takes the "add" or "remove"
    function onViewColumnsChange(changedColumn: string, action: string): void {
        if (action === "add") {
            if (!_visibleColumns.current.includes(changedColumn)) {
                _visibleColumns.current = [..._visibleColumns.current, changedColumn];
            }
        } else if (action === "remove") {
            _visibleColumns.current = _visibleColumns.current.filter((value: string, index: number, array: string[]) => { return value !== changedColumn })
        }

        if (!props.storeVisibleColumns || !props.storageKey) {
            return;
        }

        StorageUtils.getInstance().setData(props.storageKey, JSON.stringify(_visibleColumns.current));
    }

    let options = {
        ...DEFAULT_OPTIONS,
        ...props.options,
        onViewColumnsChange: onViewColumnsChange
    }


    useMount(() => {
        const asyncEffect = async () => {
            if (!props.storeVisibleColumns || !props.storageKey) {
                return;
            }

            let dataStr = await StorageUtils.getInstance().getData(props.storageKey);
            let visibleColumnsNames;
            if (dataStr) {
                visibleColumnsNames = JSON.parse(dataStr);
            } else {
                visibleColumnsNames = props.columns.filter((value: any, index: number, array: MUIDataTableColumnDef[]) => {
                    if (!value.options) {
                        return true;
                    }

                    // check equals true because display can take the value 'excluded'
                    return (value.options.display === true);
                }).map((column: any) => column.name);

                await StorageUtils.getInstance().setData(props.storageKey, JSON.stringify(visibleColumnsNames));
            }

            _visibleColumns.current = visibleColumnsNames;

            let columns = _columns.map((column: any) => {
                let copyColumn = { ...column };
                // set display to true if the name is include in the visible elements array
                // check if the display variable is a boolean to avoid overwrite a possible 'excluded' value
                if (typeof (copyColumn.options.display) === 'boolean') {
                    copyColumn.options.display = _visibleColumns.current.includes(column.name);
                }

                return copyColumn
            })
            _setColumns(columns)
        }

        asyncEffect();
    });

    return (
        <MUIDataTable
            title={props.title}
            data={props.data}
            columns={_columns}
            options={options}
        />
    )
}