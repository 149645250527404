import React from "react"
import { IonItem, IonLabel, IonList } from "@ionic/react"
import './menu-group.css'
import { Utils } from "../../../utils/utils"

const INITIAL_STATE = {
    childrenVisible: true,
}


export class MenuGroup extends React.Component<any, any> {

    constructor(props: any) {
        super(props);

        this.state = { ...INITIAL_STATE };

        this.onClick = this.onClick.bind(this);
    }

    onClick() {
        this.setState((state: any, props: any) => ({ childrenVisible: !state.childrenVisible }));
    }


    render() {
        let { name, children, faIcon } = this.props;

        faIcon = Utils.checkFaIconIsValid(faIcon) ? faIcon : "fas fa-folder";

        if (this.props.depth === 0) {
            faIcon += " menu-group-faicon__purple";
        }

        return (
            <>
                <IonItem button onClick={this.onClick} className={this.props.itemClassName}>
                    <i className={faIcon + " ion-margin-end"} />
                    <IonLabel mode='md'>{name}</IonLabel>
                </IonItem>
                {
                    children &&
                    this.state.childrenVisible &&
                    <IonList class={'menu-group-list ' + this.props.listClassName}>
                        {children}
                    </IonList>
                }
            </>
        )
    }
}

