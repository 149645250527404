import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router';
import ConfigUtils from '../utils/config-utils';
import { getEnabledAppsState } from '../store/redux-store';


// TODO : move this component
export function RedirectToHome(props: any) {

	const enabledApps = useSelector(getEnabledAppsState)

	if (enabledApps.apps === undefined) {
		return null;
	}

	if (enabledApps.apps.includes('HOME')) {
		return <Redirect to='/home' />
	} else if (enabledApps.apps.includes('FLASH')) {
		return <Redirect to={ConfigUtils.getFlashBaseURL()} />
	} else if (enabledApps.apps.includes('WEBQUERY')) {
		return <Redirect to={ConfigUtils.getWebqueryBaseURL() + '/home'} />
	} else if (enabledApps.apps.includes('PDF')) {
		return <Redirect to='/PDF/home' />
	} else if (enabledApps.apps.includes('PAYMENT')) {
		return <Redirect to='/payment/home' />
	} else if (enabledApps.apps.includes('DIGITAL-REPORT')) {
		return <Redirect to='/digital-report/home' />
	} else if (enabledApps.apps.includes('DIGITAL-ATTRIB')) {
		return <Redirect to='/digital-attribution/home' />
	} else if (enabledApps.apps.includes('DOCUSIGN')) {
		return <Redirect to='/docusign/home' />
	} else if (enabledApps.apps.includes('CTI')) {
		return <Redirect to='/CTI/home' />
	}

	return <Redirect to='/no-application' />;
}