import React, { ReactNode } from 'react';


import { FullPageLoader } from "../loader/full-page-loader";
import { LeftMenu } from "../menu/left-menu/left-menu";
import { IonPage, IonProgressBar, IonContent, IonRefresher, IonRefresherContent } from "@ionic/react";
import { Header } from "../header/header";
import { RefresherEventDetail } from '@ionic/core';


type InAppProps = {
    isPrimaryLoading: boolean,
    isSecondaryLoading: boolean,
    backUrl: string,
    title: string,
    displayMenuButton: boolean,
    showRightMenuBtn: boolean,
    children: any,
    rightMenu: ReactNode,
    refresh: ((event: CustomEvent<RefresherEventDetail>) => void) | null,
    outOfIonContentElement?: ReactNode,
    headerChildren?: ReactNode,
    contentClassName?: string
}

// TODO : add the toast in this component
export function InApp(props: InAppProps) {

    let refresher = props.refresh ?
        <IonRefresher slot="fixed" onIonRefresh={props.refresh}>
            <IonRefresherContent />
        </IonRefresher>
        :
        null;

    let contentClassName = props.contentClassName ? 'ion-padding ' + props.contentClassName : 'ion-padding';

    return (
        <>
            {
                props.isPrimaryLoading &&
                <FullPageLoader />
            }

            <LeftMenu />

            <IonPage id="main">
                {props.rightMenu}
                <Header title={props.title} backUrl={props.backUrl} displayMenuButton={props.displayMenuButton} showRightMenuBtn={props.showRightMenuBtn}>
                    {props.headerChildren}
                </Header>
                {
                    props.isSecondaryLoading &&
                    <IonProgressBar type="indeterminate"></IonProgressBar>
                }
                <IonContent id="content" class={"main-content " + contentClassName}>
                    {refresher}
                    {props.children}
                </IonContent>
                {props.outOfIonContentElement}
            </IonPage>
        </>
    )






}