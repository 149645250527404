import ConfigUtils from '../utils/config-utils';

const BASE_URL: string = ConfigUtils.getServerUrl() + "api/";

type Cache = {
    promise: Promise<any> | null,
    data: any | null
}


// TODO : REFACTOR THIS CLASS !!!!
export default class FetchGdhApiService {

    private static sCache: Map<string, Cache> = new Map();

    // make the constructor private so the class cannot be instantiated
    private constructor() { }

    public static clearCache() {
        FetchGdhApiService.sCache = new Map();
    }

    public static get(type: any,
        userData: any,
        headers: any,
        forceDownload: boolean): Promise<any> {

        const keys = Object.keys(userData);
        let data: string = "";
        for (let i = 0; i < keys.length; i++) {
            data += '&' + keys[i] + '=' + userData[keys[i]]
        }

        let url = BASE_URL + type + data;


        if (!forceDownload) {
            const cacheContent = FetchGdhApiService.sCache.get(url);
            if (cacheContent) {
                if (cacheContent.data) {
                    return new Promise((resolve) => { resolve(cacheContent.data) });
                } else if (cacheContent.promise) {
                    return cacheContent.promise;
                }
            }
        }

        const promise = new Promise((resolve, reject) => {

            fetch(url, {
                method: 'GET',
                headers: headers
            })
                .then((response: Response) => {
                    if (response.ok) {
                        return response.json();
                    } else {
                        reject("Fetch error. " + response.status + " " + response.statusText + " " + response.url);
                        return;
                    }
                })
                .then((responseJson) => {
                    FetchGdhApiService.sCache.set(url, { promise: null, data: responseJson })
                    resolve(responseJson);
                })
                .catch((error) => {
                    // if it fails, empty cache
                    FetchGdhApiService.sCache.set(url, { promise: null, data: null })
                    reject(error);
                });
        });

        FetchGdhApiService.sCache.set(url, { promise: promise, data: null })
        return promise;

    }

    public static post(type: any,
        userData: any,
        headers: any): Promise<any> {

        return new Promise((resolve, reject) => {

            const keys = Object.keys(userData);
            // URLSearchParams is necessary to escape special chars in request (for example ":")
            const data = new URLSearchParams();
            for (let i = 0; i < keys.length; i++) {
                if (userData[keys[i]] === undefined) {
                    continue;
                }
                data.append(keys[i], userData[keys[i]]);
            }

            let url = BASE_URL + type;

            fetch(url, {
                method: 'POST',
                body: data,
                headers: headers
            })
                .then((response: any) => {
                    if (response.ok) {
                        return response.json();
                    } else {
                        reject("Fetch error. " + response.status + " " + response.statusText + " " + response.url);
                    }
                })
                .then((responseJson) => {
                    resolve(responseJson);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }

    public static async postRawData(url: string,
        data: Object,
        headers: any): Promise<any> {

        const rawData = JSON.stringify(data);

        try {
            const response = await fetch(BASE_URL + url, {
                method: 'POST',
                headers: headers,
                body: rawData
            });
            if (response.ok) {
                return response.json();
            } else {
                throw new Error("Response not ok");
            }
        } catch (error: any) {
            console.error(error);
            throw new Error(error);
        }
    }


    public static delete(type: any,
        userData: any,
        headers: any): Promise<any> {

        return new Promise((resolve, reject) => {

            let url = BASE_URL + type;

            const keys = Object.keys(userData);
            // URLSearchParams is necessary to escape special chars in request (for example ":")
            const data = new URLSearchParams();
            for (let i = 0; i < keys.length; i++) {
                if (userData[keys[i]] === undefined) {
                    continue;
                }
                data.append(keys[i], userData[keys[i]]);
            }

            fetch(url, {
                method: 'DELETE',
                body: data,
                headers: headers
            })
                .then((response: any) => {
                    if (response.ok) {
                        return response.json();
                    } else {
                        reject("Fetch error. " + response.status + " " + response.statusText + " " + response.url);
                    }
                })
                .then((responseJson) => {
                    resolve(responseJson);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }

    public static put(type: any,
        headers: any): Promise<any> {

        return new Promise((resolve, reject) => {

            let url = BASE_URL + type;

            fetch(url, {
                method: 'PUT',
                headers: headers
            })
                .then((response: any) => {
                    if (response.ok) {
                        return response.json();
                    } else {
                        reject("Fetch error. " + response.status + " " + response.statusText + " " + response.url);
                    }
                })
                .then((responseJson) => {
                    resolve(responseJson);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }

}
