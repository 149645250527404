// load external libs
// load external libs
import React, { SyntheticEvent } from "react";
import HotTable from "@handsontable/react";
import {
    IonCard,
    IonGrid,
    IonRow,
    IonCol,
    IonCardContent,
    IonCardTitle,
    IonButton
} from "@ionic/react";

// load internal libs
import { TextInputItem } from "../../../../components/text-input/text-input";
import DataService from "../../../../services/data-service";
import ConfigUtils from "../../../../utils/config-utils";
import { WebquerySearchBar } from "../../../../components/applications/webquery/search-bar/webquery-search-bar";

// import external css files
import 'handsontable/dist/handsontable.full.css';

// import internal css files
import './webquery-extraction.css';
import { WebqueryRightMenu } from "../../../../components/applications/webquery/right-menu/webquery-right-menu";
import { WebqueryData } from "../../../../interfaces/webquery-data";
import { InApp } from "../../../../components/in-app/in-app";
import { WebqueryParameterData } from "../../../../interfaces/webquery-parameter-data";
import Handsontable from "handsontable";
import { IonicUtils } from "../../../../utils/ionic-utils";


const INITIAL_STATE = {
    isPrimaryLoading: false,
    isSecondaryLoading: false,
    data: [],
    headers: [],
    webquery: null,
    parameters: null
}


const DISABLE_EXPORT_BUTTON = !IonicUtils.isBrowser();
export class WebqueryExtraction extends React.Component<any, any> {

    constructor(props: any) {
        super(props);

        this.state = { ...INITIAL_STATE };

        this.onClickExport = this.onClickExport.bind(this);
        this.onClickSimu = this.onClickSimu.bind(this);
        this.handleParameterChange = this.handleParameterChange.bind(this);
        this.onClickSearchBarElement = this.onClickSearchBarElement.bind(this);
        this.getParametersValues = this.getParametersValues.bind(this);
    }

    componentWillReceiveProps(newProps: any) {
        // + sign convert string to number
        this.updatePage(+ newProps.match.params.id);
    }

    componentDidMount() {
        // + sign convert string to number
        this.updatePage(+ this.props.match.params.id);
    }


    async updatePage(webqueryId: number) {
        this.setState({ isSecondaryLoading: true });

        try {
            let webqueries: WebqueryData[] = await DataService.getWebQueries();

            let selectedWebquery: WebqueryData | null = null;
            let parameters = null;

            for (let webquery of webqueries) {
                if (webquery.id === webqueryId) {
                    selectedWebquery = webquery;
                }
            }

            if (selectedWebquery && selectedWebquery.parameters && (selectedWebquery.parameters.length > 0)) {
                parameters = selectedWebquery.parameters;

                for (let parameter of parameters) {
                    parameter.value = "";
                }
            }

            this.setState({
                webquery: selectedWebquery,
                parameters: parameters
            });


        } catch (error) {
            console.error(error);
        }

        this.setState({ isSecondaryLoading: false });
    }

    async onClickExport(event: SyntheticEvent) {
        this.setState({ isPrimaryLoading: true });

        try {
            let mediaInfo = await DataService.getWebQuery(this.props.match.params.id, false, this.getParametersValues());

            if (!mediaInfo || !mediaInfo.mediaId) {
                console.error("Unable to get media id");
                return;
            }
            let mediaB64 = await DataService.getMediaBase64(mediaInfo.mediaId, true);

            let a = document.createElement("a");
            a.href = "data:application/octet-stream;base64," + mediaB64;
            a.download = mediaInfo.filename ?? "extraction.xlsx";
            a.click();

        } catch (error) {
            console.error(error);
        }

        this.setState({ isPrimaryLoading: false });
    }

    async onClickSimu(event: SyntheticEvent) {
        this.setState({ isSecondaryLoading: true });

        try {
            const webquery = await DataService.getWebQuery(this.props.match.params.id, true, this.getParametersValues());
            const headers: any[] = Object.keys(webquery.data[0]);

            const arrayData: any[] = webquery.data.map((element: any) => {
                return Object.values(element)
            })

            this.setState({
                headers: headers,
                data: arrayData
            })
        } catch (error) {
            console.error(error)
        }

        // FIXME : wait 1 seconds before setting isSecondaryLoading state to false, in order to trigger a new render and make the
        // handsontable component visible. It seems to be the same issue as the one with leaflet component
        setTimeout(() => this.setState({ isSecondaryLoading: false }), 1000);
    }

    getParametersValues(): { [key: string]: string } {
        const parameters: { [key: string]: string } = {};
        let parameter: WebqueryParameterData;
        if (!this.state.parameters) {
            return parameters;
        }
        for (parameter of this.state.parameters) {
            if (parameter.value) {
                parameters[parameter.code] = parameter.value;
            }
        }
        return parameters;
    }

    handleParameterChange(e: any) {
        const paramValue = e.target.value;
        let paramIndex = -1;

        this.state.parameters.forEach((param: any, index: number) => {
            if (param.title === e.target.name) {
                paramIndex = index;
            }
        });

        if (paramIndex !== -1) {
            this.setState((state: any, props: any) => {
                state.parameters[paramIndex].value = paramValue;
            })
        }
    }


    onClickSearchBarElement(webqueryId: number) {
        this.props.history.push("/webquery/extraction/" + webqueryId);
    }


    render() {

        const handsontableSettings: Handsontable.GridSettings = {
            data: this.state.data,
            rowHeaders: true,
            colHeaders: this.state.headers,
            licenseKey: ConfigUtils.getHandsontableLicenceKey(),
            width: '100%',
            height: 400,
            // to disable editing on every cells
            cells: ((row: number, col: number, prop: any) => {
                return { editor: false };
            })
        }

        return (
            <InApp
                isPrimaryLoading={this.state.isPrimaryLoading}
                isSecondaryLoading={this.state.isSecondaryLoading}
                backUrl="/webquery/home"
                title={"Webquery - " + (this.state.webquery && this.state.webquery.name)}
                displayMenuButton={false}
                showRightMenuBtn={true}
                rightMenu={<WebqueryRightMenu />}
                refresh={null}
                headerChildren={<WebquerySearchBar onClickElement={this.onClickSearchBarElement} />}
            >
                <IonGrid>
                    <IonRow>

                        <IonCol size="12" size-md="6" size-xl="6">
                            <IonCard className="ion-padding web-query-extraction-card no-margin">
                                {
                                    this.state.webquery &&
                                    this.state.webquery.userDescription &&
                                    <IonCardTitle>
                                        Description : {this.state.webquery.userDescription}
                                    </IonCardTitle>
                                }

                                <div className="web-query-extraction-card-button-container ion-margin">
                                    <div>
                                        Le mode simulation affichera 20 lignes
                                    </div>
                                    <IonButton type="button" disabled={DISABLE_EXPORT_BUTTON} onClick={this.onClickExport} id="export" >EXPORTER</IonButton>
                                    <IonButton type="button" onClick={this.onClickSimu} id="simu" >SIMULER</IonButton>
                                </div>
                            </IonCard>
                        </IonCol>

                        <IonCol size="12" size-md="6" size-xl="6">
                            <IonCard className="ion-padding web-query-extraction-card no-margin">
                                <IonCardTitle>
                                    Critères de selection
                                </IonCardTitle>
                                <IonCardContent>

                                    {
                                        this.state.parameters
                                            ?
                                            this.state.parameters.map((param: any) => {
                                                return (
                                                    <TextInputItem
                                                        className="web-query-item-input ion-margin-bottom gdh-top-label-item-input gdh-top-label-item"
                                                        key={param.title}
                                                        handleChangeInput={this.handleParameterChange}
                                                        value={param.value}
                                                        label={param.title}
                                                        required={param.isRequired}
                                                        textLineProps={{ placeholder: param.title }}
                                                        isArea={false}
                                                    />
                                                )
                                            })
                                            :
                                            <div>Aucun parametre</div>
                                    }

                                </IonCardContent>

                            </IonCard>
                        </IonCol>

                    </IonRow>

                    {
                        (this.state.data.length > 0) &&
                        <IonRow>
                            <IonCol size="12">
                                <div id="hot-app">
                                    <HotTable settings={handsontableSettings} />
                                </div>
                            </IonCol>
                        </IonRow>
                    }

                </IonGrid>
            </InApp>
        )
    }
}
