import { IonCheckbox, IonCol, IonGrid, IonItem, IonLabel, IonRadio, IonRadioGroup, IonRow, IonIcon, IonModal, IonDatetime, IonButtons, IonButton, IonDatetimeButton } from '@ionic/react';
import React, { useRef, useState } from 'react';
import { TextInputItem } from '../../../../components/text-input/text-input';
import { CheckboxChangeEventDetail } from '@ionic/core/dist/types/components/checkbox/checkbox-interface'
import { RadioGroupChangeEventDetail } from '@ionic/core/dist/types/components/radio-group/radio-group-interface'
import { InputChangeEventDetail } from '@ionic/core/dist/types/components/input/input-interface';
import { TextareaChangeEventDetail } from '@ionic/core/dist/types/components/textarea/textarea-interface';
import { Dialog } from '../../../../components/dialog/dialog';
import { help } from 'ionicons/icons';
import { Utils } from '../../../../utils/utils';

export const RADIO_BUTTON_YES_RESPONSE = 'oui'

type DigitalReportHomeStep3Props = {
    onChangeVictimInfoCheckbox: (event: CustomEvent<CheckboxChangeEventDetail>) => void,

    onChangeAuthorInfoRadio: (event: CustomEvent<RadioGroupChangeEventDetail>) => void,
    onChangeAuthorIdentifiedRadio: (event: CustomEvent<RadioGroupChangeEventDetail>) => void,
    onChangeAuthorSexCheckbox: (event: CustomEvent<CheckboxChangeEventDetail>) => void,
    onChangeAuthorResidentCheckbox: (event: CustomEvent<CheckboxChangeEventDetail>) => void,
    onChangeAuthorAgeCheckbox: (event: CustomEvent<CheckboxChangeEventDetail>) => void,
    onChangeClaimRadio: (event: CustomEvent<RadioGroupChangeEventDetail>) => void,
    onChangeClaimIdText: (event: CustomEvent<TextareaChangeEventDetail | InputChangeEventDetail>) => void,
    onChangeComplaintRadio: (event: CustomEvent<RadioGroupChangeEventDetail>) => void,
    onChangeComplaintIdText: (event: CustomEvent<TextareaChangeEventDetail | InputChangeEventDetail>) => void,
    onChangeDate: (date: Date | null) => void,
    onChangeComplaintDate: (date: Date | null) => void,

    selectedDate: Date | null,
    selectedComplaintDate: Date | null,

    victimInfo: { [x: string]: any },
    selectedVictimInfo: string[],

    authorInfo: { [x: string]: any },
    selectedAuthorInfo: string,

    authorIdentified: { [x: string]: any },
    selectedAuthorIdentified: string,

    authorSex: { [x: string]: any },
    selectedAuthorSex: string[],

    authorAge: { [x: string]: any },
    selectedAuthorAge: string[],

    authorResident: { [x: string]: any },
    selectedAuthorResident: string[],

    claim: { [x: string]: any },
    selectedClaim: string,

    claimId: string,

    complaint: { [x: string]: any },
    selectedComplaint: string,

    complaintId: string,
}

export function DigitalReportHomeStep3(props: DigitalReportHomeStep3Props) {

    const [_showHelpDialog, _setShowHelpDialog] = useState<boolean>(false);

    const handleHelpDialogOkButton = () => {
        _setShowHelpDialog(false)
    }

    const dateMainCourant = useRef<null | HTMLIonDatetimeElement>(null);
    const dateComplaint = useRef<null | HTMLIonDatetimeElement>(null);

    const cancelDateMainCourant = () => {
        dateMainCourant.current?.cancel(true)
    }

    const confirmDateMainCourant = () => {
        dateMainCourant.current?.confirm()
        if (typeof (dateMainCourant.current?.value) !== "string") {
            console.error('Wrong Date Format')
            return
        }
        props.onChangeDate(new Date(dateMainCourant.current?.value))
        dateMainCourant.current?.cancel(true)
    }

    const cancelDateComplaint = () => {
        dateComplaint.current?.cancel(true)
    }

    const confirmDateComplaint = () => {
        dateComplaint.current?.confirm()
        if (typeof (dateComplaint.current?.value) !== "string") {
            console.error('Wrong Date Format')
            return
        }
        props.onChangeComplaintDate(new Date(dateComplaint.current?.value))
        dateComplaint.current?.cancel(true)
    }

    const isClaimDateDisabled = props.selectedClaim?.toLowerCase() !== RADIO_BUTTON_YES_RESPONSE
    const isComplaintDateDisabled = props.selectedComplaint?.toLowerCase() !== RADIO_BUTTON_YES_RESPONSE

    return (
        <>
            <div className="digital-report-home-title">
                Auteur(s) et victime(s)
            </div>
            <div className="digital-report-section">
                <div className="digital-report-home-section-title">
                    Victime(s) des faits
                </div>
                <IonGrid>
                    <IonRow>
                        {
                            Object.keys(props.victimInfo).map((key) => {
                                return (
                                    <IonCol key={key} size="6" sizeXl="6" size-lg="6" size-md="6" size-sm="12" size-xs="12" >
                                        <IonItem className="gdh-top-label-item">
                                            <IonLabel>{props.victimInfo[key]}</IonLabel>
                                            <IonCheckbox value={key} slot="start"
                                                checked={props.selectedVictimInfo.includes(key)}
                                                onIonChange={props.onChangeVictimInfoCheckbox}
                                            />
                                        </IonItem>
                                    </IonCol>
                                )
                            })
                        }
                    </IonRow>
                </IonGrid>

            </div>

            <div className="digital-report-section">
                <div className="digital-report-home-section-title">
                    Auteur(s) présumé(s) des faits
                </div>

                <IonGrid>
                    <IonRow>
                        <IonCol size="6" sizeXl="6" size-lg="6" size-md="6" size-sm="12" size-xs="12" >
                            <IonRadioGroup value={props.selectedAuthorInfo} onIonChange={props.onChangeAuthorInfoRadio}>
                                <IonLabel>Auteur</IonLabel>
                                {
                                    Object.keys(props.authorInfo).map((key) => {
                                        return (
                                            <IonItem key={key}>
                                                <IonLabel>{props.authorInfo[key]}</IonLabel>
                                                <IonRadio slot="start" value={key} />
                                            </IonItem>
                                        )
                                    })
                                }
                            </IonRadioGroup>

                            <IonLabel>Age</IonLabel>
                            {
                                Object.keys(props.authorAge).map((key) => {
                                    return (
                                        <IonItem key={key}>
                                            <IonLabel>{props.authorAge[key]}</IonLabel>
                                            <IonCheckbox
                                                slot="start"
                                                value={key}
                                                checked={props.selectedAuthorAge.includes(key)}
                                                onIonChange={props.onChangeAuthorAgeCheckbox}
                                            />
                                        </IonItem>
                                    )
                                })
                            }

                        </IonCol>
                        <IonCol size="6" sizeXl="6" size-lg="6" size-md="6" size-sm="12" size-xs="12" >

                            <IonRadioGroup value={props.selectedAuthorIdentified} onIonChange={props.onChangeAuthorIdentifiedRadio}>
                                <IonLabel>Identifié</IonLabel>
                                {
                                    Object.keys(props.authorIdentified).map((key) => {
                                        return (
                                            <IonItem key={key}>
                                                <IonLabel>{props.authorIdentified[key]}</IonLabel>
                                                <IonRadio slot="start" value={key} />
                                            </IonItem>
                                        )
                                    })
                                }
                            </IonRadioGroup>

                            <IonLabel>Sexe</IonLabel>
                            {
                                Object.keys(props.authorSex).map((key) => {
                                    return (
                                        <IonItem key={key}>
                                            <IonLabel>{props.authorSex[key]}</IonLabel>
                                            <IonCheckbox
                                                slot="start"
                                                value={key}
                                                checked={props.selectedAuthorSex.includes(key)}
                                                onIonChange={props.onChangeAuthorSexCheckbox}
                                            />
                                        </IonItem>
                                    )
                                })
                            }

                            <IonLabel>Résident du quartier</IonLabel>
                            {
                                Object.keys(props.authorResident).map((key) => {
                                    return (
                                        <IonItem key={key}>
                                            <IonLabel>{props.authorResident[key]}</IonLabel>
                                            <IonCheckbox
                                                slot="start"
                                                value={key}
                                                checked={props.selectedAuthorResident.includes(key)}
                                                onIonChange={props.onChangeAuthorResidentCheckbox}
                                            />
                                        </IonItem>
                                    )
                                })
                            }

                        </IonCol>
                    </IonRow>
                </IonGrid>
            </div>

            <div className="digital-report-section">
                <div className="digital-report-home-section-title">
                    Main courante
                </div>

                <IonGrid>
                    <IonRow>
                        <IonCol size="6" sizeXl="6" size-lg="6" size-md="6" size-sm="12" size-xs="12" >
                            <IonRadioGroup value={props.selectedClaim} onIonChange={props.onChangeClaimRadio}>
                                <IonLabel>Dépot de main courante</IonLabel>
                                <IonIcon slot="icon-only" icon={help} onClick={() => _setShowHelpDialog(true)} className="help-icon"></IonIcon>
                                {
                                    Object.keys(props.claim).map((key) => {
                                        return (
                                            <IonItem key={key}>
                                                <IonLabel>{props.claim[key]}</IonLabel>
                                                <IonRadio slot="start" value={key} />
                                            </IonItem>
                                        )
                                    })
                                }
                            </IonRadioGroup>

                        </IonCol>
                        <IonCol size="6" sizeXl="6" size-lg="6" size-md="6" size-sm="12" size-xs="12" >

                            <IonItem disabled={isClaimDateDisabled} className="digital-report-date-picker">
                                <span className="digital-report-date-label">Date de la main courante :</span>
                                <IonDatetimeButton className="digital-report-date-button" datetime="dateMainCourant"></IonDatetimeButton>
                                <IonModal keepContentsMounted={true}>
                                    <IonDatetime value={Utils.formatDateIfValid(props.selectedDate, 'yyyy-MM-dd')} locale="fr-FR" presentation="date" id="dateMainCourant" ref={dateMainCourant}>
                                        <IonButtons slot="buttons">
                                            <IonButton color="primary" onClick={cancelDateMainCourant}>
                                                Annuler
                                            </IonButton>
                                            <IonButton color="primary" onClick={confirmDateMainCourant}>
                                                Confirmer
                                            </IonButton>
                                        </IonButtons>
                                    </IonDatetime>
                                </IonModal>
                            </IonItem>

                            <TextInputItem
                                className="gdh-top-label-item-input"
                                handleChangeInput={props.onChangeClaimIdText}
                                value={props.claimId}
                                label="Numero de la main courante"
                                disabled={isClaimDateDisabled}
                                required={false}
                                isArea={false}
                                textLineProps={{ placeholder: "" }}
                            />

                        </IonCol>
                    </IonRow>
                </IonGrid>
            </div>

            <div className="digital-report-section">
                <div className="digital-report-home-section-title">
                    Plainte
                </div>

                <IonGrid>
                    <IonRow>
                        <IonCol size="6" sizeXl="6" size-lg="6" size-md="6" size-sm="12" size-xs="12" >
                            <IonRadioGroup value={props.selectedComplaint} onIonChange={props.onChangeComplaintRadio}>
                                <IonLabel>Dépot de plainte</IonLabel>
                                <IonIcon slot="icon-only" icon={help} onClick={() => _setShowHelpDialog(true)} className="help-icon"></IonIcon>
                                {
                                    Object.keys(props.complaint).map((key) => {
                                        return (
                                            <IonItem key={key}>
                                                <IonLabel>{props.complaint[key]}</IonLabel>
                                                <IonRadio slot="start" value={key} />
                                            </IonItem>
                                        )
                                    })
                                }
                            </IonRadioGroup>

                        </IonCol>
                        <IonCol size="6" sizeXl="6" size-lg="6" size-md="6" size-sm="12" size-xs="12" >

                            <IonItem disabled={isComplaintDateDisabled} className="digital-report-date-picker">
                                <span className="digital-report-date-label">Date du dépot de plainte :</span>
                                <IonDatetimeButton className="digital-report-date-button" datetime="dateComplaint"></IonDatetimeButton>
                                <IonModal keepContentsMounted={true}>
                                    <IonDatetime value={Utils.formatDateIfValid(props.selectedComplaintDate, 'yyyy-MM-dd')} locale="fr-FR" presentation="date" id="dateComplaint" ref={dateComplaint}>
                                        <IonButtons slot="buttons">
                                            <IonButton color="primary" onClick={cancelDateComplaint}>
                                                Annuler
                                            </IonButton>
                                            <IonButton color="primary" onClick={confirmDateComplaint}>
                                                Confirmer
                                            </IonButton>
                                        </IonButtons>
                                    </IonDatetime>
                                </IonModal>
                            </IonItem>

                            <TextInputItem
                                className="gdh-top-label-item-input"
                                handleChangeInput={props.onChangeComplaintIdText}
                                value={props.complaintId}
                                label="Numero de la plainte"
                                disabled={isComplaintDateDisabled}
                                required={false}
                                isArea={false}
                                textLineProps={{ placeholder: "" }}
                            />

                        </IonCol>
                    </IonRow>
                </IonGrid>
            </div>
            <Dialog
                open={_showHelpDialog}
                title="Aide"
                content={
                    <>
                        <span className="digital-report-help-dialog-content digital-report-help-dialog-outline">Pour rappel : Pour toute urgence, fait flagrant, découverte d’objet dangereux, composer immédiatement le 17. Rédiger la fiche ultérieurement.</span>
                        <span className="digital-report-help-dialog-content">« Sur les secteurs calmes, il est demandé de signaler tout fait d'incivilité via la fiche de signalement.</span>
                        <span className="digital-report-help-dialog-content">Pour les zones où les faits sont récurrents (Dégradations ou regroupements), il est demandé de regrouper les signalements pour dégradations dans une fiche à envoyer toutes les semaines, tous les 15 jours ou toutes les 3 semaines, en fonction des récurrences.</span>
                        <span className="digital-report-help-dialog-content">CEPENDANT, il faudra continuer de transmettre sans délai une fiche spéciale tout fait inhabituel : incendie, agression, gros vandalisme, nouveaux regroupements ou déplacement des regroupements...</span>
                        <span className="digital-report-help-dialog-content digital-report-help-dialog-outline">Si signalement de véhicules épaves : se référer la procédure B0005.</span>
                    </>
                }
                onConfirm={handleHelpDialogOkButton}
                showCancelButton={false}
            />
        </>
    )


}