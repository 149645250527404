import React from 'react';
import {
    IonGrid,
    IonRow,
    IonCol,
    IonCard,
    IonCardHeader,
    IonCardSubtitle,
    IonCardTitle,
    IonIcon,
    IonButton,
} from '@ionic/react';
import { business, person, folderOpen, construct, clipboard, paperPlane, chatbubbles, calendar } from 'ionicons/icons';
import DataService from '../../../../services/data-service';
import GDHToast from '../../../../components/toast/toast';
import { RefresherEventDetail } from '@ionic/core';


import './campaign-details.css';
import { MapboxMap } from '../../../../components/map/mapbox-map/mapbox-map';
import ConfigUtils from '../../../../utils/config-utils';
import { TabBar, TabName } from '../../../../components/flash/tab-bar/tab-bar';
import { InApp } from '../../../../components/in-app/in-app';
import { Dialog } from '../../../../components/dialog/dialog';
import { StorageUtils } from '../../../../utils/storage-utils';
import { CampaignStatus } from '../../../../buisness/campaign';
import RightMenu from '../../../../components/menu/right-menu/right-menu';

const PAGE_TITLE_PREFIX = ConfigUtils.getFlashApplicationName() + " - Détails";

const INITIAL_STATE = {
    campagneDetails: '',
    createur: '',
    campaignId: null,
    showToast: false,
    successToast: false,
    messageToast: "",
    isPrimaryLoading: false,
    isSecondaryLoading: false,
    pageTitle: PAGE_TITLE_PREFIX,
    residenceName: null,
    modalOpen: false,
    isCreator: null
}


class CampaignDetails extends React.Component<any, any> {

    private _isMounted: boolean = false;

    constructor(props: any) {
        super(props);

        this.state = { ...INITIAL_STATE };

        this.fetch = this.fetch.bind(this);
        this.closeCampaign = this.closeCampaign.bind(this);
        this.launchCampaign = this.launchCampaign.bind(this);
        this.handleToastClose = this.handleToastClose.bind(this);
        this.onClickTab = this.onClickTab.bind(this);
        this.fetchResidenceName = this.fetchResidenceName.bind(this);
        this.onModalCancel = this.onModalCancel.bind(this);
        this.onModalConfirm = this.onModalConfirm.bind(this);
        this.onClickCloseCampaignBtn = this.onClickCloseCampaignBtn.bind(this);
        this.refresh = this.refresh.bind(this);
    }

    componentWillReceiveProps(props: any) {
        let campaignId = props.match.params.id;

        if (campaignId !== this.state.campaignId) {
            this.setState({ ...INITIAL_STATE, campaignId: campaignId, isCreator: this.state.isCreator });
            this.fetch(campaignId);
        }
    }

    async componentDidMount() {
        this._isMounted = true;
        let campaignId = this.props.match.params.id;

        const isCreator = await StorageUtils.getInstance().getIsCreator();

        this.setState({
            campaignId: campaignId,
            isCreator: isCreator
        });

        this.fetch(campaignId);
    }

    componentWillUnmount() {
        this._isMounted = false;
    }


    async fetch(campaignId: number, forceDownload: boolean = false) {
        this.setState({ isPrimaryLoading: true });

        try {
            let campaignDetails = await DataService.getCampaignDetails(campaignId, forceDownload);
            if (!this._isMounted) {
                console.debug("campaign details not mounted, return");
                return;
            }
            this.setState({
                campagneDetails: campaignDetails,
                createur: campaignDetails.createdBy,
            });
            await this.fetchResidenceName(campaignDetails.pat1, forceDownload);
        } catch (error) {
            this.setState({
                error: error
            });
        }
        if (!this._isMounted) {
            console.debug("campaign details not mounted, return");
            return;
        }
        this.setState({
            isPrimaryLoading: false
        });
    }

    private async fetchResidenceName(pat1: string, forceDownload: boolean = false) {
        try {
            let residences = await DataService.searchResidence(pat1, forceDownload);
            if (!this._isMounted) {
                console.debug("campaign details not mounted, return");
                return;
            }
            if (residences && residences[0] && residences[0].HPLBLPAT) {
                this.setState({
                    pageTitle: PAGE_TITLE_PREFIX + " - " + residences[0].HPLBLPAT + " - " + pat1,
                    residenceName: residences[0].HPLBLPAT
                })
            } else {
                console.error("Unable to get residence name");
            }
        } catch (error) {
            console.error(error)
        }
    }

    async launchCampaign() {
        this.setState({ isSecondaryLoading: true });

        let message;
        let success;
        try {
            let campaignDetails = await DataService.launchCampaign(this.state.campaignId);
            if (!this._isMounted) {
                console.debug("campaign details not mounted, return");
                return;
            }
            this.setState({ campagneDetails: campaignDetails });
            message = "Campagne lancée";
            success = true;
        } catch (e) {
            console.error(e)
            message = "Erreur lors du lancement de la campagne";
            success = false;
        }
        this.setState({
            showToast: true,
            messageToast: message,
            successToast: success,
            isSecondaryLoading: false
        });
    }

    async closeCampaign() {
        this.setState({ isSecondaryLoading: true });
        let message;
        let success;
        try {
            let campaignDetails = await DataService.closeCampaign(this.state.campaignId);
            if (!this._isMounted) {
                console.debug("campaign details not mounted, return");
                return;
            }
            this.setState({ campagneDetails: campaignDetails });
            message = "Campagne cloturée";
            success = true;
        } catch (e) {
            console.error(e)
            message = "Erreur lors de la cloture de la campagne";
            success = false;
        }
        this.setState({
            showToast: true,
            messageToast: message,
            successToast: success,
            isSecondaryLoading: false
        });
    }

    handleToastClose() {
        this.setState({
            showToast: false,
            messageToast: ""
        });
    }

    onClickTab(tabName: TabName): void {
        switch (tabName) {
            case TabName.DETAILS:
                // already on this tab, do nothing
                // this.props.history.push(ConfigService.getFlashBaseURL() + "/" + this.state.campaignId + "/edit");
                return;
            case TabName.TARGETS:
                this.props.history.push(ConfigUtils.getFlashBaseURL() + "/" + this.state.campaignId + "/cibleLoc");
                return;
            case TabName.COMMENTS:
                this.props.history.push(ConfigUtils.getFlashBaseURL() + "/" + this.state.campaignId + "/commentaires");
                return;
            case TabName.SMS:
                this.props.history.push(ConfigUtils.getFlashBaseURL() + "/" + this.state.campaignId + "/sms");
                return;
            case TabName.IN_MAIL:
                this.props.history.push(ConfigUtils.getFlashBaseURL() + "/" + this.state.campaignId + "/inMail");
                return;
        }
    }

    onModalCancel() {
        this.setState({ modalOpen: false });
    }

    onModalConfirm() {
        this.closeCampaign();
        this.setState({ modalOpen: false });
    }

    onClickCloseCampaignBtn() {
        this.setState({ modalOpen: true });
    }

    async refresh(event: CustomEvent<RefresherEventDetail>) {
        this.setState({ ...INITIAL_STATE, campaignId: this.state.campaignId, isCreator: this.state.isCreator });
        await this.fetch(this.state.campaignId, true);
        event.detail.complete();
    }


    render() {
        const { campagneDetails, createur, campaignId } = this.state;
        let openingDate = (campagneDetails && campagneDetails.dateAdd) ? new Date(campagneDetails.dateAdd).toLocaleString('fr-FR') : null;
        const nomCreateur = createur.firstname + " " + createur.lastname;
        const numberOfMessagesSent = (campagneDetails.envoiesSms) ? campagneDetails.envoiesSms.length : 0;


        let displayedSmsTitle = "Dernier SMS";
        let displayedSmsContent = "aucun SMS envoyé";
        if (campagneDetails) {
            if (campagneDetails.envoiesSms && campagneDetails.envoiesSms[0] && campagneDetails.envoiesSms[0].text) {
                displayedSmsContent = campagneDetails.envoiesSms[0].text;
            } else if (campagneDetails.propositionSms) {
                displayedSmsTitle = "Demande de SMS";
                displayedSmsContent = campagneDetails.propositionSms;
            }
        }

        let markers: any[] | null = null;
        if (campagneDetails && campagneDetails.latitude && campagneDetails.longitude) {
            markers = [];
            const marker = {
                coords: [campagneDetails.latitude, campagneDetails.longitude],
                popupContent: null,
                onPopupClick: null,
                onPopupClickParam: null,
            }
            markers?.push(marker)
        }

        let statusActionBtn = null;
        // the button is only visible when the user is a creator
        if (campagneDetails) {
            if ((campagneDetails.status === CampaignStatus.STAND_BY) || (campagneDetails.status === CampaignStatus.DEMAND)) { // "En attente"
                statusActionBtn = <IonButton color="success" disabled={!this.state.isCreator} onClick={this.launchCampaign}>Lancer</IonButton>
            } else if (campagneDetails.status === CampaignStatus.OPEN) { // "En cours"
                statusActionBtn = <IonButton color="danger" disabled={!this.state.isCreator} onClick={this.onClickCloseCampaignBtn}>Clôturer</IonButton>
            }
        }

        return (
            <InApp
                isPrimaryLoading={this.state.isPrimaryLoading}
                isSecondaryLoading={this.state.isSecondaryLoading}
                backUrl={ConfigUtils.getFlashBaseURL()}
                title={this.state.pageTitle}
                displayMenuButton={false}
                showRightMenuBtn={true}
                rightMenu={<RightMenu />}
                refresh={this.refresh}
                outOfIonContentElement={
                    <TabBar
                        selected={TabName.DETAILS}
                        onClickTab={this.onClickTab}
                    />
                }
            >
                <GDHToast
                    show={this.state.showToast}
                    handleClose={this.handleToastClose}
                    success={this.state.successToast}
                    content={this.state.messageToast}
                />

                <Dialog
                    open={this.state.modalOpen}
                    onCancel={this.onModalCancel}
                    onConfirm={this.onModalConfirm}
                    title="Etes vous sur de vouloir clôturer cette campagne ?"
                    content="Vous ne pourrez plus adresser de  SMS à votre cible."
                />

                <IonGrid>
                    <IonRow>
                        <IonCol size="12">
                            <IonCard className="text-center flash-edit-card no-margin">
                                <IonCardHeader>
                                    <IonCardSubtitle><IonIcon icon={folderOpen} size="large"></IonIcon></IonCardSubtitle>
                                    <IonCardTitle>Campagne {campaignId} - {campagneDetails.titre}</IonCardTitle>
                                    <IonCardSubtitle>{campagneDetails.description}</IonCardSubtitle>
                                </IonCardHeader>
                            </IonCard>
                        </IonCol>
                    </IonRow>


                    <IonRow>
                        <IonCol size="6" size-md="3">

                            <IonCard className="text-center flash-edit-card no-margin">
                                <IonCardHeader>
                                    <IonCardSubtitle><IonIcon icon={business} size="large"></IonIcon><br />Résidence</IonCardSubtitle>
                                    <IonCardTitle>{this.state.residenceName}</IonCardTitle>
                                </IonCardHeader>
                            </IonCard>

                        </IonCol>
                        <IonCol size="6" size-md="3">

                            <IonCard className="text-center flash-edit-card no-margin">
                                <IonCardHeader>
                                    <IonCardSubtitle><IonIcon icon={calendar} size="large"></IonIcon><br />Date d'ouverture</IonCardSubtitle>
                                    <IonCardTitle>{openingDate}</IonCardTitle>
                                </IonCardHeader>
                            </IonCard>

                        </IonCol>
                        <IonCol size="6" size-md="3">

                            <IonCard className="text-center flash-edit-card no-margin">
                                <IonCardHeader>
                                    <IonCardSubtitle><IonIcon icon={person} size="large"></IonIcon><br />Créateur</IonCardSubtitle>
                                    <IonCardTitle>{nomCreateur}</IonCardTitle>
                                </IonCardHeader>
                            </IonCard>

                        </IonCol>

                        <IonCol size="6" size-md="3">

                            <IonCard className="text-center flash-edit-card no-margin">
                                <IonCardHeader>
                                    <IonCardSubtitle><IonIcon icon={construct} size="large"></IonIcon><br />Type</IonCardSubtitle>
                                    {
                                        campagneDetails.type &&
                                        <IonCardTitle>{campagneDetails.type.name}</IonCardTitle>
                                    }
                                </IonCardHeader>
                            </IonCard>

                        </IonCol>

                    </IonRow>

                    <IonRow>
                        <IonCol size="6" size-md="6">
                            <IonCard className="text-center flash-edit-card no-margin">
                                <IonCardHeader>
                                    <IonCardSubtitle><IonIcon icon={clipboard} size="large"></IonIcon><br />État</IonCardSubtitle>
                                    <IonCardTitle>
                                        {campagneDetails.statusLabel}<br />
                                        {statusActionBtn}
                                    </IonCardTitle>
                                </IonCardHeader>
                            </IonCard>
                        </IonCol>


                        <IonCol size="6" size-md="6">
                            <IonCard className="text-center flash-edit-card no-margin">
                                <IonCardHeader>
                                    <IonCardSubtitle><IonIcon icon={paperPlane} size="large"></IonIcon><br />Nombre de lots de sms envoyés</IonCardSubtitle>
                                    <IonCardTitle>{numberOfMessagesSent}</IonCardTitle>
                                </IonCardHeader>
                            </IonCard>
                        </IonCol>
                    </IonRow>

                    <IonRow>
                        <IonCol size="12">
                            <IonCard className="text-center flash-edit-card no-margin">
                                <IonCardHeader>
                                    <IonCardSubtitle><IonIcon icon={chatbubbles} size="large"></IonIcon><br />{displayedSmsTitle}</IonCardSubtitle>
                                    <IonCardTitle>{displayedSmsContent}</IonCardTitle>
                                </IonCardHeader>
                            </IonCard>
                        </IonCol>
                    </IonRow>

                    <IonRow>
                        <IonCol size="12">
                            <IonCard className="map-card no-margin no-margin">
                                <MapboxMap markers={markers} centerOnUniqueMarker={true} zoom={15} />
                            </IonCard>
                        </IonCol>
                    </IonRow>

                </IonGrid>
            </InApp>
        );
    }
}

export default CampaignDetails;


