import { Browser } from '@capacitor/browser';
import { IonButton } from '@ionic/react';
import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { InApp } from '../../../../components/in-app/in-app';
import RightMenu from '../../../../components/menu/right-menu/right-menu';
import { TextInputItem } from '../../../../components/text-input/text-input';
import DataService from '../../../../services/data-service';
import './docusign-home.css'
import { useToast } from '../../../../hooks/useToast';
import GDHToast from '../../../../components/toast/toast';
import { Utils } from '../../../../utils/utils';
import { DocusignService } from '../../../../services/docusign-service';
import { StorageUtils } from '../../../../utils/storage-utils';
import ConfigUtils from '../../../../utils/config-utils';
import { SmbGatewayApiService } from '../../../../services/smb-gateway-api-service';
import { InputChangeEventDetail } from '@ionic/core/dist/types/components/input/input-interface';

const CODE_PREFIX = '?code='
const REDIRECT_URI = ConfigUtils.getFrontendUrl() + 'docusign/home';
const CLIENT_ID = '408a2efb-bf5e-43f8-8ed4-2f0f386df294';
const DOCUSIGN_AUTH_BASE_URL = ConfigUtils.getDocusignAuthBaseUrl()
const URL_TO_CALL = `${DOCUSIGN_AUTH_BASE_URL}?response_type=code&scope=signature&client_id=${CLIENT_ID}&redirect_uri=${REDIRECT_URI}`;


export function DocusignHome(props: any) {

    const _location = useLocation();
    const _history = useHistory();

    const onCloseToast = (success: boolean) => {
        if (success) {
            _history.push('/home')
        }
    }

    const [_showToast, , _successToast, , _contentToast, , _handleCloseToast, _activateToast] = useToast(onCloseToast);
    const [_isPrimaryLoading, _setIsPrimaryLoading] = useState<boolean>(false);
    const [_username, _setUsername] = useState<string>("");
    const [_availableApplicants, _setAvailableApplicants] = useState<string[]>([]);
    const [_filteredApplicants, _setFilteredApplicants] = useState<string[]>([]);
    const [_searchQuery, _setSearchQuery] = useState<string>("");
    const [_applicantsFetched, _setApplicantsFetched] = useState<boolean>(false);
    const [_selectedApplicant, _setSelectedApplicant] = useState<string | null>(null);

    const filterApplicant = (searchQuery: string) => {
        const searchQueryLowerCase = searchQuery.toLowerCase()
        return _availableApplicants.filter((applicant: string) => {
            return applicant.toLowerCase().includes(searchQueryLowerCase)
        })
    }

    const callDocusign = async () => {
        // this shall activate only when arriving on this page from Docusign redirection and thus with the urlCode in the url
        if (!_location.search.includes(CODE_PREFIX)) {
            return;
        }

        const code = _location.search.replace(CODE_PREFIX, '');

        _setIsPrimaryLoading(true)

        try {
            const userId: string | null = await StorageUtils.getInstance().getUserId();
            if (!userId) {
                console.error('No user ID')
                _activateToast(false, 'Une erreur s\'est produite')
                return
            }

            const docusignFormStr = await StorageUtils.getInstance().getDocusignForm() as any
            const docusignForm = JSON.parse(docusignFormStr)
            const users = await DataService.getEmployeeInfo(userId)


            await DocusignService.createEnvelope(docusignForm.applicantFullname,
                docusignForm.applicantNumber,
                Utils.capitalizeUsername(users[0]?.firstname ?? '', users[0]?.lastname ?? ''),
                code)

            _activateToast(true, 'L\'enveloppe a été créée')
        } catch (error) {
            console.error(error)
            _activateToast(false, 'Un erreur inconnue est survenue')
        }

        _setIsPrimaryLoading(false)
    }

    useEffect(() => {
        callDocusign();
        // FIXME : this is probably not the best way to do this
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getUsername = async () => {
        const userId: string | null = await StorageUtils.getInstance().getUserId();
        if (userId) {
            try {
                const users = await DataService.getEmployeeInfo(userId);
                const username = users[0].firstname + ' ' + users[0].lastname?.toUpperCase();
                _setUsername(username);
            } catch (error) {
                console.error(error);
            }
        }
    }

    useEffect(() => {
        getUsername();
    }, []);

    useEffect(() => {
        _setFilteredApplicants(filterApplicant(_searchQuery));
    }, [_availableApplicants])

    const fetchApplicantsList = async () => {
        try {
            let availableApplicants = await SmbGatewayApiService.listUserDirectoryContent(_username);
            _setAvailableApplicants(availableApplicants)
            _setApplicantsFetched(true)
        } catch (error) {
            console.error(error)
        }
    }

    useEffect(() => {
        if (_username) {
            fetchApplicantsList()
        }
    }, [_username])

    const onChangeSearchQuery = (event: CustomEvent<InputChangeEventDetail>) => {
        const fieldContent = event?.detail?.value;

        if (fieldContent === undefined || fieldContent === null) {
            return;
        }

        _setSearchQuery(fieldContent);
    }

    useEffect(() => {
        _setFilteredApplicants(filterApplicant(_searchQuery));
    }, [_searchQuery])

    useEffect(() => {
        if (!_filteredApplicants.some((applicant) => applicant === _selectedApplicant)) {
            _setSelectedApplicant(null)
        }
    }, [_filteredApplicants])

    const onClick = async () => {
        // check all input fields
        if (!_selectedApplicant) {
            _activateToast(false, 'Veuillez choisir un demandeur')
            return
        }

        _setIsPrimaryLoading(true)

        const [applicantNumber, applicantFullname] = _selectedApplicant.split("-")

        if (!applicantNumber || !applicantFullname) {
            _activateToast(false, "Nom ou numero du cancidat invalide")
            return
        }

        const docusignForm = {
            applicantFullname,
            applicantNumber
        }

        await StorageUtils.getInstance().setDocusignForm(JSON.stringify(docusignForm))

        Browser.open({ url: URL_TO_CALL, windowName: '_self' });
    }

    const onClickApplicant = (applicant: string) => {
        _setSelectedApplicant(applicant)
    }

    return (
        <InApp
            isPrimaryLoading={_isPrimaryLoading}
            isSecondaryLoading={false}
            backUrl=""
            title="Docusign - créer une enveloppe"
            displayMenuButton={true}
            showRightMenuBtn={true}
            rightMenu={<RightMenu />}
            refresh={null}
        >
            <GDHToast
                show={_showToast}
                handleClose={_handleCloseToast}
                success={_successToast}
                content={_contentToast}
            />

            <TextInputItem
                className="gdh-item-input digital-attribution-home-item-input gdh-top-label-item-input gdh-top-label-item"
                handleChangeInput={onChangeSearchQuery}
                value={_searchQuery}
                label="Rechercher"
                required={true}
                isArea={false}
                textLineProps={{ placeholder: "" }}
            />

            {
                (!(_applicantsFetched && _availableApplicants.length > 0 && _filteredApplicants.length === 0)) &&
                <div className="applicants-list-container" >
                    {
                        (!_applicantsFetched) &&
                        <div className="paper paper-last paper-no-hover" >Téléchargement des candidatures...</div>
                    }
                    {
                        (_applicantsFetched && _availableApplicants.length === 0) &&
                        <div className="paper paper-last paper-no-hover" >Aucune candidature disponible</div>
                    }
                    {
                        (_applicantsFetched && _availableApplicants.length !== 0) &&
                        _filteredApplicants.map((applicant: string) => {
                            let className = "paper "
                            className += (applicant === _filteredApplicants[_filteredApplicants.length - 1]) ? "paper-last " : "";
                            className += (applicant === _selectedApplicant) ? "selected-applicant " : "";

                            return (<div key={applicant} onClick={() => onClickApplicant(applicant)} className={className} >
                                {applicant}
                            </div>)
                        })
                    }
                </div>
            }

            <div className="digital-report-button-container">
                <IonButton disabled={_selectedApplicant === null} onClick={onClick} >Créer une enveloppe</IonButton>
            </div>
        </InApp>
    )
}
