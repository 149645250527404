import { createStore, combineReducers } from 'redux';
import { NotificationData } from '../interfaces/notification-data';

type userReducerStateType = {
	logedin: boolean | undefined
}


export const ACTION_CONNECT = "ACTION_CONNECT";
export const ACTION_DISCONNECT = "ACTION_DISCONNECT";

const userReducer = (state: userReducerStateType = { logedin: undefined }, action: any) => {
	switch (action.type) {
		case ACTION_CONNECT:
			return { logedin: true };
		case ACTION_DISCONNECT:
			return { logedin: false };
		default:
			return state;
	}
}

export const getUserState = (state: any) => state.user.logedin;


export const ACTION_SET_ENABLED_APPS = "ACTION_SET_ENABLED_APPS";
export const ACTION_CLEAR_ENABLED_APPS = "ACTION_CLEAR_ENABLED_APPS";

const enabledAppsReducer = (state: { apps: string[] | undefined } = { apps: undefined }, action: any) => {
	switch (action.type) {
		case ACTION_SET_ENABLED_APPS:
			return { apps: action.payload };
		case ACTION_CLEAR_ENABLED_APPS:
			return { apps: undefined };
		default:
			return state;
	}
}

// TODO : corriger : renvoyer .apps
export const getEnabledAppsState = (state: any) => state.enabledApps;





/*
 * Notifications store
 */



type NotificationsReducerState = {
	notifications: NotificationData[],
	newNotificationsCount: number
}

type NotificationsReducerAction = {
	type: string,
	payload: {
		notifications: NotificationData[],
		newNotificationsCount: number
	}
}

const INITIAL_STATE = { notifications: [], newNotificationsCount: 0 };

export const ACTION_SET_NOTIFICATIONS = "ACTION_SET_NOTIFICATIONS";
export const ACTION_ADD_NOTIFICATION = "ACTION_ADD_NOTIFICATION";
export const ACTION_SET_NEW_NOTIFICATIONS_COUNT = "ACTION_SET_NEW_NOTIFICATIONS_COUNT";

export const notificationsReducer = (state: NotificationsReducerState = INITIAL_STATE,
	action: NotificationsReducerAction | any): NotificationsReducerState => {
	switch (action.type) {
		case ACTION_SET_NOTIFICATIONS:
			return { notifications: action.payload.notifications, newNotificationsCount: state.newNotificationsCount };
		case ACTION_ADD_NOTIFICATION:
			return { notifications: [...state.notifications, action.payload.notifications], newNotificationsCount: state.newNotificationsCount };
		case ACTION_SET_NEW_NOTIFICATIONS_COUNT:
			return { newNotificationsCount: action.payload.newNotificationsCount, notifications: state.notifications };
		default:
			return state;
	}
}

export const getNotifications = (state: any): NotificationData[] => state.notifications.notifications;
export const getNewNotificationsCount = (state: any): number => state.notifications.newNotificationsCount;




export const ReduxStore = createStore(combineReducers({
	user: userReducer,
	enabledApps: enabledAppsReducer,
	notifications: notificationsReducer
}));
