import React, { Component } from 'react';

import './comment.css';
import { CommentData } from '../../interfaces/comment-data';
import MessageCard from '../message-card/message-card';
import DataService from '../../services/data-service';


type CommentProps = {
    comment: CommentData
}

const INITIAL_STATE = {
    avatarBase64: null
}

class Comment extends Component<CommentProps, any> {

    constructor(props: CommentProps) {
        super(props);

        this.state = { ...INITIAL_STATE };
    }


    async componentDidMount() {
        // if an avatar is defined for this user
        if (this.props.comment.user.avatar) {
            try {
                const avatarBase64 = await DataService.getMediaBase64(this.props.comment.user.avatar.id);
                this.setState({ avatarBase64: avatarBase64 });
            } catch (error) {
                console.error(error);
            }
        }
    }

    render() {
        let dateTime = new Date(this.props.comment.dateUpd).toLocaleString('fr-FR');
        let fullName = this.props.comment.user.firstname + " " + this.props.comment.user.lastname;
        return (
            <MessageCard
                subTitle={fullName}
                dateTime={dateTime}
                title={null}
                avatarBase64={this.state.avatarBase64}
                senderFirstname={this.props.comment?.user?.firstname}
                senderLastname={this.props.comment?.user?.lastname}
                content={this.props.comment.contenu}
            ></MessageCard>
        );
    }
}

export default Comment;
