/**
 * This class is the mother class for all Buisness objects that are downloaded via the http API
 * T generic represents the parameters needed to download the data
 * U generic represents the type of data returned by the API
 *
 */
export abstract class Buisness<T, U> {

    data?: U;

    protected initialized: boolean;

    /**
     * The class contructor
     * @constructor
     *
     */
    protected constructor() {
        this.initialized = false;
    }

    /**
     * The initialize class that download and sanitize data
     * @param params needed to download the data from server, is of type T
     */
    async initialize(params: T): Promise<boolean> {
        if (this.data) {
            this.initialized = true;
            return true;
        }
        try {
            this.data = await this.fetch(params);
            this.data = this.sanitize();
            this.initialized = true;
            return true;
        } catch (error) {
            console.error("Unable to initialize", error);
        }
        this.initialized = false;
        return false;
    }

    /**
     * abstract method that shall be overloaded for every child, with the correct call to DataService
     *
     * @param params needed to download the data from server, is of type T
     */
    protected abstract fetch(params: T): Promise<U>;

    /**
     * The default sanityze function that does nothing
     *
     */
    protected sanitize(): U | undefined {
        return this.data;
    }

    /**
     * Clear data, and reset init flag
     *
     */
    public clear(): void {
        this.initialized = false;
        this.data = undefined;
    }

}
