import React from "react";
import { IonCard, IonCardHeader, IonCardSubtitle, IonCardTitle, IonCardContent } from "@ionic/react";

import './message-card.css'
import { Avatar } from "../avatar/avatar";


type MessageCardProps = {
    title: string | null,
    subTitle: string | null,
    content: string | null,
    dateTime: any,
    avatarBase64?: string,
    senderFirstname?: string,
    senderLastname?: string
}

class MessageCard extends React.Component<MessageCardProps, any> {

    render() {
        return (
            <IonCard>
                <IonCardHeader>
                    <IonCardSubtitle className="comment-user">{this.props.subTitle}</IonCardSubtitle>
                    {
                        this.props.title &&
                        <IonCardTitle >{this.props.title}</IonCardTitle>
                    }
                </IonCardHeader>
                <IonCardContent className="message-card-content">
                    <Avatar
                        avatarBase64={this.props.avatarBase64}
                        firstname={this.props.senderFirstname}
                        lastname={this.props.senderLastname}
                        className="message-card-content-avatar"
                    />

                    <div className="message-card-content-text" >
                        {this.props.content}
                        <div className="ion-text-right comment-date">
                            {this.props.dateTime}
                        </div>
                    </div>
                </IonCardContent>
            </IonCard>
        )
    }
}

export default MessageCard;