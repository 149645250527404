import { CampaignData } from "../interfaces/campaign-data";

export enum CampaignStatus {
    STAND_BY = 0,
    VALIDATED = 1,
    CLOSE = 2,
    OPEN = 3,
    DEMAND = 4
}


export function getCampaignStatusLabel(campaignStatus: CampaignStatus): string {
    switch (campaignStatus) {
        case CampaignStatus.OPEN:
            return "En cours";
        case CampaignStatus.CLOSE:
            return "Clôturer";
        case CampaignStatus.STAND_BY:
            return "En attente";
        case CampaignStatus.VALIDATED:
            return "Validée";
        case CampaignStatus.DEMAND:
            return "Demande";
    }
}

export function countCampaignsInEachState(campaigns: CampaignData[]): {
    standByCampaigns: number,
    validatedCampaigns: number,
    closeCampaigns: number,
    openCampaigns: number
} {

    let closeCampaigns = 0, openCampaigns = 0, standByCampaigns = 0, validatedCampaigns = 0;
    if (!campaigns || !Array.isArray(campaigns)) {
        return { standByCampaigns: 0, validatedCampaigns: 0, closeCampaigns: 0, openCampaigns: 0 };
    }

    for (let campaign of campaigns) {
        if (!campaign || (campaign.status == null)) {
            continue;
        }

        switch (campaign.status) {
            case CampaignStatus.STAND_BY:
                standByCampaigns++;
                break;
            case CampaignStatus.VALIDATED:
                validatedCampaigns++;
                break;
            case CampaignStatus.CLOSE:
                closeCampaigns++;
                break;
            case CampaignStatus.OPEN:
                openCampaigns++;
                break;
        }
    }
    return { standByCampaigns, validatedCampaigns, closeCampaigns, openCampaigns };
}
