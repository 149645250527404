import React from 'react';
import Button from '@mui/material/Button';
import MuiDialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

type DialogProps = {
    open: boolean,
    title: string,
    content?: React.ReactNode,
    onConfirm?: () => void,
    onCancel?: () => void,
    showCancelButton?: boolean,
    cancelButtonText?: string,
    showTitleInRed?: boolean,
}

export function Dialog(props: DialogProps) {
    return (
        <MuiDialog
            open={props.open}
            onClose={props.onCancel}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title" className={props.showTitleInRed ? "digital-report-color-red" : ""}>{props.title}</DialogTitle>
            {
                props.content &&
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {props.content}
                    </DialogContentText>
                </DialogContent>
            }
            <DialogActions>
                {
                    (props.showCancelButton === undefined || props.showCancelButton) &&
                    <Button onClick={props.onCancel} color="primary" autoFocus>
                        {props.cancelButtonText ?? "Annuler"}
                    </Button>
                }
                <Button onClick={props.onConfirm} color="primary">
                    Confirmer
                </Button>
            </DialogActions>
        </MuiDialog>
    )
}