import { LatLngTuple } from 'leaflet';
import { Feature } from 'geojson'

export type MapProps = {
    markers?: MarkerData[] | null,
    zoom?: number,
    centerOnUniqueMarker?: boolean
}

export type MarkerData = {
    id: number,
    coords: LatLngTuple,
    popupContent: any,
    onPopupClick: any,
    onPopupClickParam: any
}

export const DEFAULT_ZOOM = 6;
export const DEFAULT_CENTER: LatLngTuple = [44, 5];


export function getMapCenter(mapProps: MapProps): LatLngTuple {
    if (mapProps.centerOnUniqueMarker
        && (mapProps.markers)
        && (mapProps.markers.length === 1)) {
        return mapProps.markers[0].coords;
    } else {
        return DEFAULT_CENTER;
    }
}



export function invertCoords(coords: LatLngTuple): LatLngTuple {
    return [coords[1], coords[0]];
}


export function markerDataToGeoJson(marker: MarkerData): Feature {
    return {
        type: "Feature",
        geometry: {
            type: "Point",
            coordinates: [marker.coords[1], marker.coords[0]]
        },
        properties: {
            id: marker.id
        }
    } as Feature
}