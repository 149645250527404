import React, { useEffect, useRef, useState } from 'react';
import {
    IonMenu,
    IonContent,
    IonList,
} from '@ionic/react';
import { MenuElement } from '../menu-element/menu-element';
import { Browser } from '@capacitor/browser';

import logo from '../../../assets/logo-gd-apps.svg';
import { Applications } from '../../../buisness/applications';
import '../menu.css';
import './left-menu.css';
import { ApplicationsGroupData } from '../../../interfaces/application-data';
import { useHistory } from "react-router";

export function LeftMenu() {

    const [_menus, _setMenus] = useState<any[]>([])
    const _history = useHistory();
    const _ref = useRef<any>(null);

    const componentMounted = async () => {
        await Applications.getInstance().initialize();
        let applications: ApplicationsGroupData[] = Applications.getInstance().hostsToPlatformApplications();

        /*
         *  /!\ HERE IT IS HARDCODED THAT THE ACCEUIL_GROUP SHALL NOT BE DISPLAYED
         */
        applications = applications.map((appGroup: ApplicationsGroupData) => {
            if ((appGroup.name === "Accueil_group") && appGroup.children && (appGroup.children[0])) {
                return appGroup.children[0];
            }
            return appGroup;
        })

        _setMenus(applications);
    }


    useEffect(() => {
        componentMounted()
    }, [])

    const onClickItem = async (itemCode: string) => {

        // close the menu before redirecting to avoid an ionic crash
        // the second parameter is set to false to avoid the menu to be animated
        const success = await _ref.current?.setOpen(false, false)
        if (!success) {
            console.error("the menu could not be closed")
            return
        }

        let clickedItem: any
        // for each menu group
        _menus.every((element: any) => {

            // check if the element is a link eg. the acceuil btn
            if (element?.code === itemCode) {
                clickedItem = element
                return false
            }

            // if the group has children
            if (element?.children?.length > 0) {
                // we search if the clicked item is in the children
                clickedItem = element.children.find((child: any) => {
                    return (child?.code === itemCode)
                })
                if (clickedItem) {
                    // if we found the element, return false to stop the loop
                    return false
                }
            }
            // return true to continue the loop
            return true
        })

        if (!clickedItem) {
            console.error("the clicked element was not found, code : ", itemCode)
            return
        }

        // this callback is used to do some action before the redirection. Like for exemple logout the user when redirected to the signin page
        if (clickedItem.beforeRedirectCallback) {
            await clickedItem.beforeRedirectCallback();
        }

        if (clickedItem.indicAppInterne === false) {
            Browser.open({ url: clickedItem.url, windowName: '_blank' });
        } else {
            _history.push(clickedItem.url);
        }
    }

    return (
        <IonMenu ref={_ref} contentId="main" type="overlay">
            <div className="menu-logo-container">
                <img src={logo} className="left-menu-logo" alt="Logo GD apps" />
            </div>
            <IonContent>
                <IonList class="left-menu-list">
                    {
                        _menus &&
                        _menus.map((element: any, index: any) => {
                            return (
                                <MenuElement key={index} data={{ ...element, depth: 0, onClickItem: onClickItem }} />
                            )
                        })
                    }
                </IonList>
            </IonContent>
        </IonMenu>
    )
}
