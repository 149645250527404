import React from 'react';
import { MenuGroup } from '../menu-group/menu-group';
import MenuItem from '../menu-item/menu-item';


type MenuElementProps = {
    data: Data
}

type Data = {
    name: string,
    children?: MenuElementProps[],
    url?: string,
    indicAppInterne?: boolean,
    faIcon?: string,
    depth: number,
    itemClassName?: string,
    listClassName?: string,
    onClickItem?: (code: string) => void,
    code?: string,
}


// on first call, MenuElement shall be called with a depth of 0
export function MenuElement(props: MenuElementProps) {
    return (
        props.data.children ?
            <MenuGroup
                name={props.data.name.toLocaleUpperCase()}
                faIcon={props.data.faIcon}
                depth={props.data.depth}
                itemClassName={props.data.itemClassName}
                listClassName={props.data.listClassName}
                onClick={props.data.onClickItem}
            >
                {
                    props.data.children.map((element: any, index: any) => {
                        return (
                            <MenuElement key={index} data={{ ...element, depth: props.data.depth + 1, itemClassName: props.data.itemClassName, listClassName: props.data.listClassName, onClickItem: props.data.onClickItem }} />
                        )
                    })
                }
            </MenuGroup>
            :
            <MenuItem
                name={props.data.name.toLocaleUpperCase()}
                indicAppInterne={props.data.indicAppInterne}
                url={props.data.url}
                faIcon={props.data.faIcon}
                depth={props.data.depth}
                itemClassName={props.data.itemClassName}
                onClick={props.data.onClickItem}
                code={props.data.code}
            >
            </MenuItem>
    )
}