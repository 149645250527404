import React from 'react';
import { IonBackdrop, IonSpinner } from '@ionic/react';


import './full-page-loader.css';


export function FullPageLoader() {
    return (
        <>
            <IonBackdrop className="full-page-loader-backdrop" />
            <IonSpinner className="backdrop-spinner" name="crescent" />
        </>
    )
}