// load external libs
import React from "react";
import {
    IonGrid,
    IonRow,
    IonCol
} from "@ionic/react";

// load internal libs
import { WebQueryCard } from "../../../../components/web-query-card/web-query-card";
import DataService from "../../../../services/data-service";
import { WebquerySearchBar } from "../../../../components/applications/webquery/search-bar/webquery-search-bar";
import { WebqueryRightMenu } from "../../../../components/applications/webquery/right-menu/webquery-right-menu";
import { InApp } from "../../../../components/in-app/in-app";
import { WebqueryData } from "../../../../interfaces/webquery-data";

// import external css files

// import internal css files


type State = {
    isLoading: boolean,
    categories: { name: string, id: number }[]
}



const INITIAL_STATE: State = {
    isLoading: false,
    categories: []
}



export class WebqueryCategories extends React.Component<any, State> {


    constructor(props: any) {
        super(props);

        this.state = { ...INITIAL_STATE };

        this.handleClick = this.handleClick.bind(this);
        this.onClickSearchBarElement = this.onClickSearchBarElement.bind(this);
        this.fetchData = this.fetchData.bind(this);
    }

    componentDidMount() {
        this.fetchData();
    }

    async fetchData() {
        this.setState({ isLoading: true });

        try {
            let webqueries: WebqueryData[] = await DataService.getWebQueries();

            const categories: { name: string, id: number }[] = [];

            webqueries.forEach((webquery: any) => {
                let categoryData = webquery.categories[0];
                // check if this element has already been add, by checking the added names
                if (!categories.some((categorie: any) => (categorie.name === categoryData.name))) {
                    // add the category only once
                    categories.push({
                        name: categoryData.name,
                        id: categoryData.id
                    })
                }
            })

            this.setState({
                categories: categories
            });

        } catch (error) {
            console.error(error);
        }

        this.setState({ isLoading: false });
    }

    // when a card is clicked, go to the corresponding extraction page
    handleClick(id: number) {
        this.props.history.push("/webquery/category/" + id)
    }

    onClickSearchBarElement(webqueryId: number) {
        this.props.history.push("/webquery/extraction/" + webqueryId);
    }

    render() {
        return (
            <InApp
                isPrimaryLoading={false}
                isSecondaryLoading={this.state.isLoading}
                backUrl="/webquery/home"
                title={"Webquery - Catégories"}
                displayMenuButton={true}
                showRightMenuBtn={true}
                rightMenu={<WebqueryRightMenu />}
                refresh={null}
                headerChildren={<WebquerySearchBar onClickElement={this.onClickSearchBarElement} />}
            >
                <IonGrid>
                    <IonRow>

                        {
                            this.state.categories.map((category: { name: string, id: number }) => {
                                return (
                                    <IonCol size="12" size-md="4" size-xl="4" key={category.id}>
                                        <WebQueryCard
                                            className="no-margin"
                                            onClick={this.handleClick}
                                            id={category.id}
                                            title={category.name}
                                            subtitle={"sous titre"}
                                            description={"description"}
                                            note=""
                                        />
                                    </IonCol>
                                )
                            })
                        }

                    </IonRow>
                </IonGrid>
            </InApp>
        )
    }
}
