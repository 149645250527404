import { useEffect } from "react";

// this hooks allows to be triggered only once, when the compoenent is mounted.
// And it also allows to silence the warning about missing useEffect dependancies
export const useMount = (fn: () => void) => {
    useEffect(() => {
        fn();
        // eslint-disable-next-line
    }, []);
};
