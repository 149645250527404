import { IonButton, IonItem, IonLabel, IonReorder, IonReorderGroup } from '@ionic/react';
import React, { useState } from 'react';
import { InApp } from '../../../../components/in-app/in-app';
import RightMenu from '../../../../components/menu/right-menu/right-menu';
import { ItemReorderEventDetail } from '@ionic/core/dist/types/components/reorder-group/reorder-group-interface'


import GDHToast from '../../../../components/toast/toast';
import { DragAndDrop } from '../../../../components/drag-and-drop/drag-and-drop';
import { FileInputButton } from '../../../../components/file-input-button/file-input-button';
import { PdfUtils } from '../../../../utils/pdf-utils';
import { PdfService } from '../../../../services/pdf-service';
import { SEVERAL_FILES_WITH_SAME_NAME } from '../../../../utils/constants';
import { useHistory } from 'react-router-dom';
import { StorageUtils } from '../../../../utils/storage-utils';
import DataService from '../../../../services/data-service';


const CANNOT_MERGE_LESS_THAN_TWO_FILES_MSG = "Il est impossible de fusionner moins de deux documents";
const MERGE_SUCCESS_MSG = "La fusion est finie. Vous pouvez consulter le fichier";
const MERGE_ERROR_MSG = "Un erreur s'est produite pendant la fusion des fichiers";
const LOG_URL = "https://www.granddelta.fr/import/0447-logUtilisationILovePDF.php";


// FIXME : this file is a duplicate of the pdfMergeFile

/*
 * This componenent handles the PDF merge page
 */
export function PdfConvertAndMerge(props: any) {

    const [_files, _setFiles] = useState<File[]>([]);
    const [_showToast, _setShowToast] = useState<boolean>(false);
    const [_success, _setSuccess] = useState<boolean>(false);
    const [_toastMessage, _setToastMessage] = useState<string>("");
    const [_isPrimaryLoading, _setIsPrimaryLoading] = useState<boolean>(false);
    const [_openButtonEnabled, _setOpenButtonEnabled] = useState<boolean>(false);

    const _history = useHistory();

    /*
     * This fuctions is the handler for click event on merge button
     */
    const onClickMergeButton = async () => {
        if (_files.length < 2) {
            // cannot merge less than two files
            _setToastMessage(CANNOT_MERGE_LESS_THAN_TWO_FILES_MSG);
            _setSuccess(false);
            _setShowToast(true);
            return;
        }
        _setOpenButtonEnabled(false);
        _setIsPrimaryLoading(true);
        let toastMessage;
        let success;
        try {
            await PdfService.convertAndMerge(_files);
            const userId: string | null = await StorageUtils.getInstance().getUserId()
            if (userId) {
                const users = await DataService.getEmployeeInfo(userId)
                let actualUser = null

                if (users.length > 0) {
                    actualUser = users[0]
                    let filesString: any[] = []
                    _files.filter((file: any) => {
                        filesString.push(file.name)
                    })
                    const logApiResponse = await fetch(LOG_URL + "?ACTION=convert-and-merge&USER=" + actualUser?.username + "&COUNT=" + _files.length + "&FILES=" + filesString.join(" / "))
                    console.log(logApiResponse.status)
                    if (logApiResponse.status !== 200) {
                        console.log("Impossible de joindre l'API de log, Status : " + logApiResponse.status);
                    }
                }
            }
            toastMessage = MERGE_SUCCESS_MSG;
            success = true;
        } catch (error) {
            console.log(error);
            toastMessage = MERGE_ERROR_MSG;
            success = false;
        }
        _setOpenButtonEnabled(success);

        _setToastMessage(toastMessage);
        _setSuccess(success);
        _setShowToast(true);
        _setIsPrimaryLoading(false);
    }

    /*
     * This functions is the handler for click event on open button
     */
    const onClickOpenButton = () => {
        PdfService.open();
    }

    /*
     * This functions is the handler for click event on open in digital attributions button
     */
    const onClickOpenInDigitalAttributionButton = () => {
        _history.push('/digital-attribution/home');
    }

    /*
     * This fuctions is the handler for reorder events.
     */
    const onReordered = (event: CustomEvent<ItemReorderEventDetail>) => {
        const from = event.detail.from;
        const to = event.detail.to;

        // swap elements in array
        const files = _files;
        files.splice(to, 0, files.splice(from, 1)[0]);
        _setFiles(files);
        // complete function must be called to terminate the reorder event
        event.detail.complete();
    }

    /*
     * This function is called when one or several new files are imported
     */
    const onInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (!event?.target?.files || !event.target.files[0]) {
            return;
        }

        const filesArray: File[] = Object.values(event.target.files);

        addFilesToList(filesArray);
    }

    /*
     * Check if there is no several occurence of the same file and add files to the selected files
     */
    const addFilesToList = (filesToAdd: File[]) => {
        // check that the selected files do not contain two files with the same name
        let nbOccurences = PdfUtils.countMaxOccurenceOfFileName(filesToAdd, filesToAdd);
        // check for length superior to 1 because it will at least match with itself
        if (nbOccurences > 1) {
            // show a fail toast and return
            showSeveralFilesWithSameNamesToast();
            return;
        }

        // check that the selected files, and the previously selected files do not contain two files with the same name
        nbOccurences = PdfUtils.countMaxOccurenceOfFileName(filesToAdd, _files);
        // check for length superior to one because it shall not match once
        if (nbOccurences > 0) {
            // show a fail toast and return
            showSeveralFilesWithSameNamesToast();
            return;
        }

        _setFiles([..._files, ...filesToAdd]);
    }

    /*
     * Show a several files with same name error toast
     */
    const showSeveralFilesWithSameNamesToast = () => {
        _setToastMessage(SEVERAL_FILES_WITH_SAME_NAME);
        _setSuccess(false);
        _setShowToast(true);
    }

    /*
     * The handler for click on the delete buton of each file
     */
    const onClickDeleteFile = (filename: string) => {
        const remainingFiles = _files.filter((file: any) => {
            return file.name !== filename;

        })
        _setFiles(remainingFiles);
    }

    /*
     * Handler for toas close event
     */
    const handleToastClose = () => {
        _setShowToast(false);
    }

    /*
     * Handler for droped file error
     */
    const onDropedFileError = (errorMsg: string) => {
        _setShowToast(true);
        _setSuccess(false);
        _setToastMessage(errorMsg);
    }

    /*
     * Handler for droped file success
     */
    const onDropedFiles = (files: File[]) => {
        addFilesToList(files);
    }

    /*
     * Render the compoenent
     */
    return (
        <InApp
            isPrimaryLoading={_isPrimaryLoading}
            isSecondaryLoading={false}
            backUrl="/PDF/home"
            title="PDF Utils - Convertir et fusionner"
            displayMenuButton={false}
            showRightMenuBtn={true}
            rightMenu={<RightMenu />}
            refresh={null}
        >
            <div className="pdf-merge-container">
                <div className="pdf-merge-button-container">
                    <FileInputButton
                        onInputChange={onInputChange}
                        acceptedExtentions=".pdf,.png,.jpg,.jpeg,.doc,.docx"
                    />
                </div>



                <DragAndDrop
                    acceptedExtention={["pdf", "png", "jpg", "jpeg", "doc", "docx"]}
                    errorCallback={onDropedFileError}
                    isEmpty={_files.length === 0}
                    dropedFilesCallback={onDropedFiles}
                >
                    <IonReorderGroup disabled={false} onIonItemReorder={onReordered}>
                        {
                            _files.map((file: any) => {
                                return (
                                    <IonItem key={file.name}>
                                        <IonLabel>{file.name}</IonLabel>
                                        <IonReorder slot="start" />
                                        <div slot="end" onClick={() => onClickDeleteFile(file.name)} className="pdf-merge-delete-button-container">
                                            <i className="far fa-trash-alt"></i>
                                        </div>
                                    </IonItem>
                                )
                            })
                        }
                    </IonReorderGroup>
                </DragAndDrop>

                <div className="pdf-merge-button-container">
                    <IonButton disabled={_files.length < 2} expand="block" onClick={onClickMergeButton}>
                        Convertir et fusionner
                    </IonButton>
                    <IonButton disabled={!_openButtonEnabled} expand="block" onClick={onClickOpenButton}>
                        Ouvrir
                    </IonButton>
                    <IonButton disabled={!_openButtonEnabled} expand="block" onClick={onClickOpenInDigitalAttributionButton}>
                        Vers Attributions digitalisées
                    </IonButton>
                </div>
            </div>


            <GDHToast
                show={_showToast}
                handleClose={handleToastClose}
                success={_success}
                content={_toastMessage}
            />
        </InApp>
    )
}
