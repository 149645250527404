import { ApplicationsGroupData } from "../interfaces/application-data";
import { HostData } from "../interfaces/host-data";
import DataService from "../services/data-service";
import ConfigUtils from "../utils/config-utils";
import { IonicUtils } from "../utils/ionic-utils";
import { Buisness } from "./buisness";


/**
 * Defines available local applications
 *
 */
const AVAILABLE_APPS = [
    {
        baseUrl: ConfigUtils.getFlashBaseURL(),
        name: 'FLASH'
    },
    {
        baseUrl: ConfigUtils.getWebqueryBaseURL(),
        name: 'WEBQUERY'
    },
    {
        baseUrl: '/PDF',
        name: 'PDF'
    },
    {
        baseUrl: '/payment',
        name: 'PAYMENT'
    },
    {
        baseUrl: '/digital-report',
        name: 'DIGITAL-REPORT'
    },
    {
        baseUrl: '/digital-attribution',
        name: 'DIGITAL-ATTRIB'
    },
    {
        baseUrl: '/docusign',
        name: 'DOCUSIGN'
    },
    {
        baseUrl: '/CTI',
        name: 'CTI'
    },
    {
        baseUrl: '/home',
        name: 'HOME'
    },
]

/**
 * Application buisnes class, retrieve applications data from server and serves it to the rest of the app. This class is a singleton
 *
 */
export class Applications extends Buisness<void, HostData[]> {

    static s_instance?: Applications;

    /**
     * Get the only static instance of this class
     *
     */
    static getInstance(): Applications {
        if (!Applications.s_instance) {
            Applications.s_instance = new Applications();
        }
        return Applications.s_instance;
    }

    /**
     * Overload parent function to retrieve server data
     *
     */
    protected fetch() {
        return DataService.getAppsV2(true);
    }

    /**
     * Convert data from server to a more consitent format
     *
     */
    hostsToApplications(): ApplicationsGroupData[] {
        if (!this.initialized) {
            console.error("Applications is not initialized");
            return [];
        }


        const applications: ApplicationsGroupData[] = [];

        if (!this.data) {
            return applications;
        }

        for (let hostElement of this.data) {
            let menuElement;
            menuElement = {
                name: hostElement.name,
                children: hostElement.urls,
                faIcon: hostElement.faIcon,
                position: hostElement.position
            }
            applications.push(menuElement);
        }

        return applications;
    }

    /**
     * Returns applications for the current platform
     *
     */
    hostsToPlatformApplications(): ApplicationsGroupData[] {
        const platform = IonicUtils.isMobile() ? "mobile" : "web";
        let applications = this.hostsToApplications();

        // only keep urls for this device
        for (let app of applications) {
            if (app.children) {
                app.children = app.children.filter((child) => child.devices?.some((device) => device === platform))
            }
        }

        // only keep top level application with an url or at least one child
        applications = applications.filter((app) => {
            return (app.url || (app.children && app.children.length > 0))
        })

        applications.sort((a: ApplicationsGroupData, b: ApplicationsGroupData) => {
            // order by smaller position to bigger
            return (a.position ?? 0) - (b.position ?? 0);
        })

        return applications;
    }

    /**
     * Returns all local urls. i.e. urls with the wan flag
     *
     */
    public getLocalUrls(): string[] {
        if (!this.data) {
            console.warn("data is not initialized");
            return [];
        }

        let localUrls: string[] = [];
        const hosts = this.data;
        for (const host of hosts) {
            if (!host.urls) {
                continue;
            }
            for (const url of host.urls) {
                if (url.indicAppInterne && url.url) {
                    localUrls.push(url.url);
                }
            }
        }

        return localUrls;
    }

    /**
     * Returns all enabled apps. i.e. apps with local urls among AVAILABLE_APPS
     *
     */
    public getEnabledApps(): string[] {
        if (!this.data) {
            console.warn("data is not initialized");
            return [];
        }

        let enabledApps: string[] = []
        const localUrls = this.getLocalUrls();

        for (let availableApp of AVAILABLE_APPS) {
            if (localUrls.some((value: string, index: number, array: string[]) => { return value.startsWith(availableApp.baseUrl) })) {
                enabledApps.push(availableApp.name);
            }
        }

        return enabledApps;
    }


}