import { Dispatch, SetStateAction, useState } from "react";

export const useToast = (onCloseToast?: (success: boolean) => void): [boolean,
    Dispatch<SetStateAction<boolean>>,
    boolean,
    Dispatch<SetStateAction<boolean>>,
    string,
    Dispatch<SetStateAction<string>>,
    () => void,
    (success: boolean, content: string) => void
] => {
    const [_show, _setShow] = useState<boolean>(false);
    const [_success, _setSuccess] = useState<boolean>(false);
    const [_content, _setContent] = useState<string>('');

    const handleClose = () => {
        _setShow(false);
        if (onCloseToast) {
            onCloseToast(_success);
        }
    }

    const activate = (success: boolean, content: string) => {
        _setSuccess(success);
        _setContent(content);
        _setShow(true);
    }

    return [
        _show,
        _setShow,
        _success,
        _setSuccess,
        _content,
        _setContent,
        handleClose,
        activate
    ]
}