import React, { useEffect, useState } from 'react';
import {
    IonGrid,
    IonRow,
    IonCol,
} from '@ionic/react';

import { InApp } from '../../components/in-app/in-app';
import RightMenu from '../../components/menu/right-menu/right-menu';
import { RefresherEventDetail } from '@ionic/core';
import { Applications } from '../../buisness/applications';
import { ApplicationsGroupData } from '../../interfaces/application-data';
import { FolderCard } from '../../components/folder-card/folder-card';
import { HomeSearchBar } from "../../components/applications/webquery/search-bar/home-search-bar";

import './home.css';

export default function Home(props: any) {

    const [_isPrimaryLoading, _setIsPrimaryLoading] = useState(false)
    const [_applicationGroups, _setApplicationGroups] = useState<ApplicationsGroupData[]>([])

    const fetchApps = async (forceDownload: boolean = false) => {
        await Applications.getInstance().initialize();

        let applicationGroups = Applications.getInstance().hostsToPlatformApplications();
        if (!applicationGroups) {
            console.warn("applicationGroups is not defined");
            return;
        }

        /*
         *  /!\ HERE IT IS HARDCODED THAT THE ACCEUIL_GROUP SHALL NOT BE DISPLAYED
         */
        applicationGroups = applicationGroups.filter((appGroup: ApplicationsGroupData) => {
            return (appGroup.name !== "Accueil_group")
        })

        _setApplicationGroups(applicationGroups)
    }

    const onMount = async () => {
        _setIsPrimaryLoading(true)
        await fetchApps(false)
        _setIsPrimaryLoading(false)
    }

    useEffect(() => {
        onMount()
    }, [])

    const refresh = async (event: CustomEvent<RefresherEventDetail>) => {
        await fetchApps(true);
        event.detail.complete();
    }

    return (
        <InApp
            isPrimaryLoading={_isPrimaryLoading}
            isSecondaryLoading={false}
            backUrl=""
            title="Accueil"
            displayMenuButton={true}
            showRightMenuBtn={true}
            rightMenu={<RightMenu />}
            refresh={refresh}
            contentClassName="home-content"
            headerChildren={<HomeSearchBar />}
        >

            <IonGrid>
                <IonRow>
                    {
                        _applicationGroups.map((application: ApplicationsGroupData, index: number) => {
                            return (
                                <IonCol key={application.name} size-xs="12" size-sm="12" size-md="6" size-lg="4" size="4">
                                    <FolderCard
                                        title={application.name}
                                        links={application.children}
                                        faicon={application.faIcon}
                                        greyBackground={(index % 2) === 0}
                                    />
                                </IonCol>
                            )
                        })
                    }
                </IonRow>
            </IonGrid>
        </InApp>
    )
}
