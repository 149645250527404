// import external packets
import React, { useState, useEffect, useRef } from 'react';
import {
    IonGrid,
    IonRow,
    IonCol
} from '@ionic/react';

import { RefresherEventDetail } from '@ionic/core';
import { useHistory } from 'react-router-dom';

// import local packets
import DataService from '../../../../services/data-service';
import { WebQueryCard } from '../../../../components/web-query-card/web-query-card';

// import external css files

// import local css files
import './webquery-home.css';
import { WebquerySearchBar } from '../../../../components/applications/webquery/search-bar/webquery-search-bar';
import { WebqueryRightMenu } from '../../../../components/applications/webquery/right-menu/webquery-right-menu';
import { WebqueryData } from '../../../../interfaces/webquery-data';
import { InApp } from '../../../../components/in-app/in-app';


export default function WebqueryHome(props: any) {

    let [_isLoading, _setLoading] = useState(false);
    let [_history, _setHistory] = useState<WebqueryData[]>([]);

    const isMounted = useRef(true)

    useEffect(() => {
        fetchData();
        return () => { isMounted.current = false }
    }, []);



    let fetchData = async (forceDownload: boolean = false) => {

        if (isMounted.current) {
            _setLoading(true);
        }

        try {
            let webqueries: WebqueryData[] = await DataService.getWebQueries(forceDownload);

            let history: WebqueryData[] = webqueries.filter((webquery: WebqueryData) => {
                return (webquery && (webquery.myHistos) && (webquery.myHistos.length > 0));
            })

            if (history) {
                // order the hostory from the newer to the older
                history = history.sort((a: WebqueryData, b: WebqueryData) => {
                    // get the last element of the A histo as it is order from the older to the newer
                    const lastTimeA = a.myHistos[0].dateAdd;

                    // get the last element of the B histo as it is order from the older to the newer
                    const lastTimeB = b.myHistos[0].dateAdd;

                    // Function used to determine the order of the elements. It is expected to return a negative value if first argument is less than second argument
                    // but we do the opposite because we want to order from the biggest timestamp to the lowest
                    if (lastTimeA > lastTimeB) {
                        return (-1);
                    }

                    return 1;
                })

                if (history.length > 5) {
                    history = history.splice(5);
                }
            }

            if (isMounted.current) {
                _setHistory(history);
            }



        } catch (error) {
            console.error(error);
        }
        if (isMounted.current) {
            _setLoading(false);
        }
    }

    let handleClick = (id: number) => {
        props.history.push("/webquery/extraction/" + id)
    }

    let onClickSearchBarElement = (webqueryId: number) => {
        props.history.push("/webquery/extraction/" + webqueryId);
    }

    let doRefresh = async (event: CustomEvent<RefresherEventDetail>) => {
        await fetchData(true);
        event.detail.complete();
    }

    return (
        <InApp
            isPrimaryLoading={_isLoading}
            isSecondaryLoading={false}
            backUrl=""
            title="Webquery - Récents"
            displayMenuButton={true}
            showRightMenuBtn={true}
            rightMenu={<WebqueryRightMenu />}
            refresh={doRefresh}
            headerChildren={<WebquerySearchBar onClickElement={onClickSearchBarElement} />}
        >
            <IonGrid>
                <IonRow>
                    {
                        _history.map((webquery: WebqueryData) => {
                            return (
                                <IonCol size="12" size-md="4" size-xl="4" key={webquery.id}>
                                    <WebQueryCard
                                        className="no-margin"
                                        onClick={handleClick}
                                        id={webquery.id}
                                        title={webquery.categories[0].name}
                                        subtitle={webquery.code + " - " + webquery.name}
                                        description={webquery.userDescription}
                                        note={"temps moyen de generation " + webquery.averageExecTime}
                                    />
                                </IonCol>
                            )
                        })
                    }
                </IonRow>
            </IonGrid>
        </InApp>
    );

}
