import { IonCol, IonGrid, IonRow } from '@ionic/react';
import React, { useEffect, useState } from 'react';
import { InApp } from '../../../../components/in-app/in-app';
import RightMenu from '../../../../components/menu/right-menu/right-menu';
import DataService from '../../../../services/data-service';
import { CampaignData } from '../../../../interfaces/campaign-data';
import { TenantSearchData } from '../../../../interfaces/tenant-search-data';
import { useParams, useHistory } from "react-router-dom";
import { IonCard, IonCardHeader, IonCardSubtitle, IonCardContent } from '@ionic/react';
import { getCampaignStatusLabel } from '../../../../buisness/campaign'
import { Utils } from '../../../../utils/utils';
import { Browser } from '@capacitor/browser';
import ConfigUtils from '../../../../utils/config-utils';
import { Dialog } from '../../../../components/dialog/dialog';
import { TextInputItem } from '../../../../components/text-input/text-input';
import { parse, format, subMonths } from 'date-fns'
import GDHToast from '../../../../components/toast/toast';

import './CTI-home.css';
import { QpatData } from '../../../../interfaces/qpat-data';

const CAS_ADMIN_URL = "https://client.granddelta.fr/casadmin_locatairesREL/login?service=https%3A%2F%2Fclient.granddelta.fr%2Flocataires%2Findex.php%2Faccueil-non-connecte%3Fserver%3D1"
const SIGN_UP_URL = "https://client.granddelta.fr/locataires/index.php/inscription-locataire"
const SIGN_UP_SECONDARY_URL = "https://client.granddelta.fr/locataires/index.php/inscription-identifiants-provisoires"
const DEFAULT_TEXT_VALUE = "pas de données"
const YES = "OUI"
const NO = "NON"

export function CTIHome(props: any) {

    const [_flashs, _setFlashs] = useState<CampaignData[]>([])

    const { id } = useParams<{ id: string }>()
    const _history = useHistory();

    const [_showToast, _setShowToast] = useState<boolean>(false);
    const [_toastSuccess, _setToastSuccess] = useState<boolean>(false);
    const [_toastContent, _setToastContent] = useState<string>("");

    const [_tenant, _setTenant] = useState<TenantSearchData | undefined>();
    const [_residence, _setResidence] = useState<any>();
    const [_qpat, _setQpat] = useState<QpatData>();
    const [_extranetAccountCreationDate, _setExtranetAccountCreationDate] = useState<string>("");
    const [_extranetLastLoginDate, _setExtranetLastLoginDate] = useState<string>("");
    const [_extranetEmail, _setExtranetEmail] = useState<string>("");
    const [_lift, _setLift] = useState<string>(DEFAULT_TEXT_VALUE);
    const [_showSignUpButton, _setShowSignUpButton] = useState<boolean>(false);

    const [_showDialog, _setShowDialog] = useState<boolean>(false);
    const [_dialogContent, _setDialogContent] = useState<React.ReactNode>(<></>);

    // ste this to true when a sms is sent, and to false when a email is sent
    const [_sendSms, _setSendSms] = useState<boolean>(false)

    const [_sendMailAddress, _setSendMailAddress] = useState<string>("")
    const [_sendMailSubject, _setSendMailSubject] = useState<string>("")
    const [_sendMailMsg, _setSendMailMsg] = useState<string>("")

    const [_sendSmsNumber, _setSendSmsNumber] = useState<string>("")
    const [_sendSmsMsg, _setSendSmsMsg] = useState<string>("")

    const [_lna, _setLna] = useState<string>(DEFAULT_TEXT_VALUE)
    const [_mrh, _setMrh] = useState<string>(DEFAULT_TEXT_VALUE)
    const [_insuranceEndDate, _setInsuranceEndDate] = useState<string>(DEFAULT_TEXT_VALUE)

    const handleDialogCancel = () => {
        _setShowDialog(false)
    }

    const handleDialogOk = async () => {
        _setShowDialog(false)

        if (_sendSms) {
            try {
                const simulate = (ConfigUtils.getMode() !== "PROD")
                await DataService.sendSMS(_sendSmsNumber, "Grand Delta", _sendSmsMsg, simulate);
                _setToastContent("Sms envoyé")
                _setToastSuccess(true);
                _setShowToast(true);
            } catch (error) {
                console.error(error)
                _setToastContent("Erreur lors de l'envoi du sms")
                _setToastSuccess(false);
                _setShowToast(true);
            }
        } else {
            if (!Utils.checkEmail(_sendMailAddress)) {
                _setToastContent("Veuillez entrer une adresse email valide")
                _setToastSuccess(false);
                _setShowToast(true);
                return;
            }

            try {
                await DataService.sendMail("GDH", _sendMailAddress, "contact@granddelta.fr", _sendMailSubject, _sendMailMsg, "")
                _setToastContent("Email envoyé")
                _setToastSuccess(true);
                _setShowToast(true);
            } catch (error) {
                console.error(error)
                _setToastContent("Erreur lors de l'envoi du mail")
                _setToastSuccess(false);
                _setShowToast(true);
            }
        }
    }

    const onClickFlash = (flashId: Number) => {
        _history.push('/flash/' + flashId + '/edit/')
    }

    const onChangeSendMailAddress = (event: any) => {
        _setSendMailAddress(event?.target?.value)
    }

    const onChangeSendMailSubject = (event: any) => {
        _setSendMailSubject(event?.target?.value)
    }

    const onChangeSendMailMsg = (event: any) => {
        _setSendMailMsg(event?.target?.value)
    }

    const onChangeSendSmsNumber = (event: any) => {
        _setSendSmsNumber(event?.target?.value)
    }

    const onChangeSendSmsMsg = (event: any) => {
        _setSendSmsMsg(event?.target?.value)
    }

    const onClickSendMail = async () => {
        _setSendSms(false)

        _setDialogContent(
            <>
                <TextInputItem
                    className="gdh-item-input cti-popup-input"
                    handleChangeInput={onChangeSendMailAddress}
                    value={_sendMailAddress}
                    label="Email"
                    required={true}
                    isArea={false}
                    textLineProps={{ placeholder: "" }}
                />

                <TextInputItem
                    className="gdh-item-input cti-popup-input"
                    handleChangeInput={onChangeSendMailSubject}
                    value={_sendMailSubject}
                    label="Sujet"
                    required={true}
                    isArea={false}
                    textLineProps={{ placeholder: "" }}
                />

                {/* there is no label as a workaround for css difference bewteen input and text area */}
                <TextInputItem
                    className="gdh-item-input cti-popup-input cti-popup-textarea"
                    handleChangeArea={onChangeSendMailMsg}
                    value={_sendMailMsg}
                    label=""
                    required={true}
                    isArea={true}
                    textLineProps={{ placeholder: "" }}
                />
            </>
        )

        _setShowDialog(true)
    }

    const onClickSendSms = async () => {
        _setSendSms(true)


        _setDialogContent(
            <>
                <TextInputItem
                    className="gdh-item-input cti-popup-input"
                    handleChangeInput={onChangeSendSmsNumber}
                    value={_sendSmsNumber}
                    label="Numéro de téléphone"
                    required={true}
                    isArea={false}
                    textLineProps={{ placeholder: "" }}
                />

                {/* there is no label as a workaround for css difference bewteen input and text area */}
                <TextInputItem
                    className="gdh-item-input cti-popup-input cti-popup-textarea"
                    handleChangeArea={onChangeSendSmsMsg}
                    value={_sendSmsMsg}
                    label=""
                    required={true}
                    isArea={true}
                    textLineProps={{ placeholder: "" }}
                />
            </>
        )

        _setShowDialog(true)
    }

    const onClickCasAdmin = () => {
        Browser.open({ url: CAS_ADMIN_URL, windowName: '_blank' });
    }

    const onClickSingUp = () => {
        Browser.open({ url: SIGN_UP_URL, windowName: '_blank' });
    }

    const onClickSingUpSecondary = () => {
        Browser.open({ url: SIGN_UP_SECONDARY_URL, windowName: '_blank' });
    }

    useEffect(() => {
        if (!id) {
            // if id is undefined, redirect to search page
            _history.push("/CTI/search")
            return
        }

        (async function () {
            try {
                const tenants = await DataService.searchTenant(id, 5, "T", true, true, true)

                if (!(tenants && tenants.length > 0)) {
                    _history.push("/CTI/search/" + id + "/no-tenant")
                    return
                } else if (tenants.length > 1) {
                    _history.push("/CTI/search/" + id + "/multiple-tenant")
                }

                _setTenant(tenants[0])

                let creationDate = DEFAULT_TEXT_VALUE
                let lastLoginDate = DEFAULT_TEXT_VALUE
                let email = DEFAULT_TEXT_VALUE

                // check that the data is defined and correct
                if (tenants[0].CONNEXIONS && tenants[0].CONNEXIONS.length > 0 && tenants[0].CONNEXIONS[0].LOGINXNT !== ""
                    && tenants[0].CONNEXIONS[0].DTESICGU && tenants[0].CONNEXIONS[0].DTEDERCX) {

                    creationDate = Utils.rawDateToHumanReadable(tenants[0].CONNEXIONS[0].DTESICGU) ?? DEFAULT_TEXT_VALUE
                    lastLoginDate = Utils.rawDateToHumanReadable(tenants[0].CONNEXIONS[0].DTEDERCX) ?? DEFAULT_TEXT_VALUE
                    email = tenants[0].CONNEXIONS[0].LOGINXNT ?? DEFAULT_TEXT_VALUE
                }

                if (tenants[0].ASSURANCES && tenants[0].ASSURANCES.length > 0 && tenants[0].ASSURANCES[0].DATE_FIN) {
                    const twoMonthAgo = format(subMonths(new Date(), 2), "yyyyMMdd")
                    if (tenants[0].ASSURANCES[0].DATE_FIN <= twoMonthAgo) {
                        _setLna(YES)
                        _setMrh(NO)
                    } else {
                        _setLna(NO)
                        _setMrh(YES)
                    }

                    // set insurance end date
                    let date = parse(tenants[0].ASSURANCES[0].DATE_FIN, 'yyyyMMdd', new Date())
                    _setInsuranceEndDate(format(date, "dd/MM/yyyy"))
                }

                if (tenants[0]?.TELPORT) {
                    _setSendSmsNumber(tenants[0]?.TELPORT)
                }

                _setExtranetAccountCreationDate(creationDate)
                _setExtranetLastLoginDate(lastLoginDate)
                _setExtranetEmail(email)
                _setSendMailAddress(email)
                _setShowSignUpButton(creationDate === DEFAULT_TEXT_VALUE)
            } catch (err) {
                console.log(err);
            }
        })()
    }, [id])

    useEffect(() => {
        if (_tenant?.HP1) {
            (async function () {
                try {
                    const residences = await DataService.searchResidence(_tenant.HP1 as string)
                    if (residences && residences.length > 0) {
                        _setResidence(residences[0])
                    }
                } catch (err) {
                    console.log(err);
                }
            })()
        }
    }, [_tenant])


    useEffect(() => {
        if (_tenant?.NOUG_PRINC) {
            (async function () {
                try {

                    const qpat = await DataService.getQpat(_tenant.NOUG_PRINC as string);
                    if (qpat && qpat.length > 0) {
                        _setQpat(qpat[0])
                        if (qpat[0]?.ASCENCEUR !== undefined) {
                            _setLift((qpat[0].ASCENCEUR === "O") ? "OUI" : "NON")
                        } else {
                            _setLift(DEFAULT_TEXT_VALUE)
                        }
                    }
                } catch (err) {
                    console.log(err);
                }
            })()
        }
    }, [_tenant])

    useEffect(() => {
        if (_tenant?.NOCA) {
            (async function () {
                try {
                    const flashs = await DataService.getFlashsForTenant(_tenant.NOCA as string);
                    // sort campaigns in so the newest are in first, use id instead of date besause it is more simple
                    flashs.sort((a: CampaignData, b: CampaignData) => {
                        return b.id - a.id
                    })
                    _setFlashs(flashs)
                } catch (err) {
                    console.log(err);
                }
            })()
        }
    }, [_tenant])

    const handleToastClose = () => {
        // do nothing.
    }

    return (
        <InApp
            isPrimaryLoading={false}
            isSecondaryLoading={false}
            backUrl=""
            title="CTI"
            displayMenuButton={true}
            showRightMenuBtn={true}
            rightMenu={<RightMenu />}
            refresh={null}
        >
            <GDHToast
                content={_toastContent}
                handleClose={handleToastClose}
                show={_showToast}
                success={_toastSuccess}
            />

            <IonGrid className="CTI-home-container">
                <IonRow>
                    <IonCol size-xs="12" size-sm="12" size-md="12" size-lg="4" size="4">
                        <IonCard className="CTI-card-card ">
                            <IonCardHeader>
                                <IonCardSubtitle className="CTI-card-title">
                                    Infos patrimoine
                                </IonCardSubtitle>
                            </IonCardHeader>
                            <IonCardContent className="CTI-card-content" >
                                <div>
                                    <span className="CTI-home-label">Mode de chauffage à l'entrée : </span>{_qpat?.MODE_CHAUFFAGE_ENTREE ?? DEFAULT_TEXT_VALUE}
                                </div>
                                <div>
                                    <span className="CTI-home-label">Ascenseur : </span>{_lift}
                                </div>
                            </IonCardContent>
                        </IonCard>

                        <IonCard className="CTI-card-card ">
                            <IonCardHeader>
                                <IonCardSubtitle className="CTI-card-title">
                                    Infos locataire
                                </IonCardSubtitle>
                            </IonCardHeader>
                            <IonCardContent className="CTI-card-content" >
                                <div>
                                    <span className="CTI-home-label">MRH : </span>{_mrh}
                                </div>
                                <div>
                                    <span className="CTI-home-label">LNA : </span>{_lna}
                                </div>
                                <div>
                                    <span className="CTI-home-label">Date fin contrat : </span>{_insuranceEndDate}
                                </div>
                            </IonCardContent>
                        </IonCard>

                        <IonCard className="CTI-card-card ">
                            <IonCardHeader>
                                <IonCardSubtitle className="CTI-card-title">
                                    Flash client
                                </IonCardSubtitle>
                            </IonCardHeader>
                            <IonCardContent className="CTI-card-content" >
                                {
                                    (_flashs && _flashs.length === 0) ?
                                        <div>
                                            Aucune campagne en cours
                                        </div>
                                        :
                                        <div>
                                            {
                                                _flashs.map((flash: CampaignData) => {
                                                    return (
                                                        <div key={flash.id} className="cti-element-container" onClick={() => onClickFlash(flash.id)}>
                                                            <div><span className="CTI-home-label">Etat du flash : </span>{getCampaignStatusLabel(flash.status)}</div>
                                                            <div><span className="CTI-home-label">Titre : </span>{flash.titre}</div>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                }
                            </IonCardContent>
                        </IonCard>

                    </IonCol>
                    <IonCol size-xs="12" size-sm="12" size-md="12" size-lg="4" size="4">

                        <IonCard className="CTI-card-card ">
                            <IonCardHeader>
                                <IonCardSubtitle className="CTI-card-title">
                                    Adresse
                                </IonCardSubtitle>
                            </IonCardHeader>
                            <IonCardContent className="CTI-card-content" >
                                <div>
                                    <span className="CTI-home-label">Compte : </span>{_tenant?.NOCA ?? DEFAULT_TEXT_VALUE}
                                </div>
                                <div>
                                    <span className="CTI-home-label">Résidence : </span>{(_tenant) ? `${_tenant.HP1} / ${_tenant.LBL_RESIDENCE}` : DEFAULT_TEXT_VALUE}
                                </div>
                                <div>
                                    <span className="CTI-home-label">Adresse : </span>{(_tenant) ? `${_tenant.ADRESSE}, ${_tenant.LOCALITE} (${_tenant.CODEPOSTAL})` : DEFAULT_TEXT_VALUE}
                                </div>
                                <div>
                                    <span className="CTI-home-label">Bâtiment : </span>{_tenant?.HP2 ?? DEFAULT_TEXT_VALUE}
                                </div>
                                <div>
                                    <span className="CTI-home-label">Entrée : </span>{_tenant?.HP3 ?? DEFAULT_TEXT_VALUE}
                                </div>
                                <div>
                                    <span className="CTI-home-label">UG : </span>{_tenant?.NOUG_PRINC ?? DEFAULT_TEXT_VALUE}
                                </div>
                                <button onClick={onClickSendMail} className="cti-button">
                                    Envoyer un mail
                                </button>
                                <button onClick={onClickSendSms} className="cti-button">
                                    Envoyer un SMS
                                </button>
                            </IonCardContent>
                        </IonCard>


                        <IonCard className="CTI-card-card ">
                            <IonCardHeader>
                                <IonCardSubtitle className="CTI-card-title">
                                    Contacts utiles
                                </IonCardSubtitle>
                            </IonCardHeader>
                            <IonCardContent className="CTI-card-content" >
                                {
                                    (_residence?.CONTACTS_UTILS && _residence.CONTACTS_UTILS.length > 0) ?
                                        _residence.CONTACTS_UTILS.map((contact: any) => {
                                            return <div key={contact.LIBCDINT} className="CTI-list-element">
                                                {contact.LIBCDINT}
                                            </div>
                                        })
                                        :
                                        DEFAULT_TEXT_VALUE
                                }
                            </IonCardContent>
                        </IonCard>

                    </IonCol>
                    <IonCol size-xs="12" size-sm="12" size-md="12" size-lg="4" size="4">

                        <IonCard className="CTI-card-card ">
                            <IonCardHeader>
                                <IonCardSubtitle className="CTI-card-title">
                                    Espace locataire
                                </IonCardSubtitle>
                            </IonCardHeader>
                            <IonCardContent className="CTI-card-content" >
                                <div>
                                    <span className="CTI-home-label">Inscription : </span>{_extranetAccountCreationDate}
                                </div>
                                <div>
                                    <span className="CTI-home-label">Dernière connexion : </span>{_extranetLastLoginDate}
                                </div>
                                <div>
                                    <span className="CTI-home-label">Mail : </span>{_extranetEmail}
                                </div>
                                {
                                    !_showSignUpButton &&
                                    <button onClick={onClickCasAdmin} className="cti-button">
                                        Lien vers cas Admin
                                    </button>
                                }
                                {
                                    _showSignUpButton &&
                                    <button onClick={onClickSingUp} className="cti-button">
                                        Lien vers inscription standard
                                    </button>
                                }
                                {
                                    _showSignUpButton &&
                                    <button onClick={onClickSingUpSecondary} className="cti-button">
                                        Lien vers inscription personne morale
                                    </button>
                                }
                                <IonCardSubtitle className="CTI-card-title">
                                    Historique des actions
                                </IonCardSubtitle>

                                {
                                    (_tenant?.EVENEMENTS && _tenant.EVENEMENTS.length > 0) ?
                                        _tenant?.EVENEMENTS.map((event: any) => {
                                            return <div className="CTI-home-extranet-evt cti-element-container">
                                                <span className="CTI-home-label">{event.LKLBTYPACT}</span> {Utils.rawDateToHumanReadable(event.LPDATEACT)}
                                            </div>
                                        })
                                        :
                                        DEFAULT_TEXT_VALUE
                                }
                            </IonCardContent>
                        </IonCard>
                    </IonCol>
                </IonRow>
            </IonGrid>

            <Dialog
                open={_showDialog}
                title="Envoyer un message"
                content={_dialogContent}
                onCancel={handleDialogCancel}
                onConfirm={handleDialogOk}
            />
        </InApp>
    )
}