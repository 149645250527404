import React from 'react';
import TextField from '@mui/material/TextField';

type TimePickerProps = {
    onChange: (event: any) => any,
    title: string,
    value: string,
    disabled?: boolean,
}

export function TimePicker(props: TimePickerProps) {
    return (
        <TextField
            id="time"
            label={props.title}
            type="time"
            InputLabelProps={{
                shrink: true,
            }}
            value={props.value}
            onChange={props.onChange}
            disabled={props.disabled ?? false}
        />
    )
}
