import React from 'react';
import Slider from '@mui/material/Slider';
import "./range-slider.css"

type RangeSliderProps = {
  minValue: number,
  maxValue: number,
  values: number[]
  disabled?: boolean,
  onChange: (event: Event, value: number | number[], activeThumb: number) => void
}

export function RangeSlider(props: RangeSliderProps) {

  const marks = [
    {
      value: props.minValue,
      label: props.minValue,
    },
    {
      value: props.maxValue,
      label: props.maxValue,
    }
  ];

  return (
    <div className="range-slider-container">
      <Slider
        disabled={props.disabled}
        min={props.minValue}
        max={props.maxValue}
        value={props.values}
        onChange={props.onChange}
        valueLabelDisplay="auto"
        aria-labelledby="range-slider"
        marks={marks}
      />
    </div>
  );
}
