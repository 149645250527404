
const PUBLIC_KEY = 'project_public_aa21ab6f96aa388e59edcb2d4d2ceec3_amBhK3059633bfb41bf944b95d8779468e9fb';
const NO_TOKEN_ERROR = 'Unable to use I Love PDF API without token';
type Operation = "merge" | "split" | "compress" | "pdfjpg" | "imagepdf" | "unlock" | "pagenumber" | "watermark" | "officepdf" | "repair" | "rotate" | "protect" | "pdfa" | "validatepdfa" | "htmlpdf" | "extract";
export type ServerFile = { server_filename: string, filename: string };
/**
 * @class ILovePdfApiService
 * Handles all I Love PDF API calls
 * a typical workflow is :
 * - auth
 * - start
 * - upload files
 * - process
 * - download
 *
 */
export class ILovePdfApiService {

    private static s_authToken: string;

    /**
     * Private constructor. Shall never be instantiated
     */
    private constructor() { }

    /**
     * Retrieve the ILovePdfToken
     *
     * @return {void}
     */
    static async auth(): Promise<void> {
        const url = `https://api.ilovepdf.com/v1/auth`;

        const data = new FormData()
        data.append('public_key', PUBLIC_KEY)

        try {
            const response = await fetch(url, {
                method: 'POST',
                body: data
            });

            if (response.ok) {
                const json = await response.json();
                this.s_authToken = json.token;
            } else {
                throw new Error("Fetch error. " + response.status + " " + response.statusText + " " + response.url);
            }
        } catch (error: any) {
            throw new Error(error);
        }
    }

    /**
     * Count the number of occurence of the same file name between two arrays
     * @param operation {Operation} the operation to start
     *
     * @return {{server: string, task: string}} the server name and the taskId
     */
    static async start(operation: Operation): Promise<{ server: string, task: string }> {
        if (!this.s_authToken) {
            throw new Error(NO_TOKEN_ERROR);
        }

        const url = `https://api.ilovepdf.com/v1/start/${operation}`;

        try {
            const response = await fetch(url, {
                method: 'GET',
                headers: new Headers({
                    'Authorization': 'Bearer ' + this.s_authToken
                }),
            });

            if (response.ok) {
                return await response.json();
            } else {
                throw new Error("Fetch error. " + response.status + " " + response.statusText + " " + response.url);
            }
        } catch (error: any) {
            throw new Error(error);
        }
    }

    /**
     * Upload a file to the server
     * @param server {string} the server name, retrieved from the start api call
     * @param task {string} the task id, retrieved from the start api call
     * @param file {File} the file to uplaod
     *
     * @return {{server_filename: string}} the name that has been attributed to the file on the ILovePDF server
     */
    static async upload(server: string, task: string, file: File): Promise<{ server_filename: string }> {
        if (!this.s_authToken) {
            throw new Error(NO_TOKEN_ERROR);
        }

        const url = `https://${server}/v1/upload`;
        const formData = new FormData()
        formData.append('file', file)
        formData.append('task', task)

        try {
            const response = await fetch(url, {
                method: 'POST',
                headers: new Headers({
                    'Authorization': 'Bearer ' + this.s_authToken
                }),
                body: formData
            });

            if (response.ok) {
                return await response.json();
            } else {
                throw new Error("Fetch error. " + response.status + " " + response.statusText + " " + response.url);
            }
        } catch (error: any) {
            throw new Error(error);
        }
    }

    /**
     * Process files
     * @param server {string} the server name, retrieved from the start api call
     * @param task {string} the task id, retrieved from the start api call
     * @param serverFiles
     * @param operation {Operation} the operation to process
     *
     * @param optional
     */
    static async process(server: string, task: string, serverFiles: ServerFile[], operation: Operation, optional?: {}) {
        if (!this.s_authToken) {
            throw new Error(NO_TOKEN_ERROR);
        }

        const url = `https://${server}/v1/process`;

        const formData = JSON.stringify({
            task: task,
            tool: operation,
            files: serverFiles,
            ...optional
        })

        try {
            const response = await fetch(url, {
                method: 'POST',
                headers: new Headers({
                    'Authorization': 'Bearer ' + this.s_authToken,
                    'Content-Type': 'application/json'
                }),
                body: formData
            });

            if (response.ok) {
                return await response.json();
            } else {
                throw new Error("Fetch error. " + response.status + " " + response.statusText + " " + response.url);
            }
        } catch (error: any) {
            throw new Error(error);
        }
    }

    /**
     * Download the transformed file, and return the blob url
     * @param server {string} the server name, retrieved from the start api call
     * @param task {string} the task id, retrieved from the start api call
     *
     * @return {string} the downloaded file blob url
     */
    static async download(server: string, task: string): Promise<Blob> {
        const url = `https://${server}/v1/download/${task}`;

        try {
            const response = await fetch(url, {
                method: 'GET',
                headers: new Headers({
                    'Authorization': 'Bearer ' + this.s_authToken
                }),
            });

            if (response.ok) {
                const buffer = await response.arrayBuffer();
                const arr = new Uint8Array(buffer);
                return new Blob([arr], { type: 'application/pdf' });
            } else {
                throw new Error("Fetch error. " + response.status + " " + response.statusText + " " + response.url);
            }
        } catch (error: any) {
            throw new Error(error);
        }
    }
}
