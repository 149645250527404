import { IonCard } from "@ionic/react";
import React from "react";
import { useHistory } from "react-router";
import { InApp } from "../../../../components/in-app/in-app";
import RightMenu from "../../../../components/menu/right-menu/right-menu";

import './pdf-home.css';

export function PdfHome(props: any) {

    const history = useHistory();

    return (
        <InApp
            isPrimaryLoading={false}
            isSecondaryLoading={false}
            backUrl=""
            title="PDF Utils"
            displayMenuButton={true}
            showRightMenuBtn={true}
            rightMenu={<RightMenu />}
            refresh={() => null}
        >
            <div className="pdf-home-container">
                <IonCard className="pdf-home-button" onClick={() => history.push("/PDF/merge")}>
                    <div className="pdf-home-button-title">
                        Fusionner
                    </div>
                    <div className="pdf-home-button-description">
                        <div className="pdf-home-button-description-col">
                            <i className="fas fa-file-pdf pdf-home-button-icon"></i>
                        </div>
                        <div className="pdf-home-button-description-col">
                            <i className="fas fa-file-pdf pdf-home-button-icon"></i>
                        </div>
                        <div className="pdf-home-button-description-col">
                            <i className="fas fa-arrow-alt-right pdf-home-button-icon"></i>
                        </div>
                        <div className="pdf-home-button-description-col">
                            <i className="fas fa-file-pdf pdf-home-button-icon"></i>
                        </div>
                    </div>
                </IonCard>
                <IonCard className="pdf-home-button" onClick={() => history.push("/PDF/split")}>
                    <div className="pdf-home-button-title">
                        Découper
                    </div>
                    <div className="pdf-home-button-description">
                        <div className="pdf-home-button-description-col">
                            <i className="fas fa-file-pdf pdf-home-button-icon"></i>
                        </div>
                        <div className="pdf-home-button-description-col">
                            <i className="fas fa-arrow-alt-right pdf-home-button-icon"></i>
                        </div>
                        <div className="pdf-home-button-description-col">
                            <i className="fas fa-file-pdf pdf-home-button-icon"></i>
                        </div>
                        <div className="pdf-home-button-description-col">
                            <i className="fas fa-file-pdf pdf-home-button-icon"></i>
                        </div>
                    </div>
                </IonCard>
                <IonCard className="pdf-home-button" onClick={() => history.push("/PDF/convert-and-merge")}>
                    <div className="pdf-home-button-title">
                        Convertir et fusionner
                    </div>
                    <div className="pdf-home-button-description">
                        <div className="pdf-home-button-description-col">
                            <i className="fas fa-file-pdf pdf-home-button-icon"></i>
                        </div>
                        <div className="pdf-home-button-description-col">
                            <i className="far fa-file-image pdf-home-button-icon"></i>
                        </div>
                        <div className="pdf-home-button-description-col">
                            <i className="fas fa-file-word pdf-home-button-icon"></i>
                        </div>
                        <div className="pdf-home-button-description-col">
                            <i className="fas fa-arrow-alt-right pdf-home-button-icon"></i>
                        </div>
                        <div className="pdf-home-button-description-col">
                            <i className="fas fa-file-pdf pdf-home-button-icon"></i>
                        </div>
                    </div>
                </IonCard>
            </div>
        </InApp>
    )

}