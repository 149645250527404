import { IonCheckbox, IonCol, IonGrid, IonItem, IonLabel, IonRadio, IonRadioGroup, IonRow, IonDatetime, IonDatetimeButton, IonModal, IonButtons, IonButton } from '@ionic/react';
import { Utils } from '../../../../utils/utils';
import React, { useRef } from 'react';
import { RadioGroupChangeEventDetail } from '@ionic/core/dist/types/components/radio-group/radio-group-interface'
import { CheckboxChangeEventDetail } from '@ionic/core/dist/types/components/checkbox/checkbox-interface'
import { TimePicker } from '../../../../components/time-picker/time-picker';


type DigitalReportHomeStep1Props = {
    onChangeFrequencyRadio: (event: CustomEvent<RadioGroupChangeEventDetail>) => void,
    onChangeLessorLocalsCheckbox: (event: CustomEvent<CheckboxChangeEventDetail>) => void,
    onChangePrivateRoomCheckbox: (event: CustomEvent<CheckboxChangeEventDetail>) => void,
    onChangeSharedRoomExterCheckbox: (event: CustomEvent<CheckboxChangeEventDetail>) => void,
    onChangeSharedRoomInterCheckbox: (event: CustomEvent<CheckboxChangeEventDetail>) => void,
    onChangeDate: (date: Date | null) => void,
    onChangeDateObservation: (date: Date | null) => void,
    onChangeHourFrom: (event: any) => void,
    onChangeHourFromObservation: (event: any) => void,
    onChangeHourTo: (event: any) => void,
    onChangeHourToObservation: (event: any) => void,
    onChangeUnknownDate: (event: any) => void,
    onChangeUnknownHour: (event: any) => void,
    hourFrom: string,
    hourFromObservation: string,
    hourTo: string,
    hourToObservation: string,
    frequencies: { [x: string]: any },
    lessorLocals: { [x: string]: any },
    selectedFrequency: string,
    selectedLessorLocals: string[],
    selectedDate: Date | null,
    selectedDateObservation: Date | null,
    privateRooms: { [x: string]: any },
    selectedPrivateRooms: string[],
    sharedRoomsInterior: { [x: string]: any },
    selectedSharedRoomsInterior: string[],
    sharedRoomsExterior: { [x: string]: any },
    selectedSharedRoomsExterior: string[],
    dateDisabled: boolean,
    hourDisabled: boolean,
}



export function DigitalReportHomeStep1(props: DigitalReportHomeStep1Props) {

    const dateReport = useRef<null | HTMLIonDatetimeElement>(null);
    const dateObservation = useRef<null | HTMLIonDatetimeElement>(null);

    const cancelDate = () => {
        dateReport.current?.cancel(true)
    }

    const confirmDate = () => {
        dateReport.current?.confirm()
        if (typeof (dateReport.current?.value) !== "string") {
            console.error('Wrong Date Format')
            return
        }
        props.onChangeDate(new Date(dateReport.current?.value))
        dateReport.current?.cancel(true)
    }

    const cancelDateObservation = () => {
        dateObservation.current?.cancel(true)
    }

    const confirmDateObservation = () => {
        dateObservation.current?.confirm()
        if (typeof (dateObservation.current?.value) !== "string") {
            console.error('Wrong Date Format')
            return
        }
        props.onChangeDateObservation(new Date(dateObservation.current?.value))
        dateObservation.current?.cancel(true)
    }

    return (
        <>
            <div className="digital-report-home-title">
                Date et lieu des faits
            </div>
            <IonGrid>
                <IonRow>
                    <IonCol size="6" sizeXl="6" size-lg="6" size-md="6" size-sm="12" size-xs="12" className="digital-report-home-col" >

                        <div className="digital-report-section">
                            <div className="digital-report-home-section-title">
                                Date et heure des faits
                            </div>
                            <IonGrid>
                                <IonRow>
                                    <IonCol size="4" sizeXl="4" size-lg="4" size-md="4" size-sm="12" size-xs="12" >
                                        <IonDatetimeButton disabled={props.dateDisabled} className="digital-report-date-button" datetime="dateReport"></IonDatetimeButton>
                                        <IonModal keepContentsMounted={true}>
                                            <IonDatetime value={Utils.formatDateIfValid(props.selectedDate, 'yyyy-MM-dd')} locale="fr-FR" presentation="date" id="dateReport" ref={dateReport}>
                                                <IonButtons slot="buttons">
                                                    <IonButton color="primary" onClick={cancelDate}>
                                                        Annuler
                                                    </IonButton>
                                                    <IonButton color="primary" onClick={confirmDate}>
                                                        Confirmer
                                                    </IonButton>
                                                </IonButtons>
                                            </IonDatetime>
                                        </IonModal>
                                    </IonCol>
                                    <IonCol size="4" sizeXl="4" size-lg="4" size-md="4" size-sm="12" size-xs="12" >
                                        <TimePicker
                                            title='De'
                                            onChange={props.onChangeHourFrom}
                                            value={props.hourFrom}
                                            disabled={props.hourDisabled}
                                        />
                                    </IonCol>

                                    <IonCol size="4" sizeXl="4" size-lg="4" size-md="4" size-sm="12" size-xs="12" >
                                        <TimePicker
                                            title='à'
                                            onChange={props.onChangeHourTo}
                                            value={props.hourTo}
                                            disabled={props.hourDisabled}
                                        />
                                    </IonCol>
                                </IonRow>
                                <IonRow>
                                    <IonCol size="6" sizeXl="6" size-lg="6" size-md="6" size-sm="12" size-xs="12" className="digital-report-home-col">
                                        <IonItem className="gdh-top-label-item">
                                            <IonLabel>Date inconnue</IonLabel>
                                            <IonCheckbox slot="start" checked={props.dateDisabled} onIonChange={props.onChangeUnknownDate} />
                                        </IonItem>
                                        <IonItem className="gdh-top-label-item">
                                            <IonLabel>Heure inconnue</IonLabel>
                                            <IonCheckbox slot="start" checked={props.hourDisabled} onIonChange={props.onChangeUnknownHour} />
                                        </IonItem>
                                    </IonCol>
                                </IonRow>
                            </IonGrid>
                        </div>

                        <div className="digital-report-section" hidden>
                            <div className="digital-report-home-section-title">
                                Date et heure du constat
                            </div>
                            <IonGrid>
                                <IonRow>
                                    <IonCol size="4" sizeXl="4" size-lg="4" size-md="4" size-sm="12" size-xs="12" >
                                        <IonDatetimeButton className="digital-report-date-button" datetime="dateObservation"></IonDatetimeButton>
                                        <IonModal keepContentsMounted={true}>
                                            <IonDatetime value={Utils.formatDateIfValid(props.selectedDateObservation, 'yyyy-MM-dd')} locale="fr-FR" presentation="date" id="dateObservation" ref={dateObservation}>
                                                <IonButtons slot="buttons">
                                                    <IonButton color="primary" onClick={cancelDateObservation}>
                                                        Annuler
                                                    </IonButton>
                                                    <IonButton color="primary" onClick={confirmDateObservation}>
                                                        Confirmer
                                                    </IonButton>
                                                </IonButtons>
                                            </IonDatetime>
                                        </IonModal>
                                    </IonCol>
                                    <IonCol size="4" sizeXl="4" size-lg="4" size-md="4" size-sm="12" size-xs="12" >
                                        <TimePicker
                                            title='De'
                                            onChange={props.onChangeHourFromObservation}
                                            value={props.hourFromObservation}
                                        />
                                    </IonCol>

                                    <IonCol size="4" sizeXl="4" size-lg="4" size-md="4" size-sm="12" size-xs="12" >
                                        <TimePicker
                                            title='à'
                                            onChange={props.onChangeHourToObservation}
                                            value={props.hourToObservation}
                                        />
                                    </IonCol>
                                </IonRow>
                            </IonGrid>
                        </div>

                        <div className="digital-report-section">
                            <div className="digital-report-home-section-title">
                                Fréquence
                            </div>

                            <IonRadioGroup value={props.selectedFrequency} onIonChange={props.onChangeFrequencyRadio}>
                                {
                                    Object.keys(props.frequencies).map((frequencyKey) => {
                                        return (
                                            <IonItem key={frequencyKey}>
                                                <IonLabel>{props.frequencies[frequencyKey]}</IonLabel>
                                                <IonRadio slot="start" value={frequencyKey} />
                                            </IonItem>
                                        )
                                    })
                                }
                            </IonRadioGroup>
                        </div>

                        <div className="digital-report-section">
                            <div className="digital-report-home-section-title">
                                Locaux bailleurs
                            </div>
                            <IonGrid>
                                <IonRow>
                                    {
                                        Object.keys(props.lessorLocals).map((key) => {
                                            return (
                                                <IonCol key={key} size="6" sizeXl="6" size-lg="6" size-md="6" size-sm="12" size-xs="12" >
                                                    <IonItem className="gdh-top-label-item">
                                                        <IonLabel>{props.lessorLocals[key]}</IonLabel>
                                                        <IonCheckbox value={key} slot="start"
                                                            checked={props.selectedLessorLocals.includes(key)}
                                                            onIonChange={props.onChangeLessorLocalsCheckbox}
                                                        />
                                                    </IonItem>
                                                </IonCol>
                                            )
                                        })
                                    }
                                </IonRow>
                            </IonGrid>
                        </div>

                    </IonCol>

                    <IonCol size="6" sizeXl="6" size-lg="6" size-md="6" size-sm="12" size-xs="12" className="digital-report-home-col" >

                        <div className="digital-report-section">
                            <div className="digital-report-home-section-title">
                                Parties privatives
                            </div>

                            <IonGrid>
                                <IonRow>
                                    {
                                        Object.keys(props.privateRooms).map((privateRoomKey) => {
                                            return (
                                                <IonCol key={privateRoomKey} size="6" sizeXl="6" size-lg="6" size-md="6" size-sm="12" size-xs="12" >
                                                    <IonItem className="gdh-top-label-item">
                                                        <IonLabel>{props.privateRooms[privateRoomKey]}</IonLabel>
                                                        <IonCheckbox value={privateRoomKey} slot="start"
                                                            checked={props.selectedPrivateRooms.includes(privateRoomKey)}
                                                            onIonChange={props.onChangePrivateRoomCheckbox}
                                                        />
                                                    </IonItem>
                                                </IonCol>
                                            )
                                        })
                                    }
                                </IonRow>
                            </IonGrid>
                        </div>

                        <div className="digital-report-section">
                            <div className="digital-report-home-section-title">
                                Parties communes intérieures
                            </div>
                            <IonGrid>
                                <IonRow>
                                    {
                                        Object.keys(props.sharedRoomsInterior).map((sharedRoomKey) => {
                                            return (
                                                <IonCol key={sharedRoomKey} size="6" sizeXl="6" size-lg="6" size-md="6" size-sm="12" size-xs="12" >
                                                    <IonItem className="gdh-top-label-item">
                                                        <IonLabel>{props.sharedRoomsInterior[sharedRoomKey]}</IonLabel>
                                                        <IonCheckbox value={sharedRoomKey} slot="start"
                                                            checked={props.selectedSharedRoomsInterior.includes(sharedRoomKey)}
                                                            onIonChange={props.onChangeSharedRoomInterCheckbox} />
                                                    </IonItem>
                                                </IonCol>
                                            )
                                        })
                                    }
                                </IonRow>
                            </IonGrid>
                        </div>

                        <div className="digital-report-section">
                            <div className="digital-report-home-section-title">
                                Parties communes extérieures
                            </div>
                            <IonGrid>
                                <IonRow>
                                    {
                                        Object.keys(props.sharedRoomsExterior).map((sharedRoomKey) => {
                                            return (
                                                <IonCol key={sharedRoomKey} size="6" sizeXl="6" size-lg="6" size-md="6" size-sm="12" size-xs="12" >
                                                    <IonItem className="gdh-top-label-item">
                                                        <IonLabel>{props.sharedRoomsExterior[sharedRoomKey]}</IonLabel>
                                                        <IonCheckbox value={sharedRoomKey} slot="start"
                                                            checked={props.selectedSharedRoomsExterior.includes(sharedRoomKey)}
                                                            onIonChange={props.onChangeSharedRoomExterCheckbox}
                                                        />
                                                    </IonItem>
                                                </IonCol>
                                            )
                                        })
                                    }
                                </IonRow>
                            </IonGrid>
                        </div>
                    </IonCol>
                </IonRow>
            </IonGrid >
        </>
    )


}