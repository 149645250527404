import React from "react";
import RightMenu from "../../../menu/right-menu/right-menu";

export function WebqueryRightMenu(props: any) {
    return (
        <RightMenu menus={[{
            name: "Catégories",
            indicAppInterne: true,
            url: "/webquery/categories",
            faIcon: "fas fa-folder-tree",
            code: "categories",
        }]} />
    )
}