import React from 'react';
import { useHistory } from 'react-router-dom';
import { ApplicationData } from '../../interfaces/application-data';
import { Browser } from '@capacitor/browser';

import './folder-card.css';

type FolderCardProps = {
    title?: string,
    links?: ApplicationData[],
    faicon?: string,
    greyBackground?: boolean
}

export function FolderCard(props: FolderCardProps) {

    let title = props.title ?? "sans titre";
    let links = props.links ?? [];
    let faicon = props.faicon ?? "fas fa-file";
    let cardColorClass = props.greyBackground ? "folder-card-inner-grey" : "";

    let history = useHistory();

    const onClickLink = (link: ApplicationData) => {
        if (!link.url) {
            console.warn("no url on link " + link.name);
            return;
        }

        if (link.indicAppInterne === false) {
            Browser.open({ url: link.url, windowName: '_blank' });
        } else {
            history.push(link.url);
        }
    }

    return (
        <div className="folder-card-container">
            <div className={cardColorClass + " folder-card-inner"}>
                <i className={faicon + " folder-card-faicon"} />
                <div className="folder-card-title">
                    {title}
                </div>
                <div className="folder-card-buttons">
                    {
                        links.map((link: ApplicationData) => {
                            return (
                                <div onClick={() => onClickLink(link)} key={link.code} className="folder-card-button">
                                    <span>
                                        {link.name}
                                    </span>
                                </div>
                            )
                        })
                    }
                </div>

            </div>
        </div>
    )
}
