import 'date-fns';
import React from 'react';
import { DatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from 'dayjs';

type DatePickerProps = {
    minDate?: Date,
    maxDate?: Date,
    handleDateChange: (date: Date | null) => void,
    selectedDate: Date | null,
    title: string
}

// TODO : DO NOT USE THIS COMPONENT, USE ion-datetime-button ISTEAD (FOR PAYMENT HOME)
export function GDHDatePicker(props: DatePickerProps) {

    // call the parent handler only on not null dates
    const handleDateChange = (date: dayjs.Dayjs | null) => {
        props.handleDateChange(date ? date.toDate() : null);
    }

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
                // dayjs(undefined) returns today, so we need to check if minDate/maxDate is undefined before passing it to dayjs
                // otherwise it would block the user from selecting a date different than today
                minDate={props.maxDate ? dayjs(props.minDate) : undefined}
                maxDate={props.maxDate ? dayjs(props.maxDate) : undefined}
                label={props.title}
                format="DD/MM/YYYY"
                value={dayjs(props.selectedDate)}
                onChange={handleDateChange}
            />
        </LocalizationProvider>
    )
}
