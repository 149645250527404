import React, { useCallback, useEffect, useRef } from 'react';

import mapboxgl from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import './mapbox-map.css';
import { LatLngTuple } from 'leaflet';
import { FeatureCollection } from 'geojson'

import { MapProps, MarkerData, DEFAULT_ZOOM, getMapCenter, invertCoords, markerDataToGeoJson } from '../map';


export function MapboxMap(props: MapProps) {

    const initialized = useRef(false);

    const mapIsLoaded = useRef(false);

    const map = useRef<mapboxgl.Map | null>(null);

    const unclusteredPointCLickListener = useRef<any>(null);

    const clusterClickListener = useRef<any>(null);

    const onLoad = useCallback(() => {
        mapIsLoaded.current = true;
        if (!map.current) {
            console.error("unable to react to map load beacuse map is null");
            return;
        }

        map.current.resize();
    }, []);


    const onMouseenterUnclusteredPoint = useCallback(() => {
        if (!map.current) {
            return;
        }
        map.current.getCanvas().style.cursor = 'pointer';
    }, []);

    const onMouseleaveUnclusteredPoint = useCallback(() => {
        if (!map.current) {
            return;
        }
        map.current.getCanvas().style.cursor = '';
    }, []);

    const onMouseenterClusters = useCallback(() => {
        if (!map.current) {
            return;
        }
        map.current.getCanvas().style.cursor = 'pointer';
    }, []);


    const onMouseleaveClusters = useCallback(() => {
        if (!map.current) {
            return;
        }
        map.current.getCanvas().style.cursor = '';
    }, []);

    // /!\ this function shall only be called after the map is loaded
    const addMarkersWhenMapIsLoaded = useCallback(() => {
        if (!map.current || !props.markers) {
            return;
        }

        // remove the evenet listener
        if (unclusteredPointCLickListener.current != null) {
            map.current.off('click', 'unclustered-point', unclusteredPointCLickListener.current);
        }

        if (clusterClickListener.current != null) {
            map.current.off('click', 'clusters', clusterClickListener.current);
        }

        // if the source and the layers have already been added, remove them before adding them again
        if (map.current.getLayer('clusters')) map.current.removeLayer('clusters');
        if (map.current.getLayer('unclustered-point')) map.current.removeLayer('unclustered-point');
        if (map.current.getLayer('cluster-count')) map.current.removeLayer('cluster-count');
        if (map.current.getSource('campaignsSource')) map.current.removeSource('campaignsSource');

        map.current.setCenter(invertCoords(getMapCenter(props)));

        let geoJsonData: FeatureCollection = {
            type: "FeatureCollection",
            features: props.markers.map(markerDataToGeoJson)
        }

        // Add a new source from our GeoJSON data and
        // set the 'cluster' option to true. GL-JS will
        // add the point_count property to your source data.
        map.current.addSource('campaignsSource', {
            type: 'geojson',
            // Point to GeoJSON data. This example visualizes all M1.0+ earthquakes
            // from 12/22/15 to 1/21/16 as logged by USGS' Earthquake hazards program.
            // data:'https://docs.mapbox.com/mapbox-gl-js/assets/earthquakes.geojson',
            data: geoJsonData,
            cluster: true,
            clusterMaxZoom: 14, // Max zoom to cluster points on
            clusterRadius: 50 // Radius of each cluster when clustering points (defaults to 50)
        });

        map.current.addLayer({
            id: 'clusters',
            type: 'circle',
            source: 'campaignsSource',
            filter: ['has', 'point_count'],
            paint: {
                // Use step expressions (https://docs.mapbox.com/mapbox-gl-js/style-spec/#expressions-step)
                // with three steps to implement three types of circles:
                //   * Blue, 20px circles when point count is less than 5
                //   * Yellow, 30px circles when point count is between 5 and 20
                //   * Pink, 40px circles when point count is greater than or equal to 20
                'circle-color': [
                    'step',
                    ['get', 'point_count'],
                    '#51bbd6',
                    5,
                    '#f1f075',
                    20,
                    '#f28cb1'
                ],
                'circle-radius': [
                    'step',
                    ['get', 'point_count'],
                    20,
                    5,
                    30,
                    20,
                    40
                ]
            }
        });

        map.current.addLayer({
            id: 'cluster-count',
            type: 'symbol',
            source: 'campaignsSource',
            filter: ['has', 'point_count'],
            layout: {
                'text-field': '{point_count_abbreviated}',
                'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
                'text-size': 12
            }
        });

        map.current.addLayer({
            id: 'unclustered-point',
            type: 'circle',
            source: 'campaignsSource',
            filter: ['!', ['has', 'point_count']],
            paint: {
                'circle-color': '#11b4da',
                'circle-radius': 12,
                'circle-stroke-width': 1,
                'circle-stroke-color': '#fff'
            }
        });

        // inspect a cluster on click
        clusterClickListener.current = onClickCluster;
        map.current.on('click', 'clusters', clusterClickListener.current);

        // When a click event occurs on a feature in
        // the unclustered-point layer, open a popup at
        // the location of the feature, with
        // description HTML from its properties.
        unclusteredPointCLickListener.current = onClickUnclusteredPoint;
        map.current.on('click', 'unclustered-point', unclusteredPointCLickListener.current);

        // FIXME : fix the deps issue
        // eslint-disable-next-line
    }, [props])

    useEffect(() => {
        initialize();
        addMarkers();

        // FIXME : fix the deps issue
        // eslint-disable-next-line
    }, [props])

    useEffect(() => {
        return () => {
            map.current?.off('load', onLoad);
            map.current?.off('mouseenter', 'unclustered-point', onMouseenterUnclusteredPoint);
            map.current?.off('mouseleave', 'unclustered-point', onMouseleaveUnclusteredPoint);
            map.current?.off('mouseenter', 'clusters', onMouseenterClusters);
            map.current?.off('mouseleave', 'clusters', onMouseleaveClusters);
            map.current?.off('click', 'clusters', clusterClickListener.current);
            map.current?.off('click', 'unclustered-point', unclusteredPointCLickListener.current);
            map.current?.off('load', addMarkersWhenMapIsLoaded);
            map.current?.remove();
        }

        // FIXME : fix the deps issue
        // eslint-disable-next-line
    }, [])


    function initialize() {
        if (initialized.current) {
            return;
        }

        initialized.current = true;

        let centerLongLat: LatLngTuple = invertCoords(getMapCenter(props));

        mapboxgl.accessToken = 'pk.eyJ1IjoiZGVsZXV6ZSIsImEiOiJja2UycjJzdHcwYnYwMnVwNndnN3k3cjQzIn0.o2CziA3_gTZSVhAKjw3aqQ';
        map.current = new mapboxgl.Map({
            container: 'mapbox-map',
            style: 'mapbox://styles/mapbox/streets-v11', // stylesheet location
            maxZoom: 15,
            minZoom: 6,
            maxBounds: [
                [-5., 41.],
                [11., 53.]
            ],
            // dragPan: !IonicUtils.isMobile(),
            // dragRotate: false,
            // pitchWithRotate: !IonicUtils.isMobile(),
            attributionControl: false,
            center: centerLongLat,
            zoom: props.zoom ? props.zoom : DEFAULT_ZOOM
        });

        map.current.addControl(new mapboxgl.NavigationControl({
            showCompass: false
        }));

        map.current.scrollZoom.disable();

        map.current.on('load', onLoad);
        map.current.on('mouseenter', 'unclustered-point', onMouseenterUnclusteredPoint);
        map.current.on('mouseleave', 'unclustered-point', onMouseleaveUnclusteredPoint);
        map.current.on('mouseenter', 'clusters', onMouseenterClusters);
        map.current.on('mouseleave', 'clusters', onMouseleaveClusters);
    }

    function getMarkerData(id: number): MarkerData | null {
        if (!props.markers) {
            return null;
        }

        for (let marker of props.markers) {
            if (marker.id === id) {
                return marker;
            }
        }
        return null;
    }



    function onClickCluster(e: any) {
        let features = map.current?.queryRenderedFeatures(e.point, {
            layers: ['clusters']
        });

        if (!features || !features[0] || !features[0].properties) {
            console.error("unable trigger cluster click because either feature[0] or features[0].properties is null");
            return;
        }

        let clusterId = features[0].properties.cluster_id;
        let source = map.current?.getSource('campaignsSource') as any;
        source?.getClusterExpansionZoom(
            clusterId,
            (err: any, zoom: any) => {
                if (err || !features) return;

                let geom = features[0].geometry as any;
                map.current?.easeTo({
                    center: geom.coordinates,
                    zoom: zoom
                });
            }
        );

    }

    function onClickUnclusteredPoint(e: any) {
        if (!map.current || !e || !e.features || !e.features[0] || !e.features[0].geometry
            || !e.features[0].properties || !e.features[0].properties.id) {
            return;
        }
        let feature = e.features[0];

        let markerData = getMarkerData(feature.properties.id);

        if (!markerData || !markerData.onPopupClickParam || !markerData.onPopupClickParam) {
            return;
        }

        let coordinates = feature.geometry.coordinates.slice();

        // Ensure that if the map is zoomed out such that
        // multiple copies of the feature are visible, the
        // popup appears over the copy being pointed to.
        while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
            coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
        }

        let popup = new mapboxgl.Popup({ closeButton: false })
            .setLngLat(coordinates)
            .setHTML("<h1 id=\"popup\" >" + markerData.popupContent + "</h1>")
            .addTo(map.current);

        let onClickPopupCallback = () => {
            markerData?.onPopupClick(markerData.onPopupClickParam);
        }

        popup.getElement()?.addEventListener("click", onClickPopupCallback, { once: true });
    }


    // it is necessecary to remove and add event listener and layers when the props are modified, to avoid event listener beeing called with outdated data
    function addMarkers() {
        if (!map.current || !props.markers || !initialized.current) {
            return;
        }

        if (mapIsLoaded.current) {
            addMarkersWhenMapIsLoaded();
        } else {
            map.current.on('load', addMarkersWhenMapIsLoaded);
        }
    }

    return <div id='mapbox-map' />
}


