import React, { useEffect, useState, useRef } from 'react';
import {
    IonMenu,
    IonHeader,
    IonContent,
    IonList,
    IonToolbar
} from '@ionic/react';
import '../menu.css';
import './right-menu.css';

import DataService from '../../../services/data-service';
import ConfigUtils from '../../../utils/config-utils';
import MenuItem from '../menu-item/menu-item';
import { StorageUtils } from '../../../utils/storage-utils';
import { AuthentificationService } from '../../../services/authentification-service';
import { Avatar } from '../../avatar/avatar';
import { HostData } from '../../../interfaces/host-data';
import { UserData } from '../../../interfaces/user-data';
import { Browser } from '@capacitor/browser';
import { useHistory } from "react-router";

const logoutItemData: HostData = {
    name: 'Application interne',
    urls: [
        {
            name: 'Déconnexion',
            indicAppInterne: true,
            url: '/signin',
            faIcon: 'fas fa-sign-out-alt',
            code: 'logout',
            beforeRedirectCallback: AuthentificationService.logout
        }
    ],
}
// TODO : right-menu.css is a duplicate of Menu.css

export default function RightMenu(props: any) {
    const [_user, _setUser] = useState<UserData | null>(null)
    const [_avatarUrl, _setAvatarUrl] = useState<string | undefined>(undefined)

    const _ref = useRef<any>(null);
    const isMounted = useRef(true)
    const _history = useHistory();

    useEffect(() => {
        fetchData()
        return () => { isMounted.current = false }
    }, [])

    // FIXME :  this function is duplicated in left-menu.tsx. Both menus should be refactored / merged into one component
    const onClickItem = async (itemCode: string) => {

        // close the menu before redirecting to avoid an ionic crash
        // the second parameter is set to false to avoid the menu to be animated
        const success = await _ref.current?.setOpen(false, false)
        if (!success) {
            console.error("the menu could not be closed")
            return
        }

        let clickedItem: any

        if (itemCode === 'logout') {
            clickedItem = logoutItemData.urls[0]
        } else if (props?.menus?.length > 0) {
            // for each menu group
            props.menus.every((element: any) => {

                // check if the element is a link eg. the acceuil btn
                if (element?.code === itemCode) {
                    clickedItem = element
                    return false
                }

                // if the group has children
                if (element?.children?.length > 0) {
                    // we search if the clicked item is in the children
                    clickedItem = element.children.find((child: any) => {
                        return (child?.code === itemCode)
                    })
                    if (clickedItem) {
                        // if we found the element, return false to stop the loop
                        return false
                    }
                }
                // return true to continue the loop
                return true
            })
        }

        if (!clickedItem) {
            console.error("the clicked element was not found, code : ", itemCode)
            return
        }

        // this callback is used to do some action before the redirection. Like for exemple logout the user when redirected to the signin page
        if (clickedItem.beforeRedirectCallback) {
            await clickedItem.beforeRedirectCallback();
        }

        if (clickedItem.indicAppInterne === false) {
            Browser.open({ url: clickedItem.url, windowName: '_blank' });
        } else {
            _history.push(clickedItem.url);
        }
    }

    let fetchData = async () => {
        try {
            const userId: string | null = await StorageUtils.getInstance().getUserId()
            if (userId) {

                const users = await DataService.getEmployeeInfo(userId)
                let actualUser = null

                if (users.length > 0) {
                    actualUser = users[0]
                }

                let UserAvatarUrl = ''
                const employeeAvatarUrl = await DataService.getEmployeeAvatarUrl(+userId)
                if (employeeAvatarUrl) {
                    UserAvatarUrl = employeeAvatarUrl
                }

                if (isMounted.current) {
                    _setUser(actualUser)
                    _setAvatarUrl(UserAvatarUrl)
                }

            }
        } catch (error) {
            console.error(error)
        }
    }

    return (
        <IonMenu ref={_ref} className="right-menu-ion-menu" contentId="content" type="push" menuId="right" side="end">
            <IonHeader>
                {/* This is a workaround, so the content does not go under the main toolbar and gets hidden. */}
                <IonToolbar />
            </IonHeader>
            <div className="menu-logo-container menu-logo-container__right">
                <div className="right-menu-logo-container-inner">
                    <Avatar
                        avatarUrl={_avatarUrl}
                        firstname={_user?.firstname}
                        lastname={_user?.lastname}
                        className="right-menu-avatar"
                    />
                </div>
                {
                    _user &&
                    <div className="right-menu-user-name">{_user.firstname} {_user.lastname}</div>
                }
            </div>
            <IonContent className="right-menu-content">
                {/* FIXME: the rightmenu appears under the footer, I added a margin so it is possible to scroll to the end of the items */}
                <IonList className="right-menu-list">
                    {
                        props.menus &&
                        props.menus.map((menu: any, index: any) => {
                            return (
                                <MenuItem
                                    key={index}
                                    itemClassName="right-menu-item"
                                    name={menu.name}
                                    indicAppInterne={menu.indicAppInterne}
                                    url={menu.url}
                                    faIcon={menu.faIcon + " right-menu-faicon"}
                                    depth={0}
                                    onClick={onClickItem}
                                    code={menu.code}
                                />
                            )
                        })
                    }

                    <MenuItem
                        itemClassName="right-menu-item"
                        name={logoutItemData.urls[0].name}
                        indicAppInterne={logoutItemData.urls[0].indicAppInterne}
                        url={logoutItemData.urls[0].url}
                        faIcon={logoutItemData.urls[0].faIcon + " right-menu-faicon"}
                        beforeRedirectCallback={logoutItemData.urls[0].beforeRedirectCallback}
                        depth={0}
                        onClick={onClickItem}
                        code={logoutItemData.urls[0].code}
                    />

                </IonList>
                <div className="right-menu-version-footer" >
                    version {ConfigUtils.getApplicationVersion()}
                </div>
            </IonContent>
        </IonMenu>
    );

}