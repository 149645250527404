

export class PdfUtils {


    /**
     * Count the number of occurence of the same file name between two arrays
     * @param arrA {File[]} the first array
     * @param arrB {File[]} the second array
     *
     * @return {number} the number of occurence of the same file name between two arrays
     */
    public static countMaxOccurenceOfFileName(arrA: File[], arrB: File[]): number {
        let maxOccurence = 0;
        for (let fileA of arrA) {
            // check for length superior to one because it will at least match with itself
            let nbOccurence = arrB.filter((fileB: File) => { return (fileA.name === fileB.name) }).length;
            if (nbOccurence > maxOccurence) {
                maxOccurence = nbOccurence;
            }
        }
        return maxOccurence;
    }
}