import React, { useEffect } from 'react';
import { Route, Switch } from 'react-router-dom';
import {
	IonApp, IonRouterOutlet, setupIonicReact
} from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';


import ConfigUtils from './utils/config-utils';
import Signin from './pages/signin/Signin';
import FlashHome from './pages/Applications/flash/flash-home/FlashHome';
import AddFlash from './pages/Applications/flash/add-flash/add-flash';
import AddTarget from './pages/Applications/flash/add-target/add-target';
import WebqueryHome from './pages/Applications/webquery/home/webquery-home';
import Home from './pages/home/home';
import { WebqueryCategory } from './pages/Applications/webquery/category/webquery-category';
import { WebqueryExtraction } from './pages/Applications/webquery/extraction/webquery-extraction';
import { SMSDetails } from './pages/Applications/flash/sms-details/sms-details';
import CampaignDetails from './pages/Applications/flash/campaign-details/campaign-details';
import CampaignTargets from './pages/Applications/flash/campaign-targets/campaign-targets';
import { CampaignComments } from './pages/Applications/flash/campaign-comments/campaign-comments';
import { Sms } from './pages/Applications/flash/sms/sms';
import { InMail } from './pages/Applications/flash/in-mail/in-mail';
import { NoApplication } from './pages/no-application/no-application';
import { useSelector } from 'react-redux';
import { getEnabledAppsState, getUserState } from './store/redux-store';
import { RedirectToHome } from './navigation/redirect-to-home';
import { AuthentificationService } from './services/authentification-service';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

// css utils
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';


/* Theme variables */
import './theme/variables.css';

/* Global App style */
import './App.css';
import { AppUrlListener } from './navigation/app-url-listener';
import { Notifications } from './pages/notifications/notificatons';

// import font awesome css
import '../node_modules/@fortawesome/fontawesome-pro/css/all.css';
import { WebqueryCategories } from './pages/Applications/webquery/categories/webquery-categories';
import { GAUrlListener } from './navigation/ga-url-listener';

import { PdfHome } from './pages/Applications/pdf/pdf-home/pdf-home';
import { PdfMerge } from './pages/Applications/pdf/pdf-merge/pdf-merge';
import { PdfSplit } from './pages/Applications/pdf/pdf-split/pdf-split';
import { PdfConvertAndMerge } from './pages/Applications/pdf/pdf-convert-and-merge/pdf-convert-and-merge';
import { PaymentHome } from './pages/Applications/payment/payment-home/payment-home';
import { DigitalReportHome } from './pages/Applications/digital-report/digital-report-home/digital-report-home';
import { DigitalAttributionHome } from './pages/Applications/digital-attribution/digital-attribution-home/digital-attribution-home';
import { DocusignHome } from './pages/Applications/docusgin/docusign-home/docusign-home';
import { CTIHome } from './pages/Applications/CTI/CTI-home/CTI-home';
import { CTISearch } from './pages/Applications/CTI/CTI-search/CTI-search';
import { ThemeProvider, createTheme } from '@mui/material';
import { PaymentRedirect } from './pages/Applications/payment/payment-home/payment-redirect';

type Page = {
	name: string,
	application: string,
	path: string,
	component: React.ComponentType<any>,
	userOnly: boolean,
	guestOnly?: boolean,
	exactPath?: boolean
}

const pages: Page[] = [
	{
		name: 'signin',
		application: '',
		path: '/signin',
		component: Signin,
		userOnly: false,
		guestOnly: true,
		exactPath: true
	},
	{
		name: 'home',
		application: 'HOME',
		path: '/home',
		component: Home,
		userOnly: true
	},
	{
		name: 'no appication',
		application: '',
		path: '/no-application',
		component: NoApplication,
		userOnly: true,
		exactPath: true
	},
	{
		name: 'notifications',
		application: '',
		path: '/notifications',
		component: Notifications,
		userOnly: true,
		exactPath: true
	},




	{
		name: 'flash home',
		application: 'FLASH',
		path: ConfigUtils.getFlashBaseURL(),
		component: FlashHome,
		userOnly: true,
		exactPath: true
	},
	{
		name: 'flash add campaign',
		application: 'FLASH',
		path: ConfigUtils.getFlashBaseURL() + "/addFlash",
		component: AddFlash,
		userOnly: true
	},
	{
		name: 'flash add target',
		application: 'FLASH',
		path: ConfigUtils.getFlashBaseURL() + "/addTarget/:id",
		component: AddTarget,
		userOnly: true
	},
	{
		name: 'flash sms details',
		application: 'FLASH',
		path: ConfigUtils.getFlashBaseURL() + "/:campaignid/sms/:smsid",
		component: SMSDetails,
		userOnly: true
	},
	{
		name: 'flash campaign details',
		application: 'FLASH',
		path: ConfigUtils.getFlashBaseURL() + "/:id/edit",
		component: CampaignDetails,
		userOnly: true
	},
	{
		name: 'flash targets',
		application: 'FLASH',
		path: ConfigUtils.getFlashBaseURL() + "/:id/cibleLoc",
		component: CampaignTargets,
		userOnly: true
	},
	{
		name: 'flash comments',
		application: 'FLASH',
		path: ConfigUtils.getFlashBaseURL() + "/:id/commentaires",
		component: CampaignComments,
		userOnly: true
	},
	{
		name: 'flash sms',
		application: 'FLASH',
		path: ConfigUtils.getFlashBaseURL() + "/:id/sms",
		exactPath: true,
		component: Sms,
		userOnly: true
	},
	{
		name: 'flash inMail',
		application: 'FLASH',
		path: ConfigUtils.getFlashBaseURL() + "/:id/inMail",
		component: InMail,
		userOnly: true
	},




	{
		name: 'webquery home',
		application: 'WEBQUERY',
		path: ConfigUtils.getWebqueryBaseURL() + "/home",
		component: WebqueryHome,
		userOnly: true
	},
	{
		name: 'webquery categories',
		application: 'WEBQUERY',
		path: ConfigUtils.getWebqueryBaseURL() + "/categories",
		component: WebqueryCategories,
		userOnly: true
	},
	{
		name: 'webquery categorie',
		application: 'WEBQUERY',
		path: ConfigUtils.getWebqueryBaseURL() + "/category/:id",
		component: WebqueryCategory,
		userOnly: true
	},
	{
		name: 'webquery extraction',
		application: 'WEBQUERY',
		path: ConfigUtils.getWebqueryBaseURL() + "/extraction/:id",
		component: WebqueryExtraction,
		userOnly: true
	},





	{
		name: 'PDF home',
		application: 'PDF',
		path: "/PDF/home",
		component: PdfHome,
		userOnly: true
	},
	{
		name: 'PDF merge',
		application: 'PDF',
		path: "/PDF/merge",
		component: PdfMerge,
		userOnly: true
	},
	{
		name: 'PDF split',
		application: 'PDF',
		path: "/PDF/split",
		component: PdfSplit,
		userOnly: true
	},
	{
		name: 'PDF convert and merge',
		application: 'PDF',
		path: "/PDF/convert-and-merge",
		component: PdfConvertAndMerge,
		userOnly: true
	},




	{
		name: 'Payment home',
		application: 'PAYMENT',
		path: "/payment/home/:delayed?/:noca?",
		component: PaymentHome,
		userOnly: true
	},

	{
		name: 'Payment Redirect',
		application: 'PAYMENT',
		path: "/payment/redirect",
		component: PaymentRedirect,
		userOnly: true
	},

	{
		name: 'digital-report home',
		application: 'DIGITAL-REPORT',
		path: "/digital-report/home",
		component: DigitalReportHome,
		userOnly: true
	},

	{
		name: 'digital-attribution home',
		application: 'DIGITAL-ATTRIB',
		path: "/digital-attribution/home",
		component: DigitalAttributionHome,
		userOnly: true
	},



	{
		name: 'docusign home',
		application: 'DOCUSIGN',
		path: "/docusign/home",
		component: DocusignHome,
		userOnly: true
	},
	{

		name: 'CTI home',
		application: 'CTI',
		path: "/CTI/home/:id?",
		component: CTIHome,
		userOnly: true
	},
	{

		name: 'CTI search',
		application: 'CTI',
		path: "/CTI/search/:id?/:msg?",
		component: CTISearch,
		userOnly: true
	},






	// FIXME : this should work with 'exactPath: false' and be served as the default route when no other route matches but it matches and triggers even when a previous route has matched
	{
		name: 'unknown route',
		application: '',
		path: '*',
		// exactPath: false,
		component: UnknonwRoute,
		userOnly: false
	},
]

setupIonicReact();

const theme = createTheme();

const App: React.FC = () => {

	useEffect(() => {
		AuthentificationService.initialize();
	}, [])

	return (
		<ThemeProvider theme={theme}>
			<IonApp>
				<IonReactRouter>
					<AppUrlListener />
					{/* <FcmListener /> */}
					<GAUrlListener />
					<IonRouterOutlet>
						<Switch>
							{
								pages.map((page: Page, index: number, array: Page[]) => {
									const exact = page.exactPath ? page.exactPath : false;
									return (<Route key={page.name} exact={exact} path={page.path} component={(props: any) => <CustomRoute page={page} {...props} />} />)
								})
							}
						</Switch>
					</IonRouterOutlet>
				</IonReactRouter>
			</IonApp>
		</ThemeProvider>
	)
}





type PrivateRouteProps = {
	page: Page
}

function CustomRoute(props: PrivateRouteProps) {

	const userLogedIn = useSelector(getUserState)
	const enabledApps = useSelector(getEnabledAppsState)

	const MyComponent = props.page.component;

	if (userLogedIn === undefined) {
		return null;
	}

	if ((userLogedIn === false) && (props.page.userOnly)) {
		// do not redirect to sign to keep the url as is, and then redirect to the right page after signin
		return <Signin />
	}

	if ((userLogedIn === true) && (props.page.guestOnly)) {
		return <RedirectToHome {...props} />
	}

	if ((userLogedIn === false) && (!props.page.userOnly)) {
		return <MyComponent {...props} />
	}
	if (enabledApps.apps === undefined) {
		return null;
	}

	if ((props.page.application) && !enabledApps.apps.includes(props.page.application)) {
		return <RedirectToHome {...props} />
	}

	return <MyComponent {...props} />
}


function UnknonwRoute(props: any) {

	const userLogedIn = useSelector(getUserState)

	if (userLogedIn === undefined) {
		return null;
	}

	if (userLogedIn === false) {
		// do not redirect to sign to keep the url as is, and then redirect to the right page after signin
		return <Signin />
	}

	return <RedirectToHome />
}


export default App;